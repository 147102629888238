import { createYjsPlugin } from "@udecode/plate-yjs";
import { get_hub_url } from "api/env";
import { createAutoformatPlugin } from "@udecode/plate-autoformat";
import randomColor from "randomcolor";
import autoformatRules from "lib/plate/autoformat";
import resetNodeRules from "lib/plate/resetNodeRules";
import exitBreakRules from "lib/plate/exitBreakRules";
import components from "lib/plate/components";
import {
  createComboboxPlugin,
  createPlugins,
  ELEMENT_MENTION,
} from "@udecode/plate";
import { createEmojiPlugin, KEY_EMOJI } from "@udecode/plate-emoji";
import { createResetNodePlugin } from "@udecode/plate-reset-node";
import {
  createExitBreakPlugin,
  createSoftBreakPlugin,
} from "@udecode/plate-break";
import { createCaptionPlugin } from "@udecode/plate-caption";
import createSlashCommandsPlugin from "./slash-commands";
import {
  createCommentsPlugin,
  createSuggestionsPlugin,
  OdoEditor,
  OdoUser,
  OdoValue,
  OdoCursorData,
  createRefinePlugin,
  getCorePluginsList,
} from "odo";
import { emojiComboboxWithId } from "components/EditorView/Menus/EmojiCombobox";
import SlashCommandComboBox from "lib/plate/plugins/slash-commands/components/SlashCommandComboBox";
import {
  createImagePlugin,
  withImageEmbed,
  withImageUpload,
  ELEMENT_IMAGE,
  ELEMENT_MEDIA_EMBED,
} from "@udecode/plate-media";
import createDocTrackingPlugin from "./doc-tracking";
import {
  editorIdForCombobox,
  onChangeOdoCombobox,
} from "lib/plate/plugins/combobox/onChangeCombobox";
import { createDeletedPlugin } from "./deleted";
import transformIndentListNodeData from "./lists/transformIndentListNodeData";
import createLocalStoragePlugin from "./local-storage";
import createMentionsPlugin from "./mentions";
import { mentionsComboboxWithId } from "./mentions/components/MentionsComboBox";
import { createStylingPlugin } from "./styling/createStylingPlugin";
import createReferencesPlugin from "odo/src/references";

export function randomCursorData(user: OdoUser): OdoCursorData {
  return {
    color: randomColor({
      luminosity: "dark",
      alpha: 1,
      format: "hex",
    }),
    user: user,
  };
}

export const createOdoComboboxPlugin = () => {
  return createComboboxPlugin({
    then: (editor, plugin) => {
      if (plugin.handlers) {
        plugin.handlers.onChange = onChangeOdoCombobox;
      }
    },
  });
};

const generatePlugins = (
  docId: string,
  useLocalStorage: boolean,
  currentUser: OdoUser | null,
  uploadImage: ((dataUrl: string | File) => Promise<string | null>) | undefined,
  showToast: (content: string) => void
) => {
  let plugins = getCorePluginsList(transformIndentListNodeData).concat([
    createEmojiPlugin({
      renderAfterEditable: emojiComboboxWithId(KEY_EMOJI),
      then: (editor, plugin) => {
        editorIdForCombobox[KEY_EMOJI] = editor.id;
      },
    }),
    createSlashCommandsPlugin({
      renderAfterEditable: SlashCommandComboBox,
    }),
    createMentionsPlugin({
      renderAfterEditable: mentionsComboboxWithId(ELEMENT_MENTION),
      then: (editor, plugin) => {
        editorIdForCombobox[ELEMENT_MENTION] = editor.id;
      },
    }),
    createOdoComboboxPlugin(),
    createCommentsPlugin(),
    createDeletedPlugin(),
    createRefinePlugin(),
    createCaptionPlugin({
      options: { pluginKeys: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED] },
    }) as any,
    createImagePlugin({
      options: {
        uploadImage: uploadImage as any,
      },
      withOverrides: (editor, plugin) =>
        withImageUpload(withImageEmbed(editor, plugin), plugin),
    }),
    createExitBreakPlugin({
      options: {
        rules: exitBreakRules,
      },
    }),
    createAutoformatPlugin({
      options: {
        rules: autoformatRules,
        enableUndoOnDelete: true,
      },
    }),
    createResetNodePlugin({
      options: {
        rules: resetNodeRules,
      },
    }),
    createSuggestionsPlugin(),
    createDocTrackingPlugin(),
    createReferencesPlugin(),
    createStylingPlugin(),
  ]);

  plugins.push(
    createSoftBreakPlugin({
      options: {
        rules: [{ hotkey: "shift+enter" }, { hotkey: "command+enter" }],
      },
    })
  );

  if (useLocalStorage) {
    plugins.push(
      // @ts-ignore
      createLocalStoragePlugin({
        options: {
          docId: docId,
        },
      })
    );
  } else {
    plugins.push(
      createYjsPlugin({
        renderAboveEditable: null,
        options: {
          hocuspocusProviderOptions: {
            url: get_hub_url("ws", ""),
            name: docId,
            token: "insufficient-secret",
            preserveConnection: false,
            connect: false,
          },
          disableCursors: false,
          cursorOptions: currentUser
            ? {
                data: randomCursorData(currentUser),
              }
            : {},
        },
      })
    );
  }

  return createPlugins<OdoValue, OdoEditor>(plugins, {
    components,
  });
};

export default generatePlugins;
