import DeprecatedButton from "./DeprecatedButton";
import CurrentUser from "components/CurrentUser";
import { useOptionalAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import OdoLogo from "./OdoLogo";
import { cn } from "lib/utils";
import Rows from "./containers/Rows";
import Columns from "./containers/Columns";
import Spacer from "./containers/Spacer";
import {
  CollapsableContainer,
  CollapsableContent,
  CollapsedContent,
} from "./containers/CollapsableContainer";
import {
  DeprecatedDropdownMenu,
  DeprecatedDropdownMenuContent,
  DeprecatedDropdownMenuItem,
  DeprecatedDropdownMenuTrigger,
} from "components/EditorView/Menus/DeprecatedDropdownMenu";
import Icon from "./Icon";

type PageType = "proposals" | "rfps" | "final-check" | "lists";

interface NavigationViewProps {
  children?: React.ReactNode;
  selected?: PageType;
  containerClassName?: string;
  fullWidth?: boolean;
  scrollable?: boolean;
  padContent?: boolean;

  // Elements next to page links
  extraElements?: React.ReactNode;
}

const ORDERED_PAGES: {
  type: PageType;
  text: string;
  path: string;
  staffOnly?: boolean;
}[] = [
  {
    type: "rfps",
    text: "RFPs",
    path: "/",
  },
  {
    type: "lists",
    text: "Lists",
    path: "/lists/",
  },
  {
    type: "proposals",
    text: "Proposals",
    path: "/proposals/",
  },
  {
    type: "final-check",
    text: "Final Check",
    path: "/final-check/",
  },
];

const NavigationView: FC<NavigationViewProps> = (props) => {
  const {
    children,
    containerClassName,
    fullWidth = false,
    scrollable = true,
    padContent = true,
  } = props;

  return (
    <Rows className="grow mt-4xl">
      {/* Ordered at bottom to show up above main content */}
      <NavigationHeader {...props} />
      <Rows
        className={cn(
          "grow items-center relative mt-[1px]",
          scrollable && "overflow-auto",
          padContent && "p-lg",
          containerClassName
        )}
      >
        <Rows
          className={cn(
            "grow w-full",
            !fullWidth && "max-w-[950px]",
            scrollable && "overflow-visible"
          )}
        >
          {children}
        </Rows>
      </Rows>
    </Rows>
  );
};

const NavigationHeader: FC<NavigationViewProps> = ({
  selected,
  extraElements,
}) => {
  const navigate = useNavigate();
  const user = useOptionalAuthenticatedUser();
  return (
    <Columns className="border-b px-lg justify-center fixed top-0 left-0 right-0 bg-background">
      <Columns className="grow gap-lg max-w-[950px] items-center">
        <CollapsableContainer className="grow">
          <CollapsedContent>
            <Columns className="items-center gap-lg absolute inset-0">
              <div>
                <DeprecatedDropdownMenu>
                  <DeprecatedDropdownMenuTrigger>
                    <Icon name="bars" size="large" className="" />
                  </DeprecatedDropdownMenuTrigger>
                  <DeprecatedDropdownMenuContent className="ml-sm">
                    {ORDERED_PAGES.map((page) => {
                      if (page.staffOnly && !(user?.isStaff ?? false)) {
                        return null;
                      }
                      return (
                        <DeprecatedDropdownMenuItem
                          key={page.type}
                          onClick={() => navigate(page.path)}
                          className={cn(
                            selected === page.type &&
                              "bg-background-secondary font-semibold",
                            page.staffOnly && "admin text-primary"
                          )}
                        >
                          {page.text}
                        </DeprecatedDropdownMenuItem>
                      );
                    })}
                  </DeprecatedDropdownMenuContent>
                </DeprecatedDropdownMenu>
              </div>
              <OdoLogo size="small" className="grow" />
              <CurrentUser />
            </Columns>
          </CollapsedContent>
          <CollapsableContent>
            <Columns className="items-center gap-lg grow shrink-0">
              <OdoLogo size="small" path="/" className="-ml-8" />
              <Columns className="overflow-x-auto">
                {ORDERED_PAGES.map((page) => {
                  if (page.staffOnly && !(user?.isStaff ?? false)) {
                    return null;
                  }
                  return (
                    <DeprecatedButton
                      key={page.type}
                      text={page.text}
                      disabled={selected === page.type}
                      variant="navigation"
                      className={
                        page.staffOnly
                          ? "admin text-primary whitespace-nowrap"
                          : ""
                      }
                      navigate={page.path}
                    />
                  );
                })}
                {extraElements}
              </Columns>
              <Spacer />
              <CurrentUser />
            </Columns>
          </CollapsableContent>
        </CollapsableContainer>
      </Columns>
    </Columns>
  );
};

export default NavigationView;
