import { useCallback, useContext } from "react";
import { AlertProviderContext } from "../AlertProvider";
import { AlertButton, RenderBody } from "./types";
import DeprecatedButton, {
  DeprecatedButtonProps,
} from "components/common/DeprecatedButton";
import Rows from "components/common/containers/Rows";
import { IconName } from "components/common/Icon";
import Spacer from "components/common/containers/Spacer";

export interface ChoiceButton {
  text: string;
  id: string;
  icon?: IconName;
  variant?: DeprecatedButtonProps["variant"];
  tooltip?: string;
}

export type Choice = ChoiceButton | "spacer";

interface ChooseOptions {
  body?: RenderBody;
  choices: Choice[];
  dismissId?: string;
  variant?: "buttons" | "list";
}

export const useChoose = () => {
  const context = useContext(AlertProviderContext);
  if (!context) {
    throw new Error("useChoose must be used within an AlertProvider");
  }
  const show = context.show;
  return useCallback(
    async (message: string, options: ChooseOptions) => {
      if (options.variant === "buttons" && options.choices.length <= 2) {
        const buttons: AlertButton[] = options.choices.map((choice) =>
          choice === "spacer"
            ? "spacer"
            : {
                text: choice.text,
                variant: choice.variant ?? "solid-secondary",
                icon: choice.icon,
                id: choice.id,
                tooltip: choice.tooltip,
              }
        );
        return await show(message, {
          body: options.body,
          buttons,
          buttonOrientation:
            options.choices.length > 2 ? "vertical" : "horizontal",
          dismissId: options.dismissId,
        });
      } else {
        return await show(message, {
          body: (resolve) => (
            <ChooseList
              choices={options.choices}
              dismissId={options.dismissId}
              resolve={resolve}
              options={options}
            />
          ),
          buttons: [],
        });
      }
    },
    [show]
  );
};

interface ChooseListProps {
  choices: Choice[];
  dismissId?: string;
  resolve: (id: string) => void;
  options: ChooseOptions;
}

const ChooseList: React.FC<ChooseListProps> = ({
  choices,
  dismissId,
  options,
  resolve,
}) => {
  return (
    <Rows className="grow -mt-xs">
      {typeof options.body === "function"
        ? options.body(resolve)
        : options.body}
      {choices.map((choice) =>
        choice === "spacer" ? (
          <Spacer />
        ) : (
          <DeprecatedButton
            key={choice.id}
            {...choice}
            className="justify-start hover:bg-background-selected hover:opacity-100 rounded-sm"
            onClick={() => resolve(choice.id)}
          />
        )
      )}
      {dismissId && (
        <DeprecatedButton
          text="Cancel"
          variant="solid-secondary"
          className="mt-sm"
          onClick={() => {
            if (!dismissId) return;
            resolve(dismissId);
          }}
        />
      )}
    </Rows>
  );
};
