import { odoToast } from "./odoToast";

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  odoToast.success({
    title: "Copied",
    icon: "clipboard-list",
    text: "Text copied to clipboard",
  });
};
