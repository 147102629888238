import { cn } from "lib/utils";
import React from "react";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Slot } from "@radix-ui/react-slot";

/**
 * Stack children vertically (z-index)
 * The component will be sized according to the largest child element using CSS Grid.
 * All children will be stretched to match the size of the largest child.
 */
const Stack = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ children, className, ...props }, ref) => {
    const childrenArray = React.Children.toArray(children);

    if (childrenArray.length === 0) {
      return <div ref={ref} className={cn("relative", className)} {...props} />;
    }

    return (
      <div
        ref={ref}
        className={cn("grid relative overflow-hidden", className)}
        // Grid with a single cell - all children will be placed in this cell
        style={{
          gridTemplateAreas: '"stack"',
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr",
        }}
        {...props}
      >
        {childrenArray.map((child, index) => (
          <Slot
            className="overflow-hidden"
            key={index}
            // All children are placed in the same grid area, causing them to stack
            // width/height: 100% ensures all children fill the entire grid area
            style={{
              gridArea: "stack",
              zIndex: index,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "stretch",
              justifyContent: "stretch",
            }}
          >
            {child}
          </Slot>
        ))}
      </div>
    );
  }
);

export default Stack;
