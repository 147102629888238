import { PromptVariable } from "./types";
import PromptVariableConfig from "./PromptVariableConfig";
import DeprecatedButton from "../../common/DeprecatedButton";
import { cn } from "../../../lib/utils";

interface PromptVariableBarProps {
  variables: PromptVariable[];
  onAddVariable: () => void;
  onSelectedVariable: (
    variable: PromptVariable,
    as: "text" | "condition"
  ) => void;
  className?: string;
}

const PromptVariableBar: React.FC<PromptVariableBarProps> = ({
  variables,
  onSelectedVariable,
  onAddVariable,
  className,
}) => {
  return (
    <div className={cn("flex gap-md overflow-x-auto flex-wrap", className)}>
      {variables.map((variable) => (
        <PromptVariableConfig
          key={variable.id}
          variable={variable}
          handleVariableSelected={onSelectedVariable}
        />
      ))}
      <DeprecatedButton icon="plus" onClick={onAddVariable} />
    </div>
  );
};

export default PromptVariableBar;
