import { useApiClient } from "../../providers/ApiClientProvider";
import { useNavigate } from "react-router-dom";
import { FC, FormEvent, useState } from "react";
import Form from "../../components/common/forms/Form";
import Input from "../../components/common/forms/Input";
import { message_from_exception } from "../../utils";
import log, { LogType } from "../../log";
import PasswordRequirements from "../../components/login/PasswordRequirements";
import Legal from "../../components/login/Legal";
import { useRefreshAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Button from "components/common/Button";

interface CreateAccountProps {
  children?: React.ReactNode;
  username: string;
  setUsername: (username: string) => void;
  password: string;
  setPassword: (password: string) => void;
  className?: string;
  onLoginToExisting: () => void;
}

const CreateAccountView: FC<CreateAccountProps> = ({
  children,
  username,
  setUsername,
  password,
  setPassword,
  className,
  onLoginToExisting,
}) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const refreshUser = useRefreshAuthenticatedUser();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setError(null);

    try {
      const response = await apiClient.public.coreAuthUserCreate({
        email: username,
        password,
        display_name: displayName,
      });
      refreshUser();
      if (response.status === 204) {
        navigate("/verify");
      } else {
        setError("Something went wrong");
      }
    } catch (error: any) {
      log(LogType.Auth, error.error);
      setError(message_from_exception(error));
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={className}>
      {children}
      <h2 className="text-xl w-full text-center">Create Account</h2>
      <Input
        icon="envelope"
        type="email"
        id="email"
        placeholder="Email"
        variant="form"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required={true}
        tabIndex={1}
      />
      <Input
        type="name"
        icon="user"
        id="fullname"
        placeholder="Display Name"
        variant="form"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
        required={true}
        tabIndex={2}
      />
      <p className="text-xs text-secondary -mt-md text-center">
        This is how other users will refer to you inside odo
      </p>
      <Input
        type="password"
        icon="lock"
        placeholder="Password"
        variant="form"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required={true}
        tabIndex={3}
      />
      <PasswordRequirements />
      <Input
        type="password"
        icon="lock"
        placeholder="Confirm Password"
        variant="form"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required={true}
        tabIndex={4}
      />
      {error && (
        <div className="text-destructive text-center max-w-[350px]">
          {error}
        </div>
      )}
      <div className="flex flex-row">
        <Button
          size="small"
          type="button"
          variant="plain"
          emphasis="primary"
          onClick={onLoginToExisting}
          tabIndex={6}
          text="Login to Existing"
        />
        <div className="flex-grow" />
        <Button
          type="submit"
          emphasis="primary"
          className="w-[100px]"
          disabled={
            username === "" ||
            password === "" ||
            confirmPassword === "" ||
            displayName === ""
          }
          tabIndex={5}
          text="Create"
        />
      </div>
      <Legal cta="Create" />
    </Form>
  );
};

export default CreateAccountView;
