import PastProposalListView from "components/PastProposalList";
import NavigationView from "components/common/NavigationView";

const PastProposalsRoute = () => {
  return (
    <NavigationView>
      <PastProposalListView />
    </NavigationView>
  );
};

export default PastProposalsRoute;
