import { cn } from "lib/utils";
import React, { FC } from "react";
import { ComponentPropsWithoutRef, forwardRef, ReactElement } from "react";
import Rows from "./Rows";

const MaxSizeWrapper: FC<{
  children: React.ReactNode;
  maxSize: number | undefined;
}> = ({ children, maxSize }) => {
  if (!maxSize) {
    return <>{children}</>;
  }
  return <div className="max-w-[800px] mx-auto w-full">{children}</div>;
};

export const ScrollableHeader = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div"> & { maxSize?: number }
>(({ children, className, maxSize, ...props }, ref) => {
  return (
    <MaxSizeWrapper maxSize={maxSize}>
      <div
        ref={ref}
        className={cn(
          "sticky top-0 left-0 right-0 bg-background shrink-0",
          className
        )}
        {...props}
      >
        {children}
      </div>
    </MaxSizeWrapper>
  );
});

export const ScrollableFooter = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div"> & { maxSize?: number }
>(({ children, className, maxSize, ...props }, ref) => {
  return (
    <MaxSizeWrapper maxSize={maxSize}>
      <div
        ref={ref}
        className={cn("sticky bottom-0 bg-background shrink-0", className)}
        {...props}
      >
        {children}
      </div>
    </MaxSizeWrapper>
  );
});

interface ScrollableProps extends ComponentPropsWithoutRef<"div"> {
  vertical?: boolean;
  horizontal?: boolean;
  alwaysShowScrollbar?: boolean;
  children: React.ReactNode;
  // Restrics the size of the content while still showing scrollbars
  // at full size
  maxContentSize?: number;
}

const Scrollable = forwardRef<HTMLDivElement, ScrollableProps>((props, ref) => {
  const {
    children,
    vertical = true,
    horizontal = false,
    className,
    alwaysShowScrollbar = false,
    maxContentSize,
    ...rest
  } = props;

  let suffix = alwaysShowScrollbar ? "scroll" : "auto";

  // Extract header and footer from children
  let header: ReactElement | null = null;
  let footer: ReactElement | null = null;
  let content: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === ScrollableHeader) {
        if (header) {
          throw new Error("Only one Header component is allowed");
        }
        header = child;
      } else if (child.type === ScrollableFooter) {
        if (footer) {
          throw new Error("Only one Footer component is allowed");
        }
        footer = child;
      } else {
        content.push(child);
      }
    } else {
      content.push(child);
    }
  });

  return (
    <Rows ref={ref} className={cn("relative", className)} {...rest}>
      {header}
      <div
        className={cn(
          "grow",
          vertical ? `overflow-y-${suffix}` : "overflow-y-hidden",
          horizontal ? `overflow-x-${suffix}` : "overflow-x-hidden"
        )}
      >
        <MaxSizeWrapper maxSize={maxContentSize}>{content}</MaxSizeWrapper>
      </div>
      {footer}
    </Rows>
  );
});

export default Scrollable;
