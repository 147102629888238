import useYjsMap from "api/useYjsMap";
import useYjsProvider from "hooks/yjs/useYjsProvider";
import { SampleContent } from "odo";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";

export interface BaseContentItem {
  itemId: string;
  sampleIndex?: number;
}

interface SectionDetails {
  details: string;
  pastProposal: string;
  baseContentItems: BaseContentItem[];
}

interface SectionDetailsResponse extends SectionDetails {
  id: string;
  name: string;
  headingLevel: number;
  baseContentItems: BaseContentItem[];
  samples: SampleContent[];

  setDetails: (details: string) => void;
  setPastProposal: (pastProposal: string) => void;
  setBaseContentItems: (baseContentItems: BaseContentItem[]) => void;
}

const useSctionDetails = (): SectionDetailsResponse => {
  const provider = useYjsProvider();

  const { activeSection } = useEditorDocData();
  const [allSections, setAllSections] = useYjsMap<SectionDetails>(
    provider,
    "sections",
    {},
    false
  );

  if (!activeSection || !activeSection.id)
    return {
      id: "",
      name: "",
      details: "",
      pastProposal: "",
      baseContentItems: [],
      setDetails: () => {},
      setPastProposal: () => {},
      setBaseContentItems: () => {},
      headingLevel: 0,
      samples: [],
    };

  const setDetails = (details: string) => {
    const prev = allSections[activeSection.id as string];
    if (!prev) {
      setAllSections(activeSection.id as string, {
        details,
        pastProposal: "",
        baseContentItems: [],
      });
    } else {
      setAllSections(activeSection.id as string, {
        ...prev,
        details,
      });
    }
  };

  const setPastProposal = (pastProposal: string) => {
    const prev = allSections[activeSection.id as string];
    if (!prev) {
      setAllSections(activeSection.id as string, {
        details: "",
        pastProposal,
        baseContentItems: [],
      });
    } else {
      setAllSections(activeSection.id as string, {
        ...prev,
        pastProposal,
      });
    }
  };

  const setBaseContentItems = (baseContentItems: BaseContentItem[]) => {
    const prev = allSections[activeSection.id as string];
    if (!prev) {
      setAllSections(activeSection.id as string, {
        details: "",
        pastProposal: "",
        baseContentItems,
      });
    } else {
      setAllSections(activeSection.id as string, {
        ...prev,
        baseContentItems,
      });
    }
  };

  const section = allSections[activeSection.id as string];
  if (!section)
    return {
      id: activeSection.id,
      name: activeSection.name,
      headingLevel: activeSection.level,
      details: "",
      pastProposal: "",
      baseContentItems: [],
      samples: [],
      setBaseContentItems,
      setDetails,
      setPastProposal,
    };

  return {
    name: activeSection.name,
    id: activeSection.id,
    headingLevel: activeSection.level,
    samples: activeSection.samples || [],
    ...section,
    baseContentItems: section.baseContentItems || [],
    setDetails,
    setPastProposal,
    setBaseContentItems,
  };
};

export default useSctionDetails;
