import { cn } from "lib/utils";
import { ComponentPropsWithoutRef, forwardRef, useState } from "react";

interface TextAreaProps
  extends Omit<ComponentPropsWithoutRef<"textarea">, "onChange"> {
  characterLimit?: number;
  onChange?: (text: string) => void;
}

/**
 * A traditional textarea that has a fixed height and a scrollbar when the content overflows.
 */
const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, characterLimit, onChange, value, ...props }, ref) => {
    // const [focused, setFocused] = useState(false);
    // const internalOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    //   // if (characterLimit && e.target.value.length > characterLimit) {
    //   //   e.target.value = e.target.value.slice(0, characterLimit);
    //   //   toast.error("Character limit reached");
    //   // }
    //   onChange?.(e.target.value);
    // };

    return (
      <textarea
        ref={ref}
        // onFocus={() => setFocused(true)}
        // onBlur={() => setFocused(false)}
        className={cn(
          "border rounded-md p-sm bg-background tablet:text-md text-lg",
          // focused && "border-primary",
          className
        )}
        {...props}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    );
  }
);

export default TextArea;
