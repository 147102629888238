import { FC } from "react";

import {
  Portal as RadixPortal,
  PortalProps as RadixPortalProps,
} from "@radix-ui/react-portal";

interface PortalProps extends RadixPortalProps {}

const Portal: FC<PortalProps> = ({ container, ...props }) => {
  return (
    <RadixPortal
      container={container ?? document.getElementById("app-container")}
      {...props}
    />
  );
};

export default Portal;
