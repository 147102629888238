import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import { message_from_exception } from "utils";
import DeprecatedRFPPDFView from "components/rfps/DeprecatedRFPPDFView";
import { format_date } from "lib/utils";
import { rfpFromApiDetails } from "types/RFP";
import DeprecatedButton from "components/common/DeprecatedButton";
import CopyableText from "components/CopyableText";
import { odoToast } from "lib/odoToast";

interface RFPDetailsOverlayProps {
  rfpId: string;
  rfpName: string;
  onDismiss?: () => void;
}

const RFPDetailsOverlay: FC<RFPDetailsOverlayProps> = ({
  rfpId,
  rfpName,
  onDismiss,
}) => {
  const apiClient = useApiClient();
  const [rfp, , { error }] = useFetchedData(async () => {
    const response = await apiClient.user.rfpRfpRead(rfpId);
    return rfpFromApiDetails(response.data);
  });

  const handleRegenerateProfile = async () => {
    try {
      await apiClient.admin.rfpRegenerateProfile(rfpId!);
      odoToast.success({
        title: "Profile Requeued",
        text: "This will take a few minutes to complete",
      });
    } catch (e) {
      odoToast.caughtError(e, "Regenerating Profile");
    }
  };

  return (
    <Overlay
      title={rfpName ?? undefined}
      open={!!rfpId}
      onClose={onDismiss}
      variant="full-screen-bordered"
      scrollable={false}
    >
      <Columns>
        {/* Details Form */}
        <AsyncLoadedDiv
          value={rfp}
          error={error ? message_from_exception(error) : undefined}
          className="grow basis-0 flex flex-col"
          whileLoaded={(rfp) => {
            return (
              <Rows className="gap-md grow">
                <LabeledFormInput label="Id">
                  <CopyableText text={rfp.id} />
                </LabeledFormInput>
                <LabeledFormInput label="Created">
                  {format_date(rfp.created)}
                </LabeledFormInput>
                <LabeledFormInput label="Issuing Org">
                  <CopyableText text={rfp.issuingOrg ?? "unknown"} />
                </LabeledFormInput>
                <Columns className="shrink-0">
                  <DeprecatedButton
                    icon="address-card"
                    text="Regenerate Profile"
                    variant="destructive"
                    onClick={() => handleRegenerateProfile()}
                  />
                </Columns>
              </Rows>
            );
          }}
        />
        {/* RFP Preview */}
        <Rows className="grow basis-0">
          <DeprecatedRFPPDFView rfpId={rfpId} />
        </Rows>
      </Columns>
      {/* <Rows className="gap-md"> */}
      {/* <RFPCategoryEditor
          rfpId={rfpId}
          rfpName={rfpName}
          onSaved={fetchData.refresh}
        /> */}
      {/* <LabeledFormInput label="Comparisons">
          <ComparisonTableView
            differentiating={{ column: "Orgs", id: rfpId }}
            results={comparisons}
            paginatedData={fetchData}
          >
            <Button
              icon="address-card"
              text="Regenerate Profile"
              variant="destructive"
              onClick={() => handleRegenerateProfile()}
            />
            <div className="grow" />
          </ComparisonTableView>
        </LabeledFormInput>
        <Columns className="shrink-0"> */}
      {/* <div className="grow" /> */}
      {/* <Button
            icon="refresh"
            text="Rerun All"
            variant="destructive"
            onClick={() => handleRerun()}
          /> */}
      {/* </Columns>
      </Rows> */}
    </Overlay>
  );
};

// const RFPCategoryEditor: FC<{
//   rfpId: string;
//   rfpName: string;
//   onSaved: () => void;
// }> = ({ rfpId, rfpName, onSaved }) => {
//   const apiClient = useApiClient();
//   const [details, setDetails, { error }] = useFetchedData(async () => {
//     return (await apiClient.user.rfpRfpRead(rfpId)).data;
//   });
//   const [changed, setChanged] = useState(false);
//   const [isCategorizing, setIsCategorizing] = useState(false);

//   const handleSave = async () => {
//     if (!details) return;
//     if (!changed) return;
//     try {
//       await apiClient.user.rfpRfpUpdate(rfpId, details);
//       toast.success("Category updated successfully");
//       setChanged(false);
//       onSaved();
//     } catch (e) {
//       toast.error(message_from_exception(e));
//     }
//   };

//   return (
//     <AsyncLoadedDiv
//       error={error ? message_from_exception(error) : undefined}
//       value={details}
//       whileLoaded={(details) => {
//         return (
//           <LabeledFormInput label="Category">
//             <RFPCategoriesDropDown
//               className="grow"
//               categories={details.categories}
//               onChange={(categories) => {
//                 setDetails((prev) => {
//                   if (!prev) return prev;
//                   return { ...prev, categories };
//                 });
//                 setChanged(true);
//               }}
//               onSave={handleSave}
//             />
//           </LabeledFormInput>
//         );
//       }}
//     />
//   );
// };

export default RFPDetailsOverlay;
