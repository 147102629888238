import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ReferenceRefManager from "./ReferenceManager";

interface ReferenceContextType {
  activeReferenceId: string | null;
  setActiveReferenceId: (id: string | null) => void;
  registerReferenceClickCallback: (
    callback: (referenceId: string) => void
  ) => () => void;
  onReferenceClicked?: (id: string) => void;
  refManager: ReferenceRefManager | null;
}

const ReferenceContext = createContext<ReferenceContextType>({
  activeReferenceId: null,
  setActiveReferenceId: () => {},
  onReferenceClicked: undefined,
  registerReferenceClickCallback: () => () => {},
  refManager: null,
});

export const useReferenceContext = () => useContext(ReferenceContext);

interface ReferenceProviderProps {
  children: React.ReactNode;
  onReferenceClicked?: (id: string) => void;
  obfuscateReferences?: boolean;
}

export const ReferenceProvider: React.FC<ReferenceProviderProps> = ({
  children,
  onReferenceClicked,
  obfuscateReferences = false,
}) => {
  const [activeReferenceId, setActiveReferenceId] = useState<string | null>(
    null
  );
  const [referenceClickCallbacks, setReferenceClickCallbacks] = useState<
    ((referenceId: string) => void)[]
  >([]);

  const refManager = useMemo(
    () => (obfuscateReferences ? new ReferenceRefManager() : null),
    [obfuscateReferences]
  );

  const registerReferenceClickCallback = useCallback(
    (callback: (referenceId: string) => void) => {
      setReferenceClickCallbacks((prev) => [...prev, callback]);
      return () => {
        setReferenceClickCallbacks((prev) =>
          prev.filter((cb) => cb !== callback)
        );
      };
    },
    []
  );

  const handleReferenceClicked = (id: string) => {
    setActiveReferenceId(id);
    onReferenceClicked?.(id);
    referenceClickCallbacks.forEach((callback) => {
      callback(id);
    });
  };

  return (
    <ReferenceContext.Provider
      value={{
        onReferenceClicked: handleReferenceClicked,
        activeReferenceId,
        setActiveReferenceId,
        registerReferenceClickCallback,
        refManager,
      }}
    >
      {children}
    </ReferenceContext.Provider>
  );
};
