import { getSelectedEnv, setSelectedEnv } from "api/env";
import { cn } from "lib/utils";
import {
  useAuthenticatedUser,
  useOptionalAuthenticatedUser,
} from "providers/AuthenticatedUserProvider";
import { useEffect, useMemo, useRef, useState } from "react";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";

import posthog from "posthog-js";
import ComboBox, { ResultBase } from "components/common/menus/ComboBox";
import useFetchedOptions from "hooks/useFetchedOptions";
import { useApiClient } from "providers/ApiClientProvider";
import { odoToast } from "lib/odoToast";
import Rows from "components/common/containers/Rows";
import Overlay from "components/common/containers/overlays/Overlay";
import { Portal } from "@radix-ui/react-portal";
import DropdownMenu from "components/common/menus/DropdownMenu";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import useLocalStorage from "lib/useLocalStorage";

const ADMIN_BAR_HEIGHT = 28;

const AdminBar = () => {
  const user = useOptionalAuthenticatedUser();
  const navigate = useNavigate();
  const [showingQuickActions, setShowingQuickActions] = useState(false);
  const { name: selectedEnv, isOverride } = getSelectedEnv();
  const [showAdminComponents, setShowAdminComponents] = useLocalStorage(
    "showAdminComponents",
    true
  );

  const location = useLocation();

  const showing = useMemo(() => {
    if (user === undefined) return false;
    if (!(user?.isWriter ?? false) && !isOverride) return false;
    return true;
  }, [user, isOverride]);

  useEffect(() => {
    document
      .getElementById("app-container")
      ?.style.setProperty(
        "--screen-height",
        showing ? `calc(100vh - ${ADMIN_BAR_HEIGHT}px)` : "100vh"
      );
  }, [showing]);

  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
        setShowingQuickActions(true);
      }
      if (e.key === "Escape") setShowingQuickActions(false);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [setShowingQuickActions]);

  const availableEnvs = useMemo(() => {
    const raw = process.env.REACT_APP_AVAILABLE_DOMAINS;
    if (!raw) return [];

    try {
      const dict = JSON.parse(raw);
      return Object.keys(dict);
    } catch {
      return [];
    }
  }, []);

  useEffect(() => {
    if (showAdminComponents) {
      document.body.classList.remove("hide-admin");
    } else {
      document.body.classList.add("hide-admin");
    }
  }, [showAdminComponents]);

  if (!showing) return null;

  const handleSelectEnv = (env: string) => {
    setSelectedEnv(env);
    // Reload the app completely to apply the new env
    window.location.href = "/";
  };

  const handleToggleAdminComponents = () => {
    setShowAdminComponents(!showAdminComponents);
  };

  return (
    <Portal container={document.getElementById("admin-bar")}>
      <Columns
        className={cn(
          "bg-secondary px-2m py-xs text-center text-background text-sm relative h-xl justify-center gap-md",
          user?.isWriter && "cursor-pointer hover:opacity-80",
          selectedEnv !== "prod" && "bg-destructive"
        )}
        style={{ height: ADMIN_BAR_HEIGHT }}
        onClick={() => user?.isWriter && navigate("/admin")}
      >
        {user?.isStaff && (
          <div className="absolute left-md top-0 bottom-0 flex items-center justify-center">
            <DropdownMenu
              items={availableEnvs.map((env) => ({
                text: env,
                onClick: () => handleSelectEnv(env),
              }))}
            >
              backend: {selectedEnv}
            </DropdownMenu>
          </div>
        )}
        <Button
          variant="plain"
          icon={showAdminComponents ? "eye" : "eye-slash"}
          className="text-background"
          onClick={handleToggleAdminComponents}
        />
        {user?.isWriter ? "Admin" : "Override Only"}
        <div className="absolute right-md top-0 bottom-0 flex items-center justify-center">
          {process.env.REACT_APP_DEPLOY_VERSION}
        </div>
      </Columns>
      {showingQuickActions && (
        <Overlay
          variant="transparent"
          fillWidth={true}
          onClose={() => setShowingQuickActions(false)}
        >
          <ActionView hide={() => setShowingQuickActions(false)} />
          <div className="h-[40vh]" />
        </Overlay>
      )}
    </Portal>
  );
};

interface Action extends ResultBase {
  display: string;
  handle: () => void;
  requireStaff: boolean;
}

const ActionView = ({ hide }: { hide: () => void }) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const authenticatedUser = useAuthenticatedUser();
  const [searchText, setSearchText] = useState("");
  const orgs: Action[] = useFetchedOptions(
    {
      endpoint: apiClient.admin.coreOrgList,
      searchText,
    },
    authenticatedUser.isStaff
  ).map((org) => ({
    id: "org-" + org.public_id!,
    display: org.name,
    category: "Organizations",
    requireStaff: true,
    handle: async () => {
      try {
        await apiClient.admin.coreOrgSet({ public_id: org.public_id! });
        window.location.href = "/";
      } catch (e) {
        odoToast.caughtError(e, "Setting Organization");
      }
    },
  }));

  const navigateAction = (
    id: string,
    display: string,
    destination: string,
    requireStaff = true
  ): Action => {
    return {
      id: "nav-" + id,
      category: "Navigate",
      display,
      requireStaff,
      handle: () => {
        navigate(destination);
      },
    };
  };

  const hardcodedActions = useRef(
    [
      navigateAction("home", "Admin Home", "/admin/", false),
      navigateAction("prompts", "Prompts", "/admin/prompts/"),
      navigateAction(
        "proposals",
        "Unpublished Proposals",
        "/admin/proposals/",
        false
      ),
      navigateAction("rfps", "RFPs", "/admin/rfps/"),
      navigateAction("orgs", "Organizations", "/admin/orgs/"),
      navigateAction("logout", "Logout", "/logout", false),
      navigateAction("event", "Events", "/admin/events/"),
      navigateAction("editor", "Editor", "/admin/editor/"),
      navigateAction("search", "Search", "/admin/search/", false),
      navigateAction(
        "rfp-categories",
        "RFP Categories",
        "/admin/rfp-categories/"
      ),
      navigateAction("ui-playground", "UI Playground", "/admin/ui-playground/"),
      navigateAction("content", "Content", "/content/"),
      navigateAction("lists", "Lists", "/lists/"),
      navigateAction("exit", "Exit", "/", false),
    ]
      .filter((action) => !action.requireStaff || authenticatedUser.isStaff)
      .sort((a, b) => a.display.localeCompare(b.display))
  );

  const actions = hardcodedActions.current.concat(orgs);

  return (
    <div
      className="max-w-[600px] mx-auto w-full bg-background popover p-md"
      onClick={(e) => e.stopPropagation()}
    >
      <ComboBox
        placeholder="Search for an action"
        options={actions}
        renderOption={(option) => option.display}
        onSelected={(option) => {
          option.handle();
          hide();
          return true;
        }}
        autoFocus={true}
        filterOptions={(options, text) => {
          setSearchText(text);
          return options.filter((option) =>
            option.display.toLowerCase().includes(text.toLowerCase())
          );
        }}
      />
    </div>
  );
};

export const wrapInAdminBar = (routes: RouteObject[]) => {
  return routes.map((route) => {
    return {
      ...route,
      element: (
        <>
          <Rows className="relative grow">{route.element}</Rows>
          <AdminBar />
        </>
      ),
    };
  });
};
