/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Debug {
  /** Use llm fixtures */
  use_llm_fixtures?: boolean;
}

export interface Domain {
  /**
   * Domain
   * @minLength 1
   */
  domain: string;
}

export interface Org {
  /**
   * Public id
   * @minLength 1
   */
  public_id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  email_domains?: Domain[];
  /** Is trial approved */
  is_trial_approved?: boolean;
  /** Is subscribed */
  is_subscribed?: boolean;
  categories?: string[];
  /** Domain */
  domain?: string;
}

export interface OrgCreate {
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** Domain */
  domain?: string;
}

export interface OrgSet {
  /**
   * Public id
   * @format uuid
   */
  public_id: string;
}

export interface OdoUser {
  /**
   * Display name
   * @minLength 1
   * @maxLength 255
   */
  display_name: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
}

export interface AdminOrgDetails {
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** Is trial approved */
  is_trial_approved?: boolean;
  /** Is subscribed */
  is_subscribed?: boolean;
  /**
   * Profile
   * @maxLength 4096
   */
  profile?: string | null;
  members: OdoUser[];
}

export interface ForgotPassword {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface RecoverPasswordEmailUser {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Token
   * @format uuid
   */
  token: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
}

export interface ReverifyEmailUser {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface GoogleTokenObtainPair {
  /**
   * Credentials
   * @minLength 1
   */
  credentials: string;
}

export interface UserDetailPostAuth {
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
  /** Org id */
  org_id?: string;
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email?: string;
  /**
   * Display name
   * @minLength 1
   */
  display_name?: string;
  /** Is staff */
  is_staff?: boolean;
  /** Is writer */
  is_writer?: boolean;
  /** Is verified */
  is_verified?: boolean;
  /** Features */
  features?: Record<string, boolean>;
  /**
   * Favorite list id
   * @maxLength 255
   */
  favorite_list_id?: string | null;
  /** Org name */
  org_name?: string;
}

export interface CreateEmailUser {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
  /**
   * Display name
   * @minLength 1
   */
  display_name: string;
}

export interface VerifyEmailUser {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Verify token
   * @format uuid
   */
  verify_token: string;
}

export interface Feedback {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email?: string;
  /**
   * Feedback
   * @minLength 1
   */
  feedback: string;
  /**
   * Data
   * @minLength 1
   */
  data?: string;
  /**
   * Subject
   * @minLength 1
   */
  subject?: string;
}

export interface ApplyCode {
  /**
   * Code
   * @minLength 1
   */
  code: string;
}

export interface EmailTokenObtainPair {
  /**
   * Username
   * @minLength 1
   */
  username: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
}

export interface TokenRefresh {
  /**
   * Refresh
   * @minLength 1
   */
  refresh: string;
  /**
   * Access
   * @minLength 1
   */
  access?: string;
}

export interface OrgDetails {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * Description
   * @maxLength 4096
   */
  description?: string | null;
  /**
   * Services
   * @maxLength 4096
   */
  services?: string | null;
  /**
   * Customers
   * @maxLength 4096
   */
  customers?: string | null;
  state_locations: string[];
  issuing_org_kinds: string[];
  /**
   * Profile
   * @maxLength 4096
   */
  profile?: string | null;
  raw_excluded_scopes?: string[];
  raw_preferred_scopes?: string[];
  /** Drafting style */
  drafting_style?: string | null;
}

export interface Unsubscribe {
  /**
   * Template id
   * @minLength 1
   */
  template_id: string;
}

export interface ImageDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /** Download url */
  download_url?: string;
  /**
   * Extension
   * @minLength 1
   * @maxLength 16
   */
  extension: string;
}

export interface ImageCreate {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Extension
   * @minLength 1
   * @maxLength 16
   */
  extension: string;
  /**
   * Doc id
   * @minLength 1
   */
  doc_id?: string;
  /** Doc type */
  doc_type?: "proposal" | "collab";
  /** Upload url */
  upload_url?: string;
}

export interface Event {
  /** Id */
  id?: number;
  /** User username */
  user_username?: string;
  /**
   * Kind
   * @minLength 1
   * @maxLength 32
   */
  kind: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * User id
   * @min -2147483648
   * @max 2147483647
   */
  user_id?: number | null;
  /**
   * Doc id
   * @minLength 1
   * @maxLength 36
   */
  doc_id?: string | null;
  /** Details */
  details?: object | null;
  /**
   * Prompt name
   * @minLength 1
   * @maxLength 64
   */
  prompt_name?: string | null;
  /**
   * Llm model
   * @minLength 1
   * @maxLength 64
   */
  llm_model?: string | null;
  /** Llm temperature */
  llm_temperature?: number | null;
  /**
   * Llm system prompt
   * @minLength 1
   */
  llm_system_prompt?: string | null;
  /**
   * Llm user prompt
   * @minLength 1
   */
  llm_user_prompt?: string | null;
  /**
   * Llm response ms
   * @min -2147483648
   * @max 2147483647
   */
  llm_response_ms?: number | null;
  /**
   * Llm response text
   * @minLength 1
   */
  llm_response_text?: string | null;
  /**
   * Llm prompt tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_prompt_tokens?: number | null;
  /**
   * Llm completion tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_completion_tokens?: number | null;
  /**
   * Llm total tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_total_tokens?: number | null;
  /**
   * Bot question id
   * @minLength 1
   * @maxLength 64
   */
  bot_question_id?: string | null;
  /** Bot answer result */
  bot_answer_result?: "none" | "suggested" | "answered" | null;
}

export interface EventCreate {
  /**
   * Kind
   * @minLength 1
   * @maxLength 32
   */
  kind: string;
  /**
   * Doc id
   * @minLength 1
   * @maxLength 36
   */
  doc_id?: string | null;
  /** Details */
  details?: object | null;
  /**
   * Prompt name
   * @minLength 1
   * @maxLength 64
   */
  prompt_name?: string | null;
  /**
   * Llm model
   * @minLength 1
   * @maxLength 64
   */
  llm_model?: string | null;
  /** Llm temperature */
  llm_temperature?: number | null;
  /**
   * Llm system prompt
   * @minLength 1
   */
  llm_system_prompt?: string | null;
  /**
   * Llm user prompt
   * @minLength 1
   */
  llm_user_prompt?: string | null;
  /**
   * Llm response ms
   * @min -2147483648
   * @max 2147483647
   */
  llm_response_ms?: number | null;
  /**
   * Llm response text
   * @minLength 1
   */
  llm_response_text?: string | null;
  /**
   * Llm prompt tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_prompt_tokens?: number | null;
  /**
   * Llm completion tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_completion_tokens?: number | null;
  /**
   * Llm total tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_total_tokens?: number | null;
  /**
   * Bot question id
   * @minLength 1
   * @maxLength 64
   */
  bot_question_id?: string | null;
  /** Bot answer result */
  bot_answer_result?: "none" | "suggested" | "answered" | null;
}

export interface EventCapture {
  /** Name */
  name: "proposal_changed";
  /** Data */
  data?: Record<string, string | null>;
}

export interface Prompt {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Description
   * @minLength 1
   */
  description?: string | null;
  /** Used in product */
  used_in_product?: boolean;
}

export interface PromptDetail {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /**
   * Description
   * @minLength 1
   */
  description?: string | null;
  /** Content */
  content?: object;
  /** Variables */
  variables?: object;
  /** Value groups */
  value_groups?: object;
  /** Variations */
  variations?: object;
  /** Variation model */
  variation_model?: object;
  /** Variation notes */
  variation_notes?: object;
  /** Variation tools */
  variation_tools?: object;
  /**
   * Active variation
   * @min -2147483648
   * @max 2147483647
   */
  active_variation?: number | null;
  /** Used in product */
  used_in_product?: boolean;
}

export interface PromptFixture {
  /** Id */
  id?: number;
  /** Fixtures */
  fixtures: object;
}

export interface PromptVariable {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Description
   * @minLength 1
   */
  description?: string | null;
}

export interface PromptVariablesDetail {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /**
   * Description
   * @minLength 1
   */
  description?: string | null;
}

export interface PromptVariableValue {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Value
   * @minLength 1
   */
  value: string;
}

export interface PromptVariableValueDetails {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Value
   * @minLength 1
   */
  value: string;
}

export interface Diff {
  /**
   * Finalize
   * @default true
   */
  finalize?: boolean;
}

export interface Org {
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
}

export interface ProposalAdminDetail {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Title
   * @minLength 1
   */
  title?: string;
  /**
   * Rfp id
   * @format uuid
   */
  rfp_id: string;
  /**
   * Due date
   * @format date
   */
  due_date?: string | null;
  org?: Org;
}

export interface RequirementGetRelevantContext {
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface ProposalRFPContextInput {
  /**
   * Section title
   * @minLength 1
   */
  section_title: string;
}

export interface ProposalRFPBlock {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Type
   * @minLength 1
   */
  type: string;
  /**
   * Content
   * @minLength 1
   */
  content: string;
}

export interface ProposalRFPContextOutput {
  rfp_instructions: ProposalRFPBlock[];
  rfp_context: ProposalRFPBlock[];
}

export interface RFPList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /** Display name */
  display_name?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /** Processing status */
  processing_status?: string;
  /** Next step */
  next_step?: string;
  orgs?: string[];
  /** Is uploaded */
  is_uploaded?: boolean;
  /** Has markdown */
  has_markdown?: boolean;
  /** Issuing org */
  issuing_org?: string;
  /**
   * Foia request job id
   * @min -2147483648
   * @max 2147483647
   */
  foia_request_job_id?: number | null;
  /**
   * Invalid reason
   * @maxLength 255
   */
  invalid_reason?: string | null;
  /**
   * Test data id
   * @min -2147483648
   * @max 2147483647
   */
  test_data_id?: number | null;
}

export interface RFPCSVUpload {
  /**
   * Content
   * @minLength 1
   */
  content: string;
  /**
   * Source
   * @minLength 1
   */
  source?: string;
}

export interface SearchInput {
  /**
   * Search
   * @minLength 1
   */
  search?: string | null;
  content_embedding?: number[] | null;
  /**
   * Alg
   * @minLength 1
   * @default "default"
   */
  alg?: string;
  /**
   * Proposal id
   * @minLength 1
   */
  proposal_id?: string | null;
  /**
   * Kind
   * @minLength 1
   */
  kind?: string | null;
  /**
   * Org id
   * @minLength 1
   */
  org_id?: string | null;
  /**
   * Associated only
   * @default false
   */
  associated_only?: boolean;
}

export interface ItemSearch {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** Kind */
  kind: "cover_letter" | "overview" | "project" | "person" | "subcontractor" | "approach";
  /**
   * Topic
   * @maxLength 255
   */
  topic?: string | null;
  /**
   * Title
   * @maxLength 1024
   */
  title?: string | null;
  /**
   * Name
   * @maxLength 1024
   */
  name?: string | null;
  /**
   * Customer
   * @maxLength 255
   */
  customer?: string | null;
  /**
   * Location
   * @maxLength 255
   */
  location?: string | null;
  /**
   * Start month
   * @min -2147483648
   * @max 2147483647
   */
  start_month?: number | null;
  /**
   * Start year
   * @min -2147483648
   * @max 2147483647
   */
  start_year?: number | null;
  /** Distance */
  distance?: number;
  /** Base distance */
  base_distance?: number;
  /** Title distance */
  title_distance?: number;
  /** Base title distance */
  base_title_distance?: number;
  /** Content distance */
  content_distance?: number;
  /** In proposal */
  in_proposal?: boolean;
}

export interface PaginatedItemSearch {
  /**
   * Next
   * @format uri
   * @minLength 1
   */
  next?: string;
  /**
   * Previous
   * @format uri
   * @minLength 1
   */
  previous?: string;
  results: ItemSearch[];
}

export interface PastProposalBlockChunkCreate {
  block_indexes: number[];
  /** Chunk strategy */
  chunk_strategy: "paragraph" | "sentence";
  embedding: number[];
}

export interface PastProposalBlockChunkBatchCreate {
  chunks: PastProposalBlockChunkCreate[];
}

export interface PastProposalBlockChunkBatchResponse {
  /** Created count */
  created_count: number;
  /** Error count */
  error_count: number;
  /**
   * Message
   * @minLength 1
   */
  message: string;
}

export interface InternalProposalDetails {
  /** Participant emails */
  participant_emails?: string;
}

export interface RFPCreate {
  /**
   * Hash
   * @minLength 1
   */
  hash: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /**
   * For admin
   * @default false
   */
  for_admin?: boolean;
  /**
   * Source
   * @minLength 1
   */
  source?: string;
}

export interface RFPBlockChunkCreate {
  block_indexes: number[];
  /**
   * Rfp id
   * @format uuid
   */
  rfp_id: string;
  /** Chunk strategy */
  chunk_strategy: "paragraph" | "sentence";
  embedding: number[];
}

export interface RFPBlockChunkBatchCreate {
  chunks: RFPBlockChunkCreate[];
}

export interface RFPBlockChunkBatchResponse {
  /** Created count */
  created_count: number;
  /** Error count */
  error_count: number;
  /**
   * Message
   * @minLength 1
   */
  message: string;
}

export interface RFPKeyDates {
  /**
   * Date
   * @minLength 1
   */
  date: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface InternalRFPList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /** Display name */
  display_name?: string;
  /** Description */
  description?: string;
  /**
   * Due date
   * @format date
   */
  due_date?: string | null;
  /** Budget */
  budget?: string;
  /** Tasks */
  tasks?: string;
  /** Minimum qualifications */
  minimum_qualifications?: string;
  /** Location */
  location?: string;
  /** Issuing org */
  issuing_org?: string;
  other_key_dates?: RFPKeyDates[];
  /**
   * Slug
   * @format slug
   * @minLength 1
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug?: string | null;
}

export interface PendingMatchesOrg {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
}

export interface RFPFile {
  /** Id */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 1024
   */
  name: string;
  /**
   * Extension
   * @minLength 1
   * @maxLength 10
   */
  extension: string;
  /** Upload url */
  upload_url?: string;
}

export interface RFPFileDetail {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 1024
   */
  name: string;
  /**
   * Extension
   * @minLength 1
   */
  extension?: string;
  /** Read url */
  read_url?: string;
  /** Uploaded */
  uploaded?: boolean;
}

export interface FinalCheckCreate {
  /**
   * Rfp hash
   * @minLength 1
   */
  rfp_hash: string;
  /**
   * Proposal hash
   * @minLength 1
   */
  proposal_hash: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email?: string;
}

export interface FinalCheckDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 256
   */
  name: string;
  /** Is proposal uploaded */
  is_proposal_uploaded?: boolean;
  /** Report */
  report?: object | null;
  /** Rfp url */
  rfp_url?: string;
  /** Rfp name */
  rfp_name?: string;
}

export interface InternalRFPDetails {
  /** Link */
  link?: string;
}

export interface ItemDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** Kind */
  kind: "cover_letter" | "overview" | "project" | "person" | "subcontractor" | "approach";
  /**
   * Topic
   * @maxLength 255
   */
  topic?: string | null;
  /**
   * Title
   * @maxLength 1024
   */
  title?: string | null;
  /**
   * Name
   * @maxLength 1024
   */
  name?: string | null;
  /**
   * Customer
   * @maxLength 255
   */
  customer?: string | null;
  /**
   * Location
   * @maxLength 255
   */
  location?: string | null;
  /**
   * Start month
   * @min -2147483648
   * @max 2147483647
   */
  start_month?: number | null;
  /**
   * Start year
   * @min -2147483648
   * @max 2147483647
   */
  start_year?: number | null;
  /**
   * End month
   * @min -2147483648
   * @max 2147483647
   */
  end_month?: number | null;
  /**
   * End year
   * @min -2147483648
   * @max 2147483647
   */
  end_year?: number | null;
  /**
   * Contact name
   * @maxLength 255
   */
  contact_name?: string | null;
  /**
   * Contact title
   * @maxLength 255
   */
  contact_title?: string | null;
  /**
   * Contact email
   * @maxLength 1023
   */
  contact_email?: string | null;
  /**
   * Contact phone
   * @maxLength 255
   */
  contact_phone?: string | null;
  /**
   * Contact address
   * @maxLength 255
   */
  contact_address?: string | null;
  /**
   * Content id
   * @format uuid
   */
  content_id?: string | null;
}

export interface ItemInsert {
  /** Mode */
  mode: "as-is" | "tailored" | "reference";
  /** Section depth */
  section_depth: number;
  /** Location */
  location: "into" | "rewrite" | "above" | "below";
}

export interface FinalCheckList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 256
   */
  name: string;
}

export interface AddedRFPToList {
  /**
   * Rfp id
   * @minLength 1
   */
  rfp_id: string;
  /**
   * List name
   * @minLength 1
   */
  list_name: string;
}

export interface RemovedRFPFromList {
  /**
   * Rfp id
   * @minLength 1
   */
  rfp_id: string;
  /**
   * List name
   * @minLength 1
   */
  list_name: string;
}

export interface NAICSLeaf {
  /** Score */
  score: number;
  /** Id */
  id: number;
  /**
   * Title
   * @minLength 1
   */
  title: string;
}

export interface NAICSSubsector {
  /** Id */
  id: number;
  /**
   * Title
   * @minLength 1
   */
  title: string;
  /** Score */
  score: number;
  /** Total children */
  total_children: number;
  children: NAICSLeaf[];
}

export interface NAICSSector {
  /** Id */
  id: number;
  /**
   * Title
   * @minLength 1
   */
  title: string;
  /** Score */
  score: number;
  /** Total children */
  total_children: number;
  children: NAICSSubsector[];
}

export interface NAICSSelection {
  naics_codes: string[];
}

export interface PastProposalList {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /** Is processed */
  is_processed: boolean;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Block chunking job id
   * @min -2147483648
   * @max 2147483647
   */
  block_chunking_job_id?: number | null;
  /** Is uploaded */
  is_uploaded: boolean;
  /** In proposal */
  in_proposal?: boolean;
  /**
   * Topic
   * @minLength 1
   * @maxLength 255
   */
  topic?: string | null;
}

export interface PastProposalBlockResult {
  /** ID */
  id?: number;
  /** Preview */
  preview?: string;
  /**
   * Past proposal id
   * @minLength 1
   */
  past_proposal_id?: string;
  /**
   * Past proposal name
   * @minLength 1
   */
  past_proposal_name?: string;
  /** Distance */
  distance?: number;
  /** Raw distance */
  raw_distance?: number;
  /**
   * Type
   * @minLength 1
   * @maxLength 255
   */
  type: string;
}

export interface PastProposalBlockSearchOutput {
  results: PastProposalBlockResult[];
}

export interface BBox {
  /** Left */
  left: number;
  /** Top */
  top: number;
  /** Width */
  width: number;
  /** Height */
  height: number;
}

export interface PastProposalDetail {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /** Is processed */
  is_processed?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Block chunking job id
   * @min -2147483648
   * @max 2147483647
   */
  block_chunking_job_id?: number | null;
  /** Is uploaded */
  is_uploaded?: boolean;
  /** View url */
  view_url?: string;
  /** Markdown url */
  markdown_url?: string;
  /**
   * Topic
   * @minLength 1
   * @maxLength 255
   */
  topic?: string | null;
}

export interface PastProposalBlockWithProposal {
  /** Id */
  id: number;
  /**
   * Content
   * @minLength 1
   */
  content: string;
  /**
   * Type
   * @minLength 1
   * @maxLength 255
   */
  type: string;
  /**
   * Page
   * @min -2147483648
   * @max 2147483647
   */
  page: number;
  bbox: BBox;
  /**
   * Index
   * @min -2147483648
   * @max 2147483647
   */
  index: number;
  past_proposal: PastProposalDetail;
}

export interface PastProposalCreate {
  /**
   * Hash
   * @minLength 1
   */
  hash: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /**
   * Topic
   * @minLength 1
   */
  topic?: string;
}

export interface PastProposalBlock {
  /** Id */
  id: number;
  /**
   * Content
   * @minLength 1
   */
  content: string;
  /**
   * Type
   * @minLength 1
   * @maxLength 255
   */
  type: string;
  /**
   * Page
   * @min -2147483648
   * @max 2147483647
   */
  page: number;
  bbox: BBox;
  /**
   * Index
   * @min -2147483648
   * @max 2147483647
   */
  index: number;
}

export interface ProposalListCreate {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Title
   * @minLength 1
   */
  title?: string;
  /**
   * Rfp id
   * @format uuid
   */
  rfp_id: string;
  /**
   * Due date
   * @format date
   */
  due_date?: string | null;
}

export interface ProposalEligibility {
  /** Force */
  force?: boolean;
}

export interface ProposalMetadataSnapshot {
  /** ID */
  id?: number;
  /** Metadata */
  metadata: object;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Proposal
   * @format uuid
   */
  proposal?: string;
  /** Creator */
  creator?: number;
}

export interface Participant {
  /**
   * Display name
   * @minLength 1
   * @maxLength 255
   */
  display_name: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
  /** Is owner */
  is_owner?: boolean;
}

export interface BorderStyle {
  /** Size */
  size?: number;
  /**
   * Color
   * @minLength 1
   */
  color?: string;
  /** Style */
  style?: "single" | "double" | "dotted" | "dashed" | "thick" | "thin";
  /** Position */
  position?: "top" | "bottom" | "around";
}

export interface TextStyle {
  /**
   * Font
   * @minLength 1
   */
  font?: string;
  /** Font size */
  font_size?: number;
  /**
   * Font weight
   * @minLength 1
   */
  font_weight?: string;
  /** Character style */
  character_style?: "normal" | "italic" | "underline";
  /** Case style */
  case_style?: "normal" | "uppercase" | "lowercase";
  /** Padding top */
  padding_top?: number;
  /** Padding bottom */
  padding_bottom?: number;
  /** Padding left */
  padding_left?: number;
  /** Padding right */
  padding_right?: number;
  /** Margin top */
  margin_top?: number;
  /** Margin bottom */
  margin_bottom?: number;
  /** Line spacing */
  line_spacing?: number;
  /** Contextual spacing */
  contextual_spacing?: boolean;
  /**
   * Color
   * @minLength 1
   */
  color?: string;
  /**
   * Background color
   * @minLength 1
   */
  background_color?: string;
  border?: BorderStyle;
  /** Alignment */
  alignment?: "left" | "center" | "right" | "both";
}

export interface TextStyles {
  default?: TextStyle;
  p?: TextStyle;
  p_list?: TextStyle;
  h1?: TextStyle;
  h2?: TextStyle;
  h3?: TextStyle;
  h4?: TextStyle;
  h5?: TextStyle;
  h6?: TextStyle;
}

export interface PageSize {
  /** Width */
  width?: number;
  /** Height */
  height?: number;
  /** Margin top */
  margin_top?: number;
  /** Margin bottom */
  margin_bottom?: number;
  /** Margin left */
  margin_left?: number;
  /** Margin right */
  margin_right?: number;
  /** Header */
  header?: number;
  /** Footer */
  footer?: number;
  /** Orientation */
  orientation?: "portrait" | "landscape";
}

export interface TextRun {
  /**
   * Text
   * @minLength 1
   */
  text?: string;
  style?: TextStyle;
  /** Type */
  type?: "gif" | "jpg" | "png";
  /**
   * Url
   * @minLength 1
   */
  url?: string;
  /**
   * Data
   * @minLength 1
   */
  data?: string;
  /** Width */
  width?: number;
  /** Height */
  height?: number;
  /** X */
  x?: number;
  /** Y */
  y?: number;
}

export interface Paragraph {
  children: TextRun[];
  style?: TextStyle;
}

export interface StyleGuide {
  text_styles?: TextStyles;
  page_size?: PageSize;
  header?: Paragraph[];
  footer?: Paragraph[];
}

export interface ProposalDetail {
  participants?: Participant[];
  /**
   * Title
   * @minLength 1
   * @maxLength 255
   */
  title: string;
  /**
   * Rfp id
   * @minLength 1
   */
  rfp_id?: string;
  /**
   * Published at
   * @format date-time
   */
  published_at?: string | null;
  /**
   * Due date
   * @format date
   */
  due_date?: string | null;
  /**
   * Org id
   * @minLength 1
   */
  org_id?: string;
  /** Instructions */
  instructions?: string | null;
  style_guide?: StyleGuide;
  /**
   * Archived at
   * @format date-time
   */
  archived_at?: string | null;
  /**
   * Topic
   * @maxLength 255
   */
  topic?: string | null;
  /**
   * Initiated at
   * @format date-time
   */
  initiated_at?: string | null;
  /** Is initialized */
  is_initialized?: boolean;
}

export interface AIAction {
  /** Action */
  action: "turn_into_paragraphs" | "turn_into_lists" | "make_shorter" | "make_longer";
  content: (string | null)[];
  /**
   * Section id
   * @minLength 1
   */
  section_id?: string;
}

export interface PastProposalByProposalResult {
  /**
   * Proposal id
   * @minLength 1
   */
  proposal_id: string;
  /**
   * Proposal name
   * @minLength 1
   */
  proposal_name: string;
  blocks: PastProposalBlockResult[];
}

export interface PastProposalOrganizedSearchOutput {
  proposals: PastProposalByProposalResult[];
}

export interface RequirementAdd {
  /**
   * Target id
   * @minLength 1
   */
  target_id: string;
  /** Target name */
  target_name: string;
  /** Mode */
  mode: "above" | "below" | "rewrite";
  /** Level */
  level: number;
  /**
   * Rfp block id
   * @minLength 1
   */
  rfp_block_id: string;
  previous_headings?: string[];
  /**
   * Update content
   * @default true
   */
  update_content?: boolean;
}

export interface StrategyCreateInput {
  /**
   * Heading
   * @minLength 1
   */
  heading: string;
  rfp_instruction_blocks: string[];
  rfp_context_blocks: string[];
  past_proposal_blocks: string[];
  /** Custom instructions */
  custom_instructions?: string;
  /**
   * Include outline
   * @default false
   */
  include_outline?: boolean;
}

export interface StrategyCreateOutput {
  strategy: Record<string, string | null>[];
}

export interface DraftPricingInput {
  /**
   * Heading
   * @minLength 1
   */
  heading: string;
  rfp_instruction_blocks: string[];
  rfp_context_blocks: string[];
  past_proposal_blocks: string[];
  /** Use fixture */
  use_fixture: boolean;
}

export interface DraftTimelineInput {
  /**
   * Heading
   * @minLength 1
   */
  heading: string;
  rfp_instruction_blocks: string[];
  rfp_context_blocks: string[];
  past_proposal_blocks: string[];
  /** Use fixture */
  use_fixture: boolean;
}

export interface DraftInput {
  /**
   * Heading
   * @minLength 1
   */
  heading: string;
  strategy: Record<string, string | null>[];
  rfp_instruction_block_ids: string[];
  /**
   * Length
   * @minLength 1
   */
  length: string;
  /** Use fixture */
  use_fixture: boolean;
}

export interface RequirementGenerate {
  /**
   * Use fixture
   * @default false
   */
  use_fixture?: boolean;
  /** Feedback */
  feedback?: string;
  /**
   * Title
   * @minLength 1
   */
  title: string;
  /**
   * Outline only
   * @default false
   */
  outline_only?: boolean;
  /**
   * Heading level
   * @default 1
   */
  heading_level?: number;
  /**
   * Include previous content
   * @default true
   */
  include_previous_content?: boolean;
  /**
   * Include existing content
   * @default true
   */
  include_existing_content?: boolean;
  /**
   * Tailored
   * @default true
   */
  tailored?: boolean;
}

export interface RequirementRelevantExperienceGenerate {
  /**
   * Use fixture
   * @default false
   */
  use_fixture?: boolean;
  selected_project_ids: string[];
}

export interface RequirementStatus {
  /** Id */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Icon
   * @minLength 1
   */
  icon?: string;
}

export interface ProposalGrant {
  /**
   * User id
   * @format uuid
   */
  user_id: string;
  /**
   * Make owner
   * @default false
   */
  make_owner?: boolean;
}

export interface RFPMultiList {
  rfp_ids: string[];
}

export interface RFPSearch {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Display name
   * @minLength 1
   */
  display_name: string;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Due date
   * @format date
   */
  due_date: string;
  /** Budget */
  budget?: string;
  /** Issuing org */
  issuing_org?: string;
  /** Location */
  location?: string;
  /** Description */
  description?: string;
}

export interface KeyDate {
  /**
   * Date
   * @minLength 1
   */
  date: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface RFPNAICSCode {
  /**
   * Id
   * @minLength 1
   * @maxLength 6
   */
  id: string;
  /**
   * Title
   * @minLength 1
   */
  title: string;
  /**
   * Description
   * @minLength 1
   */
  description: string;
}

export type ShortlistedList = object;

export interface RFPDetail {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /** View url */
  view_url?: string;
  /** Download url */
  download_url?: string;
  /** Markdown url */
  markdown_url?: string;
  /**
   * Processing job id
   * @min -2147483648
   * @max 2147483647
   */
  processing_job_id?: number | null;
  /**
   * Project name
   * @maxLength 1024
   */
  project_name?: string | null;
  /** Issuing org */
  issuing_org?: string;
  /**
   * Slug
   * @format slug
   * @minLength 1
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug?: string | null;
  start_questions?: string[];
  key_dates?: KeyDate[];
  minimum_qualifications?: string[];
  /** Description */
  description?: string;
  tasks?: string[];
  /**
   * Due date
   * @format date
   */
  due_date: string;
  /**
   * Display name
   * @minLength 1
   */
  display_name: string;
  /** Budget */
  budget?: string;
  /** Location */
  location?: string;
  naics_codes?: RFPNAICSCode[];
  shortlisted_lists?: ShortlistedList[];
  /** Questionable status */
  questionable_status?: "ready" | "not-processing" | "processing";
}

export interface RFPAnswerInput {
  /**
   * Question
   * @minLength 1
   */
  question: string;
}

export interface RFPAllBlock {
  /** Id */
  id: number;
  bbox: BBox;
  /**
   * Page
   * @min -2147483648
   * @max 2147483647
   */
  page: number;
  /**
   * Type
   * @minLength 1
   * @maxLength 255
   */
  type: string;
  /**
   * Index
   * @min -2147483648
   * @max 2147483647
   */
  index: number;
}

export interface RFPREquirement {
  /** ID */
  id?: number;
  /**
   * Description
   * @minLength 1
   */
  description: string;
  /**
   * Category
   * @minLength 1
   * @maxLength 255
   */
  category: string;
}

export interface RFPBlock {
  /**
   * Index
   * @min -2147483648
   * @max 2147483647
   */
  index: number;
  bbox: BBox;
  /**
   * Page
   * @min -2147483648
   * @max 2147483647
   */
  page: number;
  /**
   * Group id
   * @min -2147483648
   * @max 2147483647
   */
  group_id?: number | null;
  requirements: RFPREquirement[];
}

export interface SavedSearchUser {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
}

export interface SavedSearchList {
  /** Id */
  id: number;
  /** Is default */
  is_default?: boolean;
  /** Display name */
  display_name?: string;
  user?: SavedSearchUser;
}

export interface SavedSearchDetail {
  /** Id */
  id?: number;
  /**
   * Name
   * @maxLength 255
   */
  name?: string | null;
  /** Is default */
  is_default?: boolean;
  locations: string[];
  keywords: string[];
  exclude_keywords: string[];
  naics_codes: string[];
  /**
   * Posted before
   * @format date
   */
  posted_before?: string | null;
  /**
   * Posted after
   * @format date
   */
  posted_after?: string | null;
  /**
   * Due before
   * @format date
   */
  due_before?: string | null;
  /**
   * Due after
   * @format date
   */
  due_after?: string | null;
  /**
   * Posted date range
   * @default "all"
   */
  posted_date_range?: "all" | "today" | "less_than_1_week_ago" | "less_than_2_weeks_ago";
  /**
   * Due date range
   * @default "all"
   */
  due_date_range?: "all" | "more_than_1_week" | "more_than_2_weeks" | "more_than_3_weeks" | "more_than_4_weeks";
  user?: SavedSearchUser;
  /** Save as new search */
  save_as_new_search?: boolean | null;
  customers: string[];
}

export interface CategoryList {
  /**
   * Id
   * @minLength 1
   */
  id: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface SuggestInput {
  /** Freeform description */
  freeform_description?: string;
  categories?: (
    | "auditing"
    | "web_development"
    | "economic_consulting"
    | "cyber_security"
    | "education"
    | "healthcare"
    | "information_technology"
    | "infrastructure"
    | "marketing_advertising"
    | "transportation"
  )[];
}

export interface SuggestOutput {
  keywords: string[];
  codes: string[];
}

export interface Choice {
  /** Copy search id */
  copy_search_id?: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "/api/v1" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Document Editor API
 * @version v1
 * @baseUrl /api/v1
 *
 * API for the Document Editor web app
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  user = {
    /**
     * No description
     *
     * @tags collab_docs
     * @name CollabDocsPermissionList
     * @summary Check if the user has permission to access a collaborative document
     * @request GET:/collab_docs/user/{id}/permission/
     * @secure
     */
    collabDocsPermissionList: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          allow?: boolean;
          auth_data?: object;
        },
        any
      >({
        path: `/collab_docs/user/${id}/permission/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreApplyCodeCreate
     * @request POST:/core/user/apply-code/
     * @secure
     */
    coreApplyCodeCreate: (data: ApplyCode, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/core/user/apply-code/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreAuthLogout
     * @request POST:/core/user/auth/logout/
     * @secure
     */
    coreAuthLogout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/core/user/auth/logout/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreAuthTokenCreate
     * @request POST:/core/user/auth/token/
     * @secure
     */
    coreAuthTokenCreate: (data: EmailTokenObtainPair, params: RequestParams = {}) =>
      this.request<UserDetailPostAuth, any>({
        path: `/core/user/auth/token/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreAuthTokenRefresh
     * @request POST:/core/user/auth/token/refresh/
     * @secure
     */
    coreAuthTokenRefresh: (params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/core/user/auth/token/refresh/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgRead
     * @request GET:/core/user/org/
     * @secure
     */
    coreOrgRead: (params: RequestParams = {}) =>
      this.request<OrgDetails, any>({
        path: `/core/user/org/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgUpdate
     * @request PUT:/core/user/org/
     * @secure
     */
    coreOrgUpdate: (data: OrgDetails, params: RequestParams = {}) =>
      this.request<OrgDetails, any>({
        path: `/core/user/org/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgPartialUpdate
     * @request PATCH:/core/user/org/
     * @secure
     */
    coreOrgPartialUpdate: (data: OrgDetails, params: RequestParams = {}) =>
      this.request<OrgDetails, any>({
        path: `/core/user/org/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgAuthList
     * @summary Check if the user has permission to access a collaborative document
     * @request GET:/core/user/org/{org_id}/auth/
     * @secure
     */
    coreOrgAuthList: (orgId: string, params: RequestParams = {}) =>
      this.request<
        {
          allow?: boolean;
          auth_data?: object;
        },
        any
      >({
        path: `/core/user/org/${orgId}/auth/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgUsersList
     * @request GET:/core/user/org/{org_id}/users/
     * @secure
     */
    coreOrgUsersList: (orgId: string, params: RequestParams = {}) =>
      this.request<OdoUser[], any>({
        path: `/core/user/org/${orgId}/users/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreUnsubscribeCreate
     * @request POST:/core/user/unsubscribe/
     * @secure
     */
    coreUnsubscribeCreate: (data: Unsubscribe, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/core/user/unsubscribe/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreUserRead
     * @request GET:/core/user/user/
     * @secure
     */
    coreUserRead: (params: RequestParams = {}) =>
      this.request<UserDetailPostAuth, any>({
        path: `/core/user/user/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreUserUpdate
     * @request PUT:/core/user/user/
     * @secure
     */
    coreUserUpdate: (data: UserDetailPostAuth, params: RequestParams = {}) =>
      this.request<UserDetailPostAuth, any>({
        path: `/core/user/user/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreUserPartialUpdate
     * @request PATCH:/core/user/user/
     * @secure
     */
    coreUserPartialUpdate: (data: UserDetailPostAuth, params: RequestParams = {}) =>
      this.request<UserDetailPostAuth, any>({
        path: `/core/user/user/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags images
     * @name ImagesCreate
     * @request POST:/images/user/
     * @secure
     */
    imagesCreate: (data: ImageCreate, params: RequestParams = {}) =>
      this.request<ImageCreate, any>({
        path: `/images/user/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags images
     * @name ImagesRead
     * @request GET:/images/user/{id}/
     * @secure
     */
    imagesRead: (id: string, params: RequestParams = {}) =>
      this.request<ImageDetails, any>({
        path: `/images/user/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags images
     * @name ImagesMarkUploaded
     * @request POST:/images/user/{id}/mark-uploaded/
     * @secure
     */
    imagesMarkUploaded: (id: string, params: RequestParams = {}) =>
      this.request<ImageDetails, any>({
        path: `/images/user/${id}/mark-uploaded/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns whether the user can debug
     *
     * @tags metrics
     * @name CanDebug
     * @request GET:/metrics/user/can-debug/
     * @secure
     */
    canDebug: (params: RequestParams = {}) =>
      this.request<
        {
          can_debug?: boolean;
        },
        any
      >({
        path: `/metrics/user/can-debug/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name MetricsEventCreate
     * @request POST:/metrics/user/event/
     * @secure
     */
    metricsEventCreate: (data: EventCreate, params: RequestParams = {}) =>
      this.request<EventCreate, any>({
        path: `/metrics/user/event/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name MetricsEventCapture
     * @request POST:/metrics/user/event/capture/
     * @secure
     */
    metricsEventCapture: (data: EventCapture, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/metrics/user/event/capture/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCreateCreate
     * @request POST:/rfp/user/content-library/create/
     * @secure
     */
    rfpContentLibraryCreateCreate: (data: ItemDetails, params: RequestParams = {}) =>
      this.request<ItemDetails, any>({
        path: `/rfp/user/content-library/create/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPastProposalAssociationCreate
     * @request POST:/rfp/user/content-library/past_proposal/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryPastProposalAssociationCreate: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/content-library/past_proposal/${id}/association/${proposalId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPastProposalAssociationDelete
     * @request DELETE:/rfp/user/content-library/past_proposal/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryPastProposalAssociationDelete: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/content-library/past_proposal/${id}/association/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySearchCreate
     * @request POST:/rfp/user/content-library/search/
     * @secure
     */
    rfpContentLibrarySearchCreate: (data: SearchInput, params: RequestParams = {}) =>
      this.request<PaginatedItemSearch, any>({
        path: `/rfp/user/content-library/search/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryRead
     * @request GET:/rfp/user/content-library/{id}/
     * @secure
     */
    rfpContentLibraryRead: (id: string, params: RequestParams = {}) =>
      this.request<ItemDetails, any>({
        path: `/rfp/user/content-library/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryUpdate
     * @request PUT:/rfp/user/content-library/{id}/
     * @secure
     */
    rfpContentLibraryUpdate: (id: string, data: ItemDetails, params: RequestParams = {}) =>
      this.request<ItemDetails, any>({
        path: `/rfp/user/content-library/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPartialUpdate
     * @request PATCH:/rfp/user/content-library/{id}/
     * @secure
     */
    rfpContentLibraryPartialUpdate: (id: string, data: ItemDetails, params: RequestParams = {}) =>
      this.request<ItemDetails, any>({
        path: `/rfp/user/content-library/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryDelete
     * @request DELETE:/rfp/user/content-library/{id}/
     * @secure
     */
    rfpContentLibraryDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/content-library/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryAssociationCreate
     * @request POST:/rfp/user/content-library/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryAssociationCreate: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/content-library/${id}/association/${proposalId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryAssociationDelete
     * @request DELETE:/rfp/user/content-library/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryAssociationDelete: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/content-library/${id}/association/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryInsertCreate
     * @request POST:/rfp/user/content-library/{id}/insert/{proposal_id}/{requirement_id}/
     * @secure
     */
    rfpContentLibraryInsertCreate: (
      id: string,
      proposalId: string,
      requirementId: string,
      data: ItemInsert,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/user/content-library/${id}/insert/${proposalId}/${requirementId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpFinalCheckList
     * @request GET:/rfp/user/final-check/
     * @secure
     */
    rfpFinalCheckList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: FinalCheckList[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/rfp/user/final-check/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpListsAddedCreate
     * @request POST:/rfp/user/lists/{list_id}/added/
     * @secure
     */
    rfpListsAddedCreate: (listId: string, data: AddedRFPToList, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/lists/${listId}/added/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpListsRemovedCreate
     * @request POST:/rfp/user/lists/{list_id}/removed/
     * @secure
     */
    rfpListsRemovedCreate: (listId: string, data: RemovedRFPFromList, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/lists/${listId}/removed/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpNaicsSearchList
     * @request GET:/rfp/user/naics/search/
     * @secure
     */
    rfpNaicsSearchList: (params: RequestParams = {}) =>
      this.request<NAICSSector[], any>({
        path: `/rfp/user/naics/search/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpNaicsSelectionCreate
     * @request POST:/rfp/user/naics/selection/
     * @secure
     */
    rfpNaicsSelectionCreate: (data: NAICSSelection, params: RequestParams = {}) =>
      this.request<NAICSSector[], any>({
        path: `/rfp/user/naics/selection/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalList
     * @request GET:/rfp/user/past-proposal/
     * @secure
     */
    rfpPastProposalList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: PastProposalList[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/rfp/user/past-proposal/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalBlocksSearchList
     * @request GET:/rfp/user/past-proposal/blocks/search/
     * @secure
     */
    rfpPastProposalBlocksSearchList: (
      query?: {
        /** @minLength 1 */
        search?: string;
        /** @default false */
        include_paragraphs?: boolean;
        /** @default false */
        include_sentences?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PastProposalBlockSearchOutput, any>({
        path: `/rfp/user/past-proposal/blocks/search/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalBlocksRead
     * @request GET:/rfp/user/past-proposal/blocks/{id}/
     * @secure
     */
    rfpPastProposalBlocksRead: (id: string, params: RequestParams = {}) =>
      this.request<PastProposalBlockWithProposal, any>({
        path: `/rfp/user/past-proposal/blocks/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalCreateCreate
     * @request POST:/rfp/user/past-proposal/create/
     * @secure
     */
    rfpPastProposalCreateCreate: (data: PastProposalCreate, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
          upload_url?: string;
          topic?: string;
        },
        any
      >({
        path: `/rfp/user/past-proposal/create/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalRead
     * @request GET:/rfp/user/past-proposal/{id}/
     * @secure
     */
    rfpPastProposalRead: (id: string, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/user/past-proposal/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalUpdate
     * @request PUT:/rfp/user/past-proposal/{id}/
     * @secure
     */
    rfpPastProposalUpdate: (id: string, data: PastProposalDetail, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/user/past-proposal/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalPartialUpdate
     * @request PATCH:/rfp/user/past-proposal/{id}/
     * @secure
     */
    rfpPastProposalPartialUpdate: (id: string, data: PastProposalDetail, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/user/past-proposal/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalFinalizeCreate
     * @request POST:/rfp/user/past-proposal/{id}/finalize/
     * @secure
     */
    rfpPastProposalFinalizeCreate: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/past-proposal/${id}/finalize/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpPastProposalBlocksList
     * @request GET:/rfp/user/past-proposal/{past_proposal_id}/blocks/
     * @secure
     */
    rfpPastProposalBlocksList: (pastProposalId: string, params: RequestParams = {}) =>
      this.request<PastProposalBlock[], any>({
        path: `/rfp/user/past-proposal/${pastProposalId}/blocks/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalList
     * @request GET:/rfp/user/proposal/
     * @secure
     */
    rfpProposalList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ProposalListCreate[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/rfp/user/proposal/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalCreate
     * @request POST:/rfp/user/proposal/
     * @secure
     */
    rfpProposalCreate: (data: ProposalListCreate, params: RequestParams = {}) =>
      this.request<ProposalListCreate, any>({
        path: `/rfp/user/proposal/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalEligibilityGet
     * @request POST:/rfp/user/proposal/eligibility/
     * @secure
     */
    rfpProposalEligibilityGet: (data: ProposalEligibility, params: RequestParams = {}) =>
      this.request<
        {
          eligibility?: "demo_required" | "trial" | "trial_expired" | "allowed";
        },
        any
      >({
        path: `/rfp/user/proposal/eligibility/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalInactiveList
     * @request GET:/rfp/user/proposal/inactive/
     * @secure
     */
    rfpProposalInactiveList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ProposalListCreate[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/rfp/user/proposal/inactive/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalInactiveCreate
     * @request POST:/rfp/user/proposal/inactive/
     * @secure
     */
    rfpProposalInactiveCreate: (data: ProposalListCreate, params: RequestParams = {}) =>
      this.request<ProposalListCreate, any>({
        path: `/rfp/user/proposal/inactive/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalSnapshotCreate
     * @request POST:/rfp/user/proposal/snapshot/{proposal_id}/
     * @secure
     */
    rfpProposalSnapshotCreate: (proposalId: string, data: ProposalMetadataSnapshot, params: RequestParams = {}) =>
      this.request<ProposalMetadataSnapshot, any>({
        path: `/rfp/user/proposal/snapshot/${proposalId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRead
     * @request GET:/rfp/user/proposal/{proposal_id}/
     * @secure
     */
    rfpProposalRead: (proposalId: string, params: RequestParams = {}) =>
      this.request<ProposalDetail, any>({
        path: `/rfp/user/proposal/${proposalId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalUpdate
     * @request PUT:/rfp/user/proposal/{proposal_id}/
     * @secure
     */
    rfpProposalUpdate: (proposalId: string, data: ProposalDetail, params: RequestParams = {}) =>
      this.request<ProposalDetail, any>({
        path: `/rfp/user/proposal/${proposalId}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPartialUpdate
     * @request PATCH:/rfp/user/proposal/{proposal_id}/
     * @secure
     */
    rfpProposalPartialUpdate: (proposalId: string, data: ProposalDetail, params: RequestParams = {}) =>
      this.request<ProposalDetail, any>({
        path: `/rfp/user/proposal/${proposalId}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalDelete
     * @request DELETE:/rfp/user/proposal/{proposal_id}/
     * @secure
     */
    rfpProposalDelete: (proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/proposal/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalAiActionCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/ai_action/
     * @secure
     */
    rfpProposalAiActionCreate: (proposalId: string, data: AIAction, params: RequestParams = {}) =>
      this.request<AIAction, any>({
        path: `/rfp/user/proposal/${proposalId}/ai_action/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalAuthList
     * @request GET:/rfp/user/proposal/{proposal_id}/auth/
     * @secure
     */
    rfpProposalAuthList: (proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/proposal/${proposalId}/auth/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalExportList
     * @request GET:/rfp/user/proposal/{proposal_id}/export/
     * @secure
     */
    rfpProposalExportList: (proposalId: string, params: RequestParams = {}) =>
      this.request<
        {
          download_url?: string;
        },
        any
      >({
        path: `/rfp/user/proposal/${proposalId}/export/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastProposalList
     * @request GET:/rfp/user/proposal/{proposal_id}/past_proposal/
     * @secure
     */
    rfpProposalPastProposalList: (
      proposalId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: PastProposalList[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/rfp/user/proposal/${proposalId}/past_proposal/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastProposalBlocksSearchList
     * @request GET:/rfp/user/proposal/{proposal_id}/past_proposal/blocks/search/
     * @secure
     */
    rfpProposalPastProposalBlocksSearchList: (
      proposalId: string,
      query?: {
        /** @minLength 1 */
        search?: string;
        /** @default false */
        include_paragraphs?: boolean;
        /** @default false */
        include_sentences?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PastProposalOrganizedSearchOutput, any>({
        path: `/rfp/user/proposal/${proposalId}/past_proposal/blocks/search/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastProposalRead
     * @request GET:/rfp/user/proposal/{proposal_id}/past_proposal/{id}/
     * @secure
     */
    rfpProposalPastProposalRead: (proposalId: string, id: string, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/user/proposal/${proposalId}/past_proposal/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastProposalUpdate
     * @request PUT:/rfp/user/proposal/{proposal_id}/past_proposal/{id}/
     * @secure
     */
    rfpProposalPastProposalUpdate: (
      proposalId: string,
      id: string,
      data: PastProposalDetail,
      params: RequestParams = {},
    ) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/user/proposal/${proposalId}/past_proposal/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastProposalPartialUpdate
     * @request PATCH:/rfp/user/proposal/{proposal_id}/past_proposal/{id}/
     * @secure
     */
    rfpProposalPastProposalPartialUpdate: (
      proposalId: string,
      id: string,
      data: PastProposalDetail,
      params: RequestParams = {},
    ) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/user/proposal/${proposalId}/past_proposal/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequestPermissionCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/request_permission/
     * @secure
     */
    rfpProposalRequestPermissionCreate: (proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/proposal/${proposalId}/request_permission/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementCreateCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/requirement/create/
     * @secure
     */
    rfpProposalRequirementCreateCreate: (proposalId: string, data: RequirementAdd, params: RequestParams = {}) =>
      this.request<RequirementAdd, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement/create/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementStrategyCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/requirement/strategy
     * @secure
     */
    rfpProposalRequirementStrategyCreate: (proposalId: string, data: StrategyCreateInput, params: RequestParams = {}) =>
      this.request<StrategyCreateOutput, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement/strategy`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementDraftPricingCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/requirement/{requirement_id}/draft-pricing/
     * @secure
     */
    rfpProposalRequirementDraftPricingCreate: (
      proposalId: string,
      requirementId: string,
      data: DraftPricingInput,
      params: RequestParams = {},
    ) =>
      this.request<DraftPricingInput, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement/${requirementId}/draft-pricing/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementDraftTimelineCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/requirement/{requirement_id}/draft-timeline/
     * @secure
     */
    rfpProposalRequirementDraftTimelineCreate: (
      proposalId: string,
      requirementId: string,
      data: DraftTimelineInput,
      params: RequestParams = {},
    ) =>
      this.request<DraftTimelineInput, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement/${requirementId}/draft-timeline/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementDraftCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/requirement/{requirement_id}/draft/
     * @secure
     */
    rfpProposalRequirementDraftCreate: (
      proposalId: string,
      requirementId: string,
      data: DraftInput,
      params: RequestParams = {},
    ) =>
      this.request<DraftInput, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement/${requirementId}/draft/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementGenerateCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/requirement/{requirement_id}/generate/
     * @secure
     */
    rfpProposalRequirementGenerateCreate: (
      proposalId: string,
      requirementId: string,
      data: RequirementGenerate,
      params: RequestParams = {},
    ) =>
      this.request<RequirementGenerate, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement/${requirementId}/generate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementGenerateDelete
     * @request DELETE:/rfp/user/proposal/{proposal_id}/requirement/{requirement_id}/generate/
     * @secure
     */
    rfpProposalRequirementGenerateDelete: (proposalId: string, requirementId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement/${requirementId}/generate/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementGenerateRelevantExperienceCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/requirement/{requirement_id}/generate_relevant_experience/
     * @secure
     */
    rfpProposalRequirementGenerateRelevantExperienceCreate: (
      proposalId: string,
      requirementId: string,
      data: RequirementRelevantExperienceGenerate,
      params: RequestParams = {},
    ) =>
      this.request<RequirementRelevantExperienceGenerate, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement/${requirementId}/generate_relevant_experience/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementStatusList
     * @request GET:/rfp/user/proposal/{proposal_id}/requirement_status/
     * @secure
     */
    rfpProposalRequirementStatusList: (proposalId: string, params: RequestParams = {}) =>
      this.request<RequirementStatus[], any>({
        path: `/rfp/user/proposal/${proposalId}/requirement_status/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementStatusCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/requirement_status/
     * @secure
     */
    rfpProposalRequirementStatusCreate: (proposalId: string, data: RequirementStatus, params: RequestParams = {}) =>
      this.request<RequirementStatus, any>({
        path: `/rfp/user/proposal/${proposalId}/requirement_status/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalUsersCreate
     * @request POST:/rfp/user/proposal/{proposal_id}/users/
     * @secure
     */
    rfpProposalUsersCreate: (proposalId: string, data: ProposalGrant, params: RequestParams = {}) =>
      this.request<
        {
          message?: string;
        },
        any
      >({
        path: `/rfp/user/proposal/${proposalId}/users/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalUsersDelete
     * @request DELETE:/rfp/user/proposal/{proposal_id}/users/{user_id}/
     * @secure
     */
    rfpProposalUsersDelete: (proposalId: string, userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/proposal/${proposalId}/users/${userId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpCreateCreate
     * @request POST:/rfp/user/rfp/create/
     * @secure
     */
    rfpRfpCreateCreate: (data: RFPCreate, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
          upload_url?: string;
        },
        any
      >({
        path: `/rfp/user/rfp/create/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get multiple RFPs by their IDs
     *
     * @tags rfp
     * @name RfpRfpMultiListCreate
     * @request POST:/rfp/user/rfp/multi-list/
     * @secure
     */
    rfpRfpMultiListCreate: (data: RFPMultiList, params: RequestParams = {}) =>
      this.request<RFPSearch[], any>({
        path: `/rfp/user/rfp/multi-list/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpSearchList
     * @request GET:/rfp/user/rfp/search/
     * @secure
     */
    rfpRfpSearchList: (
      query?: {
        /** A search term. */
        search?: string;
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPSearch[];
          /** The current page number. */
          page?: number;
          /** The total number of pages. */
          page_count?: number;
        },
        any
      >({
        path: `/rfp/user/rfp/search/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpRead
     * @request GET:/rfp/user/rfp/{rfp_id}/
     * @secure
     */
    rfpRfpRead: (rfpId: string, params: RequestParams = {}) =>
      this.request<RFPDetail, any>({
        path: `/rfp/user/rfp/${rfpId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpUpdate
     * @request PUT:/rfp/user/rfp/{rfp_id}/
     * @secure
     */
    rfpRfpUpdate: (rfpId: string, data: RFPDetail, params: RequestParams = {}) =>
      this.request<RFPDetail, any>({
        path: `/rfp/user/rfp/${rfpId}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpPartialUpdate
     * @request PATCH:/rfp/user/rfp/{rfp_id}/
     * @secure
     */
    rfpRfpPartialUpdate: (rfpId: string, data: RFPDetail, params: RequestParams = {}) =>
      this.request<RFPDetail, any>({
        path: `/rfp/user/rfp/${rfpId}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Answer a question about an RFP
     *
     * @tags rfp
     * @name RfpRfpAnswerCreate
     * @request POST:/rfp/user/rfp/{rfp_id}/answer/
     * @secure
     */
    rfpRfpAnswerCreate: (rfpId: string, data: RFPAnswerInput, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/rfp/user/rfp/${rfpId}/answer/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpBlocksList
     * @request GET:/rfp/user/rfp/{rfp_id}/blocks/
     * @secure
     */
    rfpRfpBlocksList: (rfpId: string, params: RequestParams = {}) =>
      this.request<RFPAllBlock[], any>({
        path: `/rfp/user/rfp/${rfpId}/blocks/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesList
     * @request GET:/rfp/user/rfp/{rfp_id}/files/
     * @secure
     */
    rfpRfpFilesList: (rfpId: string, params: RequestParams = {}) =>
      this.request<RFPFile[], any>({
        path: `/rfp/user/rfp/${rfpId}/files/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesCreate
     * @request POST:/rfp/user/rfp/{rfp_id}/files/
     * @secure
     */
    rfpRfpFilesCreate: (rfpId: string, data: RFPFile, params: RequestParams = {}) =>
      this.request<RFPFile, any>({
        path: `/rfp/user/rfp/${rfpId}/files/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesRead
     * @request GET:/rfp/user/rfp/{rfp_id}/files/{id}/
     * @secure
     */
    rfpRfpFilesRead: (rfpId: string, id: string, params: RequestParams = {}) =>
      this.request<RFPFileDetail, any>({
        path: `/rfp/user/rfp/${rfpId}/files/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesUpdate
     * @request PUT:/rfp/user/rfp/{rfp_id}/files/{id}/
     * @secure
     */
    rfpRfpFilesUpdate: (rfpId: string, id: string, data: RFPFileDetail, params: RequestParams = {}) =>
      this.request<RFPFileDetail, any>({
        path: `/rfp/user/rfp/${rfpId}/files/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesPartialUpdate
     * @request PATCH:/rfp/user/rfp/{rfp_id}/files/{id}/
     * @secure
     */
    rfpRfpFilesPartialUpdate: (rfpId: string, id: string, data: RFPFileDetail, params: RequestParams = {}) =>
      this.request<RFPFileDetail, any>({
        path: `/rfp/user/rfp/${rfpId}/files/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesDelete
     * @request DELETE:/rfp/user/rfp/{rfp_id}/files/{id}/
     * @secure
     */
    rfpRfpFilesDelete: (rfpId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/rfp/${rfpId}/files/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpMarkUploadedCreate
     * @request POST:/rfp/user/rfp/{rfp_id}/mark-uploaded/
     * @secure
     */
    rfpRfpMarkUploadedCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/rfp/${rfpId}/mark-uploaded/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpSearchCreate
     * @request POST:/rfp/user/rfp/{rfp_id}/search/
     * @secure
     */
    rfpRfpSearchCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/rfp/${rfpId}/search/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpBlocksList2
     * @request GET:/rfp/user/rfp/{rfp_id}/{page}/blocks/
     * @originalName rfpRfpBlocksList
     * @duplicate
     * @secure
     */
    rfpRfpBlocksList2: (rfpId: string, page: string, params: RequestParams = {}) =>
      this.request<RFPBlock[], any>({
        path: `/rfp/user/rfp/${rfpId}/${page}/blocks/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchList
     * @request GET:/rfp/user/saved-search/
     * @secure
     */
    rfpSavedSearchList: (
      query?: {
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: SavedSearchList[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/rfp/user/saved-search/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchCreate
     * @request POST:/rfp/user/saved-search/
     * @secure
     */
    rfpSavedSearchCreate: (data: SavedSearchList, params: RequestParams = {}) =>
      this.request<SavedSearchList, any>({
        path: `/rfp/user/saved-search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchCurrentRead
     * @request GET:/rfp/user/saved-search/current/
     * @secure
     */
    rfpSavedSearchCurrentRead: (params: RequestParams = {}) =>
      this.request<SavedSearchDetail, any>({
        path: `/rfp/user/saved-search/current/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchCurrentUpdate
     * @request PUT:/rfp/user/saved-search/current/
     * @secure
     */
    rfpSavedSearchCurrentUpdate: (data: SavedSearchDetail, params: RequestParams = {}) =>
      this.request<SavedSearchDetail, any>({
        path: `/rfp/user/saved-search/current/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchCurrentPartialUpdate
     * @request PATCH:/rfp/user/saved-search/current/
     * @secure
     */
    rfpSavedSearchCurrentPartialUpdate: (data: SavedSearchDetail, params: RequestParams = {}) =>
      this.request<SavedSearchDetail, any>({
        path: `/rfp/user/saved-search/current/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchCurrentDelete
     * @request DELETE:/rfp/user/saved-search/current/
     * @secure
     */
    rfpSavedSearchCurrentDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/saved-search/current/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Get list of available categories
     *
     * @tags rfp
     * @name RfpSavedSearchSuggestList
     * @request GET:/rfp/user/saved-search/suggest/
     * @secure
     */
    rfpSavedSearchSuggestList: (params: RequestParams = {}) =>
      this.request<CategoryList[], any>({
        path: `/rfp/user/saved-search/suggest/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Suggest keywords and NAICS codes for a saved search
     *
     * @tags rfp
     * @name RfpSavedSearchSuggestCreate
     * @request POST:/rfp/user/saved-search/suggest/
     * @secure
     */
    rfpSavedSearchSuggestCreate: (data: SuggestInput, params: RequestParams = {}) =>
      this.request<SuggestOutput, any>({
        path: `/rfp/user/saved-search/suggest/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchRead
     * @request GET:/rfp/user/saved-search/{id}/
     * @secure
     */
    rfpSavedSearchRead: (id: string, params: RequestParams = {}) =>
      this.request<SavedSearchDetail, any>({
        path: `/rfp/user/saved-search/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchUpdate
     * @request PUT:/rfp/user/saved-search/{id}/
     * @secure
     */
    rfpSavedSearchUpdate: (id: string, data: SavedSearchDetail, params: RequestParams = {}) =>
      this.request<SavedSearchDetail, any>({
        path: `/rfp/user/saved-search/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchPartialUpdate
     * @request PATCH:/rfp/user/saved-search/{id}/
     * @secure
     */
    rfpSavedSearchPartialUpdate: (id: string, data: SavedSearchDetail, params: RequestParams = {}) =>
      this.request<SavedSearchDetail, any>({
        path: `/rfp/user/saved-search/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpSavedSearchDelete
     * @request DELETE:/rfp/user/saved-search/{id}/
     * @secure
     */
    rfpSavedSearchDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/user/saved-search/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Choose a saved search
     *
     * @tags rfp
     * @name RfpSavedSearchChooseCreate
     * @request POST:/rfp/user/saved-search/{id}/choose/
     * @secure
     */
    rfpSavedSearchChooseCreate: (id: string, data: Choice, params: RequestParams = {}) =>
      this.request<SavedSearchDetail, any>({
        path: `/rfp/user/saved-search/${id}/choose/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags core
     * @name CoreDebugRead
     * @request GET:/core/admin/debug/
     * @secure
     */
    coreDebugRead: (params: RequestParams = {}) =>
      this.request<Debug, any>({
        path: `/core/admin/debug/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreDebugUpdate
     * @request PUT:/core/admin/debug/
     * @secure
     */
    coreDebugUpdate: (data: Debug, params: RequestParams = {}) =>
      this.request<Debug, any>({
        path: `/core/admin/debug/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreDebugPartialUpdate
     * @request PATCH:/core/admin/debug/
     * @secure
     */
    coreDebugPartialUpdate: (data: Debug, params: RequestParams = {}) =>
      this.request<Debug, any>({
        path: `/core/admin/debug/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgList
     * @request GET:/core/admin/org/
     * @secure
     */
    coreOrgList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Org[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/core/admin/org/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgCreateCreate
     * @request POST:/core/admin/org/create/
     * @secure
     */
    coreOrgCreateCreate: (data: OrgCreate, params: RequestParams = {}) =>
      this.request<OrgCreate, any>({
        path: `/core/admin/org/create/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgSet
     * @request POST:/core/admin/org/set/
     * @secure
     */
    coreOrgSet: (data: OrgSet, params: RequestParams = {}) =>
      this.request<OrgSet, any>({
        path: `/core/admin/org/set/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgRead
     * @request GET:/core/admin/org/{org_id}/
     * @secure
     */
    coreOrgRead: (orgId: string, params: RequestParams = {}) =>
      this.request<AdminOrgDetails, any>({
        path: `/core/admin/org/${orgId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgUpdate
     * @request PUT:/core/admin/org/{org_id}/
     * @secure
     */
    coreOrgUpdate: (orgId: string, data: AdminOrgDetails, params: RequestParams = {}) =>
      this.request<AdminOrgDetails, any>({
        path: `/core/admin/org/${orgId}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgPartialUpdate
     * @request PATCH:/core/admin/org/{org_id}/
     * @secure
     */
    coreOrgPartialUpdate: (orgId: string, data: AdminOrgDetails, params: RequestParams = {}) =>
      this.request<AdminOrgDetails, any>({
        path: `/core/admin/org/${orgId}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreOrgDelete
     * @request DELETE:/core/admin/org/{org_id}/
     * @secure
     */
    coreOrgDelete: (orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/core/admin/org/${orgId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name MetricsEventsDocRead
     * @request GET:/metrics/admin/events/doc/{id}/
     * @secure
     */
    metricsEventsDocRead: (id: string, params: RequestParams = {}) =>
      this.request<Event[], any>({
        path: `/metrics/admin/events/doc/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name MetricsEventsUserList
     * @request GET:/metrics/admin/events/user/
     * @secure
     */
    metricsEventsUserList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Event[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/metrics/admin/events/user/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new user using pydantic model
     *
     * @tags prompt_refinery
     * @name PromptRefineryArbitraryCreate
     * @request POST:/prompt_refinery/admin/arbitrary/
     * @secure
     */
    promptRefineryArbitraryCreate: (
      data: {
        /**
         * Model
         * @default "gpt-4"
         */
        model?: string;
        /**
         * Temperature
         * @default 0.2
         */
        temperature?: number;
        /** Messages */
        messages: any[];
        /**
         * Tools
         * @default null
         */
        tools?: object[];
        /**
         * Functions
         * @default null
         */
        functions?: any[];
      },
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/prompt_refinery/admin/arbitrary/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryAuthList
     * @request GET:/prompt_refinery/admin/auth/
     * @secure
     */
    promptRefineryAuthList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prompt_refinery/admin/auth/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptList
     * @request GET:/prompt_refinery/admin/prompt/
     * @secure
     */
    promptRefineryPromptList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Prompt[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/prompt_refinery/admin/prompt/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptCreate
     * @request POST:/prompt_refinery/admin/prompt/
     * @secure
     */
    promptRefineryPromptCreate: (data: Prompt, params: RequestParams = {}) =>
      this.request<Prompt, any>({
        path: `/prompt_refinery/admin/prompt/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptRead
     * @request GET:/prompt_refinery/admin/prompt/{id}/
     * @secure
     */
    promptRefineryPromptRead: (id: number, params: RequestParams = {}) =>
      this.request<PromptDetail, any>({
        path: `/prompt_refinery/admin/prompt/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptUpdate
     * @request PUT:/prompt_refinery/admin/prompt/{id}/
     * @secure
     */
    promptRefineryPromptUpdate: (id: number, data: PromptDetail, params: RequestParams = {}) =>
      this.request<PromptDetail, any>({
        path: `/prompt_refinery/admin/prompt/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptPartialUpdate
     * @request PATCH:/prompt_refinery/admin/prompt/{id}/
     * @secure
     */
    promptRefineryPromptPartialUpdate: (id: number, data: PromptDetail, params: RequestParams = {}) =>
      this.request<PromptDetail, any>({
        path: `/prompt_refinery/admin/prompt/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptDelete
     * @request DELETE:/prompt_refinery/admin/prompt/{id}/
     * @secure
     */
    promptRefineryPromptDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prompt_refinery/admin/prompt/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptFixturesRead
     * @request GET:/prompt_refinery/admin/prompt/{id}/fixtures/
     * @secure
     */
    promptRefineryPromptFixturesRead: (id: number, params: RequestParams = {}) =>
      this.request<PromptFixture, any>({
        path: `/prompt_refinery/admin/prompt/${id}/fixtures/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptFixturesUpdate
     * @request PUT:/prompt_refinery/admin/prompt/{id}/fixtures/
     * @secure
     */
    promptRefineryPromptFixturesUpdate: (id: number, data: PromptFixture, params: RequestParams = {}) =>
      this.request<PromptFixture, any>({
        path: `/prompt_refinery/admin/prompt/${id}/fixtures/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptFixturesPartialUpdate
     * @request PATCH:/prompt_refinery/admin/prompt/{id}/fixtures/
     * @secure
     */
    promptRefineryPromptFixturesPartialUpdate: (id: number, data: PromptFixture, params: RequestParams = {}) =>
      this.request<PromptFixture, any>({
        path: `/prompt_refinery/admin/prompt/${id}/fixtures/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableList
     * @request GET:/prompt_refinery/admin/variable/
     * @secure
     */
    promptRefineryVariableList: (params: RequestParams = {}) =>
      this.request<PromptVariable[], any>({
        path: `/prompt_refinery/admin/variable/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableCreate
     * @request POST:/prompt_refinery/admin/variable/
     * @secure
     */
    promptRefineryVariableCreate: (data: PromptVariable, params: RequestParams = {}) =>
      this.request<PromptVariable, any>({
        path: `/prompt_refinery/admin/variable/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableRead
     * @request GET:/prompt_refinery/admin/variable/{id}
     * @secure
     */
    promptRefineryVariableRead: (id: number, params: RequestParams = {}) =>
      this.request<PromptVariablesDetail, any>({
        path: `/prompt_refinery/admin/variable/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableUpdate
     * @request PUT:/prompt_refinery/admin/variable/{id}
     * @secure
     */
    promptRefineryVariableUpdate: (id: number, data: PromptVariablesDetail, params: RequestParams = {}) =>
      this.request<PromptVariablesDetail, any>({
        path: `/prompt_refinery/admin/variable/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablePartialUpdate
     * @request PATCH:/prompt_refinery/admin/variable/{id}
     * @secure
     */
    promptRefineryVariablePartialUpdate: (id: number, data: PromptVariablesDetail, params: RequestParams = {}) =>
      this.request<PromptVariablesDetail, any>({
        path: `/prompt_refinery/admin/variable/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableDelete
     * @request DELETE:/prompt_refinery/admin/variable/{id}
     * @secure
     */
    promptRefineryVariableDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prompt_refinery/admin/variable/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableValueList
     * @request GET:/prompt_refinery/admin/variable/{prompt_variable_id}/value/
     * @secure
     */
    promptRefineryVariableValueList: (promptVariableId: string, params: RequestParams = {}) =>
      this.request<PromptVariableValue[], any>({
        path: `/prompt_refinery/admin/variable/${promptVariableId}/value/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableValueCreate
     * @request POST:/prompt_refinery/admin/variable/{prompt_variable_id}/value/
     * @secure
     */
    promptRefineryVariableValueCreate: (
      promptVariableId: string,
      data: PromptVariableValue,
      params: RequestParams = {},
    ) =>
      this.request<PromptVariableValue, any>({
        path: `/prompt_refinery/admin/variable/${promptVariableId}/value/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableValueRead
     * @request GET:/prompt_refinery/admin/variable/{prompt_variable_id}/value/{id}/
     * @secure
     */
    promptRefineryVariableValueRead: (promptVariableId: string, id: number, params: RequestParams = {}) =>
      this.request<PromptVariableValueDetails, any>({
        path: `/prompt_refinery/admin/variable/${promptVariableId}/value/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableValueUpdate
     * @request PUT:/prompt_refinery/admin/variable/{prompt_variable_id}/value/{id}/
     * @secure
     */
    promptRefineryVariableValueUpdate: (
      promptVariableId: string,
      id: number,
      data: PromptVariableValueDetails,
      params: RequestParams = {},
    ) =>
      this.request<PromptVariableValueDetails, any>({
        path: `/prompt_refinery/admin/variable/${promptVariableId}/value/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableValuePartialUpdate
     * @request PATCH:/prompt_refinery/admin/variable/{prompt_variable_id}/value/{id}/
     * @secure
     */
    promptRefineryVariableValuePartialUpdate: (
      promptVariableId: string,
      id: number,
      data: PromptVariableValueDetails,
      params: RequestParams = {},
    ) =>
      this.request<PromptVariableValueDetails, any>({
        path: `/prompt_refinery/admin/variable/${promptVariableId}/value/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariableValueDelete
     * @request DELETE:/prompt_refinery/admin/variable/{prompt_variable_id}/value/{id}/
     * @secure
     */
    promptRefineryVariableValueDelete: (promptVariableId: string, id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prompt_refinery/admin/variable/${promptVariableId}/value/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpDiffCreate
     * @request POST:/rfp/admin/diff/
     * @secure
     */
    rfpDiffCreate: (data: Diff, params: RequestParams = {}) =>
      this.request<Diff, any>({
        path: `/rfp/admin/diff/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalList
     * @request GET:/rfp/admin/proposal/
     * @secure
     */
    rfpProposalList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ProposalAdminDetail[];
          /** Whether there are more results available. */
          has_next?: boolean;
          /** Whether there are previous results available. */
          has_previous?: boolean;
        },
        any
      >({
        path: `/rfp/admin/proposal/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRelevantContextCreate
     * @request POST:/rfp/admin/proposal/{proposal_id}/relevant_context/
     * @secure
     */
    rfpProposalRelevantContextCreate: (
      proposalId: string,
      data: RequirementGetRelevantContext,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Relevant context from past proposal */
          relevant_context?: string;
        },
        any
      >({
        path: `/rfp/admin/proposal/${proposalId}/relevant_context/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalCalculateSectionRfpContext
     * @request POST:/rfp/admin/proposal/{proposal_id}/rfp_context/
     * @secure
     */
    rfpProposalCalculateSectionRfpContext: (
      proposalId: string,
      data: ProposalRFPContextInput,
      params: RequestParams = {},
    ) =>
      this.request<ProposalRFPContextOutput, any>({
        path: `/rfp/admin/proposal/${proposalId}/rfp_context/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpList
     * @request GET:/rfp/admin/rfp/
     * @secure
     */
    rfpRfpList: (
      query?: {
        /** A search term. */
        search?: string;
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPList[];
          /** The current page number. */
          page?: number;
          /** The total number of pages. */
          page_count?: number;
        },
        any
      >({
        path: `/rfp/admin/rfp/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpCsvCreate
     * @request POST:/rfp/admin/rfp/csv/
     * @secure
     */
    rfpRfpCsvCreate: (data: RFPCSVUpload, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/rfp/admin/rfp/csv/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFoiaCreate
     * @request POST:/rfp/admin/rfp/{rfp_id}/foia/
     * @secure
     */
    rfpRfpFoiaCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<
        {
          job_id?: string;
        },
        any
      >({
        path: `/rfp/admin/rfp/${rfpId}/foia/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpMarkAsTestDataCreate
     * @request POST:/rfp/admin/rfp/{rfp_id}/mark-as-test-data/
     * @secure
     */
    rfpRfpMarkAsTestDataCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<RFPList, any>({
        path: `/rfp/admin/rfp/${rfpId}/mark-as-test-data/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRegenerateProfile
     * @request POST:/rfp/admin/rfp/{rfp_id}/regenerate-profile/
     * @secure
     */
    rfpRegenerateProfile: (rfpId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/admin/rfp/${rfpId}/regenerate-profile/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  public = {
    /**
     * No description
     *
     * @tags core
     * @name CoreAuthForgotCreate
     * @request POST:/core/public/auth/forgot/
     * @secure
     */
    coreAuthForgotCreate: (data: ForgotPassword, params: RequestParams = {}) =>
      this.request<ForgotPassword, any>({
        path: `/core/public/auth/forgot/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreAuthRecoverCreate
     * @request POST:/core/public/auth/recover/
     * @secure
     */
    coreAuthRecoverCreate: (data: RecoverPasswordEmailUser, params: RequestParams = {}) =>
      this.request<RecoverPasswordEmailUser, any>({
        path: `/core/public/auth/recover/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreAuthResendVerifyCreate
     * @request POST:/core/public/auth/resend-verify/
     * @secure
     */
    coreAuthResendVerifyCreate: (data: ReverifyEmailUser, params: RequestParams = {}) =>
      this.request<ReverifyEmailUser, any>({
        path: `/core/public/auth/resend-verify/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreAuthTokenGoogleCreate
     * @request POST:/core/public/auth/token/google/
     * @secure
     */
    coreAuthTokenGoogleCreate: (data: GoogleTokenObtainPair, params: RequestParams = {}) =>
      this.request<UserDetailPostAuth, any>({
        path: `/core/public/auth/token/google/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreAuthUserCreate
     * @request POST:/core/public/auth/user/
     * @secure
     */
    coreAuthUserCreate: (data: CreateEmailUser, params: RequestParams = {}) =>
      this.request<CreateEmailUser, any>({
        path: `/core/public/auth/user/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreAuthVerifyCreate
     * @request POST:/core/public/auth/verify/
     * @secure
     */
    coreAuthVerifyCreate: (data: VerifyEmailUser, params: RequestParams = {}) =>
      this.request<VerifyEmailUser, any>({
        path: `/core/public/auth/verify/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreErrorList
     * @request GET:/core/public/error/
     * @secure
     */
    coreErrorList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/core/public/error/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreFeedbackCreate
     * @request POST:/core/public/feedback/
     * @secure
     */
    coreFeedbackCreate: (data: Feedback, params: RequestParams = {}) =>
      this.request<Feedback, any>({
        path: `/core/public/feedback/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreHealthcheckList
     * @request GET:/core/public/healthcheck/
     * @secure
     */
    coreHealthcheckList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/core/public/healthcheck/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpFinalCheckCreate
     * @request POST:/rfp/public/final-check/
     * @secure
     */
    rfpFinalCheckCreate: (data: FinalCheckCreate, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
          proposal_upload_url?: string;
          rfp_upload_url?: string;
        },
        any
      >({
        path: `/rfp/public/final-check/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpFinalCheckRead
     * @request GET:/rfp/public/final-check/{id}/
     * @secure
     */
    rfpFinalCheckRead: (id: string, params: RequestParams = {}) =>
      this.request<FinalCheckDetails, any>({
        path: `/rfp/public/final-check/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpFinalCheckMarkUploaded
     * @request POST:/rfp/public/final-check/{id}/uploaded/
     * @secure
     */
    rfpFinalCheckMarkUploaded: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/public/final-check/${id}/uploaded/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpRead
     * @request GET:/rfp/public/rfp/{slug}/
     * @secure
     */
    rfpRfpRead: (slug: string, params: RequestParams = {}) =>
      this.request<InternalRFPDetails, any>({
        path: `/rfp/public/rfp/${slug}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
