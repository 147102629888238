import { AdminOrgDetails, Org } from "api/Api";
import DeprecatedButton from "components/common/DeprecatedButton";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import {
  TableCell,
  TableHeaderCell,
  TableRow,
} from "components/common/containers/Table";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { useConfirm } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { useCallback, useEffect, useState } from "react";
import { message_from_exception } from "utils";

interface OrgDetailsViewProps {
  org: Org;
  dismiss: () => void;
  onDeleted: () => void;
}

const OrgDetailsView = ({ org, onDeleted, dismiss }: OrgDetailsViewProps) => {
  const apiClient = useApiClient();
  const [details, setDetails] = useState<AdminOrgDetails | null>(null);
  const [error, setError] = useState<string | null>(null);
  const confirm = useConfirm();

  const refresh = useCallback(async () => {
    try {
      setError(null);
      const repsonse = await apiClient.admin.coreOrgRead(org.public_id!);
      setDetails(repsonse.data);
    } catch (e) {
      setError(message_from_exception(e));
    }
  }, [apiClient.admin, org.public_id]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleDelete = async () => {
    const confirmed = await confirm("Are you sure?", {
      yesText: `Delete ${details!.name}`,
      yesDestructive: true,
    });

    if (confirmed) {
      try {
        await apiClient.admin.coreOrgDelete(org.public_id!);
        onDeleted();
        dismiss?.();
      } catch (error) {
        setError(message_from_exception(error));
      }
    }
  };

  return (
    <AsyncLoadedDiv
      value={details}
      error={error}
      whileLoaded={(details) => {
        return (
          <Rows className="gap-md">
            <LabeledFormInput label="Users">
              <table className="w-full">
                <thead>
                  <TableRow variant="header">
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell variant="minimumWidth">
                      Email
                    </TableHeaderCell>
                  </TableRow>
                </thead>
                <tbody>
                  {details.members.map((user) => (
                    <TableRow key={user.public_id!}>
                      <TableCell>{user.display_name}</TableCell>
                      <TableCell variant="minimumWidth">{user.email}</TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </table>
              <Columns className="shrink-0 justify-end">
                <DeprecatedButton
                  icon="xmark"
                  text="Delete"
                  className="shrink-0"
                  variant="destructive"
                  onClick={handleDelete}
                />
              </Columns>
            </LabeledFormInput>
          </Rows>
        );
      }}
    />
  );
};

export default OrgDetailsView;
