import {
  isEmptySearch,
  isSavedSearchDraft,
  SearchConfiguratorProps,
} from "./SearchConfiguratorProps";
import DeprecatedButton from "components/common/DeprecatedButton";
import {
  Choice,
  useChoose,
  useConfirm,
  useTextAreaConfirm,
} from "providers/AlertProvider";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Icon from "components/common/Icon";
import { SavedSearchList } from "api/Api";
import { FC, useState } from "react";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Spacer from "components/common/containers/Spacer";
import Rows from "components/common/containers/Rows";
import LoadableView from "components/common/containers/LoadableView";
import { odoToast } from "lib/odoToast";
import { cn } from "lib/utils";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";

const SavedSearchesConfigurator: FC<SearchConfiguratorProps> = (props) => {
  const user = useAuthenticatedUser();
  const { search } = props;
  if (isSavedSearchDraft(search, user) && !(search && isEmptySearch(search))) {
    return <HasPendingChanges {...props} />;
  } else {
    return <NoPendingChanges {...props} />;
  }
};

const NoPendingChanges: FC<SearchConfiguratorProps> = ({
  search,
  updateSearch,
  close,
  refresh,
}) => {
  const apiClient = useApiClient();
  const user = useAuthenticatedUser();
  const confirm = useConfirm();
  const [savedSearches, , paginatedData] = usePaginatedData({
    endpoint: (queryParams, options) =>
      apiClient.user.rfpSavedSearchList(
        {
          ...queryParams,
          // @ts-ignore
          include_drafts: true,
        },
        options
      ),
    map: (data) => data,
  });

  const handleChoose = async (id: number) => {
    if (id === search.id) {
      odoToast.warning({
        title: "Already on Search",
        text: "You're already on this search",
      });
      return;
    }

    if (isSavedSearchDraft(search, user) && !isEmptySearch(search)) {
      const result = await confirm(
        `Switch to ${search.name ?? "this search"}?`,
        {
          body: "This will discard your unsaved search changes",
          yesText: "Discard Unsaved Changes",
          noText: "Cancel",
        }
      );
      if (result !== true) return;
    }

    const response = await apiClient.user.rfpSavedSearchChooseCreate(
      id.toString(),
      {}
    );
    updateSearch(response.data, { saveToRemote: false });
  };

  const handleDeleteDraft = async (toDelete: SavedSearchList) => {
    const result = await confirm(
      `Are you sure you want to delete ${
        toDelete.display_name ?? "this draft"
      }?`,
      {
        yesText: "Yes, delete",
      }
    );
    if (result !== true) return;

    await apiClient.user.rfpSavedSearchDelete(toDelete.id.toString());
    if (search.id === toDelete.id) {
      refresh();
    }
    close();
  };

  return (
    <AsyncLoadedDiv
      value={savedSearches}
      whileLoaded={(savedSearches) => {
        if (savedSearches.length === 0) {
          // The user doesn't have pending changes and has no saved searches yet
          return (
            <p className="text-sm text-secondary max-w-[400px]">
              No saved searches yet. Update your search and save it to create
              your first saved search.
            </p>
          );
        } else {
          return (
            <>
              <p className="text-sm text-secondary">
                View RFPs that match your team's saved searches
              </p>
              <PaginatedTableView
                results={savedSearches}
                paginatedData={paginatedData}
                columns={[{ size: "min" }, {}, { size: "min" }]}
                onSelect={(search) => handleChoose(search.id)}
                renderRow={(element, Cell, Row) => (
                  <Row key={element.id}>
                    <Cell className="pr-none py-none rounded-l-sm">
                      <Icon
                        name={
                          element.id === search.id ? "circle-dot" : "circle"
                        }
                      />
                    </Cell>
                    <Cell
                      className={cn(
                        "text-sm py-none",
                        element.user && "text-primary admin"
                      )}
                    >
                      {element.user ? element.user.email : element.display_name}
                    </Cell>
                    <Cell className="pr-none py-none rounded-r-sm">
                      {element.is_default !== true && (
                        <DeprecatedButton
                          icon="trash"
                          onClick={() => handleDeleteDraft(element)}
                        />
                      )}
                    </Cell>
                  </Row>
                )}
              />
            </>
          );
        }
      }}
    />
  );
};

const HasPendingChanges: FC<SearchConfiguratorProps> = ({
  search,
  updateSearch,
  refresh,
  close,
}) => {
  const apiClient = useApiClient();
  const confirm = useConfirm();
  const choose = useChoose();
  const textAreaConfirm = useTextAreaConfirm();
  const [loading, setLoading] = useState(false);

  const handleDiscard = async () => {
    const result = await confirm("Discard your search changes?", {
      yesText: "Discard",
      yesDestructive: true,
      noText: "Cancel",
    });
    if (result !== true) return;
    await apiClient.user.rfpSavedSearchCurrentDelete();
    const newSearch = await apiClient.user.rfpSavedSearchCurrentRead();
    updateSearch(newSearch.data, { saveToRemote: false });
    refresh();
    close();
  };

  const saveAsNewSearch = async () => {
    const result = await textAreaConfirm("Save as New", {
      placeholder: "Enter the name of the new search",
      multiline: false,
      defaultId: "save",
      buttons: [
        {
          text: "Cancel",
          variant: "solid-secondary",
          id: "cancel",
        },
        {
          text: "Save",
          variant: "solid",
          id: "save",
        },
      ],
    });
    if (result.id !== "save") return;

    updateSearch({
      name: result.text,
      save_as_new_search: true,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await apiClient.user.rfpSavedSearchList();
      const savedSearches = response.data.results;
      if (savedSearches.length === 0) {
        setLoading(false);
        saveAsNewSearch();
      } else {
        setLoading(false);
        const result = await choose("Save Search", {
          body: (
            <p className="text-sm text-secondary mb-xs">
              Choose a saved search to overwrite
            </p>
          ),
          dismissId: "cancel",
          variant: "list",
          choices: savedSearches
            .map(
              (search): Choice => ({
                text: search.display_name ?? "Untitled",
                icon: "circle",
                id: search.id.toString(),
                variant: "DEFAULT",
              })
            )
            .concat([
              {
                id: "new",
                text: "Save as new",
                icon: "plus",
              },
            ]),
        });
        if (result === "cancel") return;
        if (result === "new") {
          saveAsNewSearch();
          return;
        }
        const response = await apiClient.user.rfpSavedSearchChooseCreate(
          result,
          {
            copy_search_id: search.id,
          }
        );
        updateSearch(response.data, { saveToRemote: false });
        close();
        const toSearch = savedSearches.find((s) => s.id === parseInt(result));
        odoToast.success({
          title: "Search Updated",
          text: `${toSearch?.display_name ?? "Search"} has been updated`,
        });
      }
    } catch (e) {
      odoToast.caughtError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadableView isLoading={loading}>
      <Rows className="gap-sm">
        <p className="text-sm text-secondary">
          Save your current search for your team to access later
        </p>
        <Columns>
          <DeprecatedButton
            text="Discard Changes"
            className="text-sm h-xl border-destructive text-destructive"
            variant="solid-secondary"
            onClick={handleDiscard}
          />
          <Spacer />
          <DeprecatedButton
            text="Save Search"
            className="text-sm h-xl"
            variant="solid"
            onClick={handleSave}
          />
        </Columns>
      </Rows>
    </LoadableView>
  );
};

export default SavedSearchesConfigurator;
