import OdoUserAvatar from "./common/OdoUserAvatar";
import { useOptionalAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import DeprecatedButton from "./common/DeprecatedButton";
import { useNavigate } from "react-router-dom";
import Icon from "./common/Icon";
import {
  DeprecatedDropdownMenu,
  DeprecatedDropdownMenuContent,
  DeprecatedDropdownMenuItem,
  DeprecatedDropdownMenuTrigger,
} from "./EditorView/Menus/DeprecatedDropdownMenu";
import { FC } from "react";

interface CurrentUserProps {
  showName?: boolean;
}

const CurrentUser: FC<CurrentUserProps> = ({ showName = true }) => {
  const user = useOptionalAuthenticatedUser();
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  return (
    <>
      <DeprecatedDropdownMenu>
        <DeprecatedDropdownMenuTrigger>
          <div className="flex gap-sm items-center">
            <OdoUserAvatar size="small" user={user} />
            {showName && <h1>{user.orgName}</h1>}
            <Icon name="chevron-down" />
          </div>
        </DeprecatedDropdownMenuTrigger>
        <DeprecatedDropdownMenuContent sideOffset={12} className="z-[1]">
          <DeprecatedDropdownMenuItem
            onClick={() => navigate("/past-proposals/")}
          >
            Past Proposals
          </DeprecatedDropdownMenuItem>
          {user?.isStaff && (
            <DeprecatedDropdownMenuItem
              onClick={() => navigate("/content/")}
              className="text-primary admin"
            >
              Content Library
            </DeprecatedDropdownMenuItem>
          )}
          <DeprecatedDropdownMenuItem
            onClick={() => navigate("/account/company-profile/")}
          >
            Company Profile
          </DeprecatedDropdownMenuItem>
          <DeprecatedDropdownMenuItem
            onClick={(e) => e.preventDefault()}
            className="bg-background-secondary border-t cursor-default hover:bg-background-secondary"
          >
            <p className="text-center text-sm w-full">{user.email}</p>
          </DeprecatedDropdownMenuItem>
        </DeprecatedDropdownMenuContent>
      </DeprecatedDropdownMenu>
      <DeprecatedButton
        icon="arrow-right-from-bracket"
        variant="outline-full"
        onClick={() => {
          navigate("/logout");
        }}
      />
    </>
  );
};

export default CurrentUser;
