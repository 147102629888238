import { DependencyList, useCallback, useRef } from "react";

/**
 * A hook that creates an abortable version of a callback function.
 * When the returned function is called, it cancels any previous in-flight calls
 * and creates a new AbortController for the current call.
 *
 * @param callback A function that accepts an AbortSignal as its first parameter
 * @returns A function that, when called, will abort any previous calls and execute the callback with a new AbortSignal
 */
export function useAbortableCallback<T extends any[], R>(
  callback: (signal: AbortSignal, ...args: T) => Promise<R>,
  deps: DependencyList
): (...args: T) => Promise<R> {
  // Keep reference to the current AbortController
  const abortControllerRef = useRef<AbortController | null>(null);

  return useCallback(
    async (...args: T): Promise<R> => {
      // Cancel any previous in-flight calls
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController for this call
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      try {
        // Call the callback with the abort signal and provided args
        return await callback(abortController.signal, ...args);
      } finally {
        // Clean up the reference if this is still the current controller
        if (abortControllerRef.current === abortController) {
          abortControllerRef.current = null;
        }
      }
    },
    [callback, deps]
  );
}

export default useAbortableCallback;
