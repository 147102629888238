import MarkdownView from "../../MarkdownView";
import { cn } from "../../../lib/utils";
import { FC, ReactNode, useRef, useState } from "react";
import DeprecatedButton from "../../common/DeprecatedButton";
import { odoToast } from "lib/odoToast";
import { useConfirm } from "providers/AlertProvider";
import Icon, { IconName } from "components/common/Icon";
import {
  DeprecatedDropdownMenu,
  DeprecatedDropdownMenuContent,
  DeprecatedDropdownMenuItem,
  DeprecatedDropdownMenuTrigger,
} from "components/EditorView/Menus/DeprecatedDropdownMenu";

interface PromptRefineryOutputProps {
  className?: string;
  output: string | null;
  error: string | null;
  onClearOutput: () => void;
}

const PromptRefineryOutput: FC<PromptRefineryOutputProps> = ({
  className,
  output,
  onClearOutput,
  error,
}) => {
  const [viewMode, setViewMode] = useState<"markdown" | "code" | "json">(
    "markdown"
  );
  const contentRef = useRef<HTMLDivElement>(null);
  const confirm = useConfirm();

  let content: ReactNode;
  let viewModeIcon: IconName = "image";
  if (error) {
    content = (
      <>
        <p className="text-destructive text-xl font-semibold">Error</p>
        <p className="text-destructive">{error}</p>
      </>
    );
  } else if (output === null) {
    content = (
      <>
        <p className="text-secondary text-xl font-semibold">No Output</p>
        <p className="text-secondary">Click “Run” to generate some output</p>
      </>
    );
  } else {
    switch (viewMode) {
      case "code":
        content = <pre className="whitespace-pre-wrap text-sm">{output}</pre>;
        viewModeIcon = "code";
        break;
      case "markdown":
        content = <MarkdownView markdown={output} />;
        viewModeIcon = "image";
        break;
      case "json":
        viewModeIcon = "list-tree";
        const json = normalizeJSON(output);
        try {
          content = (
            <pre className="whitespace-pre-wrap text-sm">
              {JSON.stringify(JSON.parse(json), null, 2)}
            </pre>
          );
        } catch (e) {
          content = (
            <>
              <p className="text-destructive">Invalid JSON</p>
            </>
          );
        }
    }
  }

  const handleClearOutput = async () => {
    // Confirm that the user wants to clear the output
    if (await confirm("Are you sure you want to clear the output?")) {
      onClearOutput();
    }
  };

  const handleClickCopy = () => {
    // Copy the rendered MarkdownView content to the clipboard
    if (!contentRef.current) {
      odoToast.error({ title: "Error", text: "No content to copy" });
      return;
    }

    const html = contentRef.current.innerHTML;
    const text = contentRef.current.innerText;
    const data = new ClipboardItem({
      "text/html": new Blob([html], { type: "text/html" }),
      "text/plain": new Blob([text], { type: "text/plain" }),
    });
    navigator.clipboard.write([data]);
    odoToast.success({ title: "Success", text: "Output copied to clipboard" });
  };

  return (
    <div className={cn("flex flex-col", className)}>
      <div
        ref={contentRef}
        className={cn(
          (output === null || error !== null) &&
            "flex flex-col items-center justify-center",
          "grow basis-0 p-md border rounded-sm overflow-y-auto"
        )}
      >
        {content}
      </div>
      <div className="flex p-md shrink-0 grow-0 gap-lg">
        <DeprecatedButton icon="broom-wide" onClick={handleClearOutput} />
        <div className="grow" />
        <DeprecatedButton icon="copy" onClick={handleClickCopy} />
        <DeprecatedDropdownMenu>
          <DeprecatedDropdownMenuTrigger>
            <Icon name={viewModeIcon} />
          </DeprecatedDropdownMenuTrigger>
          <DeprecatedDropdownMenuContent>
            <DeprecatedDropdownMenuItem onClick={() => setViewMode("markdown")}>
              <Icon name="image" />
              Markdown
            </DeprecatedDropdownMenuItem>
            <DeprecatedDropdownMenuItem onClick={() => setViewMode("code")}>
              <Icon name="code" />
              Code
            </DeprecatedDropdownMenuItem>
            <DeprecatedDropdownMenuItem onClick={() => setViewMode("json")}>
              <Icon name="list-tree" />
              JSON
            </DeprecatedDropdownMenuItem>
          </DeprecatedDropdownMenuContent>
        </DeprecatedDropdownMenu>
      </div>
    </div>
  );
};

/**
 * Normalize a json string into valid JSON to allow displaying partial JSON
 * from streaming output.
 *
 * 1. Remove any text before and after first curly brace or bracket
 * 2. Close any open quotes, braces, or brackets
 */
const normalizeJSON = (text: string) => {
  // Remove any text before first opening curly brace or bracket
  let normalized = text.replace(/\n/g, "");
  const firstCurly = normalized.indexOf("{");
  const firstBracket = normalized.indexOf("[");
  const start = Math.min(
    firstCurly === -1 ? Infinity : firstCurly,
    firstBracket === -1 ? Infinity : firstBracket
  );
  normalized = normalized.slice(start, normalized.length);

  if (normalized === "") {
    return "{}";
  }

  // Track the open quotes, braces, and brackets
  let pendingCloses: string[] = [];
  let escaped = false;
  let done = false;
  for (let i = 0; i < normalized.length; i++) {
    if (done) {
      break;
    }
    const char = normalized[i];
    if (escaped) {
      escaped = false;
      continue;
    }
    switch (char) {
      case "\\":
        escaped = !escaped;
        break;
      case "{":
        pendingCloses.push("}");
        break;
      case "[":
        pendingCloses.push("]");
        break;
      case '"':
        if (pendingCloses[pendingCloses.length - 1] === '"') {
          pendingCloses.pop();
        } else {
          pendingCloses.push('"');
        }
        break;
      case "}":
      case "]":
        if (pendingCloses.length === 0) {
          normalized = normalized.slice(0, i);
          done = true;
          break;
        }
        if (pendingCloses[pendingCloses.length - 1] === char) {
          pendingCloses.pop();
        }
        break;
    }
  }

  const match = normalized.match(/, *$/);
  if (match) {
    // Remove trailing comma
    normalized = normalized.slice(0, normalized.length - match[0].length);
  }

  const finalized = (text: string) => {
    let final = text;
    for (let i = pendingCloses.length - 1; i >= 0; i--) {
      final += pendingCloses[i];
    }
    return final;
  };

  try {
    JSON.parse(finalized(normalized));
  } catch {
    // A bit of a hack, but if the JSON is invalid, this is usually
    // because we have a key without a value. We can add a dummy value
    // We just need to know if there is a colon or not at the end

    let attempt = normalized;
    if (attempt.match(/: *$/)) {
      if (pendingCloses[pendingCloses.length - 1] === '"') {
        attempt += '"';
      } else {
        attempt += '""';
      }
    } else {
      if (pendingCloses[pendingCloses.length - 1] === '"') {
        attempt += '":"';
      } else {
        attempt += ':""';
      }
    }

    try {
      JSON.parse(finalized(attempt));
      normalized = attempt;
    } catch {
      debugger;
    }
  }

  return finalized(normalized);
};

export default PromptRefineryOutput;
