import { debounce } from "lodash";
import { useRef } from "react";
import {
  getById,
  getParent,
  getPosition,
  HierarchicalSectionInfo,
  indentSection,
  SurroundingSectionOffsets,
} from "./types";

interface ElementData {
  element: HTMLElement | null;
  container: HTMLElement | null;
  resizeObserver: ResizeObserver | null;
  height: number | null;
  containerHeight: number | null;
  isCollapsed: boolean;
  childrenIds: string[] | null;

  // Elements that have been removed are archived instead of deleted
  // to remember state like isCollapsed
  archived: boolean;
}

const useOutlineLayout = () => {
  const manager = useRef<OutlineElementLayoutManager | null>(null);
  if (!manager.current) {
    manager.current = new OutlineElementLayoutManager();
  }
  return manager.current!;
};

/**
 * Manages the layout of outline elements
 *
 * This assumes `element` is rendered inside an absolute positioned `container`
 * pinned to the top and sides (not the bottom).
 *
 * This ensures that the adjustments of the container heights will never trigger
 * resize events of the elements.
 */
export class OutlineElementLayoutManager {
  elements: Record<string, ElementData> = {};
  displayedHierarchicalSections: HierarchicalSectionInfo[] = [];
  pendingFocusId: string | null = null;
  // Set to true if changes were made to the hierarchy, until they are applied
  pendingSave: boolean = false;
  onSaveShadowData: ((sections: HierarchicalSectionInfo[]) => void) | null =
    null;

  setDisplayedHierarchicalSections(sections: HierarchicalSectionInfo[]) {
    this.displayedHierarchicalSections = sections;
  }

  saveShadowData() {
    if (!this.pendingSave) {
      return;
    }
    if (this.onSaveShadowData) {
      this.onSaveShadowData(this.displayedHierarchicalSections);
      this.pendingSave = false;
    }
  }

  focusElement(id: string) {
    const element = this.elements[id]?.element;
    if (element) {
      element.focus();
    } else {
      this.pendingFocusId = id;
    }
  }

  registerElement(id: string, element: HTMLElement | null) {
    if (element === null) {
      if (id in this.elements) {
        this.elements[id].resizeObserver?.disconnect();
        this.elements[id].element = null;
        if (!this.elements[id].container) {
          // If we also don't have a container, we can remove this element
          this.archive(id);
        }
      }
    } else {
      if (id in this.elements) {
        this.elements[id].element = element;
        this.debouncedRecalculateContainerHeights();
      } else {
        this.elements[id] = {
          element,
          container: null,
          resizeObserver: null,
          height: null,
          isCollapsed: false,
          containerHeight: null,
          childrenIds: null,
          archived: false,
        };
        // Don't recalculate container heights here because we know we don't have a container yet
      }
      this.elements[id].resizeObserver = this.observeElementForId(element, id);
      if (this.pendingFocusId === id) {
        console.log("focus", element);
        setTimeout(() => {
          element.focus();
          this.pendingFocusId = null;
        }, 0);
      }
    }
  }

  registerElementContainer(
    id: string,
    container: HTMLElement | null,
    childrenIds: string[]
  ) {
    if (container === null) {
      if (id in this.elements) {
        this.elements[id].container = null;
        this.elements[id].childrenIds = null;
        if (!this.elements[id].element) {
          // If we also don't have an element, we can remove this element
          this.archive(id);
        }
      }
    } else {
      if (id in this.elements) {
        this.elements[id].container = container;
        this.elements[id].childrenIds = childrenIds;
        this.debouncedRecalculateContainerHeights();
      } else {
        this.elements[id] = {
          element: null,
          container,
          resizeObserver: null,
          height: null,
          isCollapsed: false,
          containerHeight: null,
          childrenIds,
          archived: false,
        };
        // Don't recalculate container heights here because we know we don't have an element yet
      }
    }
  }

  setCollapsed(id: string, isCollapsed: boolean) {
    if (id in this.elements) {
      this.elements[id].isCollapsed = isCollapsed;
      // For collapse/expand actions, we want immediate feedback without debounce
      this.recalculateContainerHeights();
      this.pendingSave = true;
    }
  }

  getLevel(id: string): number {
    const section = getById(this.displayedHierarchicalSections, id);
    if (!section) {
      return 0;
    }
    return section.level;
  }

  indentSection(id: string, direction: "left" | "right") {
    const sections = this.displayedHierarchicalSections;
    const newSections = indentSection(sections, id, direction);
    this.setDisplayedHierarchicalSections(newSections);
    this.pendingSave = true;
  }

  moveSection(id: string, destinationParentId: string | null, index: number) {
    // Find the section to move and its original parent
    const section = getById(this.displayedHierarchicalSections, id);
    const originalParent = getParent(this.displayedHierarchicalSections, id);

    if (!section) return;

    // Remove section from its original parent
    if (originalParent) {
      originalParent.children = originalParent.children.filter(
        (child) => child.id !== id
      );
    } else {
      // It's a top-level section
      const topIndex = this.displayedHierarchicalSections.findIndex(
        (s) => s.id === id
      );
      if (topIndex >= 0) {
        this.displayedHierarchicalSections.splice(topIndex, 1);
      }
    }

    // Add the section to its new parent or at the top level
    if (destinationParentId) {
      const destParent = getById(
        this.displayedHierarchicalSections,
        destinationParentId
      );
      if (destParent) {
        // Insert at specified index
        destParent.children.splice(index, 0, {
          ...section,
          level: destParent.level + 1,
        });
      }
    } else {
      // Add to top level at specified index
      this.displayedHierarchicalSections.splice(index, 0, {
        ...section,
        level: 1,
      });
    }
    this.pendingSave = true;
  }

  /**
   * Retrieves a section by its ID
   * @param id ID of the section to retrieve
   * @returns The section with the given ID, or null if not found
   */
  getSection(id: string): HierarchicalSectionInfo | null {
    return getById(this.displayedHierarchicalSections, id);
  }

  getOffsetsSurroundingSection(id: string): SurroundingSectionOffsets | null {
    // We need to iterate through all elements to account for variable heights
    // and collapsed sections while calculating the offsets
    let currentY = 0;
    let tops: { id: string; top: number }[] = [];
    const position = getPosition(this.displayedHierarchicalSections, id);
    if (!position) {
      return null;
    }
    const { parent, section: targetSection, index } = position;
    let left: number | null = (targetSection.level - 1) * 24;
    let right: number | null = (targetSection.level + 1) * 24;
    if (targetSection.level >= 6) {
      // Don't allow moving deeper than h6
      right = null;
    }
    if (targetSection.level <= 1) {
      left = null;
    }
    if (parent) {
      if (index === 0) {
        // Don't allow moving more than one level deeper than parent
        right = null;
        if (parent.children.length > 1) {
          left = null;
        }
      } else if (targetSection.level === 6) {
        // Don't allow moving deeper than h6
        right = null;
      }
    } else {
      left = null;
      if (index === 0) {
        right = null;
      }
    }

    const visit = (
      section: HierarchicalSectionInfo
    ): boolean | SurroundingSectionOffsets => {
      const data = this.elements[section.id];
      if (!data || !data.height) {
        // Not ready to calculate
        return false;
      }

      tops.push({ id: section.id, top: currentY });
      currentY += data.height;
      if (tops.length > 4) {
        tops.shift();
      }

      // Exit condition - found section as first element
      if (tops.length >= 3 && tops[0].id === id) {
        return {
          topOfAbove: null,
          above: null,
          top: tops[0].top,
          bottom: tops[1].top,
          below: tops[1].id,
          bottomOfBelow: tops[2].top,
          left,
          right,
        };
      }

      // Exit condition - found section with at least 1 above and 2 below
      if (tops.length >= 4 && tops[1].id === id) {
        return {
          topOfAbove: tops[0].top,
          above: tops[0].id,
          top: tops[1].top,
          bottom: tops[2].top,
          below: tops[2].id,
          bottomOfBelow: tops[3].top,
          left,
          right,
        };
      }

      if (id !== section.id && !data.isCollapsed) {
        for (const child of section.children) {
          const result = visit(child);
          if (result !== true) {
            return result;
          }
        }
      }

      return true;
    };

    for (const section of this.displayedHierarchicalSections) {
      const result = visit(section);
      if (typeof result === "object") {
        return result;
      }
      if (result === false) {
        // Not ready to calculate
        return null;
      }
    }

    // We didn't find a match while visiting, but there are still a couple scenarios
    // that could have a match

    const indexInTops = tops.findIndex((top) => top.id === id);
    if (indexInTops === -1) {
      // We didn't find the section
      return null;
    }

    const bottomOfAll = currentY;

    switch (indexInTops) {
      case 0:
        return {
          topOfAbove: null,
          above: null,
          top: tops[0].top,
          bottom: tops[1]?.top ?? bottomOfAll,
          below: tops[1]?.id ?? null,
          bottomOfBelow: tops.length > 1 ? tops[2]?.top ?? bottomOfAll : null,
          left,
          right,
        };
      case 1:
        return {
          topOfAbove: tops[0].top,
          above: tops[0].id,
          top: tops[1].top,
          bottom: tops[2]?.top ?? bottomOfAll,
          below: tops[2]?.id ?? null,
          bottomOfBelow: tops.length > 2 ? tops[3]?.top ?? bottomOfAll : null,
          left,
          right,
        };
      case 2:
        return {
          topOfAbove: tops[1].top,
          above: tops[1].id,
          top: tops[2].top,
          bottom: tops[3]?.top ?? bottomOfAll,
          below: tops[3]?.id ?? null,
          bottomOfBelow: tops.length > 3 ? tops[4]?.top ?? bottomOfAll : null,
          left,
          right,
        };
      case 3:
        // It was the very last section
        return {
          topOfAbove: tops[2].top,
          above: tops[2].id,
          top: tops[3].top,
          bottom: bottomOfAll,
          below: null,
          bottomOfBelow: null,
          left,
          right,
        };
      default:
        throw new Error("Invalid index");
    }
  }

  /**
   * Watch the element for height changes
   */
  private observeElementForId(element: HTMLElement, id: string) {
    const resizeObserver = new ResizeObserver(() => {
      if (id in this.elements) {
        this.elements[id].height = element.offsetHeight;
        this.debouncedRecalculateContainerHeights();
      }
    });
    resizeObserver.observe(element);
    return resizeObserver;
  }

  /**
   * Recalculate the height of all elements
   *
   * Note 1: This doesn't work off the DOM, it just uses the
   * registered heights
   * Note 2: This should only be triggered only when the height of
   * an element, without its children, changes
   * Note 3: It does not try to previous container height calculations. It rebuilds
   * the full height from just the element heights
   */
  private recalculateContainerHeights() {
    const sumChildrenHeight = (id: string) => {
      let height = 0;
      const data = this.elements[id];
      if (!data) {
        return null;
      }
      if (!data.childrenIds || data.isCollapsed) {
        return height;
      }
      for (const childId of data.childrenIds) {
        if (!this.elements[childId] || !this.elements[childId].height) {
          // We're not ready to apply heights yet
          return null;
        }
        height += this.elements[childId].height!;
        const grandChildHeight = sumChildrenHeight(childId);
        if (grandChildHeight === null) {
          // We're not ready to apply heights yet
          return null;
        }
        height += grandChildHeight;
      }
      return height;
    };

    for (const key of Object.keys(this.elements)) {
      const childrenHeight = sumChildrenHeight(key);
      if (childrenHeight === null) {
        // We're not ready to apply heights yet
        return;
      }

      this.elements[key].containerHeight = childrenHeight;
    }

    this.applyHeights();
  }

  // Debounced version of recalculateContainerHeights to prevent excessive calculations
  private debouncedRecalculateContainerHeights = debounce(
    () => this.recalculateContainerHeights(),
    16,
    {
      leading: false,
      trailing: true,
      maxWait: 50,
    }
  );

  // Debounced version of applyHeights to prevent excessive DOM updates
  private applyHeights = debounce(
    () => {
      // // If we have specific elements to update, only update those
      // for (const key of Object.keys(this.elements)) {
      //   const container = this.elements[key]?.container;
      //   if (!container) {
      //     continue;
      //   }
      //   container.style.height = `${this.elements[key].containerHeight}px`;
      // }
    },
    16,
    {
      leading: false,
      trailing: true,
      maxWait: 50,
    }
  );

  private archive(id: string) {
    if (id in this.elements) {
      this.elements[id].archived = true;
      this.elements[id].height = null;
    }
  }

  private *flatGenerator(
    sections: HierarchicalSectionInfo[]
  ): Generator<HierarchicalSectionInfo> {
    function* visit(
      _this: OutlineElementLayoutManager,
      section: HierarchicalSectionInfo
    ): Generator<HierarchicalSectionInfo> {
      yield section;
      const data = _this.elements[section.id];
      if (!data || data.isCollapsed) {
        return;
      }
      if (section.children) {
        for (const child of section.children) {
          yield* visit(_this, child);
        }
      }
    }
    for (const section of sections) {
      yield* visit(this, section);
    }
  }

  private getImmediatelyBefore(id: string): HierarchicalSectionInfo | null {
    const flat = this.flatGenerator(this.displayedHierarchicalSections);
    let last: HierarchicalSectionInfo | null = null;
    for (const section of flat) {
      if (section.id === id) {
        return last;
      }
      last = section;
    }
    return null;
  }

  /**
   * Clean up all resources used by this manager
   * Call this when the component using this manager unmounts
   */
  dispose() {
    // Cancel any pending debounced operations
    this.debouncedRecalculateContainerHeights.cancel();
    this.applyHeights.cancel();

    // Disconnect all resize observers
    for (const key of Object.keys(this.elements)) {
      this.elements[key]?.resizeObserver?.disconnect();
    }

    // Clear all elements
    this.elements = {};
  }
}

export default useOutlineLayout;
