import { ButtonHTMLAttributes, MouseEvent, ReactNode, forwardRef } from "react";
import { IconName, IconProps, iconVariants } from "components/common/Icon";
import { cn } from "lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../EditorView/Menus/Tooltip";
import { useOptionalApiClient } from "../../providers/ApiClientProvider";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import Columns from "./containers/Columns";
import DropdownMenu, { DropdownMenuItemProps } from "./menus/DropdownMenu";

const buttonVariants = cva("flex items-center select-none", {
  variants: {
    emphasis: {
      primary: "",
      secondary: "",
      tertiary: "",
      destructive: "",
      success: "",
    },
    variant: {
      // Just plain text (and icon)
      plain: "flex-wrap hover:opacity-50",
      // Plain text with underline
      underline: "flex-wrap hover:opacity-50 underline",
      // Bordered and filled in with a color
      solid: "whitespace-nowrap border rounded-sm",
      // Text with border below
      navigation: "hover:opacity-50 disabled:hover:opacity-100",
      // Round button with no border, designed for icon only buttons
      round: "whitespace-nowrap border rounded-full aspect-square",
    },
    hasText: {
      true: "",
      false: "",
    },
    disabled: {
      true: "cursor-not-allowed",
      false: "",
    },
    size: {
      xsmall: "text-2xs",
      small: "text-sm gap-sm",
      medium: "text-md gap-sm",
      large: "text-lg gap-md",
      xlarge: "text-3xl gap-md",
    },
    align: {
      left: "justify-start text-left",
      center: "justify-center",
      right: "justify-end text-right",
    },
    hasSecondaryActions: {
      true: "",
      false: "",
    },
    defaultVariants: {
      emphasis: "tertiary",
      variant: "solid",
      size: "medium",
      align: "center",
      hasSecondaryActions: false,
    },
  },
  compoundVariants: [
    // ------------------------------
    // Colors
    // ------------------------------
    {
      variant: "plain",
      emphasis: "primary",
      className: "text-primary",
    },
    {
      variant: "plain",
      emphasis: "secondary",
      className: "text-foreground",
    },
    {
      variant: "plain",
      emphasis: "tertiary",
      className: "text-secondary",
    },
    {
      variant: "plain",
      emphasis: "destructive",
      className: "text-destructive",
    },
    {
      variant: "plain",
      emphasis: "success",
      className: "text-additive",
    },
    {
      variant: "underline",
      emphasis: "primary",
      className: "text-primary",
    },
    {
      variant: "underline",
      emphasis: "secondary",
      className: "text-foreground",
    },
    {
      variant: "underline",
      emphasis: "tertiary",
      className: "text-secondary",
    },
    {
      variant: "underline",
      emphasis: "destructive",
      className: "text-destructive",
    },
    {
      variant: "underline",
      emphasis: "success",
      className: "text-additive",
    },
    {
      variant: "underline",
      emphasis: "primary",
      className: "text-primary",
    },
    {
      variant: "underline",
      emphasis: "secondary",
      className: "text-foreground",
    },
    {
      variant: "underline",
      emphasis: "tertiary",
      className: "text-secondary",
    },
    {
      variant: ["solid", "round"],
      emphasis: "primary",
      className:
        "font-semibold bg-primary border-primary text-background hover:bg-primary-darker ",
    },
    {
      variant: ["solid", "round"],
      emphasis: "secondary",
      className:
        "bg-background text-primary border-primary outline-primary hover:bg-background-darker",
    },
    {
      variant: ["solid", "round"],
      emphasis: "tertiary",
      className:
        "bg-background text-foreground border-border hover:bg-background-darker",
    },
    {
      variant: ["solid", "round"],
      emphasis: "destructive",
      className:
        "font-semibold bg-destructive border-destructive text-background hover:bg-destructive-darker",
    },
    {
      variant: ["solid", "round"],
      emphasis: "success",
      className:
        "font-semibold bg-additive border-additive text-background hover:bg-additive-darker",
    },
    // ------------------------------
    // Sizing
    // ------------------------------
    {
      variant: ["solid", "round"],
      size: "xsmall",
      className: "h-lg",
    },
    {
      variant: ["solid", "round"],
      size: "small",
      className: "h-xl",
    },
    {
      variant: ["solid", "round"],
      size: "medium",
      className: "h-2xl",
    },
    {
      variant: ["solid", "round"],
      size: "large",
      className: "h-3xl",
    },
    {
      variant: ["solid", "round"],
      size: "xlarge",
      className: "h-4xl",
    },
    {
      variant: "solid",
      size: "xsmall",
      className: "px-xs",
    },
    {
      variant: "solid",
      size: "small",
      className: "px-sm",
    },
    {
      variant: "solid",
      size: "medium",
      className: "px-md",
    },
    {
      variant: "solid",
      size: "large",
      className: "px-2m",
    },
    {
      variant: "solid",
      size: "xlarge",
      className: "px-xl",
    },
    {
      variant: "round",
      size: "xsmall",
      hasText: true,
      className: "px-sm",
    },
    {
      variant: "round",
      size: "small",
      hasText: true,
      className: "px-2m",
    },
    {
      variant: "round",
      size: "medium",
      hasText: true,
      className: "px-lg",
    },
    {
      variant: "round",
      size: "large",
      hasText: true,
      className: "px-xl",
    },
    {
      variant: "round",
      size: "xlarge",
      hasText: true,
      className: "px-2xl",
    },
    {
      variant: "navigation",
      size: "xsmall",
      className: "h-lg px-xs",
    },
    {
      variant: "navigation",
      size: "small",
      className: "h-xl px-sm",
    },
    {
      variant: "navigation",
      size: "medium",
      className: "h-3xl px-2m",
    },
    {
      variant: "navigation",
      size: "large",
      className: "h-4xl px-2m",
    },
    {
      variant: "navigation",
      size: "xlarge",
      className: "h-4xl px-xl",
    },
    // ------------------------------
    // Disabled
    // ------------------------------
    {
      disabled: true,
      variant: "plain",
      className: "opacity-50",
    },
    {
      disabled: true,
      variant: "underline",
      className: "opacity-50",
    },
    {
      disabled: true,
      variant: "underline",
      className: "opacity-50",
    },
    {
      disabled: true,
      variant: ["solid", "round"],
      className: "opacity-50",
    },
    {
      disabled: true,
      variant: ["solid", "round"],
      emphasis: "primary",
      className: "hover:bg-primary",
    },
    {
      disabled: true,
      variant: ["solid", "round"],
      emphasis: "secondary",
      className: "hover:bg-background",
    },
    {
      disabled: true,
      variant: ["solid", "round"],
      emphasis: "tertiary",
      className: "hover:bg-background",
    },
    {
      disabled: true,
      variant: "navigation",
      className: "border-b border-b-[2px] border-primary text-primary",
    },
    // ------------------------------
    // Has secondary actions
    // ------------------------------
    {
      hasSecondaryActions: true,
      variant: "solid",
      className: "rounded-none rounded-l-sm",
    },
  ],
});

const dropdownButtonVariants: typeof buttonVariants = cva("", {
  variants: {
    emphasis: {
      primary: "",
      secondary: "",
      tertiary: "",
      destructive: "",
      success: "",
    },
    variant: {
      // Just plain text (and icon)
      plain: "",
      // Plain text with underline
      underline: "",
      // Bordered and filled in with a color
      solid: "rounded-none rounded-r-sm border-l-0",
      // Round button with no border, designed for icon only buttons
      round: "hidden",
      // Text with border below
      navigation: "hidden",
    },
    hasText: {
      true: "",
      false: "",
    },
    disabled: {
      true: "",
      false: "",
    },
    size: {
      xsmall: "",
      small: "w-[28px]",
      medium: "w-[32px] ",
      large: "w-[40px]",
      xlarge: "w-[48px]",
    },
    align: {
      left: "",
      center: "",
      right: "",
    },
  },
  compoundVariants: [
    {
      variant: "solid",
      emphasis: "primary",
      className: "border-l border-l-background",
    },
    {
      variant: "solid",
      emphasis: "destructive",
      className: "border-l border-l-background",
    },
  ],
});

export type ButtonVariantProps = VariantProps<typeof buttonVariants>;

export interface ButtonProps
  extends ButtonVariantProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  onClick?: (e: MouseEvent<Element>) => void;
  // Warning: Should only be used for positioning purposes
  className?: string;
  icon?: IconName;
  iconVariant?: IconProps["variant"];
  disabled?: boolean;
  tooltip?: string;
  tooltipDelayDuration?: number;
  tooltipSide?: "top" | "bottom" | "left" | "right";
  metricsId?: string;
  isLoading?: boolean;
  navigate?: string;
  external?: boolean;
  // If true, the button will not be interactive (and not actually a button)
  // This is to allow using the same visual with other styles of interactivity
  // (e.g. dropdowns)
  visualOnly?: boolean;

  // If provided and not empty, will render a drop down arrow with a menu of the given actions
  secondaryActions?: DropdownMenuItemProps[];
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      icon,
      iconVariant,
      disabled = false,
      variant = "solid",
      size = "medium",
      align = "center",
      emphasis = "secondary",
      tooltip,
      tooltipDelayDuration,
      tooltipSide,
      isLoading,
      metricsId,
      className,
      navigate,
      external,
      visualOnly = false,
      onClick,
      secondaryActions,
      ...props
    },
    ref
  ) => {
    if (!!secondaryActions && variant === "navigation") {
      throw new Error("Navigation buttons cannot have secondary actions");
    }

    const apiClient = useOptionalApiClient();

    let iconElement: ReactNode | null = null;
    let textElement: ReactNode | null = text;

    let iconLeftMargin: string = "";
    if (variant === "solid") {
      switch (size) {
        case "xsmall":
          iconLeftMargin = "-mx-[3px]";
          break;
        case "small":
          iconLeftMargin = "-mx-[6px]";
          break;
        case "medium":
          iconLeftMargin = "-mx-sm";
          break;
        case "large":
          iconLeftMargin = "-mx-[10px]";
          break;
        case "xlarge":
          iconLeftMargin = "-mx-[18px]";
          break;
        default:
          break;
      }
    }

    if (isLoading) {
      if (icon) {
        iconElement = (
          <div
            className={cn(
              "flex items-center justify-center",
              variant === "solid" && "aspect-square h-full",
              variant === "round" && !text && "absolute aspect-square",
              iconLeftMargin
            )}
          >
            <Spinner size="inherit" />
          </div>
        );
      } else {
        textElement = (
          <div className="relative">
            <span className="opacity-0">{text}</span>
            <Spinner
              size="inherit"
              className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
            />
          </div>
        );
      }
    } else {
      if (icon) {
        iconElement = (
          <div
            className={cn(
              "flex items-center justify-center",
              variant === "solid" && "aspect-square h-full",
              variant === "round" && !text && "absolute aspect-square",
              iconLeftMargin
            )}
          >
            <i
              className={cn(
                iconVariants({ variant: iconVariant }),
                `fa-${icon}`
              )}
              draggable={false}
            />
          </div>
        );
      }
    }

    const content = (
      <>
        {iconElement}
        {textElement}
      </>
    );

    const handleClick = (e: MouseEvent<Element>) => {
      e.stopPropagation();
      if (disabled) {
        e.preventDefault();
        return;
      }
      if (metricsId && apiClient) {
        apiClient.recordMetric(metricsId);
      }
      if (onClick) {
        onClick(e as MouseEvent<HTMLButtonElement>);
      }
    };

    const commonProps = {
      ...(navigate ? {} : { disabled }),
      className: cn(
        buttonVariants({
          variant,
          size,
          align,
          emphasis,
          disabled: disabled || isLoading,
          hasSecondaryActions: !!secondaryActions,
          hasText: !!text,
        }),
        !!onClick || !!navigate ? "cursor-pointer" : "",
        className
      ),
    };

    if (visualOnly) {
      return <div {...commonProps}>{content}</div>;
    }

    const buttonContent = navigate ? (
      external ? (
        <a
          href={navigate}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
          {...commonProps}
        >
          {content}
        </a>
      ) : (
        <Link to={navigate} onClick={handleClick} {...commonProps}>
          {content}
        </Link>
      )
    ) : (
      <button ref={ref} {...props} {...commonProps} onClick={handleClick}>
        {content}
      </button>
    );

    let primaryButton: ReactNode = buttonContent;
    if (tooltip) {
      primaryButton = (
        <Tooltip delayDuration={tooltipDelayDuration}>
          <TooltipTrigger asChild={true}>{buttonContent}</TooltipTrigger>
          <TooltipContent side={tooltipSide}>{tooltip}</TooltipContent>
        </Tooltip>
      );
    }

    if (!secondaryActions) {
      return primaryButton;
    }

    return (
      <Columns>
        {primaryButton}
        <DropdownMenu items={secondaryActions}>
          <Button
            visualOnly={true}
            variant={variant}
            size={size}
            icon={variant === "solid" ? "chevron-down" : "ellipsis-vertical"}
            disabled={disabled || isLoading}
            className={cn(
              buttonVariants({
                variant,
                size,
                align,
                emphasis,
                disabled: disabled || isLoading,
                hasText: !!text,
              }),
              dropdownButtonVariants({
                variant,
                size,
                align,
                emphasis,
                disabled: disabled || isLoading,
                hasText: !!text,
              })
            )}
          />
        </DropdownMenu>
      </Columns>
    );
  }
);

export default Button;
