import { Choice, useChoose } from "providers/AlertProvider";
import Pill from "./Pill";
import { useCallback } from "react";
import Rows from "./containers/Rows";
import Icon from "./Icon";

interface ConnectionLostPillProps {
  reconnect?: () => void;
}

const ConnectionLostPill: React.FC<ConnectionLostPillProps> = ({
  reconnect,
}) => {
  const choose = useChoose();

  const handleClickConnectionLost = useCallback(async () => {
    let choices: Choice[] = [
      {
        id: "contact",
        icon: "envelope",
        text: "Contact Support",
        variant: "outline",
      },
    ];
    if (!!reconnect) {
      choices.push({
        id: "again",
        icon: "refresh",
        text: "Reconnect",
      });
    } else {
      choices.push({
        id: "cancel",
        text: "OK",
      });
    }
    const choice = await choose("", {
      dismissId: "cancel",
      variant: "buttons",
      body: (
        <Rows className="items-center gap-md max-w-[260px] mx-auto text-center">
          <Icon name="signal-bars-slash" size="xlarge" />
          <h1 className="font-semibold text-lg">Connection Lost</h1>
          <p>We lost connection to the server.</p>
          <p>Changes will not be saved until the connection is restored.</p>
        </Rows>
      ),
      choices,
    });

    switch (choice) {
      case "contact":
        window.open("mailto:support@odo.do");
        break;
      case "again":
        reconnect?.();
        break;
      case "cancel":
        break;
    }
  }, [choose, reconnect]);
  return (
    <Pill
      text="Connection Lost"
      className="bg-destructive text-background cursor-pointer"
      icon="signal-bars-slash"
      onClick={handleClickConnectionLost}
    />
  );
};

export default ConnectionLostPill;
