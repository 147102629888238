import DeprecatedButton from "components/common/DeprecatedButton";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { useConfirmModal } from "components/common/modals/ConfirmModal";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import { useNavigate } from "react-router-dom";
import { proposalFromAPI } from "types/Proposal";

const ProposalsRoute = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const [proposals, setProposals, paginatedData] = usePaginatedData({
    endpoint: apiClient.admin.rfpProposalList,
    map: (proposal) => ({
      ...proposalFromAPI(proposal),
      org: proposal.org?.name ?? "Uknown",
    }),
  });
  const confirm = useConfirmModal();

  const handleArchive = async (proposalId: string) => {
    if (
      !(await confirm({
        title: "Archive Proposal",
        message: "Are you sure you want to archive this proposal?",
        confirmText: "Archive",
        isDestructive: true,
      }))
    ) {
      return;
    }

    // @ts-ignore
    await apiClient.user.rfpProposalPartialUpdate(proposalId, {
      archived_at: new Date().toISOString(),
    });

    setProposals((prev) => {
      if (!prev) return prev;
      return prev.filter((p) => p.id !== proposalId);
    });
  };

  return (
    <PaginatedTableView
      results={proposals}
      paginatedData={paginatedData}
      onSelect={(p) => navigate(`/proposal/${p.id}`)}
      columns={[
        { name: "Title" },
        { name: "Org", size: "min" },
        { name: "Due Date", size: "min" },
        { name: "", size: "min", center: true },
      ]}
      rowSeparators={true}
      rowHeight={84}
      renderRow={(proposal, Cell, Row) => (
        <Row key={proposal.id}>
          <Cell>{proposal.title}</Cell>
          <Cell>{proposal.org}</Cell>
          <Cell>{proposal.dueDate}</Cell>
          <Cell center={true}>
            <DeprecatedButton
              icon="trash"
              onClick={() => handleArchive(proposal.id)}
            />
          </Cell>
        </Row>
      )}
    />
  );
};

export default ProposalsRoute;
