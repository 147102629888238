import { OrgDetails } from "api/Api";
import DeprecatedButton from "components/common/DeprecatedButton";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import NavigationView from "components/common/NavigationView";
import useFetchedData from "hooks/useFetchedData";
import SavedStateIndicator, {
  SaveState,
} from "components/common/forms/SavedStatusIndicator";
import { cn } from "lib/utils";
import { debounce } from "lodash";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, forwardRef, useEffect, useRef, useState } from "react";
import { message_from_exception } from "utils";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";

const CompanyProfileRoute: FC = () => {
  const apiClient = useApiClient();
  const basicInfoRef = useRef<HTMLDivElement>(null);
  const { isStaff } = useAuthenticatedUser();
  const recommendationProfileRef = useRef<HTMLDivElement>(null);
  const [highlightedSection, setHighlightedSection] = useState<
    "basic" | "recommend" | null
  >(null);

  const [saveState, setSaveState] = useState<SaveState>("unchanged");
  const [saveError, setSaveError] = useState<string | null>(null);
  const [localOrgDetails, doSetLocalOrgDetails] = useState<OrgDetails | null>(
    null
  );
  const [orgDetails, , { error }] = useFetchedData(async () => {
    const response = await apiClient.user.coreOrgRead();
    return response.data;
  }, []);

  const debouncedSave = useRef(
    debounce(async (details: OrgDetails) => {
      try {
        await apiClient.user.coreOrgUpdate({
          ...details,
          name: details.name ? details.name : "Unnammed Company",
        });
        setSaveState("saved");
        setSaveError(null);
      } catch (e) {
        setSaveState("error");
        setSaveError(message_from_exception(e));
      }
    }, 500)
  );

  useEffect(() => {
    if (orgDetails) {
      doSetLocalOrgDetails(orgDetails);
    }
  }, [orgDetails]);

  const setLocalOrgDetails = (details: OrgDetails) => {
    setSaveState("saving");
    doSetLocalOrgDetails(details);
    debouncedSave.current(details);
  };

  const scrollToElement = (ref: React.RefObject<HTMLDivElement>) => {
    if (!ref.current) return;

    // Scroll to the element and flash it
    ref.current.scrollIntoView({ behavior: "smooth" });
    setHighlightedSection(ref === basicInfoRef ? "basic" : "recommend");
    setTimeout(() => {
      setHighlightedSection(null);
    }, 500);
  };

  const tryAgain = () => {
    if (localOrgDetails) {
      setSaveState("saving");
      setSaveError(null);
      debouncedSave.current(localOrgDetails);
    }
  };

  return (
    <NavigationView scrollable={false}>
      <AsyncLoadedDiv
        value={localOrgDetails}
        className="grow flex flex-col overflow-hidden"
        error={error ? message_from_exception(error) : null}
        whileLoaded={(details) => (
          <>
            {/********************** Header ***********************************/}
            <Columns className="items-center shrink-0 mb-md">
              <h1 className="text-2xl font-semibold grow shrink-0">
                Company Profile
              </h1>
              {saveError && (
                <p className="grow text-destructive text-right mr-md">
                  {saveError}
                </p>
              )}
              <SavedStateIndicator state={saveState} tryAgain={tryAgain} />
            </Columns>
            <Columns className="gap-lg border rounded-md">
              {/********************** Nav ***********************************/}
              <Rows className="items-start bg-background-secondary rounded-sm overflow-y-auto shrink-0 p-sm border-r hidden tablet:block">
                <DeprecatedButton
                  text="Company Name"
                  className="pt-sm"
                  onClick={() => scrollToElement(basicInfoRef)}
                />
                <DeprecatedButton
                  text="Profile"
                  className="pt-sm"
                  onClick={() => scrollToElement(recommendationProfileRef)}
                />
              </Rows>
              {/********************** Sections ***********************************/}
              <Rows className="grow gap-lg overflow-y-auto">
                {/********************** Basic Info ***********************************/}
                <Section
                  title="Company Name"
                  ref={basicInfoRef}
                  highlighted={highlightedSection === "basic"}
                >
                  <Field title="Company Name*">
                    <Input
                      placeholder="Acme Corporation"
                      value={details.name}
                      onChange={(e) => {
                        setLocalOrgDetails({
                          ...details,
                          name: e.target.value,
                        });
                      }}
                    />
                  </Field>
                </Section>
                {/********************** Profile ***********************************/}
                <Section
                  title="Profile"
                  ref={recommendationProfileRef}
                  highlighted={highlightedSection === "recommend"}
                  instructions="Tell us about your company (optional)"
                >
                  <Field
                    title="Company Description"
                    instructions="In one or two sentences, describe your company."
                  >
                    <NaturalHeightTextArea
                      placeholder="Acme Corporation is a leading provider of..."
                      characterLimit={4096}
                      className="tablet:min-h-[90px] min-h-[150px]"
                      value={details.description ?? ""}
                      onChange={(text) => {
                        setLocalOrgDetails({
                          ...details,
                          description: text,
                        });
                      }}
                    />
                  </Field>
                  <Field
                    title="Services"
                    instructions="What are your primary services? What makes you unique?"
                  >
                    <NaturalHeightTextArea
                      placeholder="We offer a wide range of services..."
                      className="tablet:min-h-[90px] min-h-[150px]"
                      characterLimit={4096}
                      value={details.services ?? ""}
                      onChange={(text) =>
                        setLocalOrgDetails({
                          ...details,
                          services: text,
                        })
                      }
                    />
                  </Field>
                  {isStaff && (
                    <Field
                      title="Drafting Style"
                      instructions="Custom instructions on how to write proposals"
                    >
                      <NaturalHeightTextArea
                        placeholder="We offer a wide range of services..."
                        className="tablet:min-h-[90px] min-h-[150px]"
                        characterLimit={4096}
                        value={details.drafting_style ?? ""}
                        onChange={(text) =>
                          setLocalOrgDetails({
                            ...details,
                            drafting_style: text,
                          })
                        }
                      />
                    </Field>
                  )}
                </Section>
              </Rows>
            </Columns>
          </>
        )}
      />
    </NavigationView>
  );
};

interface SectionProps {
  title: string;
  instructions?: string;
  children?: React.ReactNode;
  highlighted?: boolean;
  className?: string;
}

const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ title, instructions, highlighted, className, children }, ref) => {
    return (
      <Rows
        ref={ref}
        className={cn("gap-lg shrink-0 p-md relative", className)}
      >
        <Rows className="gap-sm shrink-0">
          <h2 className="text-xl font-semibold border-b py-sm">{title}</h2>
          {instructions && <p>{instructions}</p>}
        </Rows>
        <Rows className="gap-xl">{children}</Rows>
        <div
          className="bg-background-secondary absolute z-[-1] inset-0 transition-opacity duration-300"
          style={{ opacity: highlighted ? 1 : 0 }}
        />
      </Rows>
    );
  }
);

interface FieldProps {
  title: string;
  instructions?: string;
  children?: React.ReactNode;
}

const Field: FC<FieldProps> = ({ title, instructions, children }) => {
  return (
    <LabeledFormInput label={title} variant="long-form">
      {instructions && <p className="text-secondary">{instructions}</p>}
      <Rows className="mt-sm justify-start">{children}</Rows>
    </LabeledFormInput>
  );
};

export default CompanyProfileRoute;
