import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Toggle from "components/common/forms/Toggle";
import { cn } from "lib/utils";
import HandsFreeSectionDetailsPanel from "./HandsFreeSectionDetailsPanel";
import DeprecatedSectionDetailsPanel from "./DeprecatedSectionDetailsPanel";
import useLocalStorage from "lib/useLocalStorage";

interface SectionDetailsPanelProps {
  className?: string;
}

const SectionDetailsPanel: React.FC<SectionDetailsPanelProps> = ({
  className,
}) => {
  const [handsFree, setHandsFree] = useLocalStorage<boolean>(
    "hands-free",
    false
  );

  return (
    <Rows className={cn("grow", className)}>
      <Columns className="shrink-0 justify-end">
        <Toggle
          toggleType="switch"
          text="Hands Free"
          on={handsFree}
          onToggle={setHandsFree}
        />
      </Columns>
      <Rows className="grow">
        {handsFree ? (
          <HandsFreeSectionDetailsPanel />
        ) : (
          <DeprecatedSectionDetailsPanel />
        )}
      </Rows>
    </Rows>
  );
};

export default SectionDetailsPanel;
