import { forwardRef, useMemo, useRef } from "react";
import PDFView, {
  PDFViewProps,
  PDFViewRef,
} from "components/common/pdf/PDFView";
import { useApiClient } from "providers/ApiClientProvider";

export interface RFPPDFViewProps
  extends Omit<PDFViewProps, "docId" | "getBlocksEndpoint" | "fileUrl"> {
  rfpId: string;
  fileUrl: string | null;
}

const RFPPDFView = forwardRef<PDFViewRef, RFPPDFViewProps>(
  ({ rfpId, fileUrl = null, ...props }, ref) => {
    const apiClient = useApiClient();

    const actualFileUrl = useMemo(() => {
      if (fileUrl) return fileUrl;

      return async (docId: string) => {
        const response = await apiClient.user.rfpRfpRead(docId);
        return response.data.view_url!;
      };
    }, [fileUrl, apiClient]);

    return (
      <PDFView
        {...props}
        ref={ref}
        docId={rfpId}
        getBlocksEndpoint={apiClient.user.rfpRfpBlocksList}
        fileUrl={actualFileUrl}
      />
    );
  }
);

export default RFPPDFView;
