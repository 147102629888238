import Icon from "components/common/Icon";
import { useConfirmModal } from "components/common/modals/ConfirmModal";
import {
  DeprecatedDropdownMenu,
  DeprecatedDropdownMenuContent,
  DeprecatedDropdownMenuItem,
  DeprecatedDropdownMenuTrigger,
} from "components/EditorView/Menus/DeprecatedDropdownMenu";
import { useConfirm, useTextAreaConfirm } from "providers/AlertProvider";
import { FC } from "react";

interface ListActionsButtonProps {
  listName: string;
  deleteList: () => void;
  renameList: (newName: string) => boolean;
}

const ListActionsButton: FC<ListActionsButtonProps> = ({
  listName,
  deleteList,
  renameList,
}) => {
  const confirm = useConfirmModal();
  const textAreaConfirm = useTextAreaConfirm();
  const handleDeleteList = async () => {
    const confirmed = await confirm({
      title: "Delete List?",
      message: `Are you sure you want to delete ${listName}? This action cannot be undone but you'll still be able to find the RFPs in our system.`,
      confirmText: "Delete Permanently",
      isDestructive: true,
    });
    if (confirmed) {
      deleteList();
    }
  };

  const handleRenameList = async () => {
    let keepRenaming = true;
    let newName = listName;
    while (keepRenaming) {
      const result = await textAreaConfirm("Rename List", {
        placeholder: "Enter the new name for the list",
        initialText: newName,
        multiline: false,
        buttons: [
          {
            text: "Cancel",
            id: "cancel",
          },
          {
            text: "Rename",
            id: "rename",
            variant: "solid",
          },
        ],
      });
      if (result.id === "rename") {
        keepRenaming = !renameList(result.text);
        newName = result.text;
      } else {
        keepRenaming = false;
      }
    }
  };

  return (
    <DeprecatedDropdownMenu>
      <DeprecatedDropdownMenuTrigger className="px-2m py-sm -ml-xs hover:opacity-50">
        <Icon name="ellipsis-vertical" className="text-secondary" />
      </DeprecatedDropdownMenuTrigger>
      <DeprecatedDropdownMenuContent>
        <DeprecatedDropdownMenuItem icon="pen" onClick={handleRenameList}>
          Rename List
        </DeprecatedDropdownMenuItem>
        <DeprecatedDropdownMenuItem icon="trash" onClick={handleDeleteList}>
          Delete List
        </DeprecatedDropdownMenuItem>
      </DeprecatedDropdownMenuContent>
    </DeprecatedDropdownMenu>
  );
};

export default ListActionsButton;
