import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useState,
  useEffect,
} from "react";
import { v4 } from "uuid";
import LoadableView from "components/common/containers/LoadableView";
import Overlay from "components/common/containers/overlays/Overlay";
import { message_from_exception } from "utils";
import { CustomOptions } from "./Alerts/types";
import DeprecatedButton from "components/common/DeprecatedButton";
import { cn } from "lib/utils";
import Spacer from "components/common/containers/Spacer";

interface AlertProviderProps {
  children?: ReactNode;
}

export interface Alert {
  message: string;
  options: CustomOptions;
  id: string;
  promise: (buttonId: string) => void;
}

interface AlertProviderData {
  show: (message: string, options: CustomOptions) => Promise<string>;
}

export const AlertProviderContext = createContext<AlertProviderData | null>(
  null
);

const AlertContent: FC<Alert & { hide: () => void }> = ({
  message,
  options,
  promise,
  hide,
}) => {
  const selectId = useCallback(
    (id: string) => {
      hide();
      promise(id);
    },
    [hide, promise]
  );

  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!options.defaultId) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && options.defaultId) {
        e.preventDefault();
        selectId(options.defaultId);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [options.defaultId, selectId]);

  return (
    <Overlay
      maxWidth={500}
      title={message}
      onClose={
        !!options.dismissId ? () => selectId(options.dismissId!) : undefined
      }
      fillWidth={options.fillWidth}
    >
      <LoadableView
        isLoading={loading}
        className="flex flex-col gap-lg font-medium"
      >
        {options.body && (
          <div>
            {typeof options.body === "function"
              ? options.body(selectId)
              : options.body}
          </div>
        )}
        {options.buttons.length > 0 && (
          <div
            className={cn(
              "flex gap-md justify-end text-sm",
              options.buttonOrientation === "vertical" ? "flex-col" : ""
            )}
          >
            {options.buttons.map((button) => {
              if (button === "spacer") {
                return <Spacer />;
              }
              const { id, process, ...buttonProps } = button;
              return (
                <DeprecatedButton
                  key={id}
                  {...buttonProps}
                  onClick={async () => {
                    if (button.process) {
                      try {
                        setLoading(true);
                        await button.process(id);
                      } catch (e) {
                        setError(message_from_exception(e));
                        return;
                      } finally {
                        setLoading(false);
                      }
                    }
                    selectId(button.id);
                  }}
                />
              );
            })}
          </div>
        )}
        {error && (
          <div className="text-destructive text-center">Error: {error}</div>
        )}
      </LoadableView>
    </Overlay>
  );
};

export const AlertProvider: FC<AlertProviderProps> = ({ children }) => {
  const [pendingAlerts, setPendingAlerts] = useState<Alert[]>([]);

  const show = useCallback(
    (message: string, options: CustomOptions) => {
      const id = v4();
      return new Promise<string>((resolve) => {
        setPendingAlerts((prev) => [
          ...prev,
          { message, id, options, promise: resolve },
        ]);
      });
    },
    [setPendingAlerts]
  );

  return (
    <AlertProviderContext.Provider value={{ show }}>
      {children}
      {pendingAlerts.map(({ promise, ...props }) => (
        <AlertContent
          key={props.id}
          {...props}
          promise={promise}
          hide={() =>
            setPendingAlerts((prev) =>
              prev.filter((a) => a.promise !== promise)
            )
          }
        />
      ))}
    </AlertProviderContext.Provider>
  );
};

// Re-export all hooks
export { useAlert } from "./Alerts/Alert";
export { useConfirm } from "./Alerts/Confirm";
export { useChoose, type Choice } from "./Alerts/Choose";
export { useTextAreaConfirm } from "./Alerts/TextAreaConfirm";
