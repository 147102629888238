import { RFPAllBlock } from "api/Api";
import BlockReferences from "./blockReferences";
import Rows from "components/common/containers/Rows";
import { ConcreteBlockLayout } from "./layout";
import { cn } from "lib/utils";
import { ReferenceColor } from "components/common/pdf/Reference";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";
import { type } from "os";

/**
 * A component that displays highlights a block in the PDF view.
 */
const PDFBlock: React.FC<{
  block: RFPAllBlock;
  layout: ConcreteBlockLayout;
  blockReferences: BlockReferences;
  activeBlockId: string | null;
  color?: ReferenceColor;
  showDebugInfo?: boolean;
  onClick?: () => void;
}> = ({
  block,
  layout,
  blockReferences,
  activeBlockId,
  color,
  showDebugInfo,
  onClick,
}) => {
  const padding = 8;
  const id = block.id!.toString();
  let colorClass = "bg-primary";
  let borderClass = "border-primary";
  switch (color) {
    case "primary":
      colorClass = "bg-primary";
      borderClass = "border-primary";
      break;
    case "secondary":
      colorClass = "bg-warning";
      borderClass = "border-warning";
      break;
    case "tertiary":
      colorClass = "bg-tertiary";
      borderClass = "border-tertiary";
      break;
    default:
      colorClass = "";
      borderClass = "";
      break;
  }
  return (
    <Rows
      ref={(ref) => blockReferences.setElement(id, ref)}
      className={cn(
        "absolute rounded-sm group overflow-visible",
        showDebugInfo && "hover:border hover:border-[3px]",
        borderClass,
        activeBlockId === id && "border border-[3px]",
        !!onClick && "cursor-pointer"
      )}
      onFocus={() => console.log("focus")}
      style={{
        left: layout.left - padding,
        top: layout.top - padding,
        width: layout.width + padding * 2,
        height: layout.height + padding * 2,
      }}
      onClick={
        onClick &&
        ((e) => {
          e.stopPropagation();
          onClick();
        })
      }
    >
      <div className={cn("opacity-10 grow", colorClass)} />
      {showDebugInfo && (
        <div className="opacity-0 group-hover:opacity-100 absolute bottom-full -right-[3px] bg-tertiary text-background rounded-t-sm px-xs">
          {id} ({block.index}): {block.type}
        </div>
      )}
    </Rows>
  );
};

export default PDFBlock;
