import { FC, ReactNode, useEffect } from "react";
import { cva, VariantProps } from "class-variance-authority";
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "@radix-ui/react-dialog";
import Columns from "../Columns";
import { cn } from "lib/utils";
import Button from "components/common/Button";

const overlayVariants = cva("inset-0 bg-overlay", {
  variants: {
    variant: {
      // Center the content on the screen
      // If the content is too large, it will scroll
      centered: "",
      // Cover the entire screen
      // The content will be top-aligned and scrollable
      "full-screen": "",
      // Cover the entire screen except for a fixed-size border
      // The content will be top-aligned and scrollable
      // If the screen is small (mobile), this will be full-screen
      "full-screen-bordered": "",
      // Centered but no border, padding, or background
      transparent: "",
    },
    inDialog: {
      true: "fixed",
      false: "block",
    },
    fillWidth: {
      true: "",
      false: "",
    },
    fillHeight: {
      true: "",
      false: "",
    },
  },
  defaultVariants: {
    variant: "centered",
    inDialog: true,
  },
});

const contentVariants: typeof overlayVariants = cva("flex flex-col", {
  variants: {
    variant: {
      centered:
        "bg-background top-[max(20px,50%)] left-[max(20px,50%)] -translate-x-[max(20px,50%)] -translate-y-[max(20px,50%)] max-w-[calc(100%-40px)] max-h-[calc(100%-40px)] rounded-md",
      "full-screen": "bg-background inset-0",
      "full-screen-bordered":
        "bg-background inset-0 mobile:inset-xl mobile:rounded-md",
      transparent:
        "top-[max(20px,50%)] left-[max(20px,50%)] -translate-x-[max(20px,50%)] -translate-y-[max(20px,50%)] max-w-[calc(100%-40px)] max-h-[calc(100%-40px)]",
    },
    inDialog: {
      true: "fixed",
      false: "block",
    },
    fillWidth: {
      true: "w-full",
      false: "",
    },
    fillHeight: {
      true: "h-full",
      false: "",
    },
  },
  defaultVariants: {
    variant: "centered",
    inDialog: true,
    fillWidth: false,
    fillHeight: false,
  },
  compoundVariants: [
    {
      variant: "full-screen",
      fillWidth: true,
      className: "w-full",
    },
    {
      variant: "full-screen",
      fillHeight: true,
      className: "h-full",
    },
    {
      variant: "full-screen-bordered",
      fillWidth: true,
      className: "w-full mobile:w-[calc(100%-var(--space-xl)*2)]",
    },
    {
      variant: "full-screen-bordered",
      fillHeight: true,
      className: "h-full mobile:h-[calc(100%-var(--space-xl)*2)]",
    },
  ],
});

export type OverlayVariant = VariantProps<typeof overlayVariants>["variant"];

export interface OverlayProps extends VariantProps<typeof overlayVariants> {
  children: ReactNode;
  open?: boolean;
  title?: string;
  // If true, the content is expected to be laid out naturally and if
  // it is too large, it will scroll automatically
  // If false, the content is placed in a flex column and will not scroll
  // automatically
  scrollable?: boolean;
  // The maximum width of the content
  maxWidth?: number;
  // If true, the content will try to fill the width of the screen
  // (this is usually used in conjection with a maxWidth to fill up to the max)
  fillWidth?: boolean;
  // If true, the content will try to fill the height of the screen
  fillHeight?: boolean;
  // Other elements to add to the title bar
  extraTitleBarElements?: ReactNode;
  // Called when the overlay is dismissed
  // If not specified, there will be no close button
  onClose?: (force: boolean) => void;
  // If true, the overlay will be rendered even if it is not open (hidden)
  forceRender?: boolean;
  // Called when the overlay is opened
  onOpen?: () => void;
  // If true, the overlay will be dismissed when the user clicks outside of it
  dismissOnClickOutside?: boolean;
}

export const OverlayContent: FC<OverlayProps & { inDialog?: boolean }> = ({
  variant,
  children,
  title,
  scrollable = true,
  maxWidth,
  fillWidth = false,
  fillHeight = false,
  extraTitleBarElements,
  onClose,
  inDialog = true,
}) => {
  const dismiss = (force: boolean) => {
    onClose?.(force);
  };

  const TitleComponent = inDialog ? DialogTitle : "div";

  return (
    <div
      style={{ maxWidth }}
      className={cn(
        contentVariants({ variant, inDialog, fillWidth, fillHeight })
      )}
    >
      {/* Top Toolbar (only if title is present) */}
      {title && (
        <Columns
          className={cn(
            "flex gap-lg items-center shrink-0",
            variant !== "transparent" && "py-2m px-xl"
          )}
        >
          <TitleComponent className="text-xl font-semibold overflow-hidden">
            <p className="truncate">{title}</p>
          </TitleComponent>
          {extraTitleBarElements}
          {onClose && (
            <Columns
              className={cn(
                "grow justify-end shrink-0",
                variant !== "transparent" && "-mr-md"
              )}
            >
              <Button
                icon="xmark"
                variant="plain"
                className="shrink-0"
                onClick={() => dismiss(false)}
              />
            </Columns>
          )}
        </Columns>
      )}
      <div
        className={cn(
          "px-xl pb-lg relative w-full",
          scrollable
            ? "overflow-y-auto"
            : "flex flex-col overflow-hidden w-full grow",
          variant !== "transparent" && !title && "pt-xl",
          !!maxWidth && "mx-auto"
        )}
      >
        {children}
      </div>
    </div>
  );
};

const Overlay: FC<OverlayProps> = ({ ...props }) => {
  const {
    open = true,
    onClose,
    dismissOnClickOutside = true,
    variant,
    forceRender,
    onOpen,
  } = props;

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [onOpen, open]);

  return (
    <Dialog
      open={forceRender || open}
      // modal={true}
      onOpenChange={(open) => {
        if (!open && dismissOnClickOutside) {
          onClose?.(false);
        }
      }}
    >
      <DialogPortal
        onClick={() => {
          debugger;
        }}
      >
        <DialogOverlay
          className={cn(
            overlayVariants({ variant }),
            forceRender && !open && "hidden"
          )}
        />
        <DialogContent
          className={cn(forceRender && !open && "hidden")}
          onPointerDownOutside={(e) => {
            if (!dismissOnClickOutside) {
              e.preventDefault();
            }
          }}
        >
          <OverlayContent {...props} inDialog={true} />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
export default Overlay;
