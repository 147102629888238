import Columns from "components/common/containers/Columns";
import EditableText, {
  EditableTextRef,
} from "components/common/forms/EditableText";
import { cn } from "lib/utils";
import { FC, Ref, useRef } from "react";
import Icon from "components/common/Icon";
import Button from "components/common/Button";
import { OutlineElementLayoutManager } from "./hooks/useOutlineLayout";
import { useDragHandle } from "./OutlineDragDrop";
import { HierarchicalSectionInfo } from "./hooks/types";
import { Flipped } from "react-flip-toolkit";
import { useOdoEditorRef } from "hooks/odo-editor/useOdoEditorRef";
import { useConfirmModal } from "components/common/modals/ConfirmModal";
import StatusDropDown from "components/proposals/StatusDropDown";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";

interface OutlineElementViewProps {
  layoutManager: OutlineElementLayoutManager;
  section: HierarchicalSectionInfo;
  selectedSectionId?: string | null;
  onSectionClick?: (section: HierarchicalSectionInfo) => void;
  onNameChange?: (section: HierarchicalSectionInfo, newName: string) => void;
  onToggleExpand?: () => void;
  isCollapsed: boolean;
  isDragging?: boolean;
  isDragOverlay?: boolean;
  onKeyDown?: (
    section: HierarchicalSectionInfo,
    event: React.KeyboardEvent
  ) => void;
  className?: string;
  style?: React.CSSProperties;
  editable?: boolean;
}

/**
 * Renders a single outline element in the navigation panel
 */
const OutlineElementView: FC<OutlineElementViewProps> = ({
  layoutManager,
  section,
  onSectionClick,
  onNameChange,
  onKeyDown,
  onToggleExpand,
  isCollapsed,
  isDragging = false,
  isDragOverlay = false,
  className,
  style,
  editable = true,
  selectedSectionId,
}) => {
  const editor = useOdoEditorRef();
  const editableTextRef = useRef<EditableTextRef>(null);

  const handleNameChange = (newText: string) => {
    onNameChange?.(section, newText);
  };

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    onKeyDown?.(section, event);
  };

  const handleClick = () => {
    editableTextRef.current?.focusEnd();
    onSectionClick?.(section);
  };

  const handleFocus = (e: React.FocusEvent) => {
    if (e.target !== e.currentTarget) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      editableTextRef.current?.focus();
    }, 100);
  };

  const handleStatusChange = (status: {
    status: string;
    statusName: string;
  }) => {
    editor.setSectionStatus(section.id, status);
  };

  const ref = (element: HTMLElement | null) => {
    layoutManager.registerElement(section.id, element);
  };

  const marginLeft = 24 * (section.level - 1);

  return (
    <Flipped key={section.id} flipId={section.id}>
      <div
        className={cn(
          "grow relative outline-element",
          editable ? "py-xs" : isCollapsed ? "py-xs" : "pt-xs",
          isDragging ? "z-[0]" : "z-[1]",
          className
        )}
        style={{
          marginLeft,
          ...style,
        }}
        ref={ref}
        tabIndex={0}
        onFocus={handleFocus}
      >
        {editable && (
          <FloatingInsertButton
            layoutManager={layoutManager}
            section={section}
          />
        )}
        {editable && (
          <FloatingChildrenBottomTab visible={isCollapsed && !isDragging} />
        )}
        <div className="relative" style={{}}>
          <FloatingDragDestinationIndicator visible={isDragging} />
          <Columns
            className={cn(
              "items-stretch shrink-0 hover:bg-background-selected cursor-pointer",
              "rounded-md relative select-none",
              editable
                ? "bg-background shadow-sm hover:bg-background-selected"
                : "hover:bg-background-tertiary text-sm",
              "group",
              isDragging && "opacity-0",
              !editable &&
                selectedSectionId === section.id &&
                "bg-background-tertiary-darker"
            )}
            style={{
              transformOrigin: "center",
            }}
            onClick={handleClick}
          >
            {editable ? (
              <DragHandle isDragging={isDragging} section={section} />
            ) : (
              <StatusDropDown
                className="shrink-0 grow-0 p-sm pr-xs"
                status={section.status ?? "blank"}
                statusName={section.statusName ?? undefined}
                onChange={handleStatusChange}
              />
            )}
            <CollapsedToggle
              isCollapsed={isCollapsed}
              onToggle={onToggleExpand}
              isDragging={isDragging}
            />
            <Name
              section={section}
              editable={editable && !isDragOverlay}
              onNameChange={handleNameChange}
              onKeyDown={handleKeyDown}
              ref={editableTextRef}
            />
            {editable && <DeleteButton section={section} />}
          </Columns>
        </div>
      </div>
    </Flipped>
  );
};

const FloatingInsertButton: FC<{
  layoutManager: OutlineElementLayoutManager;
  section: HierarchicalSectionInfo;
}> = ({ layoutManager, section }) => {
  const editor = useOdoEditorRef();
  return (
    <div className="absolute -top-[8px] h-[16px] left-0 right-0 group z-[2]">
      <div className="absolute top-[7px] left-[16px] right-0 h-[2px] bg-primary mx-sm group-hover:opacity-100 opacity-0" />
      <Button
        icon="circle-plus"
        iconVariant="solid"
        variant="plain"
        className="absolute text-primary top-0 left-0 mx-sm group-hover:opacity-100 opacity-0"
        onClick={() => {
          const id = editor.insertSection(section.id, section.level);
          if (id) {
            layoutManager.focusElement(id);
          }
        }}
      />
    </div>
  );
};

const FloatingChildrenBottomTab: FC<{ visible: boolean }> = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className="h-[10px] absolute top-[calc(100%-8px)] left-xl right-sm bg-background rounded-b-md shadow-sm" />
  );
};

const FloatingDragDestinationIndicator: FC<{ visible: boolean }> = ({
  visible,
}) => {
  if (!visible) return null;
  return (
    <div
      className={cn(
        "absolute inset-0 border border-dashed rounded-md border-foreground"
      )}
    />
  );
};

const DragHandle: FC<{
  isDragging: boolean;
  section: HierarchicalSectionInfo;
}> = ({ isDragging, section }) => {
  const { props } = useDragHandle(section);
  return (
    <Icon
      {...props}
      name="grip-vertical"
      variant="solid"
      className={cn(
        "text-secondary cursor-grab flex items-center pl-md pr-sm",
        "hover:text-foreground",
        isDragging && "text-foreground"
      )}
    />
  );
};

const CollapsedToggle: FC<{
  isCollapsed: boolean;
  isDragging: boolean;
  onToggle?: () => void;
}> = ({ isCollapsed, onToggle }) => {
  if (!onToggle) return null;
  return (
    <button
      className="w-[20px] transition-transform shrink-0 text-secondary hover:text-foreground"
      onClick={onToggle}
    >
      <Icon
        name="chevron-right"
        variant="solid"
        style={{
          transform: isCollapsed ? "rotate(0deg)" : "rotate(90deg)",
        }}
      />
    </button>
  );
};

const Name: FC<{
  section: HierarchicalSectionInfo;
  editable: boolean;
  onNameChange: (newName: string) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  ref: Ref<EditableTextRef>;
}> = ({ section, editable, onNameChange, onKeyDown, ref }) => {
  if (editable) {
    return (
      <EditableText
        value={section.name}
        className={cn(
          "grow p-sm pr-0",
          editable ? "text-foreground" : "text-background-darker"
        )}
        placeholder="Section name"
        style={{
          wordBreak: "break-word",
        }}
        onChange={onNameChange}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={onKeyDown}
        ref={ref}
      />
    );
  } else {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="grow p-xs pr-0 truncate">{section.name}</div>
        </TooltipTrigger>
        <TooltipContent>{section.name}</TooltipContent>
      </Tooltip>
    );
  }
};

const DeleteButton: FC<{ section: HierarchicalSectionInfo }> = ({
  section,
}) => {
  const editor = useOdoEditorRef();
  const confirm = useConfirmModal();

  const handleClick = async () => {
    const result = await confirm({
      title: "Delete Section",
      message: "Are you sure you want to delete this section?",
      confirmText: "Delete",
    });
    if (!result) return;
    editor.deleteSection(section.id);
  };

  return (
    <Button
      icon="xmark"
      size="small"
      iconVariant="solid"
      onClick={handleClick}
      variant="plain"
      className="pl-xs pr-sm transition-transform shrink-0 text-secondary group-hover:opacity-100 opacity-0 hover:text-foreground"
    />
  );
};

export default OutlineElementView;
