import Button from "components/common/Button";
import DropdownMenu, {
  DropdownMenuItemProps,
} from "components/common/menus/DropdownMenu";
import { DeprecatedDropdownMenuTrigger } from "components/EditorView/Menus/DeprecatedDropdownMenu";
import { Dispatch, SetStateAction, useMemo } from "react";

interface LengthDropdownProps {
  length: string;
  setLength: Dispatch<SetStateAction<string>>;
}

export const LengthDropdown: React.FC<LengthDropdownProps> = ({
  length,
  setLength,
}) => {
  const options: DropdownMenuItemProps[] = useMemo(
    () => [
      {
        icon: "horizontal-rule",
        text: "short",
        onClick: () => setLength("short"),
      },
      {
        icon: "grip-lines",
        text: "medium",
        onClick: () => setLength("medium"),
      },
      { icon: "align-justify", text: "long", onClick: () => setLength("long") },
    ],
    [setLength]
  );

  return (
    <DropdownMenu items={options}>
      <DeprecatedDropdownMenuTrigger asChild>
        <Button text={length} visualOnly={true} emphasis="tertiary" />
      </DeprecatedDropdownMenuTrigger>
    </DropdownMenu>
  );
};
