import { useCallback } from "react";
import { Buffer } from "buffer";
import { useApiClient } from "providers/ApiClientProvider";
import { v4 } from "uuid";
import { useEditorRef } from "@udecode/plate-common";
import { ELEMENT_IMAGE } from "@udecode/plate";
import { Location } from "slate";
import { odoToast } from "lib/odoToast";

const useImageUpload = (docId: string) => {
  const apiClient = useApiClient();
  const editor = useEditorRef();
  let docType: "proposal" | "collab" = "proposal";
  if (docId.startsWith("collab-")) {
    docType = "collab";
  }
  const uploadImage = useCallback(
    async (
      dataUrl: string | File,
      onProcessed?: (tempId: string) => void
    ): Promise<string | null> => {
      try {
        let buf: Buffer;
        let contentType: string;
        let aspectRatio: number;
        if (typeof dataUrl === "string") {
          const stringDataUrl = dataUrl.toString();
          aspectRatio = await getImageAspectRatio(stringDataUrl);
          contentType = stringDataUrl.substring(
            stringDataUrl.indexOf(":") + 1,
            stringDataUrl.indexOf(";")
          );
          buf = Buffer.from(
            stringDataUrl.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          );
        } else {
          contentType = dataUrl.type;
          buf = Buffer.from(await dataUrl.arrayBuffer());
          aspectRatio = await getImageAspectRatio(URL.createObjectURL(dataUrl));
        }

        onProcessed?.(`id:_|${aspectRatio}`);

        // Create the image record on the backend
        const response = await apiClient.user.imagesCreate({
          doc_id: docId,
          doc_type: docType,
          extension: contentType.split("/")[1],
        });

        if (!response.data.upload_url) {
          odoToast.error({
            title: "Failed to get upload url",
            text: "Please try again later",
          });
          return "";
        }

        // Upload the image
        await fetch(response.data.upload_url, {
          method: "PUT",
          body: buf,
          headers: {
            "Content-Type": contentType,
            "Content-Encoding": "base64",
          },
        });

        // Mark the image as uploaded (and get read url)
        await apiClient.user.imagesMarkUploaded(response.data.id!);
        return `id:${response.data.id!}|${aspectRatio}`;
      } catch (err) {
        odoToast.caughtError(err, "Uploading Image");
        return null;
      }
    },
    [apiClient.user, docId, docType]
  );

  const insertImage = useCallback(
    async (file: File, options?: { atEnd?: boolean }) => {
      const id = v4();
      let location: Location | undefined = undefined;
      if (options?.atEnd) {
        location = [editor.children.length];
      }
      const url = await uploadImage(file, (tempId) => {
        // Insert a temporary image element to show loading
        editor.insertNodes(
          {
            // @ts-ignore
            type: ELEMENT_IMAGE,
            id,
            url: tempId,
            children: [{ text: "" }],
          },
          { at: location }
        );
      });

      // Replace the url of the temporary image element with the actual url
      editor.setNodes(
        {
          // @ts-ignore
          url,
        },
        { at: [], match: (n) => "id" in n && n.id === id }
      );
    },
    [editor, uploadImage]
  );

  return { uploadImage, insertImage };
};

const getImageAspectRatio = (base64: string): Promise<number> => {
  return new Promise((resolved, rejected) => {
    try {
      let i = new Image();
      i.onload = function () {
        resolved(i.width / i.height);
      };
      i.src = base64;
    } catch (err) {
      odoToast.caughtError(err, "Getting Image Aspect Ratio");
      rejected(err);
    }
  });
};

export default useImageUpload;
