import Overlay from "components/common/containers/overlays/Overlay";
import { ModalOptions, ModalProps } from "components/common/modals/useModal";
import { createContext, FC, ReactNode, useState } from "react";
import { v4 } from "uuid";

export type ShowModal<OutputType, T extends ModalProps<OutputType>> = (
  component: FC<T>,
  props: Omit<T, "resolve">,
  options: ModalOptions
) => Promise<OutputType>;

interface ModalProviderData {
  show: ShowModal<any, any>;
}

export const ModalProviderContext = createContext<ModalProviderData | null>(
  null
);

interface Modal<T> {
  id: string;
  component: FC<ModalProps<T>>;
  props: Omit<ModalProps<T>, "resolve">;
  options: ModalOptions;
  resolve: (value: any) => void;
}

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<Modal<any>[]>([]);

  const show: ShowModal<any, any> = (component, props, options) => {
    return new Promise<any>((resolve) => {
      const id = v4();
      setModals((prev) => [
        ...prev,
        {
          id,
          component,
          props,
          options,
          resolve: (value) => {
            resolve(value);
            setModals((prev) => prev.filter((m) => m.id !== id));
          },
        },
      ]);
    });
  };

  return (
    <ModalProviderContext.Provider value={{ show }}>
      {children}
      {modals.map(({ id, component: Component, props, options, resolve }) => {
        return (
          <Overlay key={id} {...options}>
            <Component {...props} resolve={resolve} />
          </Overlay>
        );
      })}
    </ModalProviderContext.Provider>
  );
};
