import React, {
  BaseHTMLAttributes,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useState,
} from "react";
import { cn } from "lib/utils";
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
} from "@radix-ui/react-popover";

interface EditableTextProps
  extends Omit<BaseHTMLAttributes<HTMLDivElement>, "onChange"> {
  value: string;
  onChange?: (newText: string, oldText: string) => string | void;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  readonly?: boolean;
  placeholder?: string;
  forcePlaceholder?: boolean;
  mode?: "div" | "p";
}

export type EditableTextRef = {
  focus: () => void;
  focusEnd: () => void;
  blur: () => void;
};

const EditableText = forwardRef<EditableTextRef, EditableTextProps>(
  (
    {
      value,
      onChange,
      onFocus,
      onBlur,
      onSubmit,
      placeholder,
      readonly = false,
      forcePlaceholder,
      mode = "div",
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = useState(false);

    const internalOnFocus: React.FocusEventHandler = (e) => {
      setIsFocused(true);
      onFocus?.(e);
    };

    const internalOnBlur: React.FocusEventHandler = (e) => {
      setIsFocused(false);
      onBlur?.(e);
    };

    const insertText = useCallback(
      (text: string) => {
        if (!internalRef.current) {
          return;
        }
        if (document.activeElement === internalRef.current) {
          // Get the current selection
          const selection = window.getSelection();
          if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const startOffset = range.startOffset;
            const endOffset = range.endOffset;
            const nodeContent = range.startContainer.textContent || "";

            // Check the character before the insertion point
            const charBefore =
              startOffset > 0 ? nodeContent[startOffset - 1] : null;
            const charAfter =
              endOffset < nodeContent.length ? nodeContent[endOffset] : null;

            if (charBefore && !charBefore.match(/\s/)) {
              text = " " + text;
            }
            if (!charAfter || !charAfter.match(/\s/)) {
              text += " ";
            }

            // Create a text node for the plain text
            const textNode = document.createTextNode(text);

            // Insert the text node at the current position
            range.deleteContents();
            range.insertNode(textNode);

            // Position the cursor after the inserted text node
            range.setStartAfter(textNode);
            range.setEndAfter(textNode);
            selection.removeAllRanges();
            selection.addRange(range);

            // Apply highlighting based on the regex
            const newText =
              onChange?.(internalRef.current.innerText, value) ?? undefined;
            if (newText !== undefined) {
              internalRef.current.innerText = newText;
            }
          }
        }
      },
      [onChange]
    );

    useImperativeHandle(ref, () => ({
      insertText,
      focus: () => {
        const el = internalRef.current;
        if (!el) return;
        el.focus();
      },
      focusEnd: () => {
        const el = internalRef.current;
        if (!el) return;
        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      },
      blur: () => {
        const el = internalRef.current;
        if (!el) return;
        el.blur();
      },
    }));

    const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = async (
      event
    ) => {
      event.preventDefault();
      if (typeof document.execCommand === "function") {
        const text = event.clipboardData.getData("text/plain");
        document.execCommand("insertText", false, text);
      } else {
        const text = await navigator.clipboard.readText();
        insertText(text);
      }
    };

    useEffect(() => {
      if (internalRef.current && internalRef.current.innerText !== value) {
        internalRef.current.innerText = value;
      }
    }, [value]);

    const Element = mode === "p" ? "p" : "div";

    const element = (
      <Element
        {...props}
        ref={internalRef}
        contentEditable={!readonly}
        suppressContentEditableWarning={true}
        onFocus={internalOnFocus}
        onBlur={internalOnBlur}
        placeholder={placeholder}
        data-persistent-placeholder={forcePlaceholder ?? false}
        onInput={(e) => {
          const newText =
            onChange?.(e.currentTarget.innerText, value) ?? undefined;
          if (newText !== undefined) {
            e.currentTarget.innerText = newText;
          }
        }}
        onPaste={handlePaste}
        className={cn(
          mode === "div" && "break-word outline-0 whitespace-pre-wrap",
          props.className
        )}
      />
    );

    return element;
  }
);

export default EditableText;
