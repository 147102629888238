import { RFPSearch, SavedSearchDetail } from "api/Api";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { useMemo, useRef, useState } from "react";
import NavigationView from "components/common/NavigationView";
import RFPListItemView from "components/rfps/RFPListItemView";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import Overlay from "components/common/containers/overlays/Overlay";
import RFPDetailsView from "components/rfps/RFPDetailsView";
import DeprecatedButton from "components/common/DeprecatedButton";
import RFPSearchMenu from "components/rfps/search-menu/RFPSearchMenu";
import Columns from "components/common/containers/Columns";
import {
  ShortListsProvider,
  useShortLists,
} from "providers/ShortListsProvider";
import { copyToClipboard } from "lib/clipboard";
import Rows from "components/common/containers/Rows";
import ConnectionLostPill from "components/common/ConnecionLostPill";
import Button from "components/common/Button";
import SearchWizard from "components/rfps/SearchWizard";
import useFetchedData from "hooks/useFetchedData";
import { isEmptySearch } from "components/rfps/search-menu/SearchConfiguratorProps";

const RFPsRoute = () => {
  const apiClient = useApiClient();
  const [rfps, , paginatedData] = usePaginatedData({
    endpoint: apiClient.user.rfpRfpSearchList,
    mode: "pages",
    map: (data) => data,
    deps: [],
  });

  const [viewingRFP, setViewingRFP] = useState<RFPSearch | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerPinned, setHeaderPinned] = useState(false);
  const [search, setSearch, { error }] = useFetchedData(async () => {
    const result = await apiClient.user.rfpSavedSearchCurrentRead();
    return result.data;
  }, []);
  const [undoUpdateState, setUndoUpdateState] =
    useState<SavedSearchDetail | null>(null);

  const viewDetails = (rfp: RFPSearch) => {
    setViewingRFP(rfp);
  };

  const copyLink = (rfp: RFPSearch) => {
    copyToClipboard(`${window.location.origin}/rfps/${rfp.id}/`);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!headerRef.current) return;
    const target = e.target as HTMLElement;
    setHeaderPinned(target.scrollTop > headerRef.current.clientHeight);
  };

  const [showSearchWizard, setShowSearchWizard] = useState(false);

  return (
    <ShortListsProvider>
      <NavigationView
        selected="rfps"
        fullWidth={true}
        scrollable={false}
        padContent={false}
      >
        <Columns className="flex gap-md justify-center grow">
          <div
            className="grow overflow-y-scroll pl-[18px]"
            onScroll={handleScroll}
          >
            <Rows
              className="shrink-0 max-w-[950px] mx-auto pt-lg pb-md"
              ref={headerRef}
            >
              <Columns className="gap-md items-baseline">
                <h1 className="text-2xl font-semibold w-full grow">RFPs</h1>
                {!!paginatedData.count && (
                  <div className="px-sm text-secondary flex items-center select-none shrink-0">
                    {paginatedData.count}{" "}
                    {paginatedData.count === 1 ? "RFP" : "RFPs"} found
                  </div>
                )}
              </Columns>
              <Columns className="gap-sm items-baseline">
                <p className="text-secondary grow">
                  Discover RFPs and shortlist them for later.
                </p>
                <Button
                  variant="plain"
                  emphasis="primary"
                  text="Want help setting up your search?"
                  align="right"
                  onClick={() => setShowSearchWizard(true)}
                />
              </Columns>
            </Rows>
            <RFPSearchMenu
              className="max-w-[950px] mx-auto w-full sticky top-0 z-[1]"
              isPinnedToTop={headerPinned}
              resultCount={paginatedData.count}
              refreshFilter={() => {
                paginatedData.refresh();
              }}
              onUpdated={() => {
                paginatedData.refresh();
              }}
              search={search}
              setSearch={setSearch}
              error={error}
              undoUpdateState={undoUpdateState}
              setUndoUpdateState={setUndoUpdateState}
            />
            <Rows
              className="max-w-[950px] mx-auto transition-[max-height] duration-1000"
              style={{
                maxHeight: !!search && isEmptySearch(search) ? 150 : 0,
              }}
            >
              <Rows className="p-md mt-md bg-background-secondary rounded-md border gap-sm">
                <h1 className="text-lg font-semibold">
                  Set up your search with AI
                </h1>
                <p className="text-secondary">
                  Use AI to help set up your first search
                </p>
                <Columns>
                  <Button
                    emphasis="primary"
                    text="Get Started"
                    onClick={() => setShowSearchWizard(true)}
                  />
                </Columns>
              </Rows>
            </Rows>
            <PaginatedTableView
              results={rfps}
              paginatedData={paginatedData}
              columns={[]}
              className="pt-xs"
              internalScroll={false}
              scrollableClassName="border-0"
              renderRow={(rfp, Cell, Row) => (
                <Row key={rfp.id}>
                  <Cell className="px-none max-w-[950px] mx-auto">
                    <RFPListItemView
                      rfp={rfp}
                      onViewDetails={() => viewDetails(rfp)}
                      exclusiveListId={null}
                      allowStarting={false}
                    />
                  </Cell>
                </Row>
              )}
            />
          </div>
        </Columns>
        {viewingRFP && (
          <Overlay
            open={true}
            title={viewingRFP.display_name}
            scrollable={false}
            extraTitleBarElements={
              <DeprecatedButton
                icon="link-simple"
                className="ml-sm grow-0"
                onClick={() => copyLink(viewingRFP)}
              />
            }
            variant="full-screen-bordered"
            onClose={() => setViewingRFP(null)}
          >
            <RFPDetailsView rfp={viewingRFP} />
          </Overlay>
        )}
        <RFPsConnectionLostPill />
      </NavigationView>
      {search && (
        <Overlay
          open={showSearchWizard}
          title="Set up your search with AI"
          onClose={() => setShowSearchWizard(false)}
          fillWidth={true}
          maxWidth={900}
          scrollable={false}
        >
          <SearchWizard
            onComplete={(result) => {
              setShowSearchWizard(false);
              if (!result) return;
              setSearch(result.search);
              paginatedData.refresh();
              setUndoUpdateState(result.revert);
            }}
            search={search}
          />
        </Overlay>
      )}
    </ShortListsProvider>
  );
};

const RFPsConnectionLostPill = () => {
  const { connectionStatus, refreshConnection } = useShortLists();
  return useMemo(() => {
    switch (connectionStatus) {
      case "reconnecting":
      case "closed":
        return (
          <div className="absolute bottom-sm left-0 right-0 flex justify-center">
            <ConnectionLostPill reconnect={refreshConnection} />
          </div>
        );
      default:
        return null;
    }
  }, [connectionStatus, refreshConnection]);
};

export default RFPsRoute;
