import { FC, useContext } from "react";
import { OverlayProps } from "../containers/overlays/Overlay";
import { ModalProviderContext } from "providers/common/ModalProvider";

export type ModalOptions = Omit<OverlayProps, "children">;

/**
 * A modal is a component that can be displayed inside of an asynchronous
 * function. It is a component that is displayed in the center of the screen,
 * as a blocking experience until the modal is closed.
 */
export interface ModalProps<OutputType> {
  resolve: (value: OutputType) => void;
}

export const useModal = () => {
  const data = useContext(ModalProviderContext);
  return async <OutputType, T extends ModalProps<OutputType>>(
    component: FC<T>,
    props: Omit<T, "resolve">,
    options: ModalOptions
  ) => {
    if (!data) {
      throw new Error("ModalProvider not found");
    }
    return data.show(component, props, options);
  };
};
