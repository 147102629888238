import { createPluginFactory } from "@udecode/plate-common";

export const ELEMENT_REFERENCE = "reference";

const createReferencesPlugin = createPluginFactory({
  key: ELEMENT_REFERENCE,
  isElement: true,
  isInline: true,
  isVoid: true,
});

export default createReferencesPlugin;
