import { ReactElement, useEffect, useRef, useState } from "react";
import Rows from "./Rows";

export type TransitionState = string | number | symbol;

export interface TransitionViewProps<T extends TransitionState> {
  // Current state that determines which view to show
  state: T;

  // Object mapping states to view components
  views: Record<T, ReactElement | null>;

  // Transition duration in milliseconds (default: 300ms)
  duration?: number;

  // Optional class name for the container
  className?: string;

  // Optional callback when transition is complete
  onTransitionComplete?: (state: T) => void;

  // Optional transition timing function (default: ease-in-out)
  timingFunction?: string;
}

/**
 * A component that smoothly transitions between different views based on state changes.
 * Uses CSS transitions for smooth opacity animations.
 */
const TransitionView = <T extends TransitionState>({
  state,
  views,
  duration = 300,
  className,
  onTransitionComplete,
  timingFunction = "ease-in-out",
}: TransitionViewProps<T>) => {
  // Track the current visual state (what's being shown)
  const [visualState, setVisualState] = useState<T>(state);
  const containerRef = useRef<HTMLDivElement>(null);
  const transitionManager = useRef<TransitionManager<T>>(
    new TransitionManager(
      state,
      setVisualState,
      containerRef,
      duration,
      onTransitionComplete
    )
  );

  useEffect(() => {
    transitionManager.current?.setState(state);
  }, [state]);

  return (
    <Rows
      ref={containerRef}
      className="w-full h-full transition-opacity duration-300"
      style={{
        transitionDuration: `${duration}ms`,
        transitionTimingFunction: timingFunction,
      }}
    >
      {views[visualState]}
    </Rows>
  );
};

class TransitionManager<T extends TransitionState> {
  private currentState: T;
  private targetState: T | null;
  private animationState: "idle" | "in" | "out";
  private setRenderingState: (state: T) => void;
  private containerRef: React.RefObject<HTMLDivElement>;
  private duration: number;
  private nextStateTimeout: NodeJS.Timeout | null;
  private onCompletedTransition?: (state: T) => void;

  constructor(
    startingState: T,
    setRenderingState: (state: T) => void,
    containerRef: React.RefObject<HTMLDivElement>,
    duration: number,
    onCompletedTransition?: (state: T) => void
  ) {
    this.currentState = startingState;
    this.targetState = null;
    this.animationState = "idle";
    this.setRenderingState = setRenderingState;
    this.containerRef = containerRef;
    this.duration = duration;
    this.nextStateTimeout = null;
    this.onCompletedTransition = onCompletedTransition;
  }

  setState(state: T) {
    if (this.currentState === state) {
      switch (this.animationState) {
        case "idle":
          // We're already in the target state and not transitioning
          // No need to do anything
          return;
        case "in":
          // We're already transitioning into this state
          // No need to do anything
          break;
        case "out":
          // We're currently transitioning out of this state
          // We need to interrupt the transition and transition into the new state
          this.cancelNextState();
          this.finishAnimateIn();
          break;
      }
    }

    if (this.targetState === state) {
      // We're already transitioning into this state
      // No need to do anything
      return;
    }

    this.targetState = state;
    this.animationState = "in";
    this.animateOut();
  }

  /**
   * Sets the target state to be visible and then finishes the transition
   */
  private prepAnimateIn() {
    if (!this.targetState) {
      return;
    }
    this.setRenderingState(this.targetState);
    this.currentState = this.targetState;
    this.targetState = null;

    this.setTimeout(() => {
      this.finishAnimateIn();
    }, 0);
  }

  /**
   * Finishes the transition by setting the opacity to 1 and then calling the onCompletedTransition callback
   */
  private finishAnimateIn() {
    this.setOpacity(1);
    this.setTimeout(() => {
      this.animationState = "idle";
      this.onCompletedTransition?.(this.currentState);
    }, this.duration);
  }

  private animateOut() {
    this.setOpacity(0);
    this.setTimeout(() => {
      this.prepAnimateIn();
    }, this.duration);
  }

  private cancelNextState() {
    if (this.nextStateTimeout) {
      clearTimeout(this.nextStateTimeout);
    }
  }

  private setTimeout(callback: () => void, delay: number) {
    this.cancelNextState();
    this.nextStateTimeout = setTimeout(callback, delay);
  }

  private setOpacity(opacity: number) {
    if (this.containerRef.current) {
      this.containerRef.current.style.opacity = opacity.toString();
    }
  }
}
export default TransitionView;
