import { CategoryList, SavedSearchDetail } from "api/Api";
import Button from "components/common/Button";
import MessageView from "components/common/containers/MessageView";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Stack from "components/common/containers/Stack";
import TextArea from "components/common/forms/TextArea";
import TypingAnimation from "components/common/TypingAnimation";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { SetStateAction, Dispatch, useState } from "react";
import { message_from_exception } from "utils";
import useFetchedData from "hooks/useFetchedData";
import Toggle from "components/common/forms/Toggle";
import Scrollable from "components/common/containers/Scrollable";
import { mergeSavedSearch } from "types/SavedSearch";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";

export interface SearchWizardResult {
  search: SavedSearchDetail;
  revert: SavedSearchDetail;
}
interface SearchWizardProps {
  search: SavedSearchDetail;
  cancelable?: boolean;
  onComplete: (result: SearchWizardResult | null) => void;
}

const SearchWizard: React.FC<SearchWizardProps> = ({
  search,
  onComplete,
  cancelable = true,
}) => {
  const [freeformDescription, setFreeformDescription] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const apiClient = useApiClient();
  const [otherSelected, setOtherSelected] = useState(false);
  const [availableCategories] = useFetchedData<CategoryList[]>(
    async () => (await apiClient.user.rfpSavedSearchSuggestList()).data,
    []
  );

  const submit = async () => {
    setIsLoading(true);
    try {
      // First, get suggestions based on the user input
      const suggestions = await apiClient.user.rfpSavedSearchSuggestCreate({
        freeform_description: freeformDescription,
        categories: selectedCategories as any,
      });

      const revert = { ...search };
      const updated = mergeSavedSearch(search, {
        keywords: suggestions.data.keywords,
        naics_codes: suggestions.data.codes,
      });

      // Apply the changes directly to the server
      const result = await apiClient.user.rfpSavedSearchCurrentPartialUpdate(
        updated
      );
      onComplete({ search: result.data, revert });
    } catch (error) {
      setError(message_from_exception(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AsyncLoadedDiv
      value={availableCategories}
      className="overflow-hidden flex"
      whileLoaded={(availableCategories) => (
        <Stack className="grow shrink-0">
          <Rows
            className={cn(
              "gap-md transition-opacity",
              isLoading || error ? "opacity-0" : ""
            )}
          >
            <p className="text-secondary">
              Select a category and provide a brief description of your company
              and services you provide.
            </p>
            <Columns className="grow gap-lg">
              <Categories
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                otherSelected={otherSelected}
                setOtherSelected={setOtherSelected}
                availableCategories={availableCategories}
              />
              <InOwnWords
                freeformDescription={freeformDescription}
                setFreeformDescription={setFreeformDescription}
                isLoading={isLoading}
                highlight={otherSelected && !freeformDescription}
              />
            </Columns>
            <Columns className="gap-md justify-center grow shrink-0">
              {cancelable && (
                <Button
                  text="Cancel"
                  emphasis="tertiary"
                  onClick={() => onComplete(null)}
                  disabled={isLoading}
                />
              )}
              <Button
                text="Set Up"
                emphasis="primary"
                onClick={submit}
                disabled={
                  isLoading ||
                  (freeformDescription === "" &&
                    selectedCategories.length === 0)
                }
              />
            </Columns>
          </Rows>
          {isLoading && (
            <div>
              <CenteredContainer>
                <TypingAnimation
                  options={["Picking keywords and NAICS codes"]}
                  type="loop"
                />
              </CenteredContainer>
            </div>
          )}
          {error && (
            <MessageView
              title="Error"
              icon="circle-exclamation"
              className="max-w-[400px] text-center"
            >
              {error}
              <Button
                text="Try again"
                emphasis="primary"
                onClick={() => {
                  setError(null);
                  submit();
                }}
                disabled={isLoading}
              />
            </MessageView>
          )}
        </Stack>
      )}
    />
  );
};

interface InOwnWordsProps {
  freeformDescription: string;
  setFreeformDescription: (value: string) => void;
  isLoading: boolean;
  highlight: boolean;
}

const InOwnWords: React.FC<InOwnWordsProps> = ({
  freeformDescription,
  setFreeformDescription,
  isLoading,
  highlight,
}) => {
  return (
    <Rows className="grow basis-0 gap-md">
      <h2 className="text-lg font-semibold">Company description</h2>
      <TextArea
        className={cn("resize-none grow px-md", highlight && "border-primary")}
        value={freeformDescription}
        onChange={setFreeformDescription}
        placeholder="Acme Corporation is a leading provider of..."
        disabled={isLoading}
      />
    </Rows>
  );
};

interface CategoriesProps {
  selectedCategories: string[];
  setSelectedCategories: Dispatch<SetStateAction<string[]>>;
  otherSelected: boolean;
  setOtherSelected: Dispatch<SetStateAction<boolean>>;
  availableCategories: CategoryList[];
}

const Categories: React.FC<CategoriesProps> = ({
  selectedCategories,
  setSelectedCategories,
  otherSelected,
  setOtherSelected,
  availableCategories,
}) => {
  return (
    <Rows className="grow basis-0 gap-md">
      <h2 className="text-lg font-semibold">Select at least 1 category</h2>
      <Scrollable className="grow border rounded-md py-xs">
        {availableCategories?.map((category) => (
          <Toggle
            key={category.id}
            text={category.name}
            toggleType="checkbox"
            on={selectedCategories.includes(category.id)}
            onToggle={() =>
              setSelectedCategories((prev) =>
                prev.includes(category.id)
                  ? prev.filter((id) => id !== category.id)
                  : [...prev, category.id]
              )
            }
          />
        ))}
        <Toggle
          text="Other"
          toggleType="checkbox"
          on={otherSelected}
          onToggle={() => setOtherSelected(!otherSelected)}
        />
      </Scrollable>
    </Rows>
  );
};

export default SearchWizard;
