import { FC, useCallback, useEffect } from "react";

import Overlay from "components/common/containers/overlays/Overlay";
import { useFinalCheckDetails } from "providers/FinalCheckDetailsProvider";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Icon from "components/common/Icon";

interface FinalCheckRFPOverlayProps {}

const FinalCheckRFPOverlay: FC<FinalCheckRFPOverlayProps> = () => {
  const {
    document,
    activeRequirement,
    setActiveRequirement,
    setCanAnimateScroll,
    setContainerWidth,
  } = useFinalCheckDetails();

  // const containerRef = useRef<HTMLDivElement>();
  const setContainerRef = useCallback(
    (ref: HTMLDivElement) => {
      setContainerWidth(ref?.clientWidth ?? 0);

      if (ref) {
        const resizeObserver = new ResizeObserver(() => {
          setContainerWidth(ref?.clientWidth ?? 0);
        });
        resizeObserver.observe(ref);
        return () => {
          resizeObserver.disconnect();
        };
      }
    },
    [setContainerWidth]
  );

  useEffect(() => {
    setCanAnimateScroll(false);
  }, [setCanAnimateScroll]);

  // useEffect(() => {
  //   console.log("useEffect", containerRef.current);
  //   // Resize page width when the container resizes
  //   if (!containerRef.current) return;

  //   const handleResize = () => {
  //     setContainerWidth(containerRef.current?.clientWidth ?? 0);
  //   };

  //   const resizeObserver = new ResizeObserver(handleResize);
  //   resizeObserver.observe(containerRef.current);
  //   handleResize();
  //   return () => {
  //     resizeObserver.disconnect();
  //   };
  // }, [setContainerWidth]);

  return (
    <Overlay
      open={!!activeRequirement}
      title="RFP Details"
      onClose={() => setActiveRequirement(null)}
      variant="full-screen-bordered"
      fillWidth={true}
      scrollable={false}
      // className={cn(
      //   "max-h-[90vh] relative min-h-[300px]",
      //   isLoaded && "w-full"
      // )}
      maxWidth={1000}
    >
      <Rows className="relative grow min-h-[300px]" ref={setContainerRef}>
        {activeRequirement?.status !== "neutral" && (
          <Columns className="shrink-0 items-center justify-center gap-md">
            <Icon
              variant="solid"
              className={
                activeRequirement?.status === "pass"
                  ? "text-[#7CB632]"
                  : "text-destructive"
              }
              name={
                activeRequirement?.status === "pass"
                  ? "comment-check"
                  : "comment-xmark"
              }
              size="xlarge"
            />
            <Rows className="py-lg">
              <h3 className="font-semibold">
                {activeRequirement?.requirement}
              </h3>
              <p>Feedback: {activeRequirement?.justification}</p>
            </Rows>
          </Columns>
        )}
        {document}
      </Rows>
    </Overlay>
  );
};

export default FinalCheckRFPOverlay;
