import { Toolbar, ToolbarButton } from "./Toolbar";
import { MarkToolbarButton } from "./MarkToolbarButton";
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import Icon from "../../common/Icon";
import { TurnIntoDropDownMenu } from "./TurnIntoDropDownMenu";
import { IndentToolbarButton } from "./IndentToolbarButton";
import { OutdentToolbarButton } from "./OutdentToolbarButton";
import { LinkToolbarButton } from "./LinkToolbarButton";
import { emptyContent, odoTagContent } from "odo";
import useAddComment from "../../../lib/plate/plugins/useAddComment";
import { isCollapsed, useEditorRef } from "@udecode/plate-common";
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight";
import { useCallback, useMemo, useState } from "react";
import AddTableView from "./AddTableView";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Overlay from "components/common/containers/overlays/Overlay";

import { MarkdownEncoder } from "odo";
import useCurrentFormatting, {
  FormattingType,
} from "hooks/odo-editor/useCurrentFormatting";
import AIActionsMenu from "./AIActionsMenu";
import ImageToolbarButton from "./ImageToolbarButton";
import { copyToClipboard } from "lib/clipboard";
import { Separator } from "./Separator";

const FixedToolbar = ({ aiEnabled = true, commentsEnabled = true }) => {
  const addComment = useAddComment();
  const editor = useEditorRef();
  const [tableCreationOpen, setTableCreationOpen] = useState(false);
  const { isWriter, hasRegenerate } = useAuthenticatedUser();
  const { marks, elementType, refreshFormatting } = useCurrentFormatting();
  const isIndentable = isElementTypeIndentable(elementType);

  const handleCopyMarkdown = useCallback(() => {
    let elements: any[];
    if (editor.selection && !isCollapsed(editor.selection)) {
      elements = Array.from(
        editor.nodes({
          at: editor.selection,
          match: (element, path) => {
            return path.length === 1;
          },
        })
      ).map(([element]) => element);
    } else {
      elements = editor.children;
    }
    const encoder = new MarkdownEncoder(elements);
    const markdown = encoder.encode();
    copyToClipboard(markdown);
  }, [editor]);

  const content = useMemo(
    () => (
      <Toolbar className="shrink-0 m-sm mb-0 rounded-full px-sm bg-background-tertiary gap-xs">
        <TurnIntoDropDownMenu
          selectedElement={elementType}
          refreshFormatting={refreshFormatting}
        />
        <Separator orientation="vertical" />
        <MarkToolbarButton
          currentMarks={marks}
          nodeType={MARK_BOLD}
          tooltip="Bold (⌘B)"
        >
          <Icon name="bold" />
        </MarkToolbarButton>
        <MarkToolbarButton
          currentMarks={marks}
          nodeType={MARK_ITALIC}
          tooltip="Italic (⌘I)"
        >
          <Icon name="italic" />
        </MarkToolbarButton>
        <MarkToolbarButton
          currentMarks={marks}
          nodeType={MARK_UNDERLINE}
          tooltip="Underline (⌘U)"
        >
          <Icon name="underline" />
        </MarkToolbarButton>
        <MarkToolbarButton
          currentMarks={marks}
          nodeType={MARK_STRIKETHROUGH}
          tooltip="Strikethrough (⌘⇧X)"
        >
          <Icon name="strikethrough" />
        </MarkToolbarButton>
        <MarkToolbarButton
          currentMarks={marks}
          nodeType={MARK_HIGHLIGHT}
          tooltip="Highlight (⌘⌥H)"
        >
          <Icon name="highlighter-line" />
        </MarkToolbarButton>
        <Separator orientation="vertical" />
        <IndentToolbarButton disabled={!isIndentable} />
        <OutdentToolbarButton disabled={!isIndentable} />
        <Separator orientation="vertical" />
        <LinkToolbarButton />
        <ToolbarButton
          tooltip="Insert Table"
          className="h-auto"
          onClick={() => setTableCreationOpen(true)}
        >
          <Icon name="table" />
        </ToolbarButton>
        <ImageToolbarButton />
        {commentsEnabled && (
          <>
            <Separator orientation="vertical" />
            <ToolbarButton
              className="h-auto"
              tooltip="Comment (⌘⇧M)"
              onClick={() => addComment(emptyContent)}
              disabled={isCollapsed(editor.selection)}
            >
              <Icon name="comment-plus" />
            </ToolbarButton>
          </>
        )}
        {aiEnabled && (hasRegenerate || isWriter) && (
          <>
            <Separator orientation="vertical" />
            <ToolbarButton
              tooltip="Tag Odo in a comment (⌘⇧O)"
              onClick={() => addComment(odoTagContent)}
              disabled={isCollapsed(editor.selection)}
              className="px-sm h-auto shrink-0"
              variant="ai"
            >
              @Odo
            </ToolbarButton>
            <Separator orientation="vertical" />
            <AIActionsMenu />
          </>
        )}
        <div className="grow border-l" />
        {isWriter && (
          <>
            <Separator orientation="vertical" className="admin" />
            <ToolbarButton
              onClick={handleCopyMarkdown}
              className="h-auto"
              tooltip="Copy the currently selected content (or the whole doc if nothing is selected) as markdown. This is useful for easily pasting existing content into section details."
            >
              <Icon name="copy" className="admin text-primary" />
            </ToolbarButton>
          </>
        )}
        <Overlay
          open={tableCreationOpen}
          title="Add Table"
          maxWidth={360}
          onClose={() => setTableCreationOpen(false)}
        >
          <AddTableView
            isOpen={tableCreationOpen}
            dismiss={() => setTableCreationOpen(false)}
          />
        </Overlay>
      </Toolbar>
    ),
    [
      elementType,
      refreshFormatting,
      marks,
      isIndentable,
      commentsEnabled,
      editor.selection,
      aiEnabled,
      hasRegenerate,
      isWriter,
      handleCopyMarkdown,
      tableCreationOpen,
      addComment,
    ]
  );

  return content;
};

const isElementTypeIndentable = (elementType: FormattingType | null) => {
  return (
    elementType === "p" ||
    elementType === "numbered" ||
    elementType === "bulleted"
  );
};

export default FixedToolbar;
