import { visit } from "unist-util-visit";
import { Node } from "unist";
import { Parent } from "mdast";

interface ReferenceNode extends Node {
  type: "reference";
  value: string;
  data: {
    hName: string;
    hProperties: {
      className: string;
    };
  };
}

// Define the reference node type for TypeScript
declare module "mdast" {
  interface StaticPhrasingContentMap {
    reference: ReferenceNode;
  }
}

// Define types for the parser and tokenizer
interface RemarkParser {
  prototype: {
    inlineTokenizers: Record<string, Function>;
    inlineMethods: string[];
  };
}

interface TokenizerThis {
  Parser?: RemarkParser;
}

type EatFunction = (match: string) => (node: any) => any;

const REFERENCE_REGEX = /\[([^\]]+)\]/g;
const SUB_REFERENCE_REGEX = /ID: (\d+),?/g;

/**
 * Remark plugin to parse reference elements in square brackets.
 * Transforms text like "[reference]" into a custom reference node.
 */
function remarkReference(this: TokenizerThis) {
  const Parser = this?.Parser;

  // Only continue if we're using the Parser
  if (Parser && Parser.prototype.inlineTokenizers) {
    // Add a new tokenizer to the parser
    const inlineTokenizers = Parser.prototype.inlineTokenizers;
    const inlineMethods = Parser.prototype.inlineMethods;

    // Create our tokenizer function
    function referenceTokenizer(
      eat: EatFunction,
      value: string,
      silent?: boolean
    ) {
      if (value.charAt(0) !== "[") return;

      const match = REFERENCE_REGEX.exec(value);
      if (!match || match.index !== 0) return;

      const [fullMatch, reference] = match;

      if (silent) return true;

      return eat(fullMatch)({
        type: "reference",
        value: reference,
        data: {
          hName: "span",
          hProperties: {
            className: "reference",
          },
        },
        children: [
          {
            type: "text",
            value: reference,
          },
        ],
      });
    }

    // Add a locator to make the parser faster
    referenceTokenizer.locator = function (value: string, fromIndex: number) {
      return value.indexOf("[", fromIndex);
    };

    // Add the tokenizer to the parser
    inlineTokenizers.reference = referenceTokenizer;
    inlineMethods.splice(inlineMethods.indexOf("text"), 0, "reference");
  }

  // For when we're not using the Parser directly (e.g., with remark-react)
  return (tree: Node) => {
    visit(tree, "text", (node: any, index: number, parent: Parent | null) => {
      if (!parent || index === null) return;

      const { value } = node;
      const parts: any[] = [];
      let lastIndex = 0;
      let match;

      // Reset regex state
      REFERENCE_REGEX.lastIndex = 0;

      // Find all references in the text
      while ((match = REFERENCE_REGEX.exec(value)) !== null) {
        const [fullMatch, reference] = match;
        const startIndex = match.index;
        const endIndex = startIndex + fullMatch.length;

        // Add text before the reference
        if (startIndex > lastIndex) {
          parts.push({
            type: "text",
            value: value.slice(lastIndex, startIndex),
          });
        }

        if (reference === "odo-cursor") {
          parts.push({
            type: "cursor",
            data: {
              hName: "cursor",
            },
          });
        } else {
          // Loop through repeats of ID: <number>, ID: <number>,?
          const match = reference.match(SUB_REFERENCE_REGEX);
          if (match) {
            for (const m of match) {
              const id = m.match(/ID: (\d+)/)?.[1];
              if (id) {
                parts.push({
                  type: "reference",
                  value: id,
                  data: {
                    hName: "reference",
                  },
                });
              }
            }
          } else {
            // This isn't a valid reference, just push text
            parts.push({
              type: "text",
              value: reference,
            });
          }
        }

        lastIndex = endIndex;
      }

      // Add any remaining text
      if (lastIndex < value.length) {
        parts.push({
          type: "text",
          value: value.slice(lastIndex),
        });
      }

      // Replace the original node with the new parts if we found any references
      if (parts.length > 1) {
        parent.children.splice(index, 1, ...parts);
        return index + parts.length;
      }
    });
  };
}

/**
 * Get all the reference ids in the markdown string in the order they first appear.
 *
 */
export const referenceIdsInMarkdown = (markdown: string) => {
  const ids: string[] = [];
  for (const match of markdown.matchAll(REFERENCE_REGEX)) {
    const rawId = match[1];
    for (const subMatch of rawId.matchAll(SUB_REFERENCE_REGEX)) {
      const id = subMatch[1];
      if (id && !ids.includes(id)) {
        ids.push(id);
      }
    }
  }
  return ids;
};

export default remarkReference;
