import Button from "components/common/Button";
import Rows from "components/common/containers/Rows";
import {
  useAlert,
  useChoose,
  useConfirm,
  useTextAreaConfirm,
} from "providers/AlertProvider";
import { Choice } from "providers/Alerts/Choose";

const AlertsPlayground = () => {
  return (
    <Rows className="gap-xl">
      <BasicAlerts />
      <ConfirmAlerts />
      <ChooseAlerts />
      <TextAreaAlerts />
    </Rows>
  );
};

const BasicAlerts = () => {
  const alert = useAlert();

  return (
    <Rows>
      <Rows className="gap-sm p-sm border border-destructive rounded-md mb-lg">
        <h1 className="text-2xl">Deprecated</h1>
        <p>
          These are deprecated and will be replaced by Modals in the future.
        </p>
      </Rows>
      <h2 className="text-lg">Basic Alerts</h2>
      <div className="flex gap-md flex-wrap">
        <Button
          text="Simple Alert"
          onClick={() => alert("This is a simple alert")}
        />
        <Button
          text="With Body"
          onClick={() =>
            alert("Alert with Body", {
              body: "This is additional body content that appears below the title that is a bit longer so that it reaches.",
            })
          }
        />
        <Button
          text="With Support"
          onClick={() =>
            alert("Need help?", {
              body: "Click the button below to contact support",
              contactSupport: true,
            })
          }
        />
        <Button
          text="Dismissible"
          onClick={() =>
            alert("Dismissible Alert", {
              body: "You can click outside or press ESC to dismiss this alert",
              dismissId: "dismiss",
            })
          }
        />
      </div>
    </Rows>
  );
};

const ConfirmAlerts = () => {
  const confirm = useConfirm();

  return (
    <Rows>
      <h2 className="text-lg">Confirm Alerts</h2>
      <div className="flex gap-md flex-wrap">
        <Button
          text="Simple Confirm"
          onClick={() => confirm("Are you sure?")}
        />
        <Button
          text="With Body"
          onClick={() =>
            confirm("Delete Item?", {
              body: "This action cannot be undone. Are you sure you want to proceed?",
            })
          }
        />
        <Button
          text="Custom Text"
          onClick={() =>
            confirm("Save changes?", {
              yesText: "Save",
              noText: "Discard",
            })
          }
        />
        <Button
          text="Destructive"
          onClick={() =>
            confirm("Delete Account?", {
              body: "This will permanently delete your account and all associated data.",
              yesText: "Delete Account",
              yesDestructive: true,
            })
          }
        />
      </div>
    </Rows>
  );
};

const ChooseAlerts = () => {
  const choose = useChoose();

  const simpleChoices: Choice[] = [
    { id: "edit", text: "Edit", icon: "pen" },
    { id: "delete", text: "Delete", icon: "trash", variant: "destructive" },
  ];

  const manyChoices: Choice[] = [
    { id: "view", text: "View Details", icon: "eye" },
    { id: "edit", text: "Edit", icon: "pen" },
    "spacer",
    { id: "share", text: "Share", icon: "share" },
    { id: "duplicate", text: "Duplicate", icon: "copy" },
    "spacer",
    {
      id: "archive",
      text: "Archive",
      icon: "box-archive",
      variant: "solid-secondary",
    },
    { id: "delete", text: "Delete", icon: "trash", variant: "destructive" },
  ];

  return (
    <Rows>
      <h2 className="text-lg">Choose Alerts</h2>
      <div className="flex gap-md flex-wrap">
        <Button
          text="Button Style"
          onClick={() =>
            choose("Choose an action", {
              choices: simpleChoices,
              variant: "buttons",
            })
          }
        />
        <Button
          text="List Style"
          onClick={() =>
            choose("Choose an action", {
              choices: simpleChoices,
              variant: "list",
            })
          }
        />
        <Button
          text="Many Options"
          onClick={() =>
            choose("Choose an action", {
              choices: manyChoices,
              variant: "list",
            })
          }
        />
        <Button
          text="With Body"
          onClick={() =>
            choose("Choose an action", {
              body: "Select one of the following options to proceed",
              choices: simpleChoices,
              variant: "buttons",
            })
          }
        />
        <Button
          text="Dismissible"
          onClick={() =>
            choose("Choose an action", {
              choices: manyChoices,
              variant: "list",
              dismissId: "cancel",
            })
          }
        />
      </div>
    </Rows>
  );
};

const TextAreaAlerts = () => {
  const textAreaConfirm = useTextAreaConfirm();

  const defaultButtons = [
    { id: "save", text: "Save", variant: "solid" as const },
    { id: "cancel", text: "Cancel", variant: "solid-secondary" as const },
  ];

  return (
    <Rows>
      <h2 className="text-lg">TextArea Alerts</h2>
      <div className="flex gap-md flex-wrap">
        <Button
          text="Single Line"
          onClick={() =>
            textAreaConfirm("Enter a name", {
              multiline: false,
              placeholder: "Enter name here...",
              buttons: defaultButtons,
            })
          }
        />
        <Button
          text="Multi Line"
          onClick={() =>
            textAreaConfirm("Enter description", {
              multiline: true,
              placeholder: "Enter description here...",
              buttons: defaultButtons,
            })
          }
        />
        <Button
          text="With Initial Text"
          onClick={() =>
            textAreaConfirm("Edit description", {
              initialText: "This is some initial text that can be edited.",
              height: 100,
              buttons: defaultButtons,
            })
          }
        />
        <Button
          text="With Processing"
          onClick={() =>
            textAreaConfirm("Enter comment", {
              placeholder: "Enter your comment...",
              buttons: [
                {
                  id: "submit",
                  text: "Submit",
                  variant: "solid",
                  process: async (_, text) => {
                    // Simulate API call
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    console.log("Submitted:", text);
                  },
                },
                { id: "cancel", text: "Cancel", variant: "solid-secondary" },
              ],
            })
          }
        />
      </div>
    </Rows>
  );
};

export default AlertsPlayground;
