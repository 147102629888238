import { FC, useMemo } from "react";
import {
  SearchConfiguratorProps,
  SearchResult,
  SearchResultsGenerator,
} from "./SearchConfiguratorProps";
import Rows from "components/common/containers/Rows";
import KeyTermsInput from "components/common/menus/KeyTermsInput";

const KeywordConfigurator: FC<SearchConfiguratorProps> = ({
  search,
  updateSearch,
}) => {
  return (
    <Rows className="max-w-[600px] gap-xs text-sm">
      <h2 className="font-semibold">Include</h2>
      <p className="text-secondary text-sm">
        Every RFP must contain at least one of these keywords
      </p>
      <KeyTermsInput
        value={search.keywords}
        onChange={(keywords) => updateSearch({ keywords })}
      />
      <h2 className="font-semibold mt-sm">Exclude</h2>
      <p className="text-secondary text-sm">
        No RFP can contain any of these keywords
      </p>
      <KeyTermsInput
        value={search.exclude_keywords}
        onChange={(keywords) => updateSearch({ exclude_keywords: keywords })}
      />
    </Rows>
  );
};

const existingKeywordToSearchResult = (keyword: string) => ({
  id: "search_text_" + keyword,
  label: keyword,
  details: "Remove RFPs that mention this keyword",
  category: "Keyword",
});

export const useCategorySearchResults: SearchResultsGenerator = (
  searchText,
  search
) => {
  const options = useMemo(() => {
    let existingKeywords: SearchResult[] = (search?.keywords ?? []).map(
      existingKeywordToSearchResult
    );

    if (searchText.length === 0) {
      return existingKeywords;
    }

    if (
      !existingKeywords.some(
        (k) => k.label.toLowerCase() === searchText.toLowerCase()
      )
    ) {
      existingKeywords.push({
        id: "search_text_" + searchText,
        label: searchText,
        details: "Include RFPs that mention this keyword",
      });
    }

    return existingKeywords;
  }, [searchText, search]);

  return {
    options,
    onSelected: (option, search, updateSearch) => {
      const keyword = option.id.replace("search_text_", "");
      if (search.keywords.includes(keyword)) {
        updateSearch({
          keywords: search.keywords.filter((id) => id !== keyword),
        });
      } else {
        updateSearch({
          keywords: [...search.keywords, keyword],
        });
      }
    },
  };
};

export default KeywordConfigurator;
