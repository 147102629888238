interface RefData {
  path: number[];
  element: HTMLElement;
}

type PastProposalId = string;
type UniqueRefId = string;

/**
 * Tracks all the references in the editor and obfuscates them
 */
class ReferenceRefManager {
  references: Record<PastProposalId, Record<UniqueRefId, RefData>> = {};
  displayIds: Record<PastProposalId, number> = {};

  setReference(
    pastProposalId: PastProposalId,
    uniqueRefId: UniqueRefId,
    refData: RefData
  ) {
    if (!this.references[pastProposalId]) {
      this.references[pastProposalId] = {};
    }
    this.references[pastProposalId][uniqueRefId] = refData;
    refData.element.textContent = this.getDisplayId(pastProposalId);
  }

  clearReference(pastProposalId: PastProposalId, uniqueRefId: UniqueRefId) {
    if (!this.references[pastProposalId]) {
      return;
    }
    delete this.references[pastProposalId][uniqueRefId];
    if (Object.keys(this.references[pastProposalId]).length === 0) {
      delete this.references[pastProposalId];
    }
    // this.recalculateEarliestPath(pastProposalId);
  }

  getDisplayId(pastProposalId: PastProposalId) {
    if (this.displayIds[pastProposalId]) {
      return this.displayIds[pastProposalId].toString();
    }
    this.displayIds[pastProposalId] = Object.keys(this.displayIds).length + 1;
    return this.displayIds[pastProposalId].toString();
  }
}

export default ReferenceRefManager;
