import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/EditorView/Menus/Popover";
import Icon from "components/common/Icon";
import EditableText, {
  EditableTextRef,
} from "components/common/forms/EditableText";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useRef, useState } from "react";

const ProposalTitle = () => {
  const titleRef = useRef<EditableTextRef>(null);
  const popoverRef = useRef<HTMLDivElement>();
  const { metadata, setMetadata } = useProposalData();
  const [isEditing, setIsEditing] = useState(false);
  const [localTitle, setLocalTitle] = useState(metadata.title);

  const commitTitle = () => {
    if (!localTitle) return;
    if (localTitle !== metadata.title && localTitle !== "") {
      let normalizedTitle = (localTitle ?? "").trim().replace(/[\n\r]+/g, " ");
      setMetadata((prev) => ({ ...prev, title: normalizedTitle }));
    }
  };

  const handleOpenChange = (open: boolean) => {
    setIsEditing(open);
    if (open) {
      setTimeout(() => {
        titleRef.current?.focusEnd();
      });
    } else {
      commitTitle();
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      commitTitle();
      setIsEditing(false);
    } else if (e.key === "Escape") {
      setIsEditing(false);
    }
  };
  return (
    <Popover onOpenChange={handleOpenChange} open={isEditing}>
      <PopoverTrigger asChild={true}>
        <div
          className="flex gap-sm overflow-hidden mx-sm shrink-1 min-w-[100px] items-center text-sm"
          ref={popoverRef as any}
        >
          <p className="truncate">{metadata.title}</p>
          <Icon name="pen" variant="light" />
        </div>
      </PopoverTrigger>
      <PopoverContent align="start" sideOffset={8}>
        <div className="max-w-[500px]">
          <EditableText
            ref={titleRef}
            value={metadata.title}
            onChange={setLocalTitle}
            onKeyDown={handleKeyDown}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ProposalTitle;
