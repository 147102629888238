import { RFPSearch } from "api/Api";
import StartNewProposalOverlay from "app/home/StartNewProposalOverlay";
import Columns from "components/common/containers/Columns";
import { IconName } from "components/common/Icon";
import { useTextAreaConfirm } from "providers/AlertProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { DEFAULT_LIST, useListsForRFP } from "providers/ShortListsProvider";
import { FC, useState } from "react";
import Button, { ButtonProps } from "components/common/Button";
import DropdownMenu from "components/common/menus/DropdownMenu";

/**
 * Display options for acting on an RFP
 *
 * There are a number scenarios this covers:
 * - User is on RFPs tab (always single button)
 *    - Org has single list
 *       - Add to List or Remove from List
 *    - Org has multiple lists
 *       - Org has a favorite list
 *           - Main action is to Add or Remove from <list name>
 *           - Secondary action shows dropdown of all lists
 * .     - Org has NO favorite list
 *           - Add to List or Manage Lists (if it is in at least one list)
 *             (both go to full list dropdown)
 * - User is on Lists tab
 *    - Remove (from current list)
 *    - Start Proposal
 * - User is on the RFP details page (direct link or dialog)
 *     - RFP is not shortlisted
 *        - Same as RFPs tab
 *     - RFP is shortlisted
 *        - Same as RFPs tab
 *        - Start Proposal
 *
 * This leaves us to the following options:
 * - exclusiveListId: The only list to show actions for (the user is currently being shown
 *   this list specifically)
 * - allowStarting: boolean for if starting a proposal is allowed (set to false if showing
 *   directly in the RFP list). If true, still only shows start proposal if the RFP is
 *   shortlisted.
 */
interface RFPOptionsViewProps {
  rfp: RFPSearch;
  // A specific list to add/remove from
  primaryListId?: string;

  allowStarting: boolean;
  exclusiveListId: string | null;
  size?: ButtonProps["size"];
}

const RFPOptionsView: FC<RFPOptionsViewProps> = ({
  rfp,
  allowStarting,
  exclusiveListId,
  size = "medium",
}) => {
  const { isShortlisted, removeRFPFromAllLists } = useOptionsData(rfp);
  const [startingProposal, setStartingProposal] = useState(false);

  return (
    <Columns className="gap-2m">
      <ShortListActionsButton
        rfp={rfp}
        exclusiveListId={exclusiveListId}
        size={size}
      />
      {isShortlisted && allowStarting && (
        <Button
          text="Start Proposal"
          icon="play"
          iconVariant="solid"
          emphasis="primary"
          size={size}
          onClick={() => setStartingProposal(true)}
        />
      )}
      <StartNewProposalOverlay
        open={startingProposal}
        existingRfp={{ id: rfp.id, title: rfp.display_name }}
        onStarted={() => {
          removeRFPFromAllLists();
          setStartingProposal(false);
        }}
        onClose={() => setStartingProposal(false)}
      />
    </Columns>
  );
};

const useOptionsData = (rfp: RFPSearch) => {
  const { inLists, allLists, removeRFPFromList } = useListsForRFP(rfp.id);
  const isShortlisted = Object.keys(inLists).length > 0;

  const removeRFPFromAllLists = () => {
    Object.keys(allLists).forEach((listId) => removeRFPFromList(listId, true));
  };

  return {
    isShortlisted,
    removeRFPFromAllLists,
  };
};

interface SecondaryActionProps {
  text: string;
  icon?: IconName;
  extraIcon?: IconName;
  onClick?: () => void;
}

const ShortListActionsButton: FC<{
  rfp: RFPSearch;
  exclusiveListId: string | null;
  size: ButtonProps["size"];
}> = ({ rfp, exclusiveListId, size }) => {
  const { favoriteListId } = useAuthenticatedUser();
  const textAreaConfirm = useTextAreaConfirm();
  const {
    inLists,
    allLists,
    addRFPToList,
    addRFPToNewList,
    removeRFPFromList,
  } = useListsForRFP(rfp.id);

  let removeFromListActions: (SecondaryActionProps | "divider")[] =
    Object.entries(inLists).map(
      ([id, list]): SecondaryActionProps => ({
        text: "Remove from " + list.name,
        onClick: () => removeRFPFromList(id),
      })
    );
  if (removeFromListActions.length > 0) {
    removeFromListActions.unshift("divider");
  }

  const handleAddNewList = async () => {
    const result = await textAreaConfirm("Add to New List", {
      placeholder: "Enter the name of the new list",
      buttons: [
        {
          text: "Cancel",
          id: "cancel",
        },
        {
          text: "Save",
          id: "save",
          variant: "solid",
        },
      ],
    });

    if (result.id !== "save" || !result.text) {
      return;
    }

    addRFPToNewList(result.text);
  };

  let buttonProps: ButtonProps;
  if (Object.keys(allLists).length === 0) {
    buttonProps = {
      icon: "plus",
      text: "Add to List",
      emphasis: "primary",
      onClick: () => addRFPToNewList(DEFAULT_LIST.name),
    };
  } else if (Object.keys(allLists).length === 1) {
    // With a single list, simple add and remove buttons
    if (Object.keys(inLists).length === 0) {
      buttonProps = {
        icon: "plus",
        text: "Add to List",
        emphasis: "primary",
        onClick: () => addRFPToList(Object.keys(allLists)[0]),
      };
    } else {
      buttonProps = {
        icon: "xmark",
        text: "Remove from List",
        emphasis: "secondary",
        onClick: () => removeRFPFromList(Object.keys(allLists)[0]),
      };
    }
  } else {
    // With multiple lists, always include the same dropdown with toggles for each list
    // + adding a new list
    const secondaryActions: ButtonProps["secondaryActions"] = Object.entries(
      allLists
    )
      .sort(([, list1], [, list2]) => list1.name.localeCompare(list2.name))
      .map(([id, list]): SecondaryActionProps => {
        return {
          icon: inLists[id] ? "square-check" : "square",
          text: list.name,
          extraIcon: favoriteListId === id ? "star" : undefined,
          onClick: () => {
            if (inLists[id]) {
              removeRFPFromList(id);
            } else {
              addRFPToList(id);
            }
          },
        };
      })
      .concat([
        {
          icon: "plus",
          text: "Add to New List",
          onClick: handleAddNewList,
        },
      ]);

    if (exclusiveListId && allLists[exclusiveListId]) {
      buttonProps = {
        icon: "xmark",
        text: "Remove",
        variant: "plain",
        onClick: () => removeRFPFromList(exclusiveListId),
      };
    } else if (favoriteListId && allLists[favoriteListId]) {
      // With multiple + favorite list, offer default action with secondary actions
      if (inLists[favoriteListId]) {
        buttonProps = {
          text: `Remove from ${allLists[favoriteListId].name}`,
          icon: "xmark",
          iconVariant: "solid",
          emphasis: "tertiary",
          onClick: () => removeRFPFromList(favoriteListId),
          secondaryActions,
        };
      } else {
        buttonProps = {
          icon: "plus",
          text: `Add to ${allLists[favoriteListId].name}`,
          emphasis: Object.keys(inLists).length > 0 ? "tertiary" : "primary",
          iconVariant: "solid",
          onClick: () => addRFPToList(favoriteListId),
          secondaryActions,
        };
      }
    } else {
      // With multiple lists + no favorite, always use dropdown
      if (Object.keys(inLists).length === 0) {
        buttonProps = {
          icon: "plus",
          text: "Add to List",
          emphasis: "primary",
          // Leaving onClick empty to trigger dropdown
        };
      } else {
        buttonProps = {
          icon: "list",
          text: "Manage Lists",
          emphasis: "tertiary",
          // Leaving onClick empty to trigger dropdown
        };
      }
      return (
        <DropdownMenu items={secondaryActions}>
          <Button size={size} visualOnly={true} {...buttonProps} />
        </DropdownMenu>
      );
    }
  }

  return <Button size={size} {...buttonProps} />;
};

export default RFPOptionsView;
