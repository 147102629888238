/**
 * A class that manages the references to the elements of the blocks in the PDF view.
 * This is used to scroll to the active block when it is clicked.
 */
class BlockReferences {
  private references: Record<string, HTMLElement> = {};
  private pendingScrollToElement: string | null = null;
  private isLoaded = false;

  public hasElement(reference: string) {
    return this.references[reference] !== undefined;
  }

  public setIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded;
    if (this.isLoaded && this.pendingScrollToElement) {
      this.scrollToElement(this.pendingScrollToElement);
    }
  }

  public setElement(reference: string, element: HTMLElement | null) {
    if (element) {
      this.references[reference] = element;
      if (this.pendingScrollToElement === reference && this.isLoaded) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        this.pendingScrollToElement = null;
      }
    } else {
      delete this.references[reference];
    }
  }

  public scrollToElement(reference: string) {
    const element = this.references[reference];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      this.pendingScrollToElement = reference;
    }
  }
}

export default BlockReferences;
