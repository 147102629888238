import { useEffect, useRef, useState } from "react";
import { ProposalMetadata } from "./useProposalDetails";
import CoreApi from "api/CoreApi";
import { useApiClient } from "providers/ApiClientProvider";
import * as Sentry from "@sentry/react";

class SnapshotTaker {
  private proposalId: string;
  private currentMetadata: ProposalMetadata | null;
  private lastSnapshot: ProposalMetadata | null;
  private apiClient: CoreApi;
  private interval: NodeJS.Timeout;

  constructor(apiClient: CoreApi, proposalId: string) {
    this.proposalId = proposalId;
    this.apiClient = apiClient;
    this.lastSnapshot = null;
    this.currentMetadata = null;

    // Start interval to take snapshots
    this.interval = setInterval(() => {
      this.takeSnapshot();
    }, 5000);
  }

  stop() {
    clearInterval(this.interval);
  }

  destroy() {
    this.stop();
  }

  updateMetadata(metadata: ProposalMetadata | null) {
    this.currentMetadata = metadata;
  }

  async takeSnapshot() {
    if (!this.currentMetadata) return;

    if (!!this.lastSnapshot) {
      const current = JSON.stringify(this.currentMetadata);
      const last = JSON.stringify(this.lastSnapshot);
      if (current === last) return;
    }

    try {
      // Save the snapshot
      await this.apiClient.user.rfpProposalSnapshotCreate(this.proposalId, {
        metadata: this.currentMetadata,
      } as any);
      this.lastSnapshot = this.currentMetadata;
    } catch (e) {
      console.error("Failed to take snapshot", e);
      Sentry.captureException(e);
    }
  }
}

const useProposalDetailSnapshoting = (
  proposalId: string,
  metadata: ProposalMetadata | null
) => {
  const apiClient = useApiClient();
  const snapshotTaker = useRef<SnapshotTaker | null>(null);

  useEffect(() => {
    const taker = new SnapshotTaker(apiClient, proposalId);
    snapshotTaker.current = taker;

    return () => {
      taker.stop();
      snapshotTaker.current = null;
    };
  }, [apiClient, proposalId]);

  useEffect(() => {
    snapshotTaker.current?.updateMetadata(metadata);
  }, [metadata, snapshotTaker]);
};

export default useProposalDetailSnapshoting;
