import { FC, useRef } from "react";
import { ToolbarButton } from "./Toolbar";
import Icon from "components/common/Icon";
import useImageUpload from "api/useImageUpload";
import { odoToast } from "lib/odoToast";
import { useEditorRef } from "@udecode/plate-common";
import { useProposalData } from "providers/ProposalDetailsProvider";

interface ImageToolbarButtonProps {}

const ImageToolbarButton: FC<ImageToolbarButtonProps> = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const editor = useEditorRef();
  const { details } = useProposalData();
  const { insertImage } = useImageUpload(
    editor.id === "main" ? details.id : editor.id
  );

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        await insertImage(file);
        e.target.value = "";
      } catch (e) {
        odoToast.caughtError(e, "Inserting Image");
      }
    }
  };

  return (
    <ToolbarButton
      className="h-auto"
      tooltip="Insert Image"
      onClick={handleClick}
    >
      <Icon name="image" />
      <input
        ref={inputRef}
        onChange={handleFileChange}
        type="file"
        accept="image/png,image/jpg"
        hidden
      />
    </ToolbarButton>
  );
};

export default ImageToolbarButton;
