import {
  ComponentPropsWithoutRef,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { OutlineElementLayoutManager } from "./hooks/useOutlineLayout";
import { Slot } from "@radix-ui/react-slot";
import { Portal } from "@radix-ui/react-portal";
import { OutlineActions } from "./hooks/useOutlineData";
import {
  getPosition,
  SurroundingSectionOffsets,
  HierarchicalSectionInfo,
} from "./hooks/types";
import OutlineElementView from "./OutlineElementView";
import { throttle } from "lodash";

interface OutlineDragDropProps extends OutlineActions {
  children?: React.ReactNode;
  layoutManager: OutlineElementLayoutManager;
}

interface DragHandleData {
  props: ComponentPropsWithoutRef<"div">;
}

interface OutlineDragDropData {
  draggingId: string | null;
  cursorPosition: { x: number; y: number } | null;
  onMouseDown: (e: React.MouseEvent, section: HierarchicalSectionInfo) => void;
}

const OutlineDragDropContext = createContext<OutlineDragDropData | null>(null);

const OutlineDragDropProvider: React.FC<OutlineDragDropProps> = ({
  children,
  layoutManager,
  startUsingShadowData,
  stopUsingShadowData,
}) => {
  const containerRef = useRef<HTMLElement>(null);
  const [draggingSection, setDraggingSection] = useState<{
    section: HierarchicalSectionInfo;
    originalChildren: HierarchicalSectionInfo[];
  } | null>(null);
  // The offset of the cursor between where it clicked the drag control
  // and the top left of the outline element
  const [cursorOffset, setCursorOffset] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [cursorSize, setCursorSize] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [cursorPosition, setCursorPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const dragManager = useRef<OutlineDragManager | null>(null);

  useEffect(() => {
    if (!dragManager.current) return;
    dragManager.current.onDragEnd = (canceled: boolean) => {
      stopUsingShadowData(
        canceled ? null : draggingSection?.section.id ?? null
      );
      setDraggingSection(null);
      setCursorPosition(null);
    };
    dragManager.current.onCursorMove = (x: number, y: number) => {
      setCursorPosition({ x, y });
    };

    return () => {
      if (dragManager.current) {
        dragManager.current.onDragEnd = undefined;
        dragManager.current.onCursorMove = undefined;
      }
    };
  }, [stopUsingShadowData, draggingSection]);

  const onMouseDown = useCallback(
    (e: React.MouseEvent, section: HierarchicalSectionInfo) => {
      setDraggingSection({
        // Temporarily remove children to avoid them bein manipulated
        // while dragging. We'll restore them when the drag ends.
        section: { ...section, children: [] },
        originalChildren: section.children,
      });
      startUsingShadowData(section.id);

      const outlineElement = e.currentTarget.closest(".outline-element");
      if (outlineElement) {
        setCursorPosition({ x: e.clientX, y: e.clientY });
        setCursorSize({
          width: outlineElement.clientWidth,
          height: outlineElement.clientHeight,
        });
        setCursorOffset({
          x: outlineElement.getBoundingClientRect().left - e.clientX,
          y: outlineElement.getBoundingClientRect().top - e.clientY,
        });
      }

      dragManager.current?.disconnect();
      dragManager.current = new OutlineDragManager(
        section.id,
        layoutManager,
        containerRef.current!
      );
    },
    [startUsingShadowData, layoutManager]
  );

  // Create a DraggingOverlay component
  const DraggingOverlay = useCallback(() => {
    // console.log(draggingSection, cursorPosition);
    if (!draggingSection || !cursorPosition || !containerRef.current)
      return null;

    // Calculate the offset to position the overlay at the cursor
    // We offset by a small amount to position it just below and to the right of the cursor
    const containerBounds = containerRef.current.getBoundingClientRect();
    const width = cursorSize?.width ?? 0;
    const height = cursorSize?.height ?? 0;
    const extraWidth = containerBounds.width - width;
    const left = Math.min(
      Math.max(containerBounds.left - 8, cursorPosition.x + cursorOffset.x),
      containerBounds.right - containerBounds.width + extraWidth + 8
    );
    const top = Math.min(
      Math.max(containerBounds.top - 4, cursorPosition.y + cursorOffset.y),
      containerBounds.bottom - height + 4
    );

    return (
      <Portal>
        <div className="fixed inset-0">
          <OutlineElementView
            layoutManager={layoutManager}
            section={draggingSection.section}
            isCollapsed={draggingSection.originalChildren.length > 0}
            className="absolute pointer-events-none transition-[left,top,width,height]"
            isDragOverlay={true}
            style={{
              left,
              top,
              width,
              height,
              marginLeft: 0,
            }}
          />
        </div>
      </Portal>
    );
  }, [
    draggingSection,
    cursorPosition,
    layoutManager,
    cursorOffset,
    cursorSize,
  ]);

  return (
    <OutlineDragDropContext.Provider
      value={{
        draggingId: draggingSection?.section.id ?? null,
        cursorPosition,
        onMouseDown,
      }}
    >
      <Slot ref={containerRef}>{children}</Slot>
      {draggingSection && (
        <Portal>
          <div className="fixed inset-0 cursor-grabbing" />
        </Portal>
      )}
      <DraggingOverlay />
    </OutlineDragDropContext.Provider>
  );
};

export const useOutlineDragDrop = (): Omit<
  OutlineDragDropData,
  "onMouseDown"
> => {
  const context = useContext(OutlineDragDropContext);
  if (!context) {
    throw new Error(
      "useOutlineDragDrop must be used within an OutlineDragDropProvider"
    );
  }
  const { draggingId, cursorPosition } = context;
  return { draggingId, cursorPosition };
};

export const useDragHandle = (
  section: HierarchicalSectionInfo
): DragHandleData => {
  const context = useContext(OutlineDragDropContext);
  if (!context) {
    throw new Error(
      "useOutlineDragDrop must be used within an OutlineDragDropProvider"
    );
  }

  const { onMouseDown } = context;
  const onHandleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      onMouseDown(e, section);
    },
    [onMouseDown, section]
  );

  return { props: { onMouseDown: onHandleMouseDown } };
};

const mousePositionWithinContainer = (
  e: MouseEvent,
  container: HTMLElement
) => {
  if (!container) {
    debugger;
  }
  const rect = container.getBoundingClientRect();

  return {
    x: e.clientX - rect.left,
    y: e.clientY - rect.top + container.scrollTop,
  };
};

class OutlineDragManager {
  draggingId: string;
  layoutManager: OutlineElementLayoutManager;
  onDragEnd?: (cancel: boolean) => void;
  onCursorMove?: (x: number, y: number) => void;
  offsets: SurroundingSectionOffsets | null;
  container: HTMLElement;
  lastCursorPosition: { x: number; y: number } | null = null;
  scrollInterval: number | null = null;
  readonly SCROLL_THRESHOLD = 40; // pixels from edge to start scrolling
  readonly MAX_SCROLL_SPEED = 4; // pixels per frame

  listeners: { type: string; callback: any }[] = [];

  constructor(
    draggingId: string,
    layoutManager: OutlineElementLayoutManager,
    container: HTMLElement
  ) {
    this.draggingId = draggingId;
    this.container = container;
    this.layoutManager = layoutManager;
    this.offsets = this.layoutManager.getOffsetsSurroundingSection(draggingId);

    const onMouseMove = this.onMouseMove.bind(this);
    const onMouseUp = this.onMouseUp.bind(this);
    const onKeydown = this.onKeydown.bind(this);

    this.listeners.push({ type: "mousemove", callback: onMouseMove });
    this.listeners.push({ type: "mouseup", callback: onMouseUp });
    this.listeners.push({ type: "keydown", callback: onKeydown });
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("keydown", onKeydown);
  }

  throttledOnMouseMove = throttle((x: number, y: number) => {
    this.onCursorMove?.(x, y);
  }, 1000 / 60);

  private startScroll() {
    if (this.scrollInterval) return;

    const scroll = () => {
      const { y } = this.lastCursorPosition || { y: 0 };
      const containerRect = this.container.getBoundingClientRect();
      const containerHeight = containerRect.height;

      // Calculate distance from top and bottom edges
      const distanceFromTop = y - this.container.scrollTop;
      const distanceFromBottom = containerHeight - y + this.container.scrollTop;
      console.log(distanceFromTop, distanceFromBottom);

      let scrollSpeed = 0;

      if (distanceFromTop < this.SCROLL_THRESHOLD) {
        // Scroll up
        scrollSpeed =
          -this.MAX_SCROLL_SPEED *
          (1 - distanceFromTop / this.SCROLL_THRESHOLD);
      } else if (distanceFromBottom < this.SCROLL_THRESHOLD) {
        // Scroll down
        scrollSpeed =
          this.MAX_SCROLL_SPEED *
          (1 - distanceFromBottom / this.SCROLL_THRESHOLD);
      }

      if (scrollSpeed !== 0) {
        this.container.scrollTop += scrollSpeed;
      }

      this.scrollInterval = requestAnimationFrame(scroll);
    };

    this.scrollInterval = requestAnimationFrame(scroll);
  }

  private stopScroll() {
    if (this.scrollInterval) {
      cancelAnimationFrame(this.scrollInterval);
      this.scrollInterval = null;
    }
  }

  onMouseMove(e: MouseEvent) {
    const { x, y } = mousePositionWithinContainer(e, this.container);
    if (this.lastCursorPosition?.x === x && this.lastCursorPosition?.y === y) {
      return;
    }
    this.lastCursorPosition = { x, y };

    // Update cursor position for overlay
    this.throttledOnMouseMove(e.clientX, e.clientY);

    // Start/update scrolling if near edges
    this.startScroll();

    let count = 0;
    // Prevent infinite loop
    while (count < 100) {
      count++;
      if (!this.offsets) {
        this.offsets = this.layoutManager.getOffsetsSurroundingSection(
          this.draggingId
        );
      }

      // Check if we need to swap any sections
      const moveAbove = this.shouldMoveAbove(y);

      if (moveAbove) {
        this.moveAbove(moveAbove);
        continue;
      }
      const moveBelow = this.shouldMoveBelow(y);
      if (moveBelow) {
        this.moveBelow(moveBelow);
        continue;
      }
      const moveLeft = this.shouldMoveLeft(x);
      if (moveLeft) {
        this.moveLeft();
        break;
      }
      const moveRight = this.shouldMoveRight(x);
      if (moveRight) {
        this.moveRight();
        break;
      }

      break;
    }

    if (count >= 100) {
      console.error("Infinite loop detected");
    }

    this.layoutManager.saveShadowData();
  }

  onMouseUp(e: MouseEvent) {
    this.stopScroll();
    this.disconnect();
    this.onDragEnd?.(false);
  }

  onKeydown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.stopScroll();
      this.disconnect();
      this.onDragEnd?.(true);
    }
  }

  disconnect() {
    this.stopScroll();
    for (const { type, callback } of this.listeners) {
      window.removeEventListener(type, callback);
    }
  }

  private shouldMoveAbove(y: number): string | null {
    if (!this.offsets?.above || this.offsets.topOfAbove === null) {
      return null;
    }

    // --------------- -
    //                 |
    //                 |
    //          Distance below top
    // Above           |
    //                \|/
    //                 -   <- point where we should swap with above
    //
    // ---------------
    // Current
    // ---------------

    // The distance below the top, is defined as the minimum of the current section height
    // and the above section height. This is to ensure that the swap will be "stable" where
    // it will not want to immediately swap back.
    const currentHeight = this.offsets.bottom - this.offsets.top;
    const aboveHeight = this.offsets.top - this.offsets.topOfAbove;
    const distanceBelowTop = Math.min(currentHeight, aboveHeight);
    if (y < this.offsets.topOfAbove + distanceBelowTop) {
      return this.offsets.above;
    }
    return null;
  }

  private shouldMoveBelow(y: number): string | null {
    if (!this.offsets?.below || this.offsets.bottomOfBelow === null) {
      return null;
    }

    // ---------------
    // Current
    // ---------------
    //
    //                 -   <- point where we should swap with below
    //                /|\
    // Below           |
    //          Distance above bottom
    //                 |
    //                 |
    // --------------- -

    // The distance above the bottom, is defined as the minimum of the current section height
    // and the below section height. This is to ensure that the swap will be "stable" where
    // it will not want to immediately swap back.
    const currentHeight = this.offsets.bottom - this.offsets.top;
    const belowHeight = this.offsets.bottomOfBelow - this.offsets.bottom;
    const distanceAboveBottom = Math.min(currentHeight, belowHeight);
    if (y > this.offsets.bottomOfBelow - distanceAboveBottom) {
      return this.offsets.below;
    }
    return null;
  }

  private shouldMoveLeft(x: number): boolean {
    if (!this.offsets || this.offsets.left === null) {
      return false;
    }
    if (x < this.offsets.left) {
      return true;
    }
    return false;
  }

  private shouldMoveRight(x: number): boolean {
    if (!this.offsets || this.offsets.right === null) {
      return false;
    }
    if (x > this.offsets.right) {
      return true;
    }
    return false;
  }

  private moveBelow(id: string) {
    const hierarchicalSections =
      this.layoutManager.displayedHierarchicalSections;
    const position = getPosition(hierarchicalSections, id);
    if (!position) {
      console.error("Failed to find position of section to move below");
      return;
    }
    const { parent, index } = position;
    this.layoutManager.moveSection(this.draggingId, parent?.id ?? null, index);
    this.offsets = null;
  }

  private moveAbove(id: string) {
    const hierarchicalSections =
      this.layoutManager.displayedHierarchicalSections;
    const position = getPosition(hierarchicalSections, id);
    if (!position) {
      console.error("Failed to find position of section to move above");
      return;
    }
    const { parent, index } = position;
    this.layoutManager.moveSection(
      this.draggingId,
      parent?.id ?? null,
      Math.max(0, index)
    );
    this.offsets = null;
  }

  private moveLeft() {
    this.layoutManager.indentSection(this.draggingId, "left");
    this.offsets = null;
  }

  private moveRight() {
    this.layoutManager.indentSection(this.draggingId, "right");
    this.offsets = null;
  }
}

export default OutlineDragDropProvider;
