interface CacheEntry<T> {
  value: T;
  timestamp: number;
}

export class PDFUrlCache {
  private static cache: Map<string, CacheEntry<string>> = new Map();
  private static readonly CACHE_TTL_MS = 10 * 60 * 1000; // 10 minutes in milliseconds

  /**
   * Get a URL from the cache if it exists and is not expired
   * @param key The cache key (e.g., proposal ID)
   * @returns The cached URL or null if not found or expired
   */
  public static get(key: string): string | null {
    const entry = this.cache.get(key);
    if (!entry) return null;

    const now = Date.now();
    if (now - entry.timestamp > this.CACHE_TTL_MS) {
      // Entry is expired, remove it
      this.cache.delete(key);
      return null;
    }

    return entry.value;
  }

  /**
   * Store a URL in the cache
   * @param key The cache key (e.g., proposal ID)
   * @param url The URL to cache
   */
  public static set(key: string, url: string): void {
    this.cache.set(key, {
      value: url,
      timestamp: Date.now(),
    });
  }

  /**
   * Clear the entire cache
   */
  public static clear(): void {
    this.cache.clear();
  }

  /**
   * Remove a specific entry from the cache
   * @param key The cache key to remove
   */
  public static remove(key: string): void {
    this.cache.delete(key);
  }
}
