/**
 * Convert a list name to a list id
 *
 * This just makes it lower case to disallow multiple lists
 * only different by their casing
 */
export const listNameToId = (listName) => {
    return listName
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^a-zA-Z0-9]/g, "_");
};
