import { useContext } from "react";
import { AlertProviderContext } from "../AlertProvider";
import { AlertButton, RenderBody } from "./types";

interface ConfirmOptions {
  body?: RenderBody;
  yesText?: string;
  yesDestructive?: boolean;
  noText?: string;
}

export const useConfirm = () => {
  const context = useContext(AlertProviderContext);
  if (!context) {
    throw new Error("useConfirm must be used within an AlertProvider");
  }
  return async (message: string, options: ConfirmOptions = {}) => {
    const yesButton: AlertButton = {
      text: options.yesText ?? "Yes",
      variant: options.yesDestructive === true ? "destructive" : "solid",
      className: "min-w-[60px]",
      id: "yes",
    };
    const noButton: AlertButton = {
      text: options.noText ?? "No",
      variant: "solid-secondary",
      className: "min-w-[60px]",
      id: "no",
    };
    const buttons: AlertButton[] = [noButton, yesButton];
    const result = await context.show(message, {
      body: options.body,
      buttons,
    });
    return result === "yes";
  };
};
