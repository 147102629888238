import { useApiClient } from "providers/ApiClientProvider";
import { FC, useRef, useState } from "react";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { cn, format_absolute_date } from "lib/utils";
import { isOnMobile, message_from_exception } from "utils";
import { usePostHog } from "posthog-js/react";
import { RFPDetail, RFPSearch } from "api/Api";
import useFetchedData from "hooks/useFetchedData";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import { useIsMetaKeyDown } from "hooks/useIsKeyDown";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Scrollable, {
  ScrollableFooter,
  ScrollableHeader,
} from "components/common/containers/Scrollable";
import RFPOptionsView from "./RFPOptionsView";
import Pill from "components/common/Pill";
import { Tooltip, TooltipContent } from "components/EditorView/Menus/Tooltip";
import { TooltipTrigger } from "@radix-ui/react-tooltip";
import { useConfirmModal } from "components/common/modals/ConfirmModal";
import Button from "components/common/Button";
import AskQuestionsView from "./AskQuestionsView";

export type RFPDetailsViewTab = "details" | "ask" | "full";
interface RFPDetailsViewProps {
  rfp: RFPSearch | string;
  showTitle?: boolean;
  className?: string;
  constrainWidth?: boolean;
  initialTab?: RFPDetailsViewTab;
  // If true, the tab will be forced to the "initialTab"
  forceTab?: boolean;
  onTabChange?: (tab: RFPDetailsViewTab) => void;
}

const RFPDetailsView: FC<RFPDetailsViewProps> = ({
  rfp,
  showTitle = false,
  className,
  constrainWidth = false,
  initialTab = "details",
  forceTab = false,
  onTabChange,
}) => {
  const apiClient = useApiClient();
  const [internalSelectedTab, doSetSelectedTab] =
    useState<RFPDetailsViewTab>(initialTab);
  const isMetaKeyDown = useIsMetaKeyDown();
  const posthog = usePostHog();
  const rfpId = typeof rfp === "string" ? rfp : rfp.id;
  const [details, setDetails, { error, refresh }] = useFetchedData(async () => {
    pdfUrlRef.current = null;
    const response = await apiClient.user.rfpRfpRead(rfpId);
    pdfUrlRef.current = response.data.download_url;
    return response.data;
  }, [rfpId]);
  const [isDownloading, setIsDownloading] = useState(false);
  const confirm = useConfirmModal();
  const pdfUrlRef = useRef<string | null | undefined>(undefined);

  const setSelectedTab = (tab: RFPDetailsViewTab) => {
    if (isMetaKeyDown) {
      // Open the details in a new tab
      window.open(`/rfps/${rfpId}/${tab}/`, "_blank");
    } else {
      doSetSelectedTab(tab);
      onTabChange?.(tab);
    }
  };

  const handleViewDetails = () => {
    if (isMetaKeyDown) {
      // Open the details in a new tab
      window.open(`/rfps/${rfpId}/`, "_blank");
      return;
    }
    setSelectedTab("details");
  };

  const triggerBookDemoCTA = async () => {
    const result = await confirm({
      title: "View Full RFP",
      message: "You need to subscribe to view full RFPs.",
      confirmText: "Book Demo",
    });
    if (result) {
      window.open("https://odo.do/book-demo/?source=full-rfp-app", "_blank");
    }
  };

  const handleViewFullRFP = async () => {
    if (!details?.view_url) {
      await triggerBookDemoCTA();
      return;
    }

    if (isOnMobile() || isMetaKeyDown) {
      // Open the full RFP in a new tab since the PDF viewer doesn't work well on mobile
      posthog.capture("rfp_viewed_full", {
        rfp_slug: details.slug ?? "unknown",
      });
      window.open(details.view_url, "_blank");
      return;
    }
    setSelectedTab("full");
  };

  const handleDownload = async () => {
    const pdfUrl = pdfUrlRef.current;
    if (!details || pdfUrl === null) {
      setIsDownloading(false);
      await triggerBookDemoCTA();
      return;
    }

    if (pdfUrl === undefined) {
      // If we haven't finished loading the PDF, try again after a short delay
      setIsDownloading(true);
      setTimeout(handleDownload, 500);
      return;
    }

    posthog.capture("rfp_viewed_full", {
      rfp_slug: details.slug ?? "unknown",
    });
    setIsDownloading(false);
    window.open(pdfUrl, "_blank");
  };

  const selectedTab = forceTab ? initialTab : internalSelectedTab;

  return (
    <AsyncLoadedDiv
      value={details}
      className={cn("grow flex overflow-hidden", className)}
      error={error ? message_from_exception(error) : null}
      whileLoaded={(details) => (
        <Rows className="grow">
          <ScrollableHeader maxSize={constrainWidth ? 800 : undefined}>
            {showTitle && (
              <h1 className="text-2xl font-semibold w-full mb-md">
                {details?.display_name}
              </h1>
            )}
            <div className="flex border-b shrink-0">
              <Button
                variant="navigation"
                text="Details"
                disabled={selectedTab === "details"}
                onClick={handleViewDetails}
              />
              <Button
                variant="navigation"
                text="Ask Questions"
                disabled={selectedTab === "ask"}
                onClick={() => setSelectedTab("ask")}
              />
              <Button
                variant="navigation"
                text="Full RFP"
                disabled={selectedTab === "full"}
                onClick={handleViewFullRFP}
              />
            </div>
          </ScrollableHeader>
          <Rows className="grow">
            <DetailsView
              details={details}
              hidden={selectedTab !== "details"}
              error={error ? message_from_exception(error) : null}
              constrainedWidth={constrainWidth ? 800 : undefined}
            />
            <InlineRFPPDFView
              details={details}
              hidden={selectedTab !== "full"}
              error={error ? message_from_exception(error) : null}
            />
            {selectedTab === "ask" && (
              <AskQuestionsView rfp={details} refreshRFP={refresh} />
            )}
          </Rows>
          <ScrollableFooter
            maxSize={constrainWidth ? 800 : undefined}
            className="py-md border-t"
          >
            <Columns className="gap-md">
              <Button
                text="Download"
                icon="download"
                emphasis="tertiary"
                onClick={handleDownload}
                isLoading={isDownloading}
              />
              <div className="grow" />
              <RFPOptionsView
                rfp={details}
                exclusiveListId={null}
                allowStarting={true}
              />
            </Columns>
          </ScrollableFooter>
        </Rows>
      )}
    />
  );
};

interface PaneViewProps {
  details: RFPDetail;
  hidden: boolean;
  error: string | null;
  constrainedWidth?: number;
}

const DetailsView: FC<PaneViewProps> = ({
  details,
  hidden,
  error,
  constrainedWidth,
}) => {
  if (hidden) {
    return null;
  }

  return (
    <Scrollable className="grow" maxContentSize={constrainedWidth}>
      <Rows className="gap-md grow">
        <Columns className="bg-background-secondary rounded-md p-md gap-lg shrink-0">
          <LabeledFormInput label="Proposal Due">
            {details.due_date ? format_absolute_date(details.due_date) : "-"}
          </LabeledFormInput>
          <LabeledFormInput label="Budget">
            {details.budget ?? "-"}
          </LabeledFormInput>
          <LabeledFormInput label="Location">
            {details.location ?? "-"}
          </LabeledFormInput>
        </Columns>
        <LabeledFormInput label="Buyer">
          {details.issuing_org ?? "-"}
        </LabeledFormInput>
        <LabeledFormInput label="Brief Description">
          <p>{details.description ?? "-"}</p>
        </LabeledFormInput>
        <LabeledFormInput label="Key Dates">
          {(details.key_dates?.length ?? 0) > 0 ? (
            <ul className="list-disc ml-lg b-md">
              {details.key_dates
                ?.filter((date) => !!date.date)
                .sort((a, b) => a.date.localeCompare(b.date))
                .map((date, index) => (
                  <li key={index}>
                    {format_absolute_date(date.date)} - {date.name}
                  </li>
                ))}
            </ul>
          ) : (
            "Refer to RFP"
          )}
        </LabeledFormInput>
        <LabeledFormInput label="Minimum Qualifications">
          {(details.minimum_qualifications?.length ?? 0) > 0 ? (
            <ul className="list-disc ml-lg b-md">
              {details.minimum_qualifications?.map((qual, index) => (
                <li key={index}>{qual}</li>
              ))}
            </ul>
          ) : (
            "Refer to RFP"
          )}
        </LabeledFormInput>
        <LabeledFormInput label="Task Summary">
          {(details.tasks?.length ?? 0) > 0 ? (
            <ul className="list-disc ml-lg b-md">
              {details.tasks?.map((task, index) => (
                <li key={index}>{task}</li>
              ))}
            </ul>
          ) : (
            "Refer to RFP"
          )}
          <div className="h-lg" />
          {details.naics_codes && details.naics_codes.length > 0 && (
            <LabeledFormInput label="NAICS Codes">
              <div>
                {details.naics_codes?.map((code) => (
                  <Tooltip key={code.id}>
                    <TooltipTrigger className="cursor-default">
                      <Pill
                        key={code.id}
                        text={code.id + " - " + code.title}
                        className="inline-block mx-xs"
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p className="max-w-[500px]">{code.description}</p>
                    </TooltipContent>
                  </Tooltip>
                ))}
              </div>
            </LabeledFormInput>
          )}
        </LabeledFormInput>
      </Rows>
    </Scrollable>
  );
};

const InlineRFPPDFView: FC<PaneViewProps> = ({ details, hidden }) => {
  const pdfUrl = details?.view_url;
  if (hidden) {
    return null;
  }
  return (
    <div className="grow bg-background-selected flex flex-col relative h-[99999px]">
      <div
        className={cn(
          "absolute inset-0 flex items-center justify-center",
          "transition-opacity duration-300",
          !!pdfUrl ? "opacity-100" : "opacity-0"
        )}
      >
        <a
          className="underline text-primary"
          href={pdfUrl ?? undefined}
          target="_blank"
          rel="noreferrer"
        >
          Download full RFP
        </a>
      </div>
      {!!pdfUrl && (
        <iframe src={pdfUrl} className="w-full grow z-[1]" title="RFP PDF" />
      )}
    </div>
  );
};

export default RFPDetailsView;
