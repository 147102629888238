import { useEffect, useState } from "react";

const useIsKeydown = (key: string) => {
  const [isDown, setIsDown] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === key) {
        setIsDown(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === key) {
        setIsDown(false);
      }
    };

    const handleBlur = () => {
      // Clear the keydown state when the window is blurred
      // This is to prevent the keydown state from being
      // retained when the window loses focus (e.g. Command + Tab on the mac)
      setIsDown(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("blur", handleBlur);
    };
  }, [key]);

  return isDown;
};

export const useIsMetaKeyDown = () => {
  return useIsKeydown("Meta");
};

export default useIsKeydown;
