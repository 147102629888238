import {
  LeftPanel,
  useEditorDocData,
} from "providers/RequirementContentEditorProvider";
import { FC } from "react";
import Rows from "components/common/containers/Rows";
import UserChoicesPanel from "./UserChoicesPanel";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import OutlineNavPanel from "./OutlineNavPanel";
import Button from "components/common/Button";

export const LeftEditorToolbar: FC = () => {
  const { activeLeftPanel, setActiveLeftPanel } = useEditorDocData();
  const { isWriter } = useAuthenticatedUser();

  const getClickHandler = (panel: LeftPanel) => () => {
    if (activeLeftPanel === panel) {
      setActiveLeftPanel(null);
    } else {
      setActiveLeftPanel(panel);
    }
  };

  return (
    <Rows className="shrink-0 gap-sm px-md py-sm">
      <Button
        tooltip="Outline"
        icon="list"
        variant="round"
        emphasis={activeLeftPanel === "outline-nav" ? "primary" : "secondary"}
        onClick={getClickHandler("outline-nav")}
      />
      {isWriter && (
        <Button
          tooltip="User Choices"
          icon="clipboard-list"
          variant="round"
          className="admin"
          emphasis={
            activeLeftPanel === "user-choices" ? "primary" : "secondary"
          }
          onClick={getClickHandler("user-choices")}
        />
      )}
    </Rows>
  );
};

export const useLeftEditorContent = () => {
  const { activeLeftPanel } = useEditorDocData();

  let activePanelComponent = null;
  switch (activeLeftPanel) {
    case "outline-nav":
      activePanelComponent = <OutlineNavPanel />;
      break;
    case "user-choices":
      activePanelComponent = <UserChoicesPanel />;
      break;
    default:
      break;
  }

  return activePanelComponent;
};
