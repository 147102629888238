import DeprecatedButton from "components/common/DeprecatedButton";
import NavigationView from "components/common/NavigationView";
import TypingAnimation from "components/common/TypingAnimation";
import MessageView from "components/common/containers/MessageView";
import { cn } from "lib/utils";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import Columns from "components/common/containers/Columns";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import Overlay from "components/common/containers/overlays/Overlay";
import RFPDetailsView from "components/rfps/RFPDetailsView";
import { ShortListsProvider } from "providers/ShortListsProvider";

interface ProposalNotReadyProps {
  className?: string;
  rfpId: string;
}

const ProposalNotReady: FC<ProposalNotReadyProps> = ({ className, rfpId }) => {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const [viewingDetails, setViewingDetails] = useState(false);
  const [rfp] = useFetchedData(async () => {
    const response = await apiClient.user.rfpRfpRead(rfpId);

    return response.data;
  }, []);

  return (
    <NavigationView containerClassName="bg-background-secondary">
      <div
        className={cn(
          "flex items-center justify-center bg-background-secondary",
          className
        )}
      >
        <MessageView icon="hourglass-half" title="We’re Working On It">
          <p className="mb-8 text-center">
            This could take up to 2 business days.
            <br />
            We'll email you when it's ready.
          </p>
          <TypingAnimation
            type="loop"
            options={["Proposal not ready.", "Check back later."]}
            className="py-2m"
          />
          <Columns className="h-3xl gap-lg">
            <DeprecatedButton
              text="Go Back"
              variant="solid-secondary"
              className="mx-auto"
              onClick={() => {
                if (window.history.length > 1) {
                  window.history.back();
                } else {
                  navigate("/proposals/");
                }
              }}
            />
            <DeprecatedButton
              icon="magnifying-glass"
              variant="outline"
              className="bg-background"
              text="View RFP"
              onClick={() => setViewingDetails(true)}
            />
          </Columns>
        </MessageView>
      </div>
      {viewingDetails && rfp && (
        <Overlay
          title={rfp.display_name}
          fillHeight={true}
          variant="full-screen-bordered"
          onClose={() => setViewingDetails(false)}
          scrollable={false}
        >
          <ShortListsProvider>
            <RFPDetailsView rfp={rfp} className="grow" constrainWidth={true} />
          </ShortListsProvider>
        </Overlay>
      )}
    </NavigationView>
  );
};

export default ProposalNotReady;
