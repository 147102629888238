import { useEffect, FunctionComponent, ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { odoToast } from "lib/odoToast";
import { message_from_exception } from "utils";
import { Root } from "@radix-ui/react-portal";

interface Props {
  children: ReactNode;
}

export const showError = (error: string | unknown) => {
  let message: string;
  if (typeof error === "string") {
    message = error;
  } else {
    message = message_from_exception(error);
  }

  odoToast.caughtError(error);
  console.error("ErrorBoundary caught an error", message);
};

const ErrorBoundary: FunctionComponent<Props> = ({ children }) => {
  useEffect(() => {
    const onError = (error: ErrorEvent) => {
      console.log("Error", error);
      showError(error);
    };
    const onRejection = (error: PromiseRejectionEvent) => {
      console.log("Error", error);
      showError(error);
    };
    window.addEventListener("error", onError);
    window.addEventListener("unhandledrejection", onRejection);
    return () => {
      window.removeEventListener("error", onError);
      window.removeEventListener("unhandledrejection", onRejection);
    };
  }, []);
  return (
    <>
      {children}
      {/**
       * Ensures toasts appear above other radix-ui components such as modals and dialogs
       */}
      <Root>
        <ToastContainer />
      </Root>
    </>
  );
};

export default ErrorBoundary;
