import { cn } from "lib/utils";
import Columns from "./containers/Columns";

interface TabBarProps {
  tabs: {
    label: string;
    value: string;
  }[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const TabBar = ({ tabs, value, onChange, className }: TabBarProps) => {
  const selectIndex = tabs.findIndex((tab) => tab.value === value);
  const isLastSelected = selectIndex === tabs.length - 1;

  return (
    <Columns className={cn(className, "bg-background-tertiary")}>
      {tabs.map((tab, index) => {
        const isFirst = index === 0;
        const isLast = index === tabs.length - 1;
        const isSelected = value === tab.value;

        let outerClassName = "";
        let innerClassName = "";

        if (isSelected) {
          outerClassName = "bg-background-secondary";
          innerClassName = "bg-background-tertiary rounded-t-md";
        } else {
          outerClassName = "bg-background-tertiary";
          innerClassName =
            "text-foreground bg-background-secondary rounded-b-md";
          if (isFirst) {
            innerClassName += " rounded-bl-[0]";
          }
          if (isLast) {
            innerClassName += " rounded-br-[0]";
          }
        }

        return (
          <div key={tab.value} className={outerClassName}>
            <button
              className={cn(
                "inline-block px-2m py-sm text-sm font-medium transition-colors",
                innerClassName
              )}
              onClick={() => onChange(tab.value)}
            >
              {tab.label}
            </button>
          </div>
        );
      })}
      <div
        className={cn(
          "grow bg-background-secondary",
          isLastSelected && "rounded-bl-md"
        )}
      />
    </Columns>
  );
};

export default TabBar;
