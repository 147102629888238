import Button from "components/common/Button";
import Rows from "components/common/containers/Rows";
import { cn } from "lib/utils";
import { FC } from "react";
import OutlineView from "./outline-view/OutlineView";
import RFPPDFView from "components/rfps/RFPPDFView";
import { usePDFRef } from "hooks/usePDFRef";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { SplitPaneContainer } from "components/common/containers/split-pane-container/SplitPaneContainer";
import { SplitPane } from "components/common/containers/split-pane-container/SplitPane";
import Columns from "components/common/containers/Columns";

interface OutlineBuilderProps {
  onClose: () => void;
  className?: string;
}

const OutlineBuilder: FC<OutlineBuilderProps> = ({ onClose, className }) => {
  const { ref: rfpPdfRef, scrollToElement: scrollToRfpElement } = usePDFRef();
  const { details } = useProposalData();

  return (
    <Rows className={cn("overflow-hidden m-sm rounded shadow-lg", className)}>
      <Rows className="pt-sm shrink-0 bg-background-tertiary justify-center items-baseline border-b">
        <Rows className="mx-auto items-center">
          <h1 className="text-xl font-semibold">Outline Builder</h1>
          <p className="text-sm text-secondary">Confirm the outline</p>
        </Rows>
      </Rows>
      <SplitPaneContainer localStorageKey="outline-builder" className="grow">
        <SplitPane
          uniqueId="outline"
          className="grow flex overflow-hidden"
          minSize={400}
        >
          <Rows className="grow gap-md">
            <OutlineView
              className="bg-background-tertiary px-md"
              editable={true}
            />
          </Rows>
        </SplitPane>
        <SplitPane uniqueId="rfp" className="grow flex" minSize={400}>
          <RFPPDFView
            ref={rfpPdfRef}
            rfpId={details.rfpId}
            fileUrl={null}
            activeBlockId={null}
            //   referenceBlocks={rfpBlockId ? [{ id: rfpBlockId }] : []}
            //   setActiveBlockId={(id) => {
            //     setActiveReferenceId(id ? `R-${id}` : null);
            //   }}
            className="grow basis-0"
          />
        </SplitPane>
      </SplitPaneContainer>
      <Columns className="shrink-0 justify-end border-t p-sm bg-background">
        <Button
          emphasis="primary"
          text="Complete Outline Review"
          onClick={onClose}
        />
      </Columns>
    </Rows>
  );
};

export default OutlineBuilder;
