import { PastProposalDetail } from "api/Api";
import Rows from "components/common/containers/Rows";
import Stack from "components/common/containers/Stack";
import { useReferenceContext } from "providers/ReferenceProvider";
import TabBar from "components/common/TabBar";
import PastProposalPDFView from "components/past-proposal/PastProposalPDFView";
import RFPPDFView from "components/rfps/RFPPDFView";
import { usePDFRef } from "hooks/usePDFRef";
import { odoToast } from "lib/odoToast";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC, useEffect, useState } from "react";
import { PDFUrlCache } from "components/common/pdf/pdfUrlCache";

interface ReferencesPanelProps {}

const ReferencesPanel: FC<ReferencesPanelProps> = () => {
  const apiClient = useApiClient();
  const { details } = useProposalData();
  const {
    activeReferenceId,
    setActiveReferenceId,
    registerReferenceClickCallback,
    refManager,
  } = useReferenceContext();
  const { setActiveRightPanel } = useEditorDocData();
  const { ref: rfpPdfRef, scrollToElement: scrollToRfpElement } = usePDFRef();
  const {
    ref: pastProposalPdfRef,
    scrollToElement: scrollToPastProposalElement,
  } = usePDFRef();
  const [pastProposal, setPastProposal] = useState<PastProposalDetail | null>(
    null
  );
  const [activeTab, setActiveTab] = useState<"rfp" | "past-proposal">("rfp");

  useEffect(() => {
    if (!activeReferenceId || !activeReferenceId.startsWith("P-")) {
      return;
    }
    const fetchPastProposal = async () => {
      const blockId = activeReferenceId.replace("P-", "");
      const response = await apiClient.user.rfpPastProposalBlocksRead(blockId);
      if (!response.data.past_proposal.view_url) {
        odoToast.error({
          title: "Not Found",
          text: "The past proposal for this reference was not found.",
        });
        return;
      }
      let viewUrl = PDFUrlCache.get(response.data.past_proposal.id);
      if (!viewUrl) {
        PDFUrlCache.set(
          response.data.past_proposal.id,
          response.data.past_proposal.view_url
        );
        viewUrl = response.data.past_proposal.view_url;
      }
      setPastProposal({ ...response.data.past_proposal, view_url: viewUrl });
    };
    fetchPastProposal();
  }, [activeReferenceId, apiClient.user]);

  // Register callback for reference clicks
  useEffect(() => {
    // Register callback to scroll to the referenced element
    const unregister = registerReferenceClickCallback((referenceId) => {
      if (!referenceId) return;
      setActiveRightPanel("references");
      if (referenceId.startsWith("R-")) {
        setActiveTab("rfp");
        scrollToRfpElement(referenceId.replace("R-", ""));
      } else if (referenceId.startsWith("P-")) {
        setActiveTab("past-proposal");
        scrollToPastProposalElement(referenceId.replace("P-", ""));
      }
    });

    // Clean up by unregistering the callback when component unmounts
    return unregister;
  }, [
    registerReferenceClickCallback,
    scrollToRfpElement,
    scrollToPastProposalElement,
    setActiveTab,
    setActiveRightPanel,
  ]);

  let rfpBlockId: string | null = null;
  if (activeReferenceId && activeReferenceId.startsWith("R-")) {
    rfpBlockId = activeReferenceId.replace("R-", "");
  }
  let pastProposalBlockId: string | null = null;
  if (activeReferenceId && activeReferenceId.startsWith("P-")) {
    pastProposalBlockId = activeReferenceId.replace("P-", "");
  }

  return (
    <Rows className="grow pt-sm bg-background-secondary">
      <TabBar
        className="shrink-0"
        tabs={
          [
            { label: "RFP", value: "rfp" },
            pastProposal && {
              label: refManager
                ? refManager.getDisplayId(pastProposal.id) +
                  " - " +
                  pastProposal.name
                : pastProposal.name,
              value: "past-proposal",
            },
          ].filter((m) => !!m) as { label: string; value: string }[]
        }
        value={activeTab}
        onChange={(value) => setActiveTab(value as "rfp" | "past-proposal")}
      />
      <Stack className="grow border-y border-background-tertiary">
        <RFPPDFView
          ref={rfpPdfRef}
          rfpId={details.rfpId}
          fileUrl={null}
          activeBlockId={rfpBlockId}
          referenceBlocks={rfpBlockId ? [{ id: rfpBlockId }] : []}
          setActiveBlockId={(id) => {
            setActiveReferenceId(id ? `R-${id}` : null);
          }}
          className={cn(
            "grow",
            activeTab === "rfp"
              ? "opacity-100"
              : "opacity-0 pointer-events-none"
          )}
        />
        {pastProposal && (
          <PastProposalPDFView
            proposalId={pastProposal.id}
            ref={pastProposalPdfRef}
            fileUrl={pastProposal.view_url!}
            activeBlockId={pastProposalBlockId}
            setActiveBlockId={(id) => {
              setActiveReferenceId(id ? `P-${id}` : null);
            }}
            referenceBlocks={
              pastProposalBlockId ? [{ id: pastProposalBlockId }] : []
            }
            className={cn(
              "grow",
              activeTab === "past-proposal"
                ? "opacity-100"
                : "opacity-0 pointer-events-none"
            )}
          />
        )}
      </Stack>
    </Rows>
  );
};

export default ReferencesPanel;
