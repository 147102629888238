import { ItemDetails } from "api/Api";
import { FC } from "react";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import Input from "components/common/forms/Input";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";

interface SubcontractorDetailsViewProps {
  details: ItemDetails;
  setDetails: (details: ItemDetails) => void;
}

const SubcontractorDetailsView: FC<SubcontractorDetailsViewProps> = ({
  details,
  setDetails,
}) => {
  return (
    <>
      <LabeledFormInput label="Name">
        <Input
          placeholder="The name of this subcontractor"
          value={details.name ?? ""}
          onChange={(e) => setDetails({ ...details, name: e.target.value })}
        />
      </LabeledFormInput>
      <LabeledFormInput label="Overview" className="grow overflow-hidden">
        <CollaborativeTextArea
          placeholder="A brief overview of the subcontractor"
          className="h-[600px]"
          docId={details.content_id!}
          key={details.content_id!}
        />
      </LabeledFormInput>
    </>
  );
};

export default SubcontractorDetailsView;
