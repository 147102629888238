import DeprecatedButton from "components/common/DeprecatedButton";
import { cn, format_absolute_date } from "lib/utils";
import { FC, useRef } from "react";
import { usePostHog } from "posthog-js/react";
import Columns from "components/common/containers/Columns";
import { RFPSearch } from "api/Api";
import RFPOptionsView from "./RFPOptionsView";
import { useListsForRFP } from "providers/ShortListsProvider";
import { useIsMetaKeyDown } from "hooks/useIsKeyDown";
import { copyToClipboard } from "lib/clipboard";
import Button from "components/common/Button";

interface RFPListItemViewProps {
  rfp: RFPSearch;
  showSelected?: boolean;
  onViewDetails: () => void;
  exclusiveListId: string | null;
  allowStarting: boolean;
}

const RFPListItemView: FC<RFPListItemViewProps> = ({
  rfp,
  onViewDetails,
  exclusiveListId,
  showSelected = true,
  allowStarting,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const posthog = usePostHog();
  const { inLists } = useListsForRFP(rfp.id);
  const isMetaKeyDown = useIsMetaKeyDown();

  const handleViewDetails = (askQuestions: boolean) => {
    if (isMetaKeyDown) {
      // Open the details in a new tab
      let url = `/rfps/${rfp.id}/`;
      if (askQuestions) {
        url += "ask/";
      }
      window.open(url, "_blank");
      return;
    }
    onViewDetails();
    try {
      posthog.capture("rfp_viewed_details", { rfp_slug: rfp.id });
    } catch (e) {
      console.error("Error capturing posthog event", e);
    }
  };

  const copyLink = (rfp: RFPSearch) => {
    copyToClipboard(`${window.location.origin}/rfps/${rfp.id}/`);
  };

  return (
    <div
      ref={containerRef}
      className={cn(
        "rounded-md flex flex-col tablet:flex-row gap-md overflow-hidden border transition-opacity",
        showSelected && Object.keys(inLists).length > 0 && "opacity-50"
      )}
    >
      <div className="tablet:w-[140px] items-center text-center tablet:items-end tablet:flex tablet:flex-col shrink-0 bg-background-secondary p-md gap-sm">
        <div className="grow tablet:hidden" />
        <div className="mx-md inline-flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <p className="text-secondary text-xs">Posted</p>
          <p className="text-sm font-semibold">
            {format_absolute_date(rfp.created)}
          </p>
        </div>
        <div className="mx-md inline-flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <p className="text-secondary text-xs">Proposal Due</p>
          <p className="text-sm font-semibold">
            {format_absolute_date(rfp.due_date)}
          </p>
        </div>
        <div className="mx-md inline-flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <span className="text-secondary text-xs">Budget </span>
          <div className="vertical-text-elipsis text-right">
            {rfp.budget ? rfp.budget : "-"}
          </div>
        </div>
        <div className="mx-md inline-flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <span className="text-secondary text-xs">Location </span>
          <div className="vertical-text-elipsis text-right text-sm">
            {rfp.location ? rfp.location : "-"}
          </div>
        </div>
        <div className="grow" />
      </div>
      <div className="grow flex flex-col gap-sm p-md">
        <Columns className="font-semibold text-lg group items-center gap-xs">
          {rfp.display_name}
          <DeprecatedButton
            icon="link-simple"
            size="small"
            className="opacity-0 group-hover:opacity-100"
            onClick={() => copyLink(rfp)}
          />
        </Columns>
        <p className="text-secondary text-md">{rfp.issuing_org}</p>
        <p className="text-sm grow shrink-0">{rfp.description}</p>
        <div className="flex gap-sm mt-md">
          {!!exclusiveListId ? (
            <Button
              text="Ask Questions"
              icon="circle-question"
              iconVariant="solid"
              emphasis="secondary"
              size="small"
              onClick={() => handleViewDetails(true)}
            />
          ) : (
            <Button
              text="View Details"
              icon="magnifying-glass"
              emphasis="secondary"
              size="small"
              onClick={() => handleViewDetails(false)}
            />
          )}
          <div className="grow" />
          <RFPOptionsView
            rfp={rfp}
            exclusiveListId={exclusiveListId}
            allowStarting={allowStarting}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default RFPListItemView;
