import Rows from "components/common/containers/Rows";
import { SearchConfiguratorProps } from "./SearchConfiguratorProps";
import { SavedSearchDetail } from "api/Api";
import RadioList, { RadioListChoice } from "components/common/menus/RadioList";

type PostedDateRange = NonNullable<SavedSearchDetail["posted_date_range"]>;
type DueDateRange = NonNullable<SavedSearchDetail["due_date_range"]>;

interface PostedDateRangeChoice extends RadioListChoice {
  value: PostedDateRange;
}

interface DueDateRangeChoice extends RadioListChoice {
  value: DueDateRange;
}

const POSTED_DATE_RANGES: PostedDateRangeChoice[] = [
  {
    id: "all",
    label: "All",
    value: "all",
  },
  {
    id: "today",
    label: "Today",
    value: "today",
  },
  {
    id: "less_than_1_week_ago",
    label: "Less than 1 week ago",
    value: "less_than_1_week_ago",
  },
  {
    id: "less_than_2_weeks_ago",
    label: "Less than 2 weeks ago",
    value: "less_than_2_weeks_ago",
  },
];

const DUE_DATE_RANGES: DueDateRangeChoice[] = [
  {
    id: "all",
    label: "Any day in the future",
    value: "all",
  },
  {
    id: "more_than_1_week",
    label: "In at least 1 week",
    value: "more_than_1_week",
  },
  {
    id: "more_than_2_weeks",
    label: "In at least 2 weeks",
    value: "more_than_2_weeks",
  },
  {
    id: "more_than_3_weeks",
    label: "In at least 3 weeks",
    value: "more_than_3_weeks",
  },
  {
    id: "more_than_4_weeks",
    label: "In at least 4 weeks",
    value: "more_than_4_weeks",
  },
];

const DatesConfigurator = ({
  search,
  updateSearch,
}: SearchConfiguratorProps) => {
  return (
    <Rows className="max-w-[600px] gap-xs text-sm">
      <h2 className="font-semibold">Posted</h2>
      <p className="text-secondary">Only show RFPs posted:</p>
      <RadioList<PostedDateRangeChoice>
        valueId={search.posted_date_range ?? "all"}
        choices={POSTED_DATE_RANGES}
        onSelect={(value) => updateSearch({ posted_date_range: value.value })}
      />
      <h2 className="font-semibold mt-sm border-t pt-md">Due</h2>
      <p className="text-secondary text-sm">Only show RFPs due:</p>
      <RadioList<DueDateRangeChoice>
        valueId={search.due_date_range ?? "all"}
        choices={DUE_DATE_RANGES}
        onSelect={(value) => updateSearch({ due_date_range: value.value })}
      />
    </Rows>
  );
};

export default DatesConfigurator;
