import { cn } from "lib/utils";
import React, { FC } from "react";

interface OdoCursorProps {
  showing?: boolean;
  className?: string;
}

const OdoCursor: FC<OdoCursorProps> = ({ showing, className }) => {
  return (
    <span
      className={cn(
        "inline-block pointer-events-none w-thin relative ml-[2px] transition-opacity",
        showing === false ? "opacity-0" : "opacity-100",
        className
      )}
    >
      <span className="block w-[2px] absolute top-0 bottom-[0.2em] left-0 bg-primary rounded" />
      <span
        className={cn(
          "whitespace-nowrap absolute text-sm top-[-40%] left-0 bg-primary text-background px-xs rounded-sm rounded-bl-[0]"
        )}
      >
        Odo
      </span>
    </span>
  );
};

export default OdoCursor;
