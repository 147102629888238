import { FC, useEffect, useRef } from "react";
import { ModalProps, useModal } from "./useModal";
import Button from "../Button";
import Rows from "../containers/Rows";
import Columns from "../containers/Columns";

export interface ConfirmModalProps extends ModalProps<boolean> {
  message: string;
  confirmText?: string;
  cancelText?: string;
  isDestructive?: boolean;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  resolve,
  message,
  confirmText = "Confirm",
  cancelText = "Cancel",
  isDestructive = false,
}) => {
  const confirmRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!isDestructive && confirmRef.current) {
        confirmRef.current.focus();
      }
    }, 0);
  }, [isDestructive]);

  return (
    <Rows className="gap-md max-w-[600px]">
      <p>{message}</p>
      <Columns className="justify-end gap-sm">
        <Button
          onClick={() => resolve(false)}
          emphasis="tertiary"
          text={cancelText}
        />
        <Button
          ref={confirmRef}
          onClick={() => resolve(true)}
          emphasis={isDestructive ? "destructive" : "primary"}
          text={confirmText}
        />
      </Columns>
    </Rows>
  );
};

interface ConfirmModalOptions extends Omit<ConfirmModalProps, "resolve"> {
  title: string;
}

export const useConfirmModal = () => {
  const show = useModal();
  return async (props: ConfirmModalOptions) => {
    return await show<boolean, ConfirmModalProps>(ConfirmModal, props, {
      title: props.title,
    });
  };
};

export default ConfirmModal;
