import React, { FC, RefObject, useCallback, useEffect, useState } from "react";
import {
  Comment,
  emptyContent,
  getCommentValue,
  isEmptyContent,
  isOdoMentioned,
} from "odo";
import {
  useActiveComment,
  useCommentReplies,
  usePendingReplies,
  useSaveComment,
  useSavePendingReply,
  useSetActiveComment,
} from "providers/CommentsProvider";
import DeprecatedButton, {
  DeprecatedButtonVariantProps,
} from "components/common/DeprecatedButton";
import { cn } from "lib/utils";
import { v4 } from "uuid";
import { cva } from "class-variance-authority";
import CommentEditorView, { CommentEditorViewRef } from "./CommentEditorView";
import { useEditorRef } from "@udecode/plate";
import { focusEditor } from "@udecode/plate-common";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import CommentView from "./CommentView";
import Rows from "components/common/containers/Rows";
import Overlay from "components/common/containers/overlays/Overlay";

interface CommentThreadViewProps {
  comment: Comment;
  setThreadRef: (id: string, ref: RefObject<HTMLElement>) => void;
  className?: string;
  areFullCommentsVisible: boolean;
  canFocusThread: boolean;
  onFocusChange?: (focused: boolean) => void;
  focused: boolean;
}

interface ButtonSpec {
  text: string;
  disabled?: boolean;
  variants?: DeprecatedButtonVariantProps;
  tooltip?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const commentThreadVariants = cva("accent py-2m rounded-md", {
  variants: {
    variant: {
      DEFAULT: "",
      active: "bg-accent-focused drop-shadow-low",
    },
  },
  defaultVariants: {
    variant: "DEFAULT",
  },
});

const CommentThreadView: FC<CommentThreadViewProps> = ({
  comment,
  setThreadRef,
  className,
  areFullCommentsVisible,
  canFocusThread,
  onFocusChange,
  focused,
}) => {
  const docEditor = useEditorRef();
  const currentUser = useAuthenticatedUser();
  const saveComment = useSaveComment();
  const pendingReplies = usePendingReplies();
  const [initialContent, setInitialContent] = useState<any[]>(emptyContent);
  const [content, setContent] = useState<any[]>(initialContent);
  const textBoxRef = React.useRef<CommentEditorViewRef>(null);
  const activeComment = useActiveComment();
  const savePendingReply = useSavePendingReply();
  const setActiveComment = useSetActiveComment();
  const [isReplying, setIsCommentEditorFocused] = useState(false);
  const replies = useCommentReplies(comment.id);
  const ref = React.useRef<HTMLElement>(null);
  const { isWriter, hasRegenerate } = useAuthenticatedUser();

  const isActive = comment.id === activeComment || comment.isPending;
  const hasOdoTagged = isOdoMentioned(content);

  useEffect(() => {
    // Reset to false for next activation
    if (!isActive) {
      setIsCommentEditorFocused(false);
      return;
    }

    const newContent = comment.isPending
      ? getCommentValue(comment)
      : pendingReplies[comment.id] || emptyContent;
    setContent(newContent);
    setInitialContent(newContent);
  }, [isActive]);

  useEffect(() => {
    setThreadRef(comment.id, ref);
  }, [comment.id, ref, setThreadRef]);

  useEffect(() => {
    if (!isReplying || comment.isPending) return;
    savePendingReply(comment.id, content);
  }, [comment.id, comment.isPending, content, isReplying, savePendingReply]);

  const onSend = () => {
    if (isEmptyContent(content)) return;
    saveComment(comment.id, {
      ...comment,
      text: content,
      isPending: false,
    });
    setContent(emptyContent);
    setInitialContent(emptyContent);
    textBoxRef.current?.reset();
  };

  const onReply = () => {
    if (!currentUser) {
      throw new Error("User must be logged in to reply");
    }
    if (isEmptyContent(content)) return;
    const id = v4();
    saveComment(id, {
      id,
      parentId: comment.id,
      userId: currentUser.publicId,
      text: content,
      createdAt: new Date().toISOString(),
      isPending: false,
      isResolved: false,
    });
    setContent(emptyContent);
    setInitialContent(emptyContent);
    textBoxRef.current?.reset();
    savePendingReply(comment.id, emptyContent);
  };

  const onCancelFocus = () => {
    // Called when the user wants to cancel the focus on
    // this comment thread
    if (comment.isPending) {
      saveComment(comment.id, null);
    } else {
      setActiveComment(null);
    }
    setIsCommentEditorFocused(false);
    focusEditor(docEditor);
  };

  const onCancelEditing = () => {
    // Called when the user is currently focused on the
    // comment editor and wants to cancel the reply
    if (isEmptyContent(content)) {
      // If the content is empty, we should remove focus on this
      // comment
      onCancelFocus();
    } else {
      savePendingReply(comment.id, emptyContent);
      setContent(emptyContent);
      setInitialContent(emptyContent);
      textBoxRef.current?.reset();
    }
  };

  const onTagOdo = (e: React.MouseEvent<HTMLButtonElement>) => {
    textBoxRef.current?.focus();
    setTimeout(() => {
      textBoxRef.current?.tagOdo();
    }, 0);
  };

  const onReplyKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.key === "o" || e.key === "ø") && (e.metaKey || e.altKey)) {
      textBoxRef.current?.tagOdo();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === "Enter" && e.metaKey) {
      if (comment.isPending) {
        onSend();
      } else {
        onReply();
      }
      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === "Escape") {
      onCancelEditing();
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const handleResolve = useCallback(() => {
    saveComment(comment.id, {
      ...comment,
      isResolved: true,
    });
    setActiveComment(null);
  }, [saveComment, comment, setActiveComment]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.focus = () => {
      textBoxRef.current?.focusEnd();
    };
  }, [ref, textBoxRef]);

  const positionOffset = isActive ? -13 : 8;
  // const positionOffset = 0;

  let buttons: (ButtonSpec | "spacer")[] = [];
  const tagOdoButton: ButtonSpec = {
    text: "@Odo",
    variants: {
      variant: "outline",
      textColor: hasOdoTagged ? "secondary" : "primary",
    },
    tooltip: "Tag Odo (⌘⇧O)",
    onClick: onTagOdo,
  };
  if (comment.isPending) {
    if (isWriter || hasRegenerate) {
      buttons.push(tagOdoButton);
    }
    buttons.push("spacer");
    buttons.push({
      text: "Cancel",
      variants: { textColor: "secondary" },
      tooltip: "Cancel (Esc)",
      onClick: onCancelFocus,
    });
    buttons.push({
      text: "Send",
      variants: { variant: "solid" },
      disabled: isEmptyContent(content),
      tooltip: "Send (⌘↩)",
      onClick: onSend,
    });
  } else if (isReplying && isActive) {
    buttons.push(tagOdoButton);
    buttons.push("spacer");
    buttons.push({
      text: "Cancel",
      tooltip: "Cancel (Esc)",
      variants: { textColor: "secondary" },
      onClick: onCancelFocus,
    });
    buttons.push({
      text: "Reply",
      variants: { variant: "solid" },
      disabled: isEmptyContent(content),
      tooltip: "Reply (⌘↩)",
      onClick: onReply,
    });
  }

  const handleFocus = () => {
    onFocusChange?.(!focused);
    setActiveComment(comment.id);
  };

  const detailsElement = (
    <div
      className={cn(
        // "min-w-[240x]",
        commentThreadVariants({ variant: isActive ? "active" : "DEFAULT" }),
        areFullCommentsVisible && "border"
      )}
      onClick={() => setActiveComment(comment.id)}
    >
      {/* The first comment */}
      <CommentView
        comment={comment}
        className={
          (!isActive && replies.length === 0) || comment.isPending
            ? undefined
            : "border-b pb-2m"
        }
        onResolve={isActive ? handleResolve : undefined}
      />
      {/* The replies */}
      {replies.map((reply, index) => (
        <CommentView
          key={reply.id}
          comment={reply}
          className={cn(
            "pt-2m",
            index !== replies.length - 1 || isActive ? "pb-2m" : undefined,
            index === replies.length - 1 && !isActive ? undefined : "border-b"
          )}
          onChange={(newContent) => {
            saveComment(reply.id, {
              ...reply,
              text: newContent,
            });
          }}
        />
      ))}

      {/* Options to continue the thread */}
      {isActive && (
        <div className={cn("px-2m pt-2m flex flex-col")}>
          <>
            <CommentEditorView
              ref={textBoxRef}
              value={initialContent}
              placeholder={comment.isPending ? "Comment" : "Reply"}
              id={comment.id + "-reply"}
              onChange={setContent}
              onFocus={() => setIsCommentEditorFocused(true)}
              onKeyDown={onReplyKeyDown}
            />
          </>
          {buttons.length === 0 ? null : (
            <div className="flex justify-end pt-2m gap-md">
              {buttons.map((button, index) =>
                button === "spacer" ? (
                  <div key={index} className="flex-1" />
                ) : (
                  <DeprecatedButton
                    key={button.text}
                    text={button.text}
                    tooltip={button.tooltip}
                    {...button.variants}
                    onClick={button.onClick}
                    disabled={button.disabled}
                  />
                )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div
      className={cn("flex flex-col absolute overflow-hidden", className)}
      data-pending-focus={true}
      ref={ref as any}
      style={{
        left: positionOffset,
        right: -positionOffset + 8,
        opacity: 0,
      }}
    >
      {areFullCommentsVisible ? (
        detailsElement
      ) : (
        <Rows className="items-start">
          <DeprecatedButton
            className={cn(
              "p-sm bg-comment-two border border-comment-underline rounded-sm"
            )}
            icon="comment"
            onClick={handleFocus}
          />
          <Overlay
            maxWidth={500}
            fillWidth={true}
            variant="transparent"
            open={
              canFocusThread && (focused || (isActive && comment.isPending))
            }
            onClose={() => {
              onFocusChange?.(false);
              comment.isPending && onCancelFocus();
            }}
          >
            {detailsElement}
          </Overlay>
        </Rows>
      )}
      <div className="h-md" />
    </div>
  );
};

export default CommentThreadView;
