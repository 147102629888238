import { PastProposalBlockResult } from "api/Api";
import Columns from "components/common/containers/Columns";
import { OverlayOnSmallScreens } from "components/common/containers/overlays/OverlayOnSmallScreens";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import Toggle from "components/common/forms/Toggle";
import { ReferenceableBlock } from "components/common/pdf/PDFView";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";
import PastProposalPDFView from "components/past-proposal/PastProposalPDFView";
import usePaginatedData from "hooks/usePaginatedData";
import { usePDFRef } from "hooks/usePDFRef";
import { useApiClient } from "providers/ApiClientProvider";
import { useMemo, useState } from "react";

const SearchRoute = () => {
  const apiClient = useApiClient();
  const [results, , pageinatedData] = usePaginatedData({
    endpoint: (queryParams, options) =>
      apiClient.user.rfpPastProposalBlocksSearchList(queryParams, options),
    map: (data) => data,
    filters: [
      {
        key: "include_paragraphs",
        name: "Paragraphs",
        default: true,
      },
      {
        key: "include_sentences",
        name: "Sentences",
        default: true,
      },
    ],
  });

  const { ref: pdfRef, scrollToElement } = usePDFRef();

  const [activeBlock, setActiveBlock] =
    useState<PastProposalBlockResult | null>(null);

  const allBlockIds: ReferenceableBlock[] = useMemo(() => {
    return (
      results?.map((result) => ({
        id: result.id!.toString(),
      })) ?? []
    );
  }, [results]);

  return (
    <Columns className="grow">
      <PaginatedTableView
        searchMode="search"
        className="grow basis-0"
        paginatedData={pageinatedData}
        results={results}
        columns={[
          { name: "Proposal" },
          { name: "Preview" },
          { name: "Sim" },
          { name: "Raw" },
        ]}
        rowSeparators={true}
        isSelected={(result) =>
          result.id!.toString() === activeBlock?.id!.toString()
        }
        onSelect={(block) => {
          setActiveBlock(block);
          scrollToElement(block.id!.toString()!);
        }}
        renderRow={(result, Cell, Row) => {
          return (
            <Row key={result.id!}>
              <Cell>
                <Tooltip>
                  <TooltipTrigger>
                    <p className="truncate max-w-[100px]">
                      {result.past_proposal_name}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent>{result.past_proposal_name}</TooltipContent>
                </Tooltip>
              </Cell>
              <Cell>{result.preview}</Cell>
              <Cell>
                <span className="text-secondary">
                  {!!result.distance
                    ? Math.round(1000 * (1 - result.distance)) / 10
                    : "?"}
                </span>
              </Cell>
              <Cell>
                <span className="text-secondary">
                  {!!result.raw_distance
                    ? Math.round(1000 * (1 - result.raw_distance)) / 10
                    : "?"}
                </span>
              </Cell>
            </Row>
          );
        }}
      />
      <OverlayOnSmallScreens
        title={activeBlock?.past_proposal_name ?? "Past Proposal"}
        scrollable={false}
        open={!!activeBlock}
        onClose={() => setActiveBlock(null)}
        minWidth={1000}
        fillHeight={true}
        fillWidth={true}
      >
        <Rows className="grow basis-0 bg-border">
          {activeBlock && activeBlock.past_proposal_id && (
            <PastProposalPDFView
              ref={pdfRef}
              className="grow"
              proposalId={activeBlock.past_proposal_id}
              activeBlockId={activeBlock?.id!.toString()!}
              referenceBlocks={allBlockIds}
              showDebugInfo={true}
              setActiveBlockId={(id) => {
                // We don't want to hide the propsoal if we clear the active block
                if (!id) return;
                setActiveBlock(
                  results?.find((result) => result.id!.toString() === id) ??
                    null
                );
              }}
            />
          )}
        </Rows>
      </OverlayOnSmallScreens>
    </Columns>
  );
};

export default SearchRoute;
