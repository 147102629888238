import DeprecatedButton from "components/common/DeprecatedButton";
import {
  FC,
  HTMLProps,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SuggestionResolver } from "odo";
import { useOdoEditorRef } from "hooks/odo-editor/useOdoEditorRef";

interface SuggestionViewProps extends HTMLProps<HTMLDivElement> {
  suggestionId: string;
  setSuggestionRef?: (id: string, ref: RefObject<HTMLElement>) => void;

  onHover?: (isHovering: boolean) => void;
}

const SuggestionControl: FC<SuggestionViewProps> = ({
  suggestionId,
  setSuggestionRef,
  onHover,
  ...props
}) => {
  const ref = useRef<HTMLElement>(null);
  const editor = useOdoEditorRef();
  const suggestionResolver = useRef(new SuggestionResolver(editor));
  const [shiftKeyDown, setShiftKeyDown] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyDown(true);
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyDown(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    setSuggestionRef?.(suggestionId, ref);
  }, [ref, setSuggestionRef, suggestionId]);

  const accept = (e: any) => {
    if (shiftKeyDown) {
      suggestionResolver.current.acceptAll();
    } else {
      suggestionResolver.current.acceptSuggestion(suggestionId);
    }
  };

  const reject = useCallback(() => {
    if (shiftKeyDown) {
      suggestionResolver.current.rejectAll();
    } else {
      suggestionResolver.current.rejectSuggestion(suggestionId);
    }
  }, [suggestionId, suggestionResolver, shiftKeyDown]);

  return (
    <div className="flex gap-xs" ref={ref as any} {...props}>
      <DeprecatedButton
        className="text-additive"
        icon="check"
        iconVariant="solid"
        variant="outline"
        size="xsmall"
        tooltip="Accept odo's suggestions"
        onClick={accept}
      />
      <DeprecatedButton
        className="text-destructive"
        icon="rotate-left"
        iconVariant="solid"
        variant="outline"
        size="xsmall"
        tooltip="Undo odo's suggestions"
        tooltipDelayDuration={0}
        onClick={reject}
        onMouseEnter={() => onHover?.(true)}
        onMouseLeave={() => onHover?.(false)}
      />
    </div>
  );
};

export default SuggestionControl;
