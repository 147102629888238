import CenteredContainer from "components/common/containers/CenteredContainer";
import Rows from "components/common/containers/Rows";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import Icon from "components/common/Icon";
import { cn } from "lib/utils";
import { ReactNode, useMemo } from "react";
import BaseItemDetailsView from "./BaseItemDetailsView";
import { ItemDetails, ItemSearch } from "api/Api";

interface ContentLibraryProjectDetailsProps {
  proposalId: string | null;
  selectedItem: ItemSearch | null;
  setSelectedItemSaveState: (state: SaveState) => void;
  updateItem: (item: ItemDetails) => void;
  deleteItem: () => Promise<void>;
  // This is a choose button that will be shown if the item
  // is selected. Used in circumstances like choosing a base
  // item for a section.
  chooseItem?: (item: ItemSearch) => Promise<void>;
  className?: string;
}

const ContentLibraryProjectDetails: React.FC<
  ContentLibraryProjectDetailsProps
> = ({
  selectedItem,
  proposalId,
  setSelectedItemSaveState,
  updateItem,
  deleteItem,
  chooseItem: doChooseItem,
  className,
}) => {
  const chooseItem = useMemo(() => {
    if (!doChooseItem) return undefined;
    return async () => {
      if (!selectedItem) return;
      await doChooseItem(selectedItem);
    };
  }, [doChooseItem, selectedItem]);

  let content: ReactNode = null;
  if (selectedItem) {
  } else {
    return <NoItemSelected />;
  }

  return (
    <Rows className={cn("basis-0 grow-[2]", className)}>
      <BaseItemDetailsView
        id={selectedItem.id!}
        proposalId={proposalId}
        onChanged={updateItem}
        onSaveStateChanged={setSelectedItemSaveState}
        deleteItem={deleteItem}
        chooseItem={chooseItem}
      />
      {content}
    </Rows>
  );
};
const NoItemSelected = () => {
  return (
    <CenteredContainer className="border-l basis-0 grow-[2]">
      <Rows className="items-center gap-md text-secondary">
        <Icon name="square-left" size="xlarge" variant="solid" />
        <p className="text-center">
          Select an item
          <br />
          from the list on the left
        </p>
      </Rows>
    </CenteredContainer>
  );
};

export default ContentLibraryProjectDetails;
