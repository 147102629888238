import BasicForm from "components/common/forms/BasicForm";
import { useApiClient } from "../../providers/ApiClientProvider";
import { useNavigate } from "react-router-dom";

const AddPromptForm = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  return (
    <BasicForm
      initialModel={{
        name: "",
        description: "",
      }}
      inputNames={{
        name: "Name",
        description: "Description",
      }}
      onSubmit={async (model) => {
        const response = await apiClient.admin.promptRefineryPromptCreate(
          model as any
        );
        navigate(`/admin/prompts/${response.data.id}`);
      }}
      submitText="Add"
      submitIcon="plus"
    />
  );
};

export default AddPromptForm;
