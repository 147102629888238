"use client";

import React, { useState } from "react";
import {
  CODE_BLOCK_LANGUAGES,
  CODE_BLOCK_LANGUAGES_POPULAR,
  useCodeBlockCombobox,
  useCodeBlockComboboxState,
} from "@udecode/plate-code-block";

import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import Icon from "components/common/Icon";
import { inputVariants } from "components/common/forms/Input";
import { cn } from "lib/utils";
import DeprecatedButton from "components/common/DeprecatedButton";
import {
  Command,
  CommandEmpty,
  CommandItem,
  CommandList,
} from "components/EditorView/Elements/Command";

interface Language {
  value: string;
  label: string;
}

const languages: Language[] = [
  { value: "text", label: "Plain Text" },
  ...Object.entries({
    ...CODE_BLOCK_LANGUAGES_POPULAR,
    ...CODE_BLOCK_LANGUAGES,
  }).map(([key, val]) => ({
    value: key,
    label: val as string,
  })),
];

export function CodeBlockCombobox() {
  const state = useCodeBlockComboboxState();
  const { commandItemProps } = useCodeBlockCombobox(state);

  const [open, setOpen] = useState(false);

  if (state.readOnly) return null;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <DeprecatedButton
          role="combobox"
          aria-expanded={open}
          className="h-5 justify-between px-thin text-2xs"
          size="small"
          icon="chevron-down"
          text={
            state.value
              ? languages.find((language) => language.value === state.value)
                  ?.label
              : "Plain Text"
          }
        />
        {/*<Icon name="chevron-down" className="ml-2 h-xs w-xs shrink-0" />*/}
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <div className="flex items-center py-sm px-2m">
            <Icon name="magnifying-glass" />
            <Command.Input
              placeholder="Search language..."
              className={cn(
                inputVariants({ variant: "menu" }),
                "border-0 pl-0"
              )}
            />
          </div>
          <CommandEmpty>No languages found.</CommandEmpty>
          <CommandList className="overflow-auto max-h-[288px]">
            {languages.map((language) => (
              <CommandItem
                key={language.value}
                selected={language.value === state.value}
                onSelect={() => {
                  commandItemProps.onSelect(language.value);
                  setOpen(false);
                }}
              >
                {language.label}
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
