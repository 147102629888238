import { useCallback, useRef } from "react";
import { PDFViewRef } from "components/common/pdf/PDFView";

/**
 * A hook that provides a ref for a PDFView component with the ability to
 * queue scroll requests before the PDFView is rendered.
 *
 * @returns An object with a ref function to pass to the PDFView and a scrollToElement function
 */
export function usePDFRef() {
  // Use a ref to store the instance of our manager class
  const pendingScroll = useRef<string | null>(null);
  const pdfRef = useRef<PDFViewRef | null>(null);

  // Create a ref callback function that will be passed to the PDFView
  const ref = useCallback(
    (instance: PDFViewRef | null) => {
      pdfRef.current = instance;
      if (pendingScroll.current && instance) {
        instance.scrollToElement(pendingScroll.current);
        pendingScroll.current = null;
      }
    },
    [pdfRef, pendingScroll]
  );

  // Function to scroll to an element, which will queue the request if the ref isn't set yet
  const scrollToElement = useCallback(
    (reference: string) => {
      if (pdfRef.current) {
        pdfRef.current.scrollToElement(reference);
      } else {
        pendingScroll.current = reference;
      }
    },
    [pdfRef, pendingScroll]
  );

  return { ref, scrollToElement };
}
