import { cn } from "lib/utils";
import { Document } from "react-pdf";
import { BBox } from "api/Api";
import { AxiosResponse } from "axios";
import { ReferenceColor } from "components/common/pdf/Reference";
import { useEffect, useImperativeHandle, useState } from "react";
import { useRef } from "react";
import { forwardRef } from "react";
import {
  calculateLayout,
  ConcretePDFLayout,
  PDFLayout,
  solidifyLayout,
  getBlockLayout,
} from "./layout";
import useFetchedData from "hooks/useFetchedData";
import BlockReferences from "./blockReferences";
import Rows from "../containers/Rows";
import CenteredContainer from "../containers/CenteredContainer";
import Spinner from "../Spinner";
import ErrorView from "../containers/ErrorView";
import Scrollable from "../containers/Scrollable";
import PDFBlock from "./PDFBlock";
import PDFPageContainer from "./PageContainer";
import { PDFUrlCache } from "./pdfUrlCache";
export interface PDFViewRef {
  scrollToElement: (reference: string) => void;
}

export interface ReferenceableBlock {
  id: string;
  color?: ReferenceColor;
}

export interface PDFViewProps {
  docId: string;
  fileUrl: ((docId: string) => Promise<string>) | string;
  className?: string;
  style?: React.CSSProperties;
  referenceBlocks?: ReferenceableBlock[];
  activeBlockId: string | null;
  setActiveBlockId?: (blockId: string | null) => void;
  getBlocksEndpoint: (
    docId: string
  ) => Promise<
    AxiosResponse<
      { id: number; bbox: BBox; page: number; type: string; index: number }[]
    >
  >;
  showDebugInfo?: boolean;
}

const PDF_PADDING = 12;

const PDFView = forwardRef<PDFViewRef, PDFViewProps>(
  (
    {
      fileUrl,
      className,
      docId,
      referenceBlocks,
      activeBlockId,
      setActiveBlockId,
      style,
      getBlocksEndpoint,
      showDebugInfo = false,
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number | null>(null);

    const [abstractLayout, setAbstractLayout] = useState<PDFLayout | null>(
      null
    );
    const [concreteLayout, setConcreteLayout] =
      useState<ConcretePDFLayout | null>(null);

    const [resolvedFileUrl, setResolvedFileUrl] = useState<string | null>(null);
    const [isLoadingUrl, setIsLoadingUrl] = useState<boolean>(false);

    useEffect(() => {
      if (typeof fileUrl === "string") {
        setResolvedFileUrl((prev) => {
          if (prev === fileUrl) return prev;
          // If the URL minus the query parameters is the same, we don't need to reload
          // This is to support signed URLs
          if (prev) {
            const prevUrl = new URL(prev);
            const newUrl = new URL(fileUrl);
            if (prevUrl.pathname === newUrl.pathname) return prev;
          }
          setAbstractLayout(null);
          setConcreteLayout(null);
          return fileUrl;
        });
        return;
      }

      const fetchFileUrl = async () => {
        const cachedUrl = PDFUrlCache.get(docId);
        if (cachedUrl) {
          setResolvedFileUrl((prev) => {
            if (prev === cachedUrl) return prev;
            setAbstractLayout(null);
            setConcreteLayout(null);
            return cachedUrl;
          });
          return;
        }

        setIsLoadingUrl(true);
        try {
          const url = await fileUrl(docId);
          PDFUrlCache.set(docId, url);
          setResolvedFileUrl((prev) => {
            if (prev === url) return prev;
            setAbstractLayout(null);
            setConcreteLayout(null);
            return url;
          });
          setPdfLoadingError(null);
        } catch (error) {
          setPdfLoadingError(error as Error);
        } finally {
          setIsLoadingUrl(false);
        }
      };

      fetchFileUrl();
    }, [fileUrl, docId]);

    useEffect(() => {
      if (abstractLayout && containerWidth) {
        setConcreteLayout(
          solidifyLayout(
            abstractLayout,
            containerWidth,
            PDF_PADDING,
            0,
            PDF_PADDING
          )
        );
      } else {
        setConcreteLayout(null);
      }
    }, [abstractLayout, containerWidth]);

    const [pdfLoadingError, setPdfLoadingError] = useState<Error | null>(null);
    const [allBlocks, , { error: fetchError }] = useFetchedData(async () => {
      const result = await getBlocksEndpoint(docId);
      return result.data;
    }, [docId]);

    const blockReferences = useRef<BlockReferences | null>(null);
    if (!blockReferences.current) {
      blockReferences.current = new BlockReferences();
    }
    const [showingActiveBlock, setShowingActiveBlock] = useState(false);

    useEffect(() => {
      if (activeBlockId) {
        setShowingActiveBlock(true);
      }
    }, [activeBlockId]);

    const isLoaded =
      abstractLayout !== null &&
      concreteLayout !== null &&
      containerWidth !== null &&
      allBlocks !== null &&
      resolvedFileUrl !== null &&
      !isLoadingUrl;

    useEffect(() => {
      blockReferences.current?.setIsLoaded(isLoaded);
    }, [isLoaded, blockReferences]);

    const error = fetchError ?? pdfLoadingError;

    useImperativeHandle(ref, () => ({
      scrollToElement: (reference: string) => {
        blockReferences.current?.scrollToElement(reference);
      },
    }));

    useEffect(() => {
      if (!containerRef.current) return;

      const resizeObserver = new ResizeObserver((entries) => {
        const { width } = entries[0].contentRect;
        setContainerWidth(width);
      });
      resizeObserver.observe(containerRef.current);
      setContainerWidth(containerRef.current.clientWidth - PDF_PADDING * 2);
      return () => resizeObserver.disconnect();
    }, [containerRef]);

    return (
      <Rows
        ref={containerRef}
        className={cn("relative white-scrollbar", className)}
        style={style}
        onClick={(e) => {
          setShowingActiveBlock(false);
          setActiveBlockId?.(null);
        }}
      >
        <CenteredContainer
          className="absolute inset-0"
          style={{ opacity: isLoaded ? 0 : 1 }}
        >
          <Spinner />
        </CenteredContainer>
        <ErrorView
          title="Error Loading PDF"
          className="absolute inset-0 bg-background-tertiary"
          style={{ opacity: !!error ? 1 : 0 }}
          error={error}
        />
        {resolvedFileUrl && (
          <Document
            className={cn(
              "grow flex overflow-hidden transition-opacity",
              isLoaded ? "opacity-100" : "opacity-0"
            )}
            file={resolvedFileUrl}
            onLoadSuccess={async (doc) => {
              setAbstractLayout(await calculateLayout(doc));
            }}
            onLoadError={(error) => {
              setPdfLoadingError(error);
            }}
          >
            <Scrollable className="grow bg-background-tertiary">
              {concreteLayout && (
                <div
                  className="relative"
                  style={{ height: concreteLayout.height }}
                >
                  {concreteLayout.pages.map((page, index) => (
                    <PDFPageContainer
                      key={index}
                      pageNumber={index + 1}
                      layout={page}
                    />
                  ))}
                  {allBlocks
                    ?.map((block) => {
                      const isReferenceBlock =
                        referenceBlocks?.some(
                          (referenceBlock) =>
                            referenceBlock.id === block.id!.toString()
                        ) ?? false;
                      return {
                        block,
                        isReferenceBlock,
                      };
                    })
                    .filter(
                      ({ isReferenceBlock }) =>
                        showDebugInfo || isReferenceBlock
                    )
                    .map(({ block, isReferenceBlock }) => {
                      const layout = getBlockLayout(block, concreteLayout);
                      const color =
                        referenceBlocks?.find(
                          (referenceBlock) =>
                            referenceBlock.id === block.id!.toString()
                        )?.color ?? (isReferenceBlock ? "tertiary" : undefined);
                      if (!layout || !blockReferences.current) return null;
                      return (
                        <PDFBlock
                          key={block.id!}
                          block={block}
                          layout={layout}
                          blockReferences={blockReferences.current}
                          onClick={
                            setActiveBlockId &&
                            (() => {
                              if (isReferenceBlock) {
                                setActiveBlockId?.(block.id!.toString());
                              }
                            })
                          }
                          showDebugInfo={showDebugInfo}
                          activeBlockId={
                            showingActiveBlock ? activeBlockId : null
                          }
                          color={color}
                        />
                      );
                    })}
                </div>
              )}
            </Scrollable>
          </Document>
        )}
      </Rows>
    );
  }
);

export default PDFView;
