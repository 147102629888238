import { cn } from "lib/utils";
import {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { v4 } from "uuid";
import { message_from_exception } from "utils";
import * as Sentry from "@sentry/react";
import LoadableView from "../containers/LoadableView";
import DeprecatedButton from "../DeprecatedButton";
import { odoToast } from "lib/odoToast";

interface ListableFile {
  id: string;
}

interface PendingFile {
  id: string;
  name: string;
  error?: string;
  isPending: true;
}

export interface MultiFileInputRef {
  clear: () => void;
}

interface MultiFileInputProps<F extends ListableFile> {
  renderFile: (file: F) => React.ReactNode;
  renderPendingFile: (file: PendingFile) => React.ReactNode;
  validFileTypes?: string[];
  className?: string;
  processFile: (file: File) => Promise<F>;
  loadInitialFiles?: () => Promise<F[]>;
  onFilesChanged?: (files: F[]) => void;
  maxSizeInMB?: number;
  ref?: React.Ref<MultiFileInputRef>;
}

/***
 * A component that renders a list of files and allows
 * the user to:
 * - Add new ones (through drag and drop or a button)
 * - Remove existing ones
 */
const MultiFileInput = <F extends ListableFile>({
  renderFile,
  renderPendingFile,
  validFileTypes,
  className,
  processFile: processTransfer,
  loadInitialFiles,
  onFilesChanged,
  ref,
  maxSizeInMB = 30,
}: MultiFileInputProps<F>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<(F | PendingFile)[]>([]);
  const [dropping, setDropping] = useState<null | "allowed" | "blocked">(null);
  const [isLoading, setIsLoading] = useState(!!loadInitialFiles);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onFilesChanged?.(files as F[]);
  }, [files, onFilesChanged]);

  useImperativeHandle(ref, () => ({
    clear: () => {
      setFiles([]);
    },
  }));

  useEffect(() => {
    if (loadInitialFiles) {
      loadInitialFiles()
        .then((initialFiles) => {
          setFiles(initialFiles);
          setIsLoading(false);
        })
        .catch((error) => {
          odoToast.caughtError(error, "Loading Initial Files");
          console.error("Error loading initial files", error);
          setIsLoading(false);
        });
    }
    // Don't include loadInitialFiles in the dependencies
    // to not trigger multiple reloads if the function changes
    // Otherwise we have to make sure we don't lose files added by the user
    // after the initial load
  }, [loadInitialFiles]);

  const handleDataTransfer = (dataTransfer: DataTransfer) => {
    let hasInvalidFile = false;
    for (const item of dataTransfer.items) {
      if (item.kind !== "file") {
        hasInvalidFile = true;
        break;
      }
      if (validFileTypes && !validFileTypes.includes(item.type)) {
        hasInvalidFile = true;
        break;
      }
    }

    if (dataTransfer.items.length === 0 || hasInvalidFile) {
      setDropping("blocked");
      dataTransfer.dropEffect = "none";
      dataTransfer.effectAllowed = "none";
      return false;
    } else {
      setDropping("allowed");
      return true;
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.dataTransfer.items.length === 0) {
      setDropping("allowed");
      return;
    }

    handleDataTransfer(e.dataTransfer);
  };

  const hanldleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    // Required to allow drop
    e.preventDefault();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.target === containerRef.current) {
      setDropping(null);
    }
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setDropping(null);
  };

  const handleAdd = async (file: File) => {
    // Allow user to select files manually (without drag and drop)
    let pendingFile: PendingFile = {
      id: v4(),
      name: file.name,
      isPending: true,
    };
    if (file.size > maxSizeInMB * 1024 * 1024) {
      odoToast.error({
        title: "File Too Large",
        text: "Please contact support",
        cta: {
          text: "Contact Support",
          onClick: () => {
            window.open("mailto:support@odo.do", "_blank");
          },
        },
      });
      Sentry.captureMessage("User tried to upload a file that is too large");
    } else {
      setFiles((prevFiles) => [pendingFile, ...prevFiles]);
      processTransfer(file)
        .then((file) => {
          setFiles((prevFiles) =>
            prevFiles.map((prevFile) =>
              prevFile.id === pendingFile.id ? file : prevFile
            )
          );
        })
        .catch((error) => {
          console.error(error);
          setFiles((prevFiles) =>
            prevFiles.map((prevFile) =>
              prevFile.id === pendingFile.id
                ? { ...prevFile, error: message_from_exception(error) }
                : prevFile
            )
          );
        });
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    setDropping(null);
    e.preventDefault();

    if (!handleDataTransfer(e.dataTransfer)) {
      odoToast.error({
        title: "Unsupported File Type",
        text: "We don't support that file type",
        cta: {
          text: "Contact Support",
          onClick: () => {
            window.open("mailto:support@odo.do", "_blank");
          },
        },
      });
      return;
    }
    if (e.dataTransfer.files.length === 0) {
      odoToast.error({
        title: "No Files",
        text: "No files were dropped",
      });
      console.error("No files were dropped");
    }
    for (const file of e.dataTransfer.files) {
      handleAdd(file);
    }
  };

  const handleRemove = (id: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  return (
    <LoadableView
      ref={containerRef}
      isLoading={isLoading}
      className={cn(
        "flex flex-col flex items-stretch justify-stretch border border-foreground border-dashed",
        "rounded outline-[2px] outline-offset-[-1px] overflow-hidden",
        dropping === "blocked" ? "" : "outline-primary",
        !!dropping ? "outline [&_*]:pointer-events-none" : "",
        className
      )}
      onDragEnter={handleDragEnter}
      onDragOver={hanldleDragOver}
      onDragEnd={handleDragEnd}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={(e) => {
          for (const file of e.target.files ?? []) {
            handleAdd(file);
          }
          e.target.value = "";
        }}
        accept={validFileTypes?.join(", ")}
        multiple={true}
      />
      <div
        className={cn(
          "w-full flex flex-col items-center p-lg border-dashed cursor-pointer group",
          files.length > 0 ? "border-b" : ""
        )}
        onClick={() => inputRef.current?.click()}
      >
        <p>Drop file(s) here</p>
        <p className="text-sm text-secondary mb-[4px]">or</p>
        <DeprecatedButton
          text="Browse Files"
          icon="magnifying-glass"
          variant="solid-secondary"
          className="pointer-events-none group-hover:opacity-50"
        />
      </div>
      {files.length > 0 && (
        <div className={cn(" max-h-[300px] overflow-auto")}>
          {files.map((file) => {
            let content: ReactNode;
            if ("error" in file && !!file.error) {
              content = (
                <>
                  <p>{file.name}</p>
                  <p className="text-sm text-destructive">{file.error}</p>
                </>
              );
            } else if ("isPending" in file) {
              content = renderPendingFile(file);
            } else {
              content = renderFile(file);
            }
            return (
              <div
                key={file.id}
                className="flex w-full items-center px-2m border-b border-dashed gap-sm last:border-b-0"
              >
                <div
                  key={file.id}
                  className="grow flex flex-col py-sm relative"
                >
                  {content}
                </div>
                {"error" in file && !!file.error && (
                  <DeprecatedButton
                    icon="trash"
                    className="ml-8"
                    onClick={() => handleRemove(file.id)}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </LoadableView>
  );
};

export default MultiFileInput;
