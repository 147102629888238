import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";

import { odoToast } from "lib/odoToast";
import { useNavigate } from "react-router-dom";

const ToastsPlayground = () => {
  const navigate = useNavigate();
  return (
    <>
      <h2 className="text-lg pb-sm">Success</h2>
      <Columns className="gap-md flex-wrap">
        <Button
          text="Success"
          onClick={() =>
            odoToast.success({
              title: "Some title",
              text: "A description of something successful",
            })
          }
        />
        <Button
          text="Success With CTA"
          onClick={() =>
            odoToast.success({
              title: "Some title",
              text: "A description of something successful",
              cta: {
                text: "View",
                onClick: () => {
                  console.log("View");
                },
              },
            })
          }
        />
        <Button
          text="Success With CTA Undo"
          onClick={() =>
            odoToast.success({
              title: "Some title",
              text: "A description of something successful",
              cta: {
                text: "View",
                onClick: () => {
                  navigate("/admin/");
                },
                undo: {
                  text: "Back",
                  onClick: () => {
                    navigate(-1);
                  },
                },
              },
            })
          }
        />
      </Columns>
      <h2 className="text-lg pt-lg pb-sm">Warning</h2>
      <Columns className="gap-md flex-wrap">
        <Button
          text="Warning"
          onClick={() =>
            odoToast.warning({
              title: "Some title",
              text: "A description of something successful",
            })
          }
        />
        <Button
          text="Warning With CTA"
          onClick={() =>
            odoToast.warning({
              title: "Some title",
              text: "A description of something successful",
              cta: {
                text: "View",
                onClick: () => {
                  console.log("View");
                },
              },
            })
          }
        />
        <Button
          text="Warning With CTA Undo"
          onClick={() =>
            odoToast.warning({
              title: "Some title",
              text: "A description of something successful",
              cta: {
                text: "View",
                onClick: () => {
                  navigate("/admin/");
                },
                undo: {
                  text: "Back",
                  onClick: () => {
                    navigate(-1);
                  },
                },
              },
            })
          }
        />
      </Columns>
      <h2 className="text-lg pt-lg pb-sm">Error</h2>
      <Columns className="gap-md flex-wrap">
        <Button
          text="Error"
          onClick={() =>
            odoToast.error({
              title: "Some title",
              text: "A description of something bad",
            })
          }
        />
        <Button
          text="Error With CTA"
          onClick={() =>
            odoToast.error({
              title: "Some title",
              text: "A description of something bad",
              cta: {
                text: "View",
                onClick: () => {
                  console.log("View");
                },
              },
            })
          }
        />
        <Button
          text="Error With CTA Undo"
          onClick={() =>
            odoToast.error({
              title: "Some title",
              text: "A description of something bad",
              cta: {
                text: "View",
                onClick: () => {
                  navigate("/admin/");
                },
                undo: {
                  text: "Back",
                  onClick: () => {
                    navigate(-1);
                  },
                },
              },
            })
          }
        />
        <Button
          text="Caught Error"
          onClick={() => odoToast.caughtError(new Error("Test error"))}
        />
        <Button
          text="Caught Error With Action"
          onClick={() =>
            odoToast.caughtError(new Error("Test error"), "Action")
          }
        />
        <Button
          text="Caught Error Object"
          onClick={() => odoToast.caughtError({ hello: "world" })}
        />
      </Columns>
    </>
  );
};

export default ToastsPlayground;
