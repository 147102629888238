import { FC } from "react";
import { ModalProps, useModal } from "./useModal";
import Rows from "../containers/Rows";
import Button from "../Button";
import Columns from "../containers/Columns";

export interface InfoModalProps extends ModalProps<void> {
  message: string;
  confirmText?: string;
}

const InfoModal: FC<InfoModalProps> = ({
  resolve,
  message,
  confirmText = "Ok",
}) => {
  return (
    <Rows className="gap-md">
      <p>{message}</p>
      <Columns className="justify-end">
        <Button onClick={() => resolve(undefined)} text={confirmText} />
      </Columns>
    </Rows>
  );
};

interface InfoModalOptions extends Omit<InfoModalProps, "resolve"> {
  title: string;
}

export const useInfoModal = () => {
  const show = useModal();
  return async (props: InfoModalOptions) => {
    const { title, ...rest } = props;
    await show<void, InfoModalProps>(InfoModal, rest, { title });
  };
};

export default InfoModal;
