import Columns from "components/common/containers/Columns";
import TestEditorView from "./TestEditorView";
import DeprecatedButton from "components/common/DeprecatedButton";
import Rows from "components/common/containers/Rows";
import { useApiClient } from "providers/ApiClientProvider";
import { odoToast } from "lib/odoToast";

const AdminEditorRoute = () => {
  const apiClient = useApiClient();
  const handleDiff = async (finalize: boolean) => {
    try {
      await apiClient.admin.rfpDiffCreate({
        finalize,
      });
    } catch (e) {
      odoToast.caughtError(e, "Diffing RFPs");
    }
  };
  return (
    <Rows className="grow gap-lg">
      <p className="shrink-0">
        Click "diff" to generate the suggestions for going from "Before" to
        "After"
      </p>
      <Columns className="grow">
        <Rows className="grow pr-md gap-sm">
          <h2 className="font-semibold">Before</h2>
          <TestEditorView id="00000000-0000-0000-0000-000000000000" />
        </Rows>
        <Rows className="grow gap-sm">
          <h2 className="font-semibold">After</h2>
          <TestEditorView id="00000000-0000-0000-0000-000000000001" />
        </Rows>
        <Rows className="justify-center w-lg relative gap-[120px] -left-[28px] overflow-visible">
          <DeprecatedButton
            text="▲ Write ▲"
            className="rotate-90 text-xs w-[80px]"
            variant="solid-secondary"
            size="xsmall"
            onClick={() => handleDiff(false)}
          />
          <DeprecatedButton
            text="▲ Diff ▲"
            className="rotate-90 text-xs w-[80px]"
            variant="solid"
            size="xsmall"
            onClick={() => handleDiff(true)}
          />
        </Rows>
        <Rows className="grow gap-sm">
          <h2 className="font-semibold">Diffed</h2>
          <TestEditorView id="00000000-0000-0000-0000-000000000002" />
        </Rows>
      </Columns>
    </Rows>
  );
};

export default AdminEditorRoute;
