import { PlateElement, PlateElementProps } from "@udecode/plate";
import { cn } from "lib/utils";
import React from "react";

const ParagraphElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ className, children, style, element, ...props }, ref) => {
  const indent = (element.indent ?? 0) as number;
  return (
    <PlateElement
      ref={ref}
      {...props}
      element={element}
      className={cn(
        className,
        element.listStyleType === "disc" && "list-item pl-xs list-outside my-xs"
      )}
      style={{
        ...style,
        marginLeft: element.listStyleType === "disc" ? indent * 12 + 24 : 0,
      }}
    >
      {children}
    </PlateElement>
  );
});

export default ParagraphElement;
