import { forwardRef, useCallback } from "react";
import PDFView, {
  PDFViewProps,
  PDFViewRef,
} from "components/common/pdf/PDFView";
import { useApiClient } from "providers/ApiClientProvider";

export interface PastProposalPDFViewProps
  extends Omit<PDFViewProps, "docId" | "getBlocksEndpoint" | "fileUrl"> {
  proposalId: string;
  fileUrl?: string;
}

const PastProposalPDFView = forwardRef<PDFViewRef, PastProposalPDFViewProps>(
  ({ proposalId, fileUrl = null, ...props }, ref) => {
    const apiClient = useApiClient();
    const getFileUrl = useCallback(
      async (proposalId: string) => {
        const result = await apiClient.user.rfpPastProposalRead(proposalId);
        const url = result.data.view_url;
        if (!url) {
          throw new Error("No PDF found for this proposal");
        }
        return url;
      },
      [apiClient]
    );
    return (
      <PDFView
        {...props}
        ref={ref}
        docId={proposalId}
        getBlocksEndpoint={apiClient.user.rfpPastProposalBlocksList}
        fileUrl={fileUrl || getFileUrl}
      />
    );
  }
);

export default PastProposalPDFView;
