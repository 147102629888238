import { FC, FormEvent, useState } from "react";
import Form from "../../components/common/forms/Form";
import Input from "../../components/common/forms/Input";
import log, { LogType } from "../../log";
import { message_from_exception } from "../../utils";
import { useApiClient } from "../../providers/ApiClientProvider";
import Button from "components/common/Button";

interface ForgotPasswordProps {
  children?: React.ReactNode;
  username: string;
  setUsername: (username: string) => void;
  className?: string;
  onLogin: () => void;
}

const ForgotPasswordView: FC<ForgotPasswordProps> = ({
  children,
  username,
  setUsername,
  className,
  onLogin,
}) => {
  const apiClient = useApiClient();
  const [error, setError] = useState<string | null>(null);
  const [sent, setSent] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const response = await apiClient.public.coreAuthForgotCreate({
        email: username,
      });

      if (response.status === 204) {
        setSent(true);
      } else {
        setError("Something went wrong");
      }
    } catch (error: any) {
      log(LogType.Auth, error.error);
      setError(message_from_exception(error, "An unexpected error occurred"));
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={className}>
      {children}
      {sent ? (
        <>
          <p className={"max-w-[360px]"}>
            If an account exists with that email address you will receive an
            email with a link to reset your password.
          </p>
          <p className="font-semibold text-center">{username}</p>
          <div className="flex flex-row mt-lg">
            <div className="flex-grow" />
            <Button type="button" onClick={onLogin} text="Login" />
            <div className="flex-grow" />
          </div>
        </>
      ) : (
        <>
          <h2 className="text-xl w-full text-center">Forgot Password</h2>
          <Input
            icon="envelope"
            type="email"
            placeholder="Email"
            variant="form"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required={true}
          />
          {error && (
            <div className="text-destructive text-center max-w-[340px]">
              {error}
            </div>
          )}
          <div className="flex flex-row">
            <Button
              type="button"
              className="text-primary text-sm"
              onClick={onLogin}
              text="Login"
            />
            <div className="flex-grow" />
            <Button
              type="submit"
              emphasis="primary"
              disabled={username === ""}
              text="Reset Password"
            />
          </div>
        </>
      )}
    </Form>
  );
};

export default ForgotPasswordView;
