import DeprecatedButton from "components/common/DeprecatedButton";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import FileInput from "components/common/forms/FileInput";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface FinalCheckChooseDocsFormProps {
  collectEmail?: boolean;
  directToLogin?: boolean;
  onContinue: (rfp: File, proposal: File, email?: string) => Promise<void>;
}

const FinalCheckChooseDocsForm: React.FC<FinalCheckChooseDocsFormProps> = ({
  onContinue,
  collectEmail = false,
  directToLogin = false,
}) => {
  const [rfp, setRFP] = useState<File | null>(null);
  const [proposal, setProposal] = useState<File | null>(null);
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  return (
    <Rows className="gap-lg">
      <p className="text-center">
        Ensure your proposal meets each of the detailed requirements in the RFP
      </p>
      <LabeledFormInput
        label="RFP"
        className="grow basis-0 gap-sm"
        variant="long-form"
      >
        <FileInput
          label="the RFP"
          accept="application/pdf"
          maxSizeInMB={200}
          onFileChanged={(file) => setRFP(file)}
        />
      </LabeledFormInput>
      <LabeledFormInput
        label="Your Proposal"
        className="grow basis-0 gap-sm"
        variant="long-form"
      >
        <FileInput
          label="your proposal"
          accept="application/pdf"
          maxSizeInMB={200}
          onFileChanged={(file) => setProposal(file)}
        />
      </LabeledFormInput>
      {collectEmail && (
        <LabeledFormInput label="Email">
          <Input
            type="email"
            icon="envelope"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </LabeledFormInput>
      )}
      <Columns className="items-center justify-center">
        {directToLogin ? (
          <DeprecatedButton
            variant="solid"
            text="Sign In"
            icon="arrow-right-to-bracket"
            size="large"
            onClick={() => {
              navigate("/login");
            }}
          />
        ) : (
          <DeprecatedButton
            variant="solid"
            text="Get Report"
            size="large"
            icon="robot"
            disabled={!rfp || !proposal || (!email && collectEmail)}
            onClick={(e) => {
              e.preventDefault();
              if (rfp && proposal) {
                onContinue(rfp, proposal, email);
              }
            }}
          />
        )}
      </Columns>
    </Rows>
  );
};

export default FinalCheckChooseDocsForm;
