import { cn } from "lib/utils";
import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import Columns from "../Columns";
import DeprecatedButton from "components/common/DeprecatedButton";
import Rows from "../Rows";

interface FlowContainerProps extends ComponentPropsWithoutRef<"div"> {}

export interface FlowContainerRef {
  goNext: () => void;
  goPrev: () => void;
  observeCurrentStep: (callback: (step: number) => void) => void;
  unobserveCurrentStep: (callback: (step: number) => void) => void;
}

/**
 * A container that displays a series of steps in a flow.
 *
 * Each child of the container represents a step in the flow and
 * only one step is displayed at a time (except during transitions).
 */
export const FlowContainer = React.forwardRef<
  FlowContainerRef,
  FlowContainerProps
>(({ children, className, ...props }, ref) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [observers, setObservers] = React.useState<((step: number) => void)[]>(
    []
  );

  // Filter out null children
  const allChildren = React.Children.toArray(children).filter(
    (child) => child !== null
  );

  const stepCount = allChildren.length;

  const goNext = useCallback(() => {
    setCurrentStep((prev) => {
      return Math.min(prev + 1, stepCount - 1);
    });
  }, [stepCount]);

  const goPrev = useCallback(() => {
    setCurrentStep((prev) => {
      return Math.max(prev - 1, 0);
    });
  }, []);

  useEffect(() => {
    observers.forEach((observer) => observer(currentStep));
  }, [currentStep, observers]);

  useImperativeHandle(ref, () => ({
    goNext,
    goPrev,
    observeCurrentStep: (callback) => {
      setObservers((prev) => [...prev, callback]);
    },
    unobserveCurrentStep: (callback) => {
      setObservers((prev) => prev.filter((cb) => cb !== callback));
    },
  }));

  return (
    <div {...props} className={cn("flex flex-col w-full relative", className)}>
      <div className="grow w-full relative overflow-hidden">
        {React.Children.map(allChildren, (child, index) => {
          return (
            <Rows
              className={cn(
                "absolute inset-0 duration-300 transition-[opacity,top]",
                currentStep === index
                  ? "opacity-100"
                  : "opacity-0 pointer-events-none"
              )}
              style={{
                top:
                  currentStep === index ? 9 : currentStep < index ? 200 : -200,
              }}
            >
              {child}
            </Rows>
          );
        })}
      </div>
      {currentStep > 0 && (
        <DeprecatedButton
          icon="chevron-left"
          size="large"
          className="absolute left-[64px] top-xl"
          onClick={goPrev}
        />
      )}
      <ProgressIndicator currentStep={currentStep} stepCount={stepCount} />
    </div>
  );
});

interface ProgressIndicatorProps extends ComponentPropsWithoutRef<"div"> {
  currentStep: number;
  stepCount: number;
}

const ProgressIndicator: FC<ProgressIndicatorProps> = ({
  currentStep,
  stepCount,
}) => {
  return (
    <Columns
      className={cn(
        "h-md w-full gap-md mb-4xl mt-md shrink-0 px-4xl",
        currentStep === 0 && "opacity-0"
      )}
    >
      {Array.from({ length: stepCount - 1 }).map((_, index) => (
        <div
          key={index}
          className={cn(
            "bg-border grow rounded-sm",
            index <= currentStep - 1 && "bg-tertiary"
          )}
        />
      ))}
    </Columns>
  );
};
