import React, { useState } from "react";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Button from "components/common/Button";
import TransitionView from "components/common/containers/TransitionView";
import Spinner from "components/common/Spinner";
import CenteredContainer from "components/common/containers/CenteredContainer";
import MessageView from "components/common/containers/MessageView";

interface Container {
  name: string;
  description: string;
  component: React.ReactNode;
}

/**
 * TransitionView demo component that allows toggling between different states
 */
const TransitionViewDemo: React.FC = () => {
  // Define different states for the transition view
  type ViewState = "loading" | "loaded";
  const [loaded, setLoaded] = useState(false);
  const [transitionCount, setTransitionCount] = useState(0);

  // Define views for each state
  const views: Record<ViewState, React.ReactElement | null> = {
    loading: (
      <CenteredContainer>
        <Spinner text="Loading data..." />
      </CenteredContainer>
    ),
    loaded: (
      <MessageView title="Data loaded successfully">
        <p>Your data has been loaded successfully.</p>
      </MessageView>
    ),
  };

  // Handle transition completion
  const handleTransitionComplete = (state: ViewState) => {
    setTransitionCount((prev) => prev + 1);
  };

  return (
    <Rows className="gap-md">
      <Columns className="gap-md shrink-0 items-center">
        <h3 className="text-lg font-medium">Toggle State:</h3>
        <Button
          emphasis={loaded ? "primary" : "secondary"}
          text={loaded ? "Loaded" : "Loading"}
          onClick={() => setLoaded(!loaded)}
        />
      </Columns>

      <div className="text-sm text-gray-500">
        Transitions completed: <strong>{transitionCount}</strong>
      </div>

      <div className="border border-gray-200 rounded-md h-64 overflow-hidden p-4">
        <TransitionView
          state={loaded ? "loaded" : "loading"}
          views={views}
          duration={400}
          onTransitionComplete={handleTransitionComplete}
          className="h-full"
        />
      </div>
    </Rows>
  );
};

/**
 * ContainersPlayground displays various container components
 */
const ContainersPlayground: React.FC = () => {
  const containers: (Container | "break")[] = [
    {
      name: "TransitionView",
      description:
        "A component that smoothly transitions between different views based on state changes",
      component: <TransitionViewDemo />,
    },
    // Add more container components here as needed
  ];

  return (
    <div className="grow p-lg">
      <h1 className="text-2xl font-bold mb-lg">Container Components</h1>
      <div className="flex flex-col gap-xl">
        {containers.map((container) => {
          if (container === "break") {
            return <div key="break" className="w-full border-t my-md" />;
          }
          return (
            <Rows key={container.name} className="gap-md max-w-[400px]">
              <div className="text-xl font-semibold">{container.name}</div>
              <div className="text-gray-500">{container.description}</div>
              <div className="border rounded-md p-lg bg-background">
                {container.component}
              </div>
            </Rows>
          );
        })}
      </div>
    </div>
  );
};

export default ContainersPlayground;
