import { useCallback, useContext } from "react";
import { AlertProviderContext } from "../AlertProvider";
import { AlertButton, CustomOptions, RenderBody } from "./types";

export interface Alert {
  message: string;
  options: CustomOptions;
  id: string;
  promise: (buttonId: string) => void;
}

interface AlertOptions {
  body?: RenderBody;
  contactSupport?: boolean;
  dismissId?: string;
}

export const useAlert = () => {
  const context = useContext(AlertProviderContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return useCallback(
    async (message: string, options: AlertOptions = {}) => {
      let buttons: AlertButton[] = [
        {
          text: "OK",
          variant: "solid-secondary",
          id: "ok",
        },
      ];
      if (options.contactSupport === true) {
        buttons.splice(0, 0, "spacer");
        buttons.splice(0, 0, {
          text: "Contact Support",
          variant: "solid",
          id: "contact",
        });
      }
      const result = await context.show(message, {
        body: options.body,
        buttons,
        dismissId: options.dismissId,
      });
      if (result === "contact") {
        window.open("mailto:support@odo.do");
      }
    },
    [context]
  );
};
