import {
  cn,
  date_from_string,
  date_string_from_date,
  format_absolute_date_long,
} from "../../lib/utils";
import DeprecatedButton from "../common/DeprecatedButton";
import { useApiClient } from "providers/ApiClientProvider";
import { odoToast } from "lib/odoToast";
import React from "react";
import Spinner from "components/common/Spinner";
import { useProposalData } from "providers/ProposalDetailsProvider";
import ProposalAdminPopover from "./ProposalAdminPopover";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProposalCollaborators from "./ProposalCollaborators";
import ProposalTitle from "./ProposalTitle";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Overlay from "components/common/containers/overlays/Overlay";
import useTriggerDownload from "hooks/useTriggerDownload";
import RFPFileMenu from "./RFPFileMenu";
import PastProposalListView from "components/PastProposalList";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";

interface ProposalTitleBarProps {
  className?: string;
}

const ProposalTitleBar: React.FC<ProposalTitleBarProps> = ({ className }) => {
  const apiClient = useApiClient();
  const { isStaff, isWriter } = useAuthenticatedUser();
  const [isExporting, setIsExporting] = React.useState(false);
  const { details, setDetails, metadata, setMetadata } = useProposalData();
  const triggerDownload = useTriggerDownload();
  const [isViewingPastProposals, setIsViewingPastProposals] =
    React.useState(false);

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const response = await apiClient.user.rfpProposalExportList(details.id);
      triggerDownload(response.data.download_url!);
    } catch (e) {
      odoToast.caughtError(e, "Exporting Proposal");
    } finally {
      setIsExporting(false);
    }
  };

  const handlePublish = async () => {
    try {
      await apiClient.user.rfpProposalPartialUpdate(details.id, {
        published_at: new Date().toISOString(),
      } as any);
      setDetails((prev) => ({ ...prev!, isPublished: true }));
      odoToast.success({
        title: "Proposal Published",
        text: "The proposal has been published successfully",
      });
    } catch (e) {
      odoToast.caughtError(e, "Publishing Proposal");
    }
  };

  const handleDateChange = async (due_date: Date) => {
    setMetadata((prev) => ({
      ...prev,
      dueDate: date_string_from_date(due_date),
    }));
  };

  return (
    <div className={cn("flex items-center px-md h-[60px]  gap-md", className)}>
      <Button
        navigate="/proposals/"
        icon="home"
        iconVariant="light"
        variant="round"
      />
      <ProposalTitle />
      <ProposalAdminPopover />
      {isStaff && !details.isPublished && (
        <DeprecatedButton
          variant="solid"
          text="Publish"
          onClick={handlePublish}
          className="mr-lg admin"
        />
      )}
      <DatePicker
        selected={metadata.dueDate ? date_from_string(metadata.dueDate) : null}
        onChange={handleDateChange}
        customInput={
          <Button
            icon="calendar"
            iconVariant="light"
            variant="round"
            emphasis="tertiary"
            size="small"
            text={
              metadata.dueDate
                ? "Due " + format_absolute_date_long(metadata.dueDate)
                : "Set Due Date"
            }
          />
        }
      />
      {/* {isWriter && (
        <DeprecatedButton
          variant="outline-full"
          icon="box-archive"
          className="mr-md admin text-primary"
          tooltip="View Past Proposals"
          onClick={() => setIsViewingPastProposals(true)}
        />
      )} */}
      <Columns className="grow justify-end">
        <RFPFileMenu rfpId={details.rfpId} className="mr-md" />
      </Columns>
      <ProposalCollaborators />
      <Button
        icon="file-export"
        size="small"
        emphasis="primary"
        variant="round"
        iconVariant="light"
        text="Export"
        tooltip="Export Proposal"
        onClick={handleExport}
        className="ml-md"
      />
      {isExporting && (
        <Overlay maxWidth={300} title="Exporting Proposal...">
          <div className="text-center my-lg flex flex-col gap-sm">
            <Spinner className="mx-auto" />
            <p className="text-sm">This may take a few moments</p>
          </div>
        </Overlay>
      )}
      {isViewingPastProposals && (
        <Overlay
          onClose={() => setIsViewingPastProposals(false)}
          variant="full-screen-bordered"
        >
          <PastProposalListView proposalId={details.id} disableAdding={true} />
        </Overlay>
      )}
    </div>
  );
};

export default ProposalTitleBar;
