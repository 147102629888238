import React, { FC, ReactNode, useEffect, useState } from "react";
import OdoUserAvatar from "components/common/OdoUserAvatar";
import { format_absolute_datetime, format_datetime } from "lib/utils";
import DeprecatedButton from "components/common/DeprecatedButton";
import { Comment, isOdoComment } from "odo";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";
import { useOrgUserWithId } from "providers/OrgUserProvider";

interface CommentAttributionViewProps {
  comment: Comment;
  onResolve?: (comment: Comment) => void;
}

const CommentAttributionView: FC<CommentAttributionViewProps> = ({
  comment,
  onResolve,
}) => {
  const user = useOrgUserWithId(comment.userId);
  // Count is used to force a re-render every second when the time will change soon
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [count, setCount] = useState(0);
  const [formattedCreatedAt, willChangeSoon] = format_datetime(
    comment.createdAt
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (willChangeSoon) {
      // Only set the interval if willChangeSoon is true
      intervalId = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clears the interval when willChangeSoon changes to false or component unmounts
      }
    };
  }, [willChangeSoon]); // Will re-run effect if willChangeSoon changes

  let avatar: ReactNode;
  let displayName: string;
  if (isOdoComment(comment)) {
    avatar = <OdoUserAvatar userType="bot" text="odo" />;
    displayName = "odo";
  } else {
    avatar = <OdoUserAvatar user={user} />;
    displayName = user?.displayName || "Unknown";
  }

  return (
    <div className="flex gap-md text-sm">
      {avatar}
      <div className="grow relative">
        <p className="absolute -top-thin text-sm font-semibold">
          {displayName}
        </p>
        <p className="absolute -bottom-thin text-secondary text-xs">
          <Tooltip>
            <TooltipTrigger className="cursor-default">
              {formattedCreatedAt}
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>
                {format_absolute_datetime(comment.createdAt)}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </p>
      </div>
      {onResolve && !comment.isPending && (
        <DeprecatedButton
          icon="check"
          variant="outline"
          size="small"
          onClick={() => onResolve(comment)}
        />
      )}
    </div>
  );
};

export default CommentAttributionView;
