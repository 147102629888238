import DeprecatedButton from "components/common/DeprecatedButton";
import LoadableView from "components/common/containers/LoadableView";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import { Category, singularNameFromCategory } from "hooks/useContentLibrary";
import { PaginatedData } from "hooks/usePaginatedData";
import { cn } from "lib/utils";
import ContentLibraryListItem from "./ContentLibraryListItem";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { ItemSearch } from "api/Api";
import { useEffect, useState } from "react";

interface ContentLibraryNavigationProps {
  items: ItemSearch[];
  paginatedData: PaginatedData;
  selectedItem: ItemSearch | null;
  setSelectedItem: (item: ItemSearch) => void;
  addItem: () => void;
  highlight?: boolean;
  activeCategory: Category;
  setActiveCategory: (category: Category) => void;
  onInsert?: (item: ItemSearch) => Promise<void>;
  onInsertPreview?: (item: ItemSearch | null) => void;
  flaggedItemIds?: string[];
}

const ContentLibraryNavigation: React.FC<ContentLibraryNavigationProps> = ({
  items,
  highlight,
  paginatedData,
  selectedItem,
  setSelectedItem,
  addItem,
  activeCategory,
  setActiveCategory,
  onInsert,
  onInsertPreview,
  flaggedItemIds,
}) => {
  const selectedClassName = "bg-primary hover:bg-primary text-background";
  const unselectedClassName =
    "py-sm px-lg hover:bg-background-selected cursor-pointer";

  const singularPropertyName = singularNameFromCategory(activeCategory);
  const { isStaff } = useAuthenticatedUser();

  // Add state for segmented items
  const [segmentedItems, setSegmentedItems] = useState<(ItemSearch | string)[]>(
    []
  );

  // Update segmented items when items change
  useEffect(() => {
    const hasProposalItems = items.some((item) => item.in_proposal === true);

    if (hasProposalItems) {
      const sorted = [...items].sort((a, b) => {
        // Sort items with in_proposal true first
        return (b.in_proposal ?? false) === (a.in_proposal ?? false)
          ? 0
          : b.in_proposal ?? false
          ? 1
          : -1;
      });

      const segmented: (ItemSearch | string)[] = [];
      let foundNotInProposal = false;

      sorted.forEach((item) => {
        if (segmented.length === 0) {
          segmented.push("Recommended");
        }
        if (!foundNotInProposal && !(item.in_proposal ?? false)) {
          segmented.push("Other");
          foundNotInProposal = true;
        }
        segmented.push(item);
      });

      setSegmentedItems(segmented);
    } else {
      setSegmentedItems(items);
    }
  }, [items]);

  return (
    <>
      {/*********************** Categories ***********************/}
      <Rows className="bg-background border-r select-none">
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "overview" && selectedClassName
          )}
          onClick={() => setActiveCategory("overview")}
        >
          Overviews
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "cover_letter" && selectedClassName
          )}
          onClick={() => setActiveCategory("cover_letter")}
        >
          Cover Letters
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "project" && selectedClassName
          )}
          onClick={() => setActiveCategory("project")}
        >
          Projects
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "person" && selectedClassName
          )}
          onClick={() => setActiveCategory("person")}
        >
          People
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "subcontractor" && selectedClassName
          )}
          onClick={() => setActiveCategory("subcontractor")}
        >
          Subcontractors
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "approach" && selectedClassName
          )}
          onClick={() => setActiveCategory("approach")}
        >
          Approaches
        </div>
        {isStaff && (
          <div
            className={cn(
              unselectedClassName,
              activeCategory === "all" && selectedClassName,
              "admin text-primary",
              activeCategory === "all" && "text-background"
            )}
            onClick={() => setActiveCategory("all")}
          >
            All
          </div>
        )}
      </Rows>
      {/*********************** Subcategories ***********************/}
      <LoadableView
        className="grow basis-0 flex"
        isLoading={paginatedData.status === "loading"}
      >
        <Rows className="grow select-none">
          <PaginatedTableView<string | ItemSearch>
            results={segmentedItems}
            variant="without-padding"
            paginatedData={paginatedData}
            searchMode="search"
            columns={1}
            className="grow basis-0 min-w-[200px] border-b"
            rowSeparators={true}
            renderRow={(item, Cell, Row) => {
              if (typeof item === "string") {
                return (
                  <Row key={item}>
                    <Cell className="font-semibold bg-background-selected text-secondary font-thin text-sm py-xs">
                      {item}
                    </Cell>
                  </Row>
                );
              }
              const isFlagged = flaggedItemIds?.includes(item.id!) ?? false;
              return (
                <Row key={item.id!}>
                  <ContentLibraryListItem
                    Cell={Cell}
                    item={item}
                    highlight={highlight}
                    activeCategory={activeCategory}
                    singularPropertyName={singularPropertyName}
                    selectedItemId={selectedItem?.id}
                    onInsert={onInsert}
                    onInsertPreview={onInsertPreview}
                    onSelected={() => setSelectedItem(item)}
                    isFlagged={isFlagged}
                  />
                </Row>
              );
            }}
          />
          <DeprecatedButton
            text={`Add ${singularPropertyName}`}
            icon="plus"
            variant="solid-secondary"
            className={cn(
              "py-sm m-md",
              highlight &&
                items.length === 0 &&
                "bg-primary text-background font-semibold"
            )}
            onClick={addItem}
          />
        </Rows>
      </LoadableView>
    </>
  );
};

export default ContentLibraryNavigation;
