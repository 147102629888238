import { FC, FormEvent, useState } from "react";
import Form from "../../components/common/forms/Form";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import Input from "../../components/common/forms/Input";
import log, { LogType } from "../../log";
import {
  clearRedirectAfterLogin,
  getRedirectAfterLogin,
  message_from_exception,
} from "../../utils";
import { useApiClient } from "../../providers/ApiClientProvider";
import { useNavigate } from "react-router-dom";
import Legal from "../../components/login/Legal";
import useDarkMode from "../../hooks/useDarkMode";
import { AxiosError } from "axios";
import { useSetAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { authenticatedUserFromDetails } from "types/AuthenticatedUser";
import Button from "components/common/Button";

interface LoginToExistingProps {
  children?: React.ReactNode;
  username: string;
  setUsername: (username: string) => void;
  password: string;
  setPassword: (password: string) => void;
  className?: string;
  onCreateAccount: () => void;
  onForgotPassword: () => void;
}

const LoginToExistingView: FC<LoginToExistingProps> = ({
  children,
  username,
  setUsername,
  password,
  setPassword,
  className,
  onCreateAccount,
  onForgotPassword,
}) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const setUser = useSetAuthenticatedUser();
  const isDarkMode = useDarkMode();

  const navigateToHome = () => {
    const path = getRedirectAfterLogin() ?? "/";
    clearRedirectAfterLogin();
    navigate(path);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const response = await apiClient.user.coreAuthTokenCreate({
        username: username.toLowerCase(),
        password,
      });

      if (response.status === 200) {
        setUser(authenticatedUserFromDetails(response.data));
        navigateToHome();
      } else {
        setError("Something went wrong");
      }
    } catch (error: any) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        setError("Incorrect username or password");
      } else {
        log(LogType.Auth, error.error);
        setError(message_from_exception(error, "An unexpected error occurred"));
      }
    }
  };

  const handleGoogleAuth = async (credentialResponse: CredentialResponse) => {
    try {
      const response = await apiClient.public.coreAuthTokenGoogleCreate({
        credentials: credentialResponse.credential!,
      });

      if (response.status === 200) {
        setUser(authenticatedUserFromDetails(response.data));
        navigateToHome();
      } else {
        setError("Something went wrong");
      }
    } catch (error) {
      // @ts-ignore
      log(LogType.Auth, error.error);
      setError(message_from_exception(error, "An unexpected error occurred"));
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={className}>
      {children}
      <h2 className="text-xl w-full text-center">Sign In</h2>
      <div className="flex justify-center">
        <GoogleLogin
          theme={isDarkMode ? "outline" : "filled_black"}
          text="signin"
          width={300}
          onSuccess={handleGoogleAuth}
          onError={() => {
            setError("Sign in failed. Please try again.");
          }}
        />
      </div>
      <div className="relative w-full h-[30px]">
        <div className="border-b w-full absolute top-[50%]" />
        <div className="absolute top-0 bottom-0 left-0 right-0 text-center">
          <p className="text-center text-secondary pt-[7px] px-sm text-xs tablet:bg-popover inline-block mx-auto z-[10] bg-background">
            OR
          </p>
        </div>
      </div>
      <Input
        icon="envelope"
        type="text"
        placeholder="Email"
        variant="form"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required={true}
        tabIndex={1}
      />
      <Input
        type="password"
        icon="lock"
        placeholder="Password"
        variant="form"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required={true}
        tabIndex={2}
      />
      {error && (
        <div className="text-destructive text-center max-w-[340px]">
          {error}
        </div>
      )}
      <div className="flex flex-row">
        <Button
          type="button"
          variant="plain"
          emphasis="primary"
          size="small"
          onClick={onCreateAccount}
          tabIndex={4}
          text="Create Account"
        />
        <div className="flex-grow" />
        <Button
          variant="solid"
          type="submit"
          emphasis="primary"
          className="w-[100px]"
          disabled={username === "" || password === ""}
          tabIndex={3}
          text="Sign In"
        />
      </div>
      <Button
        type="button"
        text="Forgot Password?"
        variant="underline"
        emphasis="tertiary"
        size="small"
        onClick={() => {
          onForgotPassword();
        }}
      />
      <Legal cta="Sign In" />
    </Form>
  );
};

export default LoginToExistingView;
