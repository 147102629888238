import StatePicker, { State, STATES } from "components/profile/StatePicker";
import {
  SearchConfiguratorProps,
  SearchResultsGenerator,
} from "./SearchConfiguratorProps";
import { useMemo } from "react";
import Rows from "components/common/containers/Rows";

const LocationConfigurator = ({
  search,
  updateSearch,
  isMobile,
}: SearchConfiguratorProps) => {
  return (
    <Rows className="text-sm gap-sm relative -mx-md w-[99999px] max-w-[min(calc(100vw-16px),700px)]">
      <p className="text-secondary ml-md select-none pointer-events-none">
        Narrow down your RFP results to specific states. Leave unselected to
        show all
      </p>
      <StatePicker
        className="-m-sm p-sm pt-none"
        stateLocations={search.locations}
        setStateLocations={(states) => {
          if (typeof states === "function") {
            updateSearch({
              locations: states(search.locations ?? []),
            });
          } else {
            updateSearch({
              locations: states,
            });
          }
        }}
        mode={isMobile ? "list" : "map"}
      />
    </Rows>
  );
};

const mapStateToSearchResult = (state: State) => {
  return {
    id: state.abbrevation,
    label: state.name,
    details: "Only show RFPs in",
    category: "Location",
  };
};

export const useLocationSearchResults: SearchResultsGenerator = (
  searchText
) => {
  const options = useMemo(() => {
    if (searchText.length === 0) {
      return STATES.slice(0, 3).map(mapStateToSearchResult);
    }

    return STATES.filter((state) => {
      return (
        state.abbrevation.toLowerCase() === searchText.toLowerCase() ||
        state.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }).map(mapStateToSearchResult);
  }, [searchText]);

  return {
    options,
    onSelected: (option, search, updateSearch) => {
      if (search.locations.includes(option.id)) {
        updateSearch({
          locations: search.locations.filter((id) => id !== option.id),
        });
      } else {
        updateSearch({
          locations: [...search.locations, option.id],
        });
      }
    },
  };
};

export default LocationConfigurator;
