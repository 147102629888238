import { cn } from "lib/utils";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import {
  createBasicMarksPlugin,
  createPlugins,
  Plate,
  PlateContent,
  PlateLeaf,
  useEditorRef,
  withProps,
} from "@udecode/plate";
import { createBasicElementsPlugin } from "@udecode/plate-basic-elements";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { focusEditor } from "@udecode/plate-common";
import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import { CodeLeaf } from "components/EditorView/Leafs/CodeLeaf";
import ParagraphElement from "./ParagraphElement";
import ReferenceElement from "../../../ReferenceElement";
import createReferencesPlugin, { ELEMENT_REFERENCE } from "odo/src/references";
import { ReferenceProvider } from "../../../../providers/ReferenceProvider";

interface EditorTextAreaProps
  extends Omit<ComponentPropsWithoutRef<"div">, "onChange"> {
  value?: any[];
  placeholder?: string;
  characterLimit?: number;
  onChange?: (value: any[]) => void;
  disabled?: boolean;
  onReferenceClicked?: (id: string) => void;
}

const editorComponents = {
  [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
  [MARK_CODE]: CodeLeaf,
  [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
  [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: "s" }),
  [MARK_SUBSCRIPT]: withProps(PlateLeaf, { as: "sub" }),
  [MARK_SUPERSCRIPT]: withProps(PlateLeaf, { as: "sup" }),
  [MARK_UNDERLINE]: withProps(PlateLeaf, { as: "u" }),
  [ELEMENT_PARAGRAPH]: ParagraphElement,
  [ELEMENT_REFERENCE]: ReferenceElement,
};

const editorPlugins = [
  createBasicElementsPlugin(),
  createBasicMarksPlugin(),
  createReferencesPlugin(),
];

interface EditorContentProps {
  id: string;
  value?: any[];
  placeholder: string;
  onFocus: (() => void) | undefined;
  onBlur: (() => void) | undefined;
  disabled?: boolean;
}

export interface EditorTextAreaRef {
  reset: () => void;
  focus: () => void;
  focusEnd: () => void;
  insertReference: (id: string) => void;
}

const EditorTextArea = forwardRef<EditorTextAreaRef, EditorTextAreaProps>(
  (
    {
      className,
      placeholder,
      onChange,
      value,
      disabled,
      onReferenceClicked,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [focused, setFocused] = useState(false);
    const [currentValue, setCurrentValue] = useState<any[]>(value || []);
    const [plugins, setPlugins] = useState<any[]>([]);

    useEffect(() => {
      setPlugins(
        createPlugins(editorPlugins, { components: editorComponents })
      );
    }, []);

    const handleFocus = useCallback(() => {
      if (!disabled) {
        setFocused(true);
      }
    }, [disabled]);

    const handleBlur = useCallback(() => {
      if (!disabled) {
        setFocused(false);
      }
    }, [disabled]);

    if (plugins.length === 0) {
      return null;
    }

    const plate = (
      <Plate
        id="editor-textarea"
        readOnly={disabled ?? false}
        plugins={plugins}
        initialValue={currentValue}
      >
        <ReferenceProvider onReferenceClicked={onReferenceClicked}>
          <EditorContent
            ref={ref}
            id="editor-textarea"
            value={value}
            placeholder={placeholder ?? ""}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </ReferenceProvider>
      </Plate>
    );

    return (
      <div
        ref={containerRef}
        className={cn(
          "border rounded-md p-sm bg-background text-lg overflow-y-auto",
          focused && "border-primary",
          className
        )}
        {...props}
      >
        {plate}
      </div>
    );
  }
);

const EditorContent = forwardRef<EditorTextAreaRef, EditorContentProps>(
  ({ id, value, onFocus, onBlur, disabled }, ref) => {
    const editor = useEditorRef();

    useEffect(() => {
      if (value) {
        for (let i = editor.children.length - 1; i >= 0; i--) {
          editor.removeNodes({ at: [i] });
        }
        editor.insertNodes(value);
      }
    }, [editor, value]);

    useImperativeHandle(ref, () => ({
      reset: () => {
        editor.reset();
      },
      focus: () => {
        focusEditor(editor);
      },
      focusEnd: () => {
        const path = [
          editor.children.length - 1,
          editor.children[editor.children.length - 1].children.length - 1,
        ];
        const end = editor.point(path, { edge: "end" });
        focusEditor(editor, end);
      },
      insertReference: (id: string) => {
        editor.insertNodes({
          // @ts-ignore
          type: ELEMENT_REFERENCE,
          id: id,
          children: [{ text: "" }],
        });
      },
    }));

    return (
      <PlateContent
        id={id}
        className="focus:outline-none"
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={disabled}
      />
    );
  }
);

export default EditorTextArea;
