import React, { FC } from "react";
import { cn } from "lib/utils";

export type ReferenceColor = "primary" | "secondary" | "tertiary";

interface ReferenceProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  active?: boolean;
  color?: ReferenceColor;
  contentRef?: (element: HTMLElement | null) => void;
}

const Reference: FC<ReferenceProps> = ({
  children,
  className,
  onClick,
  active,
  contentRef,
  color = "primary",
}) => {
  let colorClass = "bg-primary";
  let inActiveClass = "opacity-20 hover:opacity-40";
  switch (color) {
    case "primary":
      colorClass = "bg-primary";
      break;
    case "secondary":
      colorClass = "bg-warning";
      break;
    case "tertiary":
      colorClass = "bg-primary";
      inActiveClass = "opacity-10 hover:opacity-20 bg-foreground";
      break;
  }
  return (
    <span
      onClick={onClick}
      className={cn(
        "inline-block text-foreground py-thin px-xs text-xs relative",
        "align-middle cursor-pointer mr-thin rounded-sm overflow-hidden",
        active ? "text-background" : "text-foreground",
        active && colorClass,
        className
      )}
    >
      <span ref={contentRef}>{children}</span>
      <div
        className={cn(
          "absolute inset-0 transition-opacity",
          colorClass,
          active ? "opacity-0" : inActiveClass
        )}
      />
    </span>
  );
};

export default Reference;
