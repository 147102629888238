import { ItemDetails } from "api/Api";
import { FC, useEffect } from "react";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import Input from "components/common/forms/Input";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";

interface PersonDetailsViewProps {
  details: ItemDetails;
  setDetails: (details: ItemDetails) => void;
}

const PersonDetailsView: FC<PersonDetailsViewProps> = ({
  details,
  setDetails,
}) => {
  return (
    <>
      <LabeledFormInput label="Name">
        <Input
          placeholder="The name of this person"
          value={details.name ?? ""}
          onChange={(e) => setDetails({ ...details, name: e.target.value })}
        />
      </LabeledFormInput>
      <LabeledFormInput label="Title">
        <Input
          placeholder="What this person's title is"
          value={details.title ?? ""}
          onChange={(e) => setDetails({ ...details, title: e.target.value })}
        />
      </LabeledFormInput>
      <LabeledFormInput label="Bio" className="grow overflow-hidden">
        <CollaborativeTextArea
          placeholder="A detailed description of this person"
          className="h-[600px]"
          docId={details.content_id!}
          key={details.content_id}
        />
      </LabeledFormInput>
    </>
  );
};

export default PersonDetailsView;
