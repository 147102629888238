import { UserDetailPostAuth } from "api/Api";
import { FrontendUser } from "./FrontendUser";

export interface AuthenticatedUser extends FrontendUser {
  isVerified: boolean;
  isStaff: boolean;
  isWriter: boolean;
  email: string;
  hasRegenerate: boolean;
  orgId: string | null;
  favoriteListId: string | null;
  orgName: string;
}

export const authenticatedUserFromDetails = (
  data: UserDetailPostAuth
): AuthenticatedUser => ({
  publicId: data.public_id!,
  email: data.email!,
  isStaff: data.is_staff ?? false,
  isWriter: (data.is_writer ?? false) || (data.is_staff ?? false),
  displayName: data.display_name!,
  isVerified: data.is_verified ?? false,
  orgId: data.org_id ?? null,
  isPresent: true,
  hasRegenerate: true,
  favoriteListId: data.favorite_list_id ?? null,
  orgName: data.org_name!,
  // hasRegenerate: data.features?.regenerate ?? false,
});
