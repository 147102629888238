import { PlateEditor, useEditorState } from "@udecode/plate-common";
import { getSectionInfoFromElement, OdoEditor, SectionInfo } from "odo";
import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import { OutlineElementLayoutManager } from "./useOutlineLayout";
import {
  buildHierarchy,
  filterChildren,
  getPosition,
  HierarchicalSectionInfo,
  isSamePosition,
} from "./types";

export interface OutlineActions {
  startUsingShadowData: (excludingChildrenOf: string | null) => void;
  stopUsingShadowData: (saveId: string | null) => void;
}

const useOutlineData = (layoutManager: OutlineElementLayoutManager) => {
  const editor = useEditorState();
  const realSections = collectOutlineElements(editor);

  // Last real sections for comparison
  const lastRealSectionsRef = useRef<HierarchicalSectionInfo[]>([]);

  // Shadow data that can be manipulated during drag
  const [shadowSections, setShadowSections] = useState<
    HierarchicalSectionInfo[] | null
  >(null);

  const displayedSections =
    shadowSections !== null ? shadowSections : realSections;

  // Initialize or update shadow data when real data changes
  useEffect(() => {
    if (shadowSections === null) return;

    // Check if the real data has actually changed before updating shadow data
    // This prevents unnecessary rerenders when editor changes don't affect the outline
    const realSectionsJson = JSON.stringify(realSections);
    const lastRealSectionsJson = JSON.stringify(lastRealSectionsRef.current);

    if (realSectionsJson !== lastRealSectionsJson) {
      setShadowSections(JSON.parse(JSON.stringify(realSections)));
      lastRealSectionsRef.current = JSON.parse(JSON.stringify(realSections));
    }
  }, [realSections, shadowSections]);

  useEffect(() => {
    layoutManager.onSaveShadowData = (sections) => {
      setShadowSections((prev) => {
        if (prev === null) {
          return prev;
        }
        return JSON.parse(JSON.stringify(sections));
      });
    };
  }, [setShadowSections, layoutManager]);

  const startUsingShadowData = useCallback(
    (excludingChildrenOf: string | null) => {
      let newShadowSections = JSON.parse(JSON.stringify(realSections));
      if (excludingChildrenOf) {
        newShadowSections = filterChildren(
          newShadowSections,
          excludingChildrenOf
        );
      }
      setShadowSections(newShadowSections);
      layoutManager.setDisplayedHierarchicalSections(newShadowSections);
    },
    [realSections, layoutManager]
  );

  const stopUsingShadowData = useCallback(
    (saveId: string | null) => {
      const odoEditor = editor as OdoEditor;
      if (saveId && shadowSections) {
        const oldPosition = getPosition(realSections, saveId);
        const newPosition = getPosition(shadowSections, saveId);
        if (
          oldPosition &&
          newPosition &&
          !isSamePosition(oldPosition, newPosition)
        ) {
          odoEditor.moveSection(
            {
              parentId: oldPosition.parent?.id ?? null,
              index: oldPosition.index,
            },
            {
              parentId: newPosition.parent?.id ?? null,
              index: newPosition.index,
            }
          );
        }
      }
      setShadowSections(null);
    },
    [shadowSections, realSections, editor]
  );

  /**
   * Find a section by its ID (recursive search through hierarchy)
   */
  const findSectionById = useCallback(
    (
      id: string,
      sections = displayedSections
    ): HierarchicalSectionInfo | undefined => {
      const searchInChildren = (
        items: HierarchicalSectionInfo[]
      ): HierarchicalSectionInfo | undefined => {
        for (const section of items) {
          if (section.id === id) {
            return section;
          }

          const foundInChildren = searchInChildren(section.children);
          if (foundInChildren) {
            return foundInChildren;
          }
        }

        return undefined;
      };

      return searchInChildren(sections);
    },
    [displayedSections]
  );

  /**
   * Get the parent ID of a section
   */
  const getParentId = useCallback(
    (id: string, sections = displayedSections): string | null => {
      const findParentIdRecursive = (
        items: HierarchicalSectionInfo[],
        targetId: string
      ): string | null => {
        // Check if any of these sections is the direct parent
        for (const section of items) {
          // Check if any children match the target id
          const directChild = section.children.find(
            (child) => child.id === targetId
          );
          if (directChild) {
            return section.id;
          }

          // Recursively check in children
          const foundInChildren = findParentIdRecursive(
            section.children,
            targetId
          );
          if (foundInChildren) {
            return foundInChildren;
          }
        }

        return null;
      };

      return findParentIdRecursive(sections, id);
    },
    [displayedSections]
  );

  return useMemo(
    () => ({
      // Use shadow sections when dragging, otherwise use the real sections
      hierarchicalSections: displayedSections,
      actions: {
        findSectionById,
        getParentId,
        startUsingShadowData,
        stopUsingShadowData,
      } as OutlineActions,
    }),
    [
      displayedSections,
      findSectionById,
      getParentId,
      startUsingShadowData,
      stopUsingShadowData,
    ]
  );
};

/**
 * Collect outline elements from the editor and organize them in a hierarchical structure
 */
const collectOutlineElements = (
  editor: PlateEditor
): HierarchicalSectionInfo[] => {
  // First, collect all sections as a flat list
  const flatSections: SectionInfo[] = editor.children
    .map((child) => getSectionInfoFromElement(child))
    .filter((section) => !!section) as SectionInfo[];

  // Convert to hierarchical structure
  return buildHierarchy(flatSections);
};

export default useOutlineData;
