import Button, { ButtonProps } from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import { IconName } from "components/common/Icon";
import { cn } from "lib/utils";
import { useChoose } from "providers/AlertProvider";
import { usePlaygroundState } from "hooks/usePlaygroundState";

const ButtonPlayground = () => {
  const choose = useChoose();
  const emphases: ButtonProps["emphasis"][] = [
    "primary",
    "secondary",
    "tertiary",
    "destructive",
    "success",
  ];

  const [currentVariant, setCurrentVariant] = usePlaygroundState<
    ButtonProps["variant"]
  >("button-variant", "solid");
  const variants: ButtonProps["variant"][] = [
    "plain",
    "underline",
    "solid",
    "navigation",
    "round",
  ];

  const [currentSize, setCurrentSize] = usePlaygroundState<ButtonProps["size"]>(
    "button-size",
    "medium"
  );
  const sizes: ButtonProps["size"][] = [
    "xsmall",
    "small",
    "medium",
    "large",
    "xlarge",
  ];

  const [currentAlign, setCurrentAlign] = usePlaygroundState<
    ButtonProps["align"]
  >("button-align", "center");
  const aligns: ButtonProps["align"][] = ["left", "center", "right"];

  const [isLoading, setIsLoading] = usePlaygroundState("button-loading", false);
  const [isDisabled, setIsDisabled] = usePlaygroundState(
    "button-disabled",
    false
  );

  const icons: IconName[] = [
    "bolt-lightning",
    "circle",
    "xmark",
    "arrow-right",
    "list",
  ];

  const [currentIcon, setCurrentIcon] = usePlaygroundState<IconName>(
    "button-icon",
    "bolt-lightning"
  );

  const chooseIcon = async (icon: IconName) => {
    const choice = await choose("Choose Icon", {
      choices: icons.map((icon) => ({
        text: icon,
        id: icon,
        icon,
      })),
    });
    setCurrentIcon(choice as IconName);
  };

  const secondaryActions: ButtonProps["secondaryActions"] = [
    { text: "Action 1", onClick: () => {} },
    { text: "Some Action 2", onClick: () => {} },
    { text: "Action 3", onClick: () => {} },
  ];

  const secondaryActionsWithIcons: ButtonProps["secondaryActions"] = [
    { text: "Action 1", onClick: () => {}, icon: "bolt-lightning" },
    { text: "Some Action 2", onClick: () => {}, icon: "circle" },
    { text: "Action 3", onClick: () => {}, icon: "xmark" },
  ];

  return (
    <Rows className="gap-xl grow">
      <Rows className="border rounded-md shrink-0 p-sm gap-md">
        <Columns className="gap-md shrink-0 items-center">
          <h3 className="text-lg font-medium">Size:</h3>
          {sizes.map((size) => (
            <Button
              key={size}
              emphasis={currentSize === size ? "primary" : "secondary"}
              text={size as string}
              onClick={() => setCurrentSize(size)}
            />
          ))}
        </Columns>
        <Columns className="gap-md shrink-0 items-center">
          <h3 className="text-lg font-medium">Align:</h3>
          {aligns.map((align) => (
            <Button
              key={align}
              emphasis={currentAlign === align ? "primary" : "secondary"}
              text={align as string}
              onClick={() => setCurrentAlign(align)}
            />
          ))}
        </Columns>
        <Columns className="gap-md shrink-0 items-center">
          <h3 className="text-lg font-medium">Variant:</h3>
          {variants.map((variant) => (
            <Button
              key={variant}
              emphasis={currentVariant === variant ? "primary" : "secondary"}
              text={variant as string}
              onClick={() => setCurrentVariant(variant)}
            />
          ))}
        </Columns>
        <Columns className="gap-md shrink-0 items-center">
          <Button
            text="Disabled"
            iconVariant={isDisabled ? "solid" : "light"}
            icon={isDisabled ? "circle-check" : "circle"}
            onClick={() => setIsDisabled((prev) => !prev)}
          />
          <Button
            text="Loading"
            iconVariant={isLoading ? "solid" : "light"}
            icon={isLoading ? "circle-check" : "circle"}
            onClick={() => setIsLoading((prev) => !prev)}
          />
          <Button
            text="Choose Icon"
            icon={currentIcon}
            onClick={() => chooseIcon(currentIcon)}
          />
        </Columns>
      </Rows>
      <Scrollable className="grow">
        {emphases.map((emphasis) => (
          <Rows key={emphasis} className="gap-md">
            <h3 className="text-lg font-medium mt-md">{emphasis}</h3>
            <Columns
              className={cn(
                "flex-wrap gap-lg items-center",
                currentVariant === "navigation" && "border-b"
              )}
            >
              {/* Icon only */}
              <Button
                variant={currentVariant}
                size={currentSize}
                icon={currentIcon}
                tooltip="Icon only button"
                align={currentAlign}
                className={currentAlign !== "center" ? "w-[100px]" : undefined}
                disabled={isDisabled}
                isLoading={isLoading}
                emphasis={emphasis}
              />
              {/* Text only */}
              <Button
                variant={currentVariant}
                size={currentSize}
                text="Text Only"
                tooltip="Text only button"
                align={currentAlign}
                className={currentAlign !== "center" ? "w-[150px]" : undefined}
                disabled={isDisabled}
                isLoading={isLoading}
                emphasis={emphasis}
              />
              {/* Icon + Text */}
              <Button
                variant={currentVariant}
                size={currentSize}
                icon={currentIcon}
                text="Icon + Text"
                tooltip="Icon and text button"
                align={currentAlign}
                className={currentAlign !== "center" ? "w-[200px]" : undefined}
                disabled={isDisabled}
                isLoading={isLoading}
                emphasis={emphasis}
              />
              {currentVariant !== "navigation" && (
                <>
                  {/* Icon only with secondary actions */}
                  <Button
                    variant={currentVariant}
                    size={currentSize}
                    icon={currentIcon}
                    tooltip="Icon only button"
                    align={currentAlign}
                    className={
                      currentAlign !== "center" ? "w-[100px]" : undefined
                    }
                    disabled={isDisabled}
                    isLoading={isLoading}
                    emphasis={emphasis}
                    secondaryActions={secondaryActions}
                  />
                  {/* Text only with secondary actions */}
                  <Button
                    variant={currentVariant}
                    size={currentSize}
                    text="Text Only"
                    tooltip="Text only button"
                    align={currentAlign}
                    className={
                      currentAlign !== "center" ? "w-[150px]" : undefined
                    }
                    disabled={isDisabled}
                    isLoading={isLoading}
                    emphasis={emphasis}
                    secondaryActions={secondaryActions}
                  />
                  {/* Icon + Text with secondary actions */}
                  <Button
                    variant={currentVariant}
                    size={currentSize}
                    icon={currentIcon}
                    text="Icon + Text"
                    tooltip="Icon and text button"
                    align={currentAlign}
                    className={
                      currentAlign !== "center" ? "w-[200px]" : undefined
                    }
                    disabled={isDisabled}
                    isLoading={isLoading}
                    emphasis={emphasis}
                    secondaryActions={secondaryActionsWithIcons}
                  />
                </>
              )}
            </Columns>
          </Rows>
        ))}
      </Scrollable>
    </Rows>
  );
};

export default ButtonPlayground;
