import { FC, useEffect, useRef, useState } from "react";
import Icon, { IconName } from "../Icon";
import { cn } from "lib/utils";
import { odoToast } from "lib/odoToast";

interface KeyTermsInputProps {
  value: string[];
  onChange: (value: string[]) => void;
  className?: string;
}

/**
 * A component that allows the users to input multiple keywords
 *
 * It acts as a multiline input but if the user hits enter or comma, it saves out the
 * current pending text as a pill. Each pill has a clear button to remove it.
 */
const KeyTermsInput: FC<KeyTermsInputProps> = ({
  value,
  onChange,
  className,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const newTermRef = useRef<HTMLParagraphElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const ignoreNextBlurRef = useRef(false);
  const ignoreNextFocusRef = useRef(false);
  const lastInputWasMouse = useRef(false);

  useEffect(() => {
    if (isFocused) return;
    setSelectedIndex(null);
  }, [isFocused]);

  useEffect(() => {
    const handleMouseDown = () => {
      lastInputWasMouse.current = true;
    };
    const handleKeyDown = (event: KeyboardEvent) => {
      lastInputWasMouse.current = false;
    };
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const saveTerm = (text: string): boolean => {
    if (text.length === 0) return false;

    if (value.includes(text)) {
      odoToast.error({
        title: "Keyword already exists",
        text: "Please enter a unique keyword",
      });
      return false;
    }

    onChange([...value, text]);
    return true;
  };

  const handleNewTermKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const div = event.target as HTMLDivElement;
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    const isAtStart = range && range.collapsed && range.startOffset === 0;
    if (event.key === "Tab" && event.shiftKey) {
      if (value.length === 0) {
        // Allow default tab behavior (go to preivous element)
        return;
      } else {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(value.length - 1);
        ignoreNextBlurRef.current = true;
        div.blur();
      }
    } else if (["Enter", "Tab", ","].includes(event.key)) {
      const text = div.textContent?.trim();
      if (text) {
        event.preventDefault();
        event.stopPropagation();
        if (saveTerm(text)) {
          div.textContent = "";
        }
      } else if (event.key === "Tab") {
        // Allow default tab behavior (go to next element)
        event.preventDefault();
        event.stopPropagation();

        // Get the next natural focusable element, ignoring the parent container
        const nextElement = getNextFocusableElement(event.target as Element);
        if (nextElement) {
          (nextElement as HTMLElement).focus();
        }
        return;
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    } else if (["ArrowLeft", "ArrowUp"].includes(event.key)) {
      if (isAtStart) {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(value.length - 1);
        ignoreNextBlurRef.current = true;
        div.blur();
      }
    } else if (event.key === "Backspace") {
      if (isAtStart && value.length > 0) {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(value.length - 1);
        ignoreNextBlurRef.current = true;
        div.blur();
      }
    }
  };

  useEffect(() => {
    if (!isFocused) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedIndex === null) return;

      if (event.key === "Tab" && event.shiftKey) {
        if (selectedIndex === 0) {
          // Allow default tab behavior (go to preivous element)
          setSelectedIndex(null);
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(Math.max(0, selectedIndex - 1));
      } else if (["Backspace", "Delete"].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
        if (selectedIndex !== null) {
          onChange(value.filter((_, index) => index !== selectedIndex));
          if (selectedIndex === 0) {
            setSelectedIndex(null);
            newTermRef.current?.focus();
          } else {
            setSelectedIndex(Math.max(0, selectedIndex - 1));
          }
        }
      } else if (["ArrowLeft", "ArrowUp"].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
        if (selectedIndex !== null) {
          setSelectedIndex(Math.max(0, selectedIndex - 1));
        }
      } else if (["ArrowRight", "ArrowDown"].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
        if (selectedIndex !== null) {
          if (selectedIndex === value.length - 1) {
            setSelectedIndex(null);
            newTermRef.current?.focus();
          } else {
            setSelectedIndex(selectedIndex + 1);
          }
        }
      } else if (event.key === "Tab") {
        if (selectedIndex === value.length - 1) {
          event.preventDefault();
          event.stopPropagation();
          newTermRef.current?.focus();
        } else {
          event.preventDefault();
          event.stopPropagation();
          setSelectedIndex(selectedIndex + 1);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onChange, selectedIndex, value, isFocused]);

  const pillClassName =
    "inline-block px-sm py-xs rounded-md m-xs bg-background-secondary";

  return (
    <div
      className={cn("border rounded-md p-xs overflow-y-auto", className)}
      ref={containerRef}
      onClick={() => newTermRef.current?.focus()}
      onFocus={(e) => {
        setIsFocused(true);
        if (ignoreNextFocusRef.current) {
          ignoreNextFocusRef.current = false;
          return;
        }
        if (!lastInputWasMouse.current) {
          newTermRef.current?.focus();
        }
      }}
      onBlur={(e) => {
        // console.log("onBlur", e.target, e.relatedTarget);
        if (
          containerRef.current === e.target &&
          e.relatedTarget !== newTermRef.current
        ) {
          setIsFocused(false);
          setSelectedIndex(null);
        } else if (newTermRef.current === e.target) {
          // Check if the new element is within the container
          if (containerRef.current?.contains(e.relatedTarget as Node)) {
          } else {
            if (ignoreNextBlurRef.current) {
              ignoreNextBlurRef.current = false;
              // Focus the container to maintain focus (detect clicking outside somewhere)
              ignoreNextFocusRef.current = true;
              containerRef.current?.focus();
            } else {
              setIsFocused(false);
            }
          }
        }
      }}
    >
      {value.map((term, index) => (
        <KeyTermPill
          term={term}
          isSelected={selectedIndex === index}
          onSelected={(e) => {
            ignoreNextBlurRef.current = true;
            e.stopPropagation();
            newTermRef.current?.blur();
            setSelectedIndex(index);
            setIsFocused(true);
          }}
          onRemove={(e) => {
            ignoreNextBlurRef.current = true;
            e.stopPropagation();
            onChange(value.filter((_, i) => i !== index));
          }}
        />
      ))}
      <p
        ref={newTermRef}
        onFocus={() => setSelectedIndex(null)}
        className={cn(
          pillClassName,
          "focus:outline focus:outline-primary min-w-[120px]"
        )}
        placeholder="New keyword"
        contentEditable={true}
        tabIndex={-1}
        onKeyDown={handleNewTermKeyDown}
      />
    </div>
  );
};

interface KeyTermPillProps {
  term: string;
  isSelected: boolean;
  onSelected?: (e: React.MouseEvent<HTMLParagraphElement>) => void;
  onRemove?: (e: React.MouseEvent<HTMLParagraphElement>) => void;
  className?: string;
  trailingIcon?: IconName;
}

export const KeyTermPill: FC<KeyTermPillProps> = ({
  term,
  isSelected,
  onSelected,
  onRemove,
  className,
  trailingIcon,
}) => {
  return (
    <p
      key={term}
      className={cn(
        "inline-block px-sm py-xs rounded-md m-xs bg-background-secondary",
        isSelected && "outline outline-primary",
        className
      )}
      onClick={onSelected}
    >
      {term}
      {trailingIcon && (
        <Icon
          name={trailingIcon}
          className="ml-xs text-xs text-primary"
          variant="solid"
        />
      )}
      {onRemove && (
        <Icon
          name="circle-xmark"
          className={cn(
            "text-secondary ml-xs",
            isSelected ? "hover:text-background" : "hover:text-foreground"
          )}
          onClick={onRemove}
        />
      )}
    </p>
  );
};

const getFocusableElements = () => {
  // Get all potentially focusable elements
  const elements = document.querySelectorAll(`
    a[href]:not([disabled]),
    button:not([disabled]),
    input:not([disabled]),
    select:not([disabled]),
    textarea:not([disabled]),
    [tabindex]:not([tabindex="-1"]):not([disabled]),
    [contenteditable="true"]
  `);

  // Filter out hidden elements
  return Array.from(elements).filter((el) => {
    const element = el as HTMLElement;
    const style = window.getComputedStyle(element);
    return (
      style.display !== "none" &&
      style.visibility !== "hidden" &&
      style.opacity !== "0"
    );
  });
};

const getNextFocusableElement = (currentElement: Element) => {
  const focusableElements = getFocusableElements();
  const currentIndex = focusableElements.indexOf(currentElement);
  return focusableElements[currentIndex + 1];
};

export default KeyTermsInput;
