import { Dialog, DialogContent, DialogPortal } from "@radix-ui/react-dialog";
import { mergeRefs } from "lib/utils";
import React, {
  ComponentPropsWithoutRef,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 } from "uuid";

interface CoachMarkData {
  layoutManager: React.MutableRefObject<CoachMarkLayoutManager>;
}

const CoachMarkContext = React.createContext<CoachMarkData | null>(null);

interface CoachMarkProps {
  children: React.ReactNode;
  content?: React.ReactNode;
}

export const CoachMark = ({ children, content }: CoachMarkProps) => {
  const layoutManager = useRef<CoachMarkLayoutManager>(
    new CoachMarkLayoutManager()
  );

  const [layoutData, setLayoutData] = useState<CoachMarkLayoutData | null>(
    null
  );

  useEffect(() => {
    const manager = layoutManager.current;

    const updateLayout = () => {
      setLayoutData(manager.getLayoutData());
    };

    // Set up resize observer callback
    manager.setOnLayoutChange(updateLayout);

    // Initial layout
    updateLayout();

    // Handle window resize
    window.addEventListener("resize", updateLayout);

    return () => {
      window.removeEventListener("resize", updateLayout);
      manager.cleanup();
    };
  }, [layoutManager]);

  return (
    <CoachMarkContext.Provider value={{ layoutManager }}>
      <div>{children}</div>
      {layoutData && content && (
        <Dialog open={true} modal={false}>
          <DialogPortal>
            <DialogContent className="fixed">
              <div
                style={layoutData.availableSpaceStyle}
                className="fixed flex justify-start items-end select-none pointer-events-none"
              >
                <div
                  className="border border-primary bg-primary text-background px-sm py-xs rounded-sm drop-shadow pointer-events-auto"
                  style={{ minWidth: layoutData.minContentWidth }}
                >
                  {content}
                </div>
                {layoutData.arrowStyles.map((style, index) => (
                  <div key={index} style={style} className="fixed flex">
                    <Arrow />
                  </div>
                ))}
              </div>
            </DialogContent>
          </DialogPortal>
        </Dialog>
      )}
    </CoachMarkContext.Provider>
  );
};

const useCoachMarkLayoutManager = () => {
  const context = React.useContext(CoachMarkContext);
  if (!context) {
    throw new Error("CoachMarkLayoutManager must be used within a CoachMark");
  }
  return context.layoutManager;
};

export const CoachMarkAnchor = React.forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>((props, ref) => {
  const id = useMemo(() => v4(), []);
  const layoutManager = useCoachMarkLayoutManager();
  const containerRef = useRef<HTMLDivElement>(null);
  const mergedRef = mergeRefs(containerRef, ref);

  useEffect(() => {
    const manager = layoutManager.current;
    if (containerRef.current) {
      manager.setAnchorElement(id, containerRef.current);
    }

    return () => {
      manager.setAnchorElement(id, null);
    };
  }, [id, layoutManager]);

  return <div ref={mergedRef} {...props} />;
});

interface CoachMarkLayoutData {
  // Style to position the full available space to position the coach
  // mark. The mark cannot be placed anywhere outside of this space.
  //
  // This is the space above all anchor elements and up to the left,
  // top, and right edges of the window - padding.
  availableSpaceStyle: React.CSSProperties;

  arrowStyles: React.CSSProperties[];

  minContentWidth: number;
}

const Arrow: FC = () => {
  return (
    <div
      className="w-full h-full bg-primary"
      style={{
        clipPath: "polygon(0% 0%, 100% 0%, 50% 100%)",
      }}
    />
  );
};
class CoachMarkLayoutManager {
  private anchorElements: Record<string, HTMLDivElement>;
  private resizeObserver: ResizeObserver;
  private onLayoutChange?: () => void;
  private scrollParents: Set<Element | Window>;

  constructor() {
    this.anchorElements = {};
    this.scrollParents = new Set([window]);
    this.resizeObserver = new ResizeObserver(() => {
      this.onLayoutChange?.();
    });
  }

  private getScrollParents(element: HTMLElement): Element[] {
    const scrollParents: Element[] = [];
    let parent = element.parentElement;

    while (parent) {
      const style = window.getComputedStyle(parent);
      const { overflow, overflowX, overflowY } = style;

      if (/(auto|scroll)/.test(overflow + overflowY + overflowX)) {
        scrollParents.push(parent);
      }
      parent = parent.parentElement;
    }

    return scrollParents;
  }

  setOnLayoutChange(callback: () => void) {
    this.onLayoutChange = callback;
  }

  private handleScroll = () => {
    this.onLayoutChange?.();
  };

  setAnchorElement(id: string, element: HTMLDivElement | null) {
    // Clean up old elements and their scroll listeners
    if (this.anchorElements[id]) {
      this.resizeObserver.unobserve(this.anchorElements[id]);
      // Remove scroll listeners from old element's scroll parents
      this.getScrollParents(this.anchorElements[id]).forEach((parent) => {
        parent.removeEventListener("scroll", this.handleScroll);
        this.scrollParents.delete(parent);
      });
    }

    if (!element) {
      delete this.anchorElements[id];
      return;
    }

    this.anchorElements[id] = element;

    // Observe the new element for size changes
    this.resizeObserver.observe(element);

    // Add scroll listeners to all scrollable parents
    this.getScrollParents(element).forEach((parent) => {
      parent.addEventListener("scroll", this.handleScroll, { passive: true });
      this.scrollParents.add(parent);
    });
  }

  cleanup() {
    this.resizeObserver.disconnect();
    // Clean up all scroll listeners
    this.scrollParents.forEach((parent) => {
      parent.removeEventListener("scroll", this.handleScroll);
    });
    this.scrollParents.clear();
  }

  getLayoutData(): CoachMarkLayoutData | null {
    const anchorsBoundingRect = this.getAnchorsBoundingRect();
    if (!anchorsBoundingRect) {
      return null;
    }
    const arrowWidth = 20;
    const arrowHeight = 10;
    return {
      availableSpaceStyle: {
        top: 12,
        left: anchorsBoundingRect.left,
        right: 12,
        bottom: window.innerHeight - (anchorsBoundingRect.top - arrowHeight),
      },
      minContentWidth: anchorsBoundingRect.width,
      arrowStyles: Object.values(this.anchorElements).map((el) => {
        const boundingRect = el.getBoundingClientRect();
        return {
          left: boundingRect.left + (boundingRect.width / 2 - arrowWidth / 2),
          top: boundingRect.top - arrowHeight,
          width: arrowWidth,
          height: arrowHeight,
        };
      }),
    };
  }

  /**
   * Get the bounding rect containing all the anchor elements
   */
  getAnchorsBoundingRect(): DOMRect | null {
    const anchors = Object.values(this.anchorElements).flat();
    if (anchors.length === 0) {
      return null;
    }
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    for (const anchor of anchors) {
      const boundingRect = anchor.getBoundingClientRect();
      minX = Math.min(minX, boundingRect.left);
      minY = Math.min(minY, boundingRect.top);
      maxX = Math.max(maxX, boundingRect.right);
      maxY = Math.max(maxY, boundingRect.bottom);
    }
    return new DOMRect(minX, minY, maxX - minX, maxY - minY);
  }
}
