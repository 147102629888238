import { FC, useEffect, useMemo, useRef, useState } from "react";
import { SearchConfiguratorProps } from "./SearchConfiguratorProps";
import Rows from "components/common/containers/Rows";
import { debounce } from "lodash";
import { useApiClient } from "providers/ApiClientProvider";
import { NAICSSector, SavedSearchDetail } from "api/Api";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";
import Icon from "components/common/Icon";
import ComboBox, { ResultBase } from "components/common/menus/ComboBox";
import Columns from "components/common/containers/Columns";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Spinner from "components/common/Spinner";
import { UpdateSearchOptions } from "./RFPSearchMenu";

interface NAICSSearchResult extends ResultBase {
  title: string;
  indent: number;
  totalChildren: number;
}

const NAICSConfigurator: FC<SearchConfiguratorProps> = ({
  search,
  updateSearch,
  isOpen,
}) => {
  const apiClient = useApiClient();

  // Current selection info
  const [selection, setSelection] = useState<NAICSSector[] | null>(null);
  const [currentSelectionState, setCurrentSelectionState] = useState<
    NAICSSearchResult[] | null
  >(null);

  // Search based results
  const [query, setQuery] = useState("");
  const [fullResults, setFullResults] = useState<NAICSSector[] | null>(null);
  const [results, setResults] = useState<
    NAICSSearchResult[] | null | undefined
  >(undefined);

  const debouncedSearch = useRef(
    debounce(async (query: string) => {
      if (!query) {
        setResults(undefined);
        return;
      }
      setResults(null);
      const response = await apiClient.user.rfpNaicsSearchList({
        // @ts-expect-error
        query: {
          q: query,
        },
      });
      setFullResults(response.data);
    }, 500)
  );

  useEffect(() => {
    // Keep results up to date based on the full results
    // from the api and the current saved search state
    if (!fullResults) return;
    setResults(flattenResults(search, fullResults));
  }, [fullResults, search]);

  useEffect(() => {
    // Trigger a search when the query changes
    debouncedSearch.current(query);
  }, [query]);

  useEffect(() => {
    // Keep the selection state up to date based on the saved search state
    if (!isOpen) return;

    const update = async () => {
      const response = await apiClient.user.rfpNaicsSelectionCreate({
        naics_codes: search.naics_codes,
      });
      setSelection(response.data);
    };
    if (search.naics_codes.length > 0) {
      update();
    } else {
      setSelection(null);
    }
  }, [apiClient.user, isOpen, search.naics_codes]);

  useEffect(() => {
    // Keep the current selection state up to date based on the saved search state
    if (!selection) return;
    setCurrentSelectionState(flattenResults(search, selection));
  }, [search, selection]);

  const handleToggle = (result: NAICSSearchResult) => {
    if (search.naics_codes.includes(result.id)) {
      updateSearch({
        naics_codes: search.naics_codes.filter((code) => code !== result.id),
      });
    } else {
      updateSearch({ naics_codes: [...search.naics_codes, result.id] });
    }
  };

  const options = useMemo(() => {
    if (query) {
      return results ?? [];
    } else {
      return currentSelectionState ?? [];
    }
  }, [query, results, currentSelectionState]);

  return (
    <Rows className="gap-sm min-h-[200px] text-sm max-w-[calc(100vw-44px)]">
      <p className="text-sm text-secondary">
        Narrow down your RFP results to be at least one of the selected NAICS
        categories
      </p>
      <Rows className="border rounded-sm grow max-h-[400px]">
        <ComboBox
          options={options}
          placeholder="Search for a NAICS code"
          inputIcon="magnifying-glass"
          className="text-sm"
          onSearchChange={setQuery}
          maxResults={999}
          alwaysShowResults={true}
          noResultsContent={
            <CenteredContainer className="grow">
              {(results === null || results === undefined) && query ? (
                <Spinner text="Searching..." />
              ) : !!query ? (
                "No matching NAICS codes found"
              ) : (
                "The North American Industry Classification System (NAICS) is the standard used by many government agencies in classifying business establishments"
              )}
            </CenteredContainer>
          }
          onSelected={(result) => {
            handleToggle(result);
            return false;
          }}
          renderOption={(result) => (
            <NAICSResult
              result={result}
              search={search}
              updateSearch={updateSearch}
            />
          )}
        />
      </Rows>
    </Rows>
  );
};

const NAICSResult: FC<{
  result: NAICSSearchResult;
  search: SavedSearchDetail;
  updateSearch: (
    update: Partial<SavedSearchDetail>,
    options?: UpdateSearchOptions
  ) => void;
}> = ({ result, search, updateSearch }) => {
  let selectionStatus: "full" | "partial" | "none" = "none";
  if (search.naics_codes.includes(result.id.toString())) {
    selectionStatus = "full";
  } else if (
    search.naics_codes.some((code) => code.toString().startsWith(result.id))
  ) {
    selectionStatus = "partial";
  }

  const handleToggle = () => {
    if (selectionStatus === "full") {
      updateSearch({
        naics_codes: search.naics_codes.filter(
          (code) => code !== result.id.toString()
        ),
      });
    } else {
      updateSearch({
        naics_codes: [...search.naics_codes, result.id.toString()],
      });
    }
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Columns
          className="cursor-pointer shrink-0 items-center gap-sm"
          onClick={handleToggle}
          style={{ paddingLeft: result.indent * 24 }}
        >
          <Icon
            name={
              selectionStatus === "full"
                ? "square-check"
                : selectionStatus === "partial"
                ? "square-minus"
                : "square"
            }
            className="inline-block"
          />
          <p className="truncate grow">{result.title}</p>
          <p className="text-xs text-secondary">{result.id}</p>
        </Columns>
      </TooltipTrigger>
      <TooltipContent>
        <p>{result.title}</p>
      </TooltipContent>
    </Tooltip>
  );
};

const flattenResults = (
  search: SavedSearchDetail,
  response: {
    id: string | number;
    title: string;
    children?: any[];
    total_children?: number;
  }[],
  indent: number = 0
): NAICSSearchResult[] => {
  const results: NAICSSearchResult[] = [];
  for (const result of response) {
    results.push({
      id: result.id.toString(),
      title: result.title,
      indent,
      totalChildren: result.total_children ?? 0,
    });
    if (result.children) {
      // const isSelected = search.naics_codes.includes(result.id.toString());
      // if (!isSelected) {
      results.push(...flattenResults(search, result.children, indent + 1));
      // }
    }
  }
  return results;
};

export default NAICSConfigurator;
