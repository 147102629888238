import { isCollapsed } from "@udecode/plate";
import { insertText } from "@udecode/plate";
import { isOdoHeading, levelFromOdoHeading, } from "./types.js";
import { v4 } from "uuid";
import { isEmptyContent } from "../comments/types.js";
const withOdoSectionOverrides = (editor) => {
    const odoEditor = editor;
    odoEditor.sectionHeadingEntry = (id) => {
        for (let i = 0; i < editor.children.length; i++) {
            const child = editor.children[i];
            if (isOdoHeading(child) && child.id === id) {
                return [child, [i]];
            }
        }
        return null;
    };
    odoEditor.rangeOfSection = (id, includeChildren) => {
        let headingInfo;
        for (let i = 0; i < editor.children.length; i++) {
            const child = editor.children[i];
            if (isOdoHeading(child)) {
                const level = levelFromOdoHeading(child);
                if (headingInfo !== undefined) {
                    // We already found the heading, we're looking for the end
                    if (includeChildren && level > headingInfo.level) {
                        // This is not the end
                        continue;
                    }
                    // This is the end (and should not be included)
                    return {
                        anchor: editor.start(headingInfo.path),
                        focus: editor.end([i - 1]),
                    };
                }
                else if (child.id === id) {
                    // We found the beginning of the section
                    headingInfo = { path: [i], level };
                }
            }
        }
        if (headingInfo === undefined) {
            return null;
        }
        return {
            anchor: editor.start(headingInfo.path),
            focus: editor.end([editor.children.length - 1]),
        };
    };
    // Gets the range encompassing a section that is the child of a given section
    // at a given index. If that index is 1 beyond the bounds, it will return a
    // collapsed range immediately after the last child. If the parent can't be
    // found or the index is further out of bounds, it will return null.
    //
    // Edge case: If there are children that are extra levels deep, they will be
    // counted as direct children of the parent. Example:
    // # Section 1
    //     ### Section 1.1
    //   ## Section 1.2
    // When looking for the child of section-1 at index 0, it will return the
    // section-1.1 node.
    odoEditor.rangeOfChildSection = (parentId, index) => {
        if (index < 0)
            return null;
        const targetChildSectionIndex = index;
        let blockIndex = 0;
        let parentLevel = 0;
        if (parentId !== null) {
            for (; blockIndex < editor.children.length; blockIndex++) {
                const next = editor.children[blockIndex];
                if (isOdoHeading(next) && next.id === parentId) {
                    parentLevel = levelFromOdoHeading(next);
                    blockIndex++;
                    break;
                }
            }
            if (!parentLevel) {
                // Parent not found
                return null;
            }
        }
        let nextChildSectionIndex = 0;
        let childStartBlockIndex = null;
        let foundFirstChildWithExpectedLevel = false;
        for (; blockIndex < editor.children.length; blockIndex++) {
            const next = editor.children[blockIndex];
            if (!isOdoHeading(next)) {
                // Skip non-heading nodes
                continue;
            }
            const nextLevel = levelFromOdoHeading(next);
            if (nextLevel <= parentLevel) {
                // We ran into the end of the parent's section
                break;
            }
            if (levelFromOdoHeading(next) > parentLevel + 1 &&
                foundFirstChildWithExpectedLevel) {
                // Skip child sections that are further nested
                // But only if we're not at the first child section where we should
                // absorb these orphans into the parent
                continue;
            }
            if (nextChildSectionIndex === targetChildSectionIndex) {
                // We found the start of the target child section
                childStartBlockIndex = blockIndex;
            }
            else if (nextChildSectionIndex > targetChildSectionIndex) {
                // We found the start of the next child section
                // Return the range of the previous child section
                return {
                    anchor: editor.start([childStartBlockIndex]),
                    focus: editor.end([blockIndex - 1]),
                };
            }
            if (nextLevel === parentLevel + 1) {
                foundFirstChildWithExpectedLevel = true;
            }
            nextChildSectionIndex++;
        }
        if (childStartBlockIndex !== null) {
            // We found the child section but nothing following it
            // Return the range of the child section
            return {
                anchor: editor.start([childStartBlockIndex]),
                focus: editor.end([blockIndex - 1]),
            };
        }
        if (nextChildSectionIndex === targetChildSectionIndex) {
            // We got to the end but were 1 shy of the target child section
            // Return a collapsed range after the last child section
            return {
                anchor: { path: [blockIndex, 0], offset: 0 },
                focus: { path: [blockIndex, 0], offset: 0 },
            };
        }
        // We are more than 1 shy of the target child section
        return null;
    };
    odoEditor.moveSection = (from, to) => {
        editor.withoutNormalizing(() => {
            // 1) get the range of the source section
            const fromRange = odoEditor.rangeOfChildSection(from.parentId, from.index);
            if (!fromRange) {
                throw new Error(`Source section not found: parentId=${from.parentId}, index=${from.index}`);
            }
            if (!odoEditor.rangeOfChildSection(to.parentId, to.index)) {
                throw new Error(`Target section not found: parentId=${to.parentId}, index=${to.index}`);
            }
            const fromLevel = levelFromOdoHeading(editor.children[fromRange.anchor.path[0]]);
            // 2) make a copy of the source section for inserting later
            const nodes = Array.from(editor.nodes({
                at: fromRange,
                match: (_, path) => path.length === 1,
            })).map(([node]) => node);
            const copiedNodes = JSON.parse(JSON.stringify(nodes));
            // 3) delete the source section
            editor.removeNodes({ at: fromRange });
            // 4) get the range of the seciton currently filling the
            // space we want the source to fill
            try {
                let toIndex;
                let toParentLevel = 0;
                if (to.parentId) {
                    const toParentEntry = odoEditor.sectionHeadingEntry(to.parentId);
                    if (!toParentEntry) {
                        throw new Error(`Target section not found: parentId=${to.parentId}, index=${to.index}`);
                    }
                    toParentLevel = levelFromOdoHeading(toParentEntry[0]);
                }
                if (to.index === 0) {
                    // Get the range of the parent instead
                    if (to.parentId === null) {
                        toIndex = 0;
                    }
                    else {
                        const toParentRange = odoEditor.rangeOfSection(to.parentId, false);
                        if (!toParentRange) {
                            throw new Error(`Target section not found: parentId=${to.parentId}, index=${to.index}`);
                        }
                        // Add after the range
                        toIndex = toParentRange.focus.path[0] + 1;
                    }
                }
                else {
                    const toRange = odoEditor.rangeOfChildSection(to.parentId, to.index);
                    if (!toRange) {
                        throw new Error(`Target section not found: parentId=${to.parentId}, index=${to.index}`);
                    }
                    // Add before the range
                    toIndex = toRange.anchor.path[0];
                }
                // 5) Adjust the heading levels
                if (copiedNodes.length > 0 && isOdoHeading(copiedNodes[0])) {
                    const targetLevel = toParentLevel + 1;
                    const levelDiff = targetLevel - fromLevel;
                    for (const node of copiedNodes) {
                        if (isOdoHeading(node)) {
                            const currentLevel = levelFromOdoHeading(node);
                            node.type = `h${Math.min(6, currentLevel + levelDiff)}`;
                        }
                    }
                }
                // 6) insert the copied section into the target location
                editor.insertNodes(copiedNodes, { at: [toIndex] });
            }
            catch (error) {
                // Restore the original section
                editor.insertNodes(copiedNodes, { at: [fromRange.anchor.path[0]] });
                throw error;
            }
        });
    };
    odoEditor.indentSection = (id) => {
        const entry = odoEditor.sectionHeadingEntry(id);
        if (!entry) {
            return null;
        }
        const [node, path] = entry;
        const typeInteger = levelFromOdoHeading(node);
        if (typeInteger >= 6) {
            return 6;
        }
        // Find the previous heading's level
        let previousHeadingLevel = 0;
        for (let i = path[0] - 1; i >= 0; i--) {
            const prevNode = editor.children[i];
            if (isOdoHeading(prevNode)) {
                previousHeadingLevel = levelFromOdoHeading(prevNode);
                break;
            }
        }
        // Don't allow indenting beyond previous heading's level + 1
        if (typeInteger > previousHeadingLevel) {
            return typeInteger;
        }
        // Get the range of the section including all children
        const range = odoEditor.rangeOfSection(id, true);
        if (!range) {
            return null;
        }
        // Get all heading nodes in the range
        const headingNodes = Array.from(editor.nodes({
            at: range,
            match: (node) => isOdoHeading(node),
        }));
        // Calculate the level difference for each heading
        const levelDiff = 1;
        editor.withoutNormalizing(() => {
            // Update each heading's level
            for (const [headingNode, headingPath] of headingNodes) {
                const currentLevel = levelFromOdoHeading(headingNode);
                const newLevel = Math.min(6, currentLevel + levelDiff);
                editor.setNodes({
                    // @ts-ignore
                    type: `h${newLevel}`,
                }, { at: headingPath });
            }
        });
        return typeInteger + 1;
    };
    odoEditor.dedentSection = (id) => {
        const entry = odoEditor.sectionHeadingEntry(id);
        if (!entry) {
            return null;
        }
        const [node, path] = entry;
        const typeInteger = levelFromOdoHeading(node);
        if (typeInteger <= 1) {
            return 1;
        }
        // Get the range of the section including all children
        const range = odoEditor.rangeOfSection(id, true);
        if (!range) {
            return null;
        }
        // Get all heading nodes in the range
        const headingNodes = Array.from(editor.nodes({
            at: range,
            match: (node) => isOdoHeading(node),
        }));
        // Calculate the level difference for each heading
        const levelDiff = -1;
        const minLevel = 1;
        editor.withoutNormalizing(() => {
            // Update each heading's level
            for (const [headingNode, headingPath] of headingNodes) {
                const currentLevel = levelFromOdoHeading(headingNode);
                const newLevel = Math.max(minLevel, currentLevel + levelDiff);
                editor.setNodes({
                    // @ts-ignore
                    type: `h${newLevel}`,
                }, { at: headingPath });
            }
        });
        return typeInteger - 1;
    };
    odoEditor.renameSection = (id, newName) => {
        const entry = odoEditor.sectionHeadingEntry(id);
        if (!entry) {
            return false;
        }
        const [, path] = entry;
        const anchor = editor.start(path);
        const focus = editor.end(path);
        if (isCollapsed({ anchor, focus })) {
            insertText(editor, newName, { at: anchor });
        }
        else {
            insertText(editor, newName, { at: { anchor, focus } });
        }
        return true;
    };
    odoEditor.setSectionStatus = (id, { status, statusName }) => {
        const entry = odoEditor.sectionHeadingEntry(id);
        if (!entry) {
            return false;
        }
        const [, path] = entry;
        editor.setNodes({
            // @ts-ignore
            status,
            statusName,
        }, { at: path });
        return true;
    };
    odoEditor.appendSection = () => {
        // Find the last non-empty node
        let lastNonEmptyNodeIndex = editor.children.length - 1;
        while (lastNonEmptyNodeIndex >= 0 &&
            isEmptyContent([editor.children[lastNonEmptyNodeIndex]])) {
            lastNonEmptyNodeIndex--;
        }
        const id = v4();
        editor.insertNodes(
        // @ts-ignore
        [{ type: "h1", id, children: [{ text: "" }] }], { at: [lastNonEmptyNodeIndex + 1] });
        return id;
    };
    odoEditor.insertSection = (beforeId, level) => {
        const range = odoEditor.rangeOfSection(beforeId, true);
        if (!range) {
            return null;
        }
        const id = v4();
        editor.insertNodes(
        // @ts-ignore
        [{ type: `h${level}`, id, children: [{ text: "" }] }], { at: [range.anchor.path[0]] });
        return id;
    };
    odoEditor.deleteSection = (id) => {
        const range = odoEditor.rangeOfSection(id, true);
        if (!range) {
            return false;
        }
        editor.withoutNormalizing(() => {
            if (isCollapsed(range) &&
                range.anchor.path[0] < editor.children.length - 1) {
                // Just delete the range
                editor.delete({ at: range });
            }
            else {
                // This deletes the content until its blank
                editor.delete({ at: range });
                // Delete the blank node
                editor.delete({ at: [range.anchor.path[0]] });
            }
        });
        return true;
    };
    return editor;
};
export default withOdoSectionOverrides;
