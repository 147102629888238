import { FC } from "react";
import {
  useAuthenticatedUser,
  useSetAuthenticatedUser,
} from "providers/AuthenticatedUserProvider";
import { useApiClient } from "providers/ApiClientProvider";
import DeprecatedButton from "components/common/DeprecatedButton";
import { odoToast } from "lib/odoToast";

interface ToggleFavoriteButtonProps {
  listId: string;
}

const ToggleFavoriteButton: FC<ToggleFavoriteButtonProps> = ({ listId }) => {
  const { favoriteListId } = useAuthenticatedUser();
  const setUser = useSetAuthenticatedUser();
  const apiClient = useApiClient();

  const handleToggleFavoriteList = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const originalFavoriteListId = favoriteListId;
    const newFavoriteListId = favoriteListId === listId ? null : listId;

    setUser((user) => {
      if (!user) {
        return null;
      }
      return { ...user, favoriteListId: newFavoriteListId };
    });

    try {
      await apiClient.user.coreUserPartialUpdate({
        favorite_list_id: newFavoriteListId,
      });
      odoToast.success({
        title: "Favorite Updated",
        text: "Your favorite list has been updated",
        cta: {
          text: "Undo",
          onClick: async () => {
            await apiClient.user.coreUserPartialUpdate({
              favorite_list_id: originalFavoriteListId,
            });
            setUser((user) => {
              if (!user) {
                return null;
              }
              return { ...user, favoriteListId: originalFavoriteListId };
            });
          },
        },
      });
    } catch (error) {
      odoToast.caughtError(error, "Updating Favorite List");
      setUser((user) => {
        if (!user) {
          return null;
        }
        return { ...user, favoriteListId: originalFavoriteListId };
      });
    }
  };
  return (
    <DeprecatedButton
      icon="star"
      iconVariant={favoriteListId === listId ? "solid" : "light"}
      tooltip={
        favoriteListId === listId
          ? "Unmark as your favorite list"
          : "Mark as your favorite list"
      }
      onClick={handleToggleFavoriteList}
    />
  );
};

export default ToggleFavoriteButton;
