import { FC } from "react";
import { IconName, IconProps, iconVariants } from "../Icon";
import * as DD from "@radix-ui/react-dropdown-menu";
import { cn } from "lib/utils";
import Columns from "../containers/Columns";

export interface DropdownMenuItemProps {
  icon?: IconName;
  iconVariant?: IconProps["variant"];
  text: string;
  onClick?: () => void;
}

export interface DropdownMenuProps {
  items: DropdownMenuItemProps[];
  children: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
  items,
  children,
  onOpenChange,
}) => {
  return (
    <DD.DropdownMenu onOpenChange={onOpenChange}>
      <DD.DropdownMenuTrigger className="">{children}</DD.DropdownMenuTrigger>
      <DD.DropdownMenuPortal>
        <DD.DropdownMenuContent
          sideOffset={4}
          className={cn(
            "rounded-md bg-popover border drop-shadow-low p-xs mx-sm",
            "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          )}
        >
          {items.map((item) => (
            <DropdownMenuItem key={item.text} {...item} />
          ))}
        </DD.DropdownMenuContent>
      </DD.DropdownMenuPortal>
    </DD.DropdownMenu>
  );
};

const DropdownMenuItem: FC<DropdownMenuItemProps> = ({
  icon,
  iconVariant,
  text,
  onClick,
}) => {
  return (
    <DD.DropdownMenuItem onClick={onClick} asChild={true}>
      <Columns
        className={cn(
          "h-xl rounded-sm text-sm",
          "hover:bg-border-lighter cursor-pointer items-center select-none",
          onClick && "cursor-pointer"
        )}
      >
        {icon && (
          <div
            className={cn(
              "flex items-center justify-center",
              "aspect-square h-full"
            )}
          >
            <i
              className={cn(
                iconVariants({ variant: iconVariant }),
                `fa-${icon}`
              )}
              draggable={false}
            />
          </div>
        )}
        <div className={cn("pr-md", icon ? "" : "pl-sm")}>{text}</div>
      </Columns>
    </DD.DropdownMenuItem>
  );
};

export default DropdownMenu;
