class ChunkBuffer {
  private buffer: string = "";
  private isReading: boolean = true;
  private lastChunkTime: number = Date.now();
  private recentChunkSizes: number[] = [];
  private recentDelays: number[] = [];

  constructor(
    private reader: ReadableStreamDefaultReader<Uint8Array>,
    private textDecoder: TextDecoder,
    private windowSize: number = 5 // Number of samples to keep for averages
  ) {
    this.startReading();
  }

  private async startReading() {
    while (this.isReading) {
      const { done, value } = await this.reader.read();
      if (done) {
        this.isReading = false;
        break;
      }

      const chunk = this.textDecoder.decode(value);
      const now = Date.now();

      // Update statistics
      this.recentChunkSizes.push(chunk.length);
      this.recentDelays.push(now - this.lastChunkTime);
      if (this.recentChunkSizes.length > this.windowSize) {
        this.recentChunkSizes.shift();
        this.recentDelays.shift();
      }

      this.lastChunkTime = now;
      this.buffer += chunk;
    }
  }

  private get averageChunkSize(): number {
    if (this.recentChunkSizes.length === 0) return 0;
    return (
      this.recentChunkSizes.reduce((a, b) => a + b, 0) /
      this.recentChunkSizes.length
    );
  }

  private get averageDelay(): number {
    if (this.recentDelays.length === 0) return 0;
    return (
      this.recentDelays.reduce((a, b) => a + b, 0) / this.recentDelays.length
    );
  }

  public async *consume(
    targetChunkSize: number
  ): AsyncGenerator<string, void, unknown> {
    while (this.isReading || this.buffer.length > 0) {
      if (this.buffer.length >= targetChunkSize) {
        const chunk = this.buffer.slice(0, targetChunkSize);
        this.buffer = this.buffer.slice(targetChunkSize);
        yield chunk;

        // Calculate delay based on statistics, but never wait longer than averageDelay
        const delay = Math.min(
          (targetChunkSize / Math.max(this.averageChunkSize, 1)) *
            this.averageDelay,
          this.averageDelay
        );
        if (delay > 0) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      } else if (!this.isReading) {
        // Return remaining buffer
        if (this.buffer.length > 0) {
          yield this.buffer;
          this.buffer = "";
        }
        break;
      } else {
        // Wait a bit for more data
        await new Promise((resolve) => setTimeout(resolve, 10));
      }
    }
  }
}

export class StreamedResponse {
  private reader: ReadableStreamDefaultReader<Uint8Array>;
  private textDecoder: TextDecoder;
  private response: Response;
  private targetChunkCharSize?: number;

  constructor(response: Response, targetChunkCharSize?: number) {
    this.response = response;
    this.reader = response.body!.getReader();
    this.textDecoder = new TextDecoder();
    this.targetChunkCharSize = targetChunkCharSize;
  }

  public get ok(): boolean {
    return this.response.ok;
  }

  public async *iterateText(): AsyncGenerator<string, void, unknown> {
    try {
      if (this.targetChunkCharSize) {
        const buffer = new ChunkBuffer(this.reader, this.textDecoder);
        yield* buffer.consume(this.targetChunkCharSize);
      } else {
        while (true) {
          const { done, value } = await this.reader.read();
          if (done) break;
          yield this.textDecoder.decode(value);
        }
      }
    } finally {
      this.reader.releaseLock();
    }
  }

  public async getText(): Promise<string> {
    let text = "";
    for await (const chunk of this.iterateText()) {
      text += chunk;
    }
    return text;
  }

  public async json<T>(): Promise<T> {
    const text = await this.getText();
    return JSON.parse(text);
  }

  public async getMessageFromResponse(defaultError: string): Promise<string> {
    try {
      const obj = await this.json<any>();
      if (obj.error) {
        if (typeof obj.error === "string") {
          return obj.error;
        } else {
          if (obj.error.message) {
            if (typeof obj.error.message === "string") {
              return obj.error.message;
            } else {
              return JSON.stringify(obj.error.message);
            }
          } else {
            return JSON.stringify(obj.error);
          }
        }
      } else {
        return JSON.stringify(obj);
      }
    } catch {
      return defaultError;
    }
  }
}
