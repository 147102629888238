import Icon from "../Icon";
import Columns from "../containers/Columns";
import Rows from "../containers/Rows";

export interface RadioListChoice {
  id: string;
  label: string;
}

interface RadioListProps<T extends RadioListChoice> {
  valueId: string | null;
  choices: T[];
  onSelect: (value: T) => void;
  renderExtra?: (value: T) => React.ReactNode;
}

const RadioList = <T extends RadioListChoice>({
  valueId,
  choices,
  onSelect,
  renderExtra,
}: RadioListProps<T>) => {
  return (
    <Rows>
      {choices.map((choice) => (
        <Columns
          key={choice.id}
          className="items-center hover:bg-background-selected pl-xs pr-sm cursor-pointer rounded-sm"
          onClick={() => onSelect(choice)}
        >
          <Icon
            name={choice.id === valueId ? "circle-dot" : "circle"}
            className="p-sm pr-md"
          />
          <p className="grow truncate">{choice.label}</p>
          {renderExtra?.(choice)}
        </Columns>
      ))}
    </Rows>
  );
};

export default RadioList;
