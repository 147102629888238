import { PastProposalList } from "api/Api";
import DeprecatedButton from "components/common/DeprecatedButton";
import Columns from "components/common/containers/Columns";
import { FlowContainerRef } from "components/common/containers/flow-container/FlowContainer";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import Spacer from "components/common/containers/Spacer";
import Toggle from "components/common/forms/Toggle";
import { getLibraryItemInfo } from "components/content-library/ContentLibraryListItem";
import usePaginatedData from "hooks/usePaginatedData";
import { odoToast } from "lib/odoToast";
import { useApiClient } from "providers/ApiClientProvider";
import AddPastProposalOverlay from "../AddPastProposalOverlay";
import { useState } from "react";

export interface ContentItemFlowStepRef {
  refresh: () => void;
}

interface PastProposalFlowStepProps {
  proposalId: string;
  flowRef: FlowContainerRef | null;
  onGoNext?: () => void;
}

const PastProposalFlowStep = ({
  proposalId,
  flowRef,
  onGoNext,
}: PastProposalFlowStepProps) => {
  const apiClient = useApiClient();
  const [addingPastProposal, setAddingPastProposal] = useState(false);
  const [proposals, setProposals, paginatedData] = usePaginatedData({
    endpoint: async (queryParams, options) =>
      await apiClient.user.rfpProposalPastProposalList(
        proposalId,
        queryParams,
        options
      ),
    map: (remote) => remote,
  });

  const toggleItem = async (item: PastProposalList, toValue: boolean) => {
    if (toValue) {
      await apiClient.user.rfpContentLibraryPastProposalAssociationCreate(
        item.id!,
        proposalId
      );
    } else {
      await apiClient.user.rfpContentLibraryPastProposalAssociationDelete(
        item.id!,
        proposalId
      );
    }
    setProposals((prev) => {
      if (!prev) return prev;
      return prev?.map((value, index, array) => {
        if (value.id === item.id) {
          return { ...value, in_proposal: toValue };
        }
        return value;
      });
    });
  };

  const goNext = () => {
    if (onGoNext) {
      onGoNext();
    } else {
      flowRef?.goNext();
    }
  };

  const hasItemInProposal =
    proposals?.some((item) => item.in_proposal) ?? false;

  return (
    <Rows className="grow gap-lg mt-lg w-full">
      <PaginatedTableView
        className="grow w-full mx-auto"
        scrollableClassName="border-t"
        searchMode="search"
        rowSeparators={true}
        results={proposals}
        paginatedData={paginatedData}
        onSelect={async (item) => {
          try {
            const response = await apiClient.user.rfpProposalPastProposalRead(
              proposalId,
              item.id!
            );
            window.open(response.data.view_url!);
          } catch (e) {
            odoToast.caughtError(e, "Viewing Past Proposal");
          }
        }}
        columns={[
          {
            size: "min",
          },
          {},
        ]}
        renderRow={(item, Cell, Row) => {
          const { title, subtitle } = getLibraryItemInfo(item);
          return (
            <Row key={item.id!}>
              <Cell className="pr-0">
                <Toggle
                  toggleType="checkbox"
                  on={item.in_proposal ?? false}
                  onToggle={(on) => toggleItem(item, on)}
                  size="xlarge"
                />
              </Cell>
              <Cell>
                <Rows className="ml-xs">
                  {title ? title : "-"}
                  <div className="text-sm text-secondary">
                    {subtitle ? subtitle : "-"}
                  </div>
                </Rows>
              </Cell>
            </Row>
          );
        }}
        renderNoResultsFound={() => (
          <>
            <Spacer />
            <DeprecatedButton
              icon="plus"
              text="Add Past Proposal"
              className="text-lg w-[260px] mx-auto"
              variant="solid-secondary"
              size="large"
              onClick={() => setAddingPastProposal(true)}
            />
            <Spacer />
          </>
        )}
      />
      <Columns className="items-center gap-md shrink-0">
        {!paginatedData.noResultsFound && (
          <DeprecatedButton
            icon="plus"
            text="Add Past Proposal"
            className="text-primary text-lg"
            size="large"
            onClick={() => setAddingPastProposal(true)}
          />
        )}
        <Spacer />
        {!hasItemInProposal && (
          <DeprecatedButton text="Skip" onClick={goNext} />
        )}
        <DeprecatedButton
          text="Start Proposal"
          disabled={!hasItemInProposal}
          variant="solid"
          size="large"
          className="w-[150px]"
          onClick={goNext}
        />
        {paginatedData.noResultsFound && <Spacer />}
      </Columns>
      {addingPastProposal && (
        <AddPastProposalOverlay
          onClose={() => {
            setAddingPastProposal(false);
            paginatedData.refresh();
          }}
        />
      )}
    </Rows>
  );
};

export default PastProposalFlowStep;
