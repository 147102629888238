import DeprecatedButton from "components/common/DeprecatedButton";
import Icon from "components/common/Icon";
import TypingAnimation from "components/common/TypingAnimation";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Rows from "components/common/containers/Rows";
import { cn } from "lib/utils";
import { FC } from "react";

interface ConnectionLostProps {
  className?: string;
}

const ConnectionLost: FC<ConnectionLostProps> = ({ className }) => {
  return (
    <Rows className="grow bg-background-secondary">
      <CenteredContainer className="bg-background-secondary">
        <div
          className={cn(
            "flex items-center justify-center bg-background-secondary w-full",
            className
          )}
        >
          <div className="bg-background p-4xl rounded flex flex-col gap-md items-center">
            <Icon name="signal-bars-slash" size="xlarge" />
            <h1 className="text-3xl font-semibold">Connection Lost</h1>
            <p className="mb-8 text-center">
              We lost connection to the server.
              <br />
              It should come back soon.
            </p>
            <TypingAnimation
              type="loop"
              options={["Sorry for the delay.", "Hold tight."]}
              className="py-2m"
            />
            <DeprecatedButton
              text="Contact Support"
              variant="solid-secondary"
              className="mx-auto"
              onClick={() => {
                window.open("mailto:support@odo.do");
              }}
            />
          </div>
        </div>
      </CenteredContainer>
    </Rows>
  );
};

export default ConnectionLost;
