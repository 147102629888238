import Rows from "components/common/containers/Rows";

import Button from "components/common/Button";
import { OverlayContent } from "components/common/containers/overlays/Overlay";
import InfoModal from "components/common/modals/InfoModal";
import ConfirmModal from "components/common/modals/ConfirmModal";
import { ModalProps, useModal } from "components/common/modals/useModal";
import { odoToast } from "lib/odoToast";
import { FC } from "react";
import Columns from "components/common/containers/Columns";

interface TestModal<T extends ModalProps<any>> {
  name: string;
  props: Omit<T, "resolve">;
  component: FC<T>;
}

const ModalsPlayground = () => {
  const show = useModal();
  const resolve = (value: any) => {
    odoToast.success({
      title: "Modal resolved",
      text: `Whatever you did will have triggered the modal to dismiss with the result: ${value}`,
    });
  };

  const Modals: TestModal<any>[] = [
    {
      name: "Info",
      component: InfoModal,
      props: {
        message:
          "Convey information to the user where the only action is to accept the information and dismiss the modal.",
      },
    },
    {
      name: "Confirm",
      component: ConfirmModal,
      props: {
        message:
          "Prompt the user to confirm an action. The user can confirm or dismiss the modal. The confirm and cancel text are both customizable.",
      },
    },
    {
      name: "Confirm Destructive",
      component: ConfirmModal,
      props: {
        message:
          "Prompt the user to confirm a destructive action. The user can confirm or dismiss the modal. The confirm and cancel text are both customizable.",
        confirmText: "Delete",
        isDestructive: true,
      },
    },
  ];

  return (
    <div className="grow flex flex-wrap gap-md relative p-md rounded-md border bg-overlay">
      {Modals.map(({ name, component: Component, props }) => (
        <Rows key={name} className="gap-sm max-w-[400px]">
          <OverlayContent
            inDialog={false}
            title={name}
            variant="full-screen-bordered"
          >
            <Component {...props} resolve={resolve} />
          </OverlayContent>
          <Columns className="justify-center">
            <Button
              onClick={async () => {
                const result = await show(
                  Component,
                  { ...props, resolve },
                  {
                    title: name,
                  }
                );
                odoToast.success({
                  title: "Modal resolved",
                  text: `Whatever you did will have triggered the modal to dismiss with the result: ${result}`,
                });
              }}
              text="Trigger"
            />
          </Columns>
        </Rows>
      ))}
    </div>
  );
};

export default ModalsPlayground;
