import { cn } from "lib/utils";
import { PromptVariable } from "./types";
import DeprecatedButton from "components/common/DeprecatedButton";
import { usePromptRefineryContext } from "./PromptRefineryProvider";
import PromptVariableBar from "./PromptVariableBar";
import { useState } from "react";
import VariableManager from "./VariableManager";
import FixtureManager from "./FixtureManager";
import Overlay from "components/common/containers/overlays/Overlay";

interface PromptVariableHeaderProps {
  className?: string;
  onSelectedVariable: (
    variable: PromptVariable,
    as: "text" | "condition"
  ) => void;
}

export const PromptVariableHeader: React.FC<PromptVariableHeaderProps> = ({
  className,
  onSelectedVariable,
}) => {
  const { data } = usePromptRefineryContext();
  const [managingVariables, setManagingVariables] = useState(false);
  const [managingFixtures, setManagingFixtures] = useState(false);

  return (
    <div className={cn("flex gap-md overflow-x-auto p-md", className)}>
      <DeprecatedButton
        icon="layer-group"
        onClick={() => setManagingVariables(true)}
      />
      <PromptVariableBar
        variables={Object.values(data.getVariables())}
        onSelectedVariable={onSelectedVariable}
        onAddVariable={data.addVariable}
      />
      <Overlay
        title="Manage Variables"
        fillWidth={true}
        fillHeight={true}
        scrollable={false}
        open={managingVariables}
        onClose={() => setManagingVariables(false)}
      >
        <VariableManager />
      </Overlay>
      <Overlay
        title="Fixtures"
        maxWidth={600}
        open={managingFixtures}
        onClose={() => setManagingFixtures(false)}
      >
        <FixtureManager dismiss={() => setManagingFixtures(false)} />
      </Overlay>
      <div className="grow" />
      <DeprecatedButton
        icon="flask-vial"
        text="Fixtures"
        onClick={() => setManagingFixtures(true)}
      />
      <DeprecatedButton
        icon="table-columns"
        text="Add Variation"
        onClick={data.addVariation}
      />
    </div>
  );
};

export default PromptVariableHeader;
