import { KEY_SLASH_COMMANDS, SlashCommandItemData } from "../types";
import {
  ComboboxItemProps,
  ComboboxOnSelectItem,
  ComboboxProps,
  Data,
  NoData,
  TComboboxItemWithData,
} from "@udecode/plate-combobox";
import { Combobox } from "components/EditorView/Menus/Combobox";
import { PlateEditor } from "@udecode/plate-common";
import slashCommandItems, {
  SlashCommandMode,
} from "lib/plate/plugins/slash-commands/slashCommandItems";
import { useComboboxSelectors } from "@udecode/plate-combobox";
import { useEditorRef } from "@udecode/plate";
import { useCallback, useEffect, useRef, useState } from "react";
import { Transforms } from "slate";
import AddTableView from "components/EditorView/Menus/AddTableView";
import { comboboxSelectors } from "@udecode/plate-combobox";
import { isSelectionInTable } from "lib/utils";
import Overlay from "components/common/containers/overlays/Overlay";

export interface TSlashCommandComboBox<TData extends Data = NoData>
  extends Partial<ComboboxProps<TData>> {
  pluginKey?: string;
  onSelectItem?: ComboboxOnSelectItem<TData> | null;
}

const SlashCommandComboBoxItem = ({
  item,
}: ComboboxItemProps<SlashCommandItemData>) => {
  return (
    <>
      <i className={`fa-light fa-${item.data.icon}`} />
      <p>{item.text}</p>
    </>
  );
};

const SlashCommandComboBox = ({
  pluginKey = KEY_SLASH_COMMANDS,
  id = pluginKey,
  ...props
}: TSlashCommandComboBox<SlashCommandItemData>) => {
  const editor = useEditorRef();
  const [tableCreationOpen, setTableCreationOpen] = useState(false);
  const activeId = useComboboxSelectors.activeId();
  const [mode, setMode] = useState<SlashCommandMode>("root");
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const apiClient = useApiClient();
  // const { requirementId } = useEditorDocData();
  // const { uploadImage } = useImageUpload(apiClient, requirementId);

  useEffect(() => {
    if (activeId !== pluginKey) {
      return;
    }

    if (isSelectionInTable(editor)) {
      setMode("table");
    } else {
      setMode("root");
    }
  }, [activeId, setMode, editor, pluginKey]);

  const openTableCreation = () => {
    setTableCreationOpen(true);
    const targetRange = comboboxSelectors.targetRange();
    if (!targetRange) return;
    Transforms.delete(editor as any, { at: targetRange });
  };

  const onSelectItem = (
    editor: any,
    item: TComboboxItemWithData<SlashCommandItemData>
  ) => {
    item.data.onSelect(editor as any as PlateEditor<any>);
    const targetRange = comboboxSelectors.targetRange();
    if (!targetRange) return;
    Transforms.delete(editor as any, { at: targetRange });
  };

  const onUploadNewImage = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  // const handleFileChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const file = event.target.files ? event.target.files[0] : null;
  //   if (file) {
  //     // Handle the file upload process here
  //     const url = await uploadImage(file);
  //     if (editor.selection && editor.selection.focus.path[0] > 0) {
  //       const element = editor.node([editor.selection.focus.path[0]])[0];
  //       insertImage(editor, url);
  //       if (isEmptyContent([element])) {
  //         editor.removeNodes({ at: editor.selection });
  //       }
  //     } else {
  //       insertImage(editor, url);
  //     }

  //     // Reset the input
  //     event.target.value = "";
  //   }
  // };

  return (
    <>
      <Combobox<SlashCommandItemData>
        id={pluginKey}
        trigger={"/"}
        // searchPattern={".+"}
        onSelectItem={onSelectItem}
        items={slashCommandItems(openTableCreation, onUploadNewImage, mode)}
        onRenderItem={SlashCommandComboBoxItem}
        {...props}
      />
      {/* <input
        type="file"
        className="hidden"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
      /> */}
      <Overlay
        title="Add Table"
        maxWidth={360}
        open={tableCreationOpen}
        onClose={() => setTableCreationOpen(false)}
      >
        <AddTableView
          isOpen={tableCreationOpen}
          dismiss={() => setTableCreationOpen(false)}
        />
      </Overlay>
    </>
  );
};

export default SlashCommandComboBox;
