import { SavedSearchDetail } from "api/Api";

export const overwriteSavedSearch = (
  prev: SavedSearchDetail,
  update: Partial<SavedSearchDetail>
): SavedSearchDetail => {
  return { ...prev, ...update };
};

const mergeArrays = (
  prev: string[] | undefined,
  update: string[] | undefined
): string[] => {
  return [...new Set([...(prev ?? []), ...(update ?? [])])];
};

export const mergeSavedSearch = (
  current: SavedSearchDetail,
  update: Partial<SavedSearchDetail>
): SavedSearchDetail => {
  return {
    id: current.id,
    naics_codes: mergeArrays(current.naics_codes, update.naics_codes),
    keywords: mergeArrays(current.keywords, update.keywords),
    exclude_keywords: mergeArrays(
      current.exclude_keywords,
      update.exclude_keywords
    ),
    locations: mergeArrays(current.locations, update.locations),
    customers: mergeArrays(current.customers, update.customers),
    due_after: update.due_after ?? current.due_after,
    due_before: update.due_before ?? current.due_before,
    posted_after: update.posted_after ?? current.posted_after,
    posted_before: update.posted_before ?? current.posted_before,
    due_date_range: update.due_date_range ?? current.due_date_range,
    posted_date_range: update.posted_date_range ?? current.posted_date_range,
  };
};
