import { useDraggable } from "@dnd-kit/core";
import { ItemDetails, ItemSearch, PastProposalList } from "api/Api";
import DeprecatedButton from "components/common/DeprecatedButton";
import Columns from "components/common/containers/Columns";
import { CellProps } from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import { Category } from "hooks/useContentLibrary";
import { cn } from "lib/utils";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, useEffect, useMemo, useState } from "react";

interface ContentLibraryListItemProps {
  item: ItemSearch;
  highlight?: boolean;
  activeCategory: Category;
  singularPropertyName: string;
  selectedItemId?: string;
  onSelected: (item: ItemSearch) => void;
  onInsert?: (item: ItemSearch) => Promise<void>;
  onInsertPreview?: (item: ItemSearch | null) => void;
  Cell: FC<CellProps>;
  isFlagged?: boolean;
}

const ContentLibraryListItem: FC<ContentLibraryListItemProps> = ({
  activeCategory,
  item,
  Cell,
  selectedItemId,
  singularPropertyName,
  highlight = false,
  onSelected,
  onInsert,
  onInsertPreview,
  isFlagged = false,
}) => {
  const { title, subtitle } = getLibraryItemInfo(item);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: activeCategory + "_" + item.id,
    data: {},
  });
  const [previewing, setPreviewing] = useState(false);
  const { isStaff } = useAuthenticatedUser();

  useEffect(() => {
    if (previewing) {
      onInsertPreview?.(item);
    } else {
      onInsertPreview?.(null);
    }
  }, [previewing, onInsertPreview, item]);

  let insertButton = useMemo(() => {
    if (!onInsert) return null;

    return (
      <DeprecatedButton
        icon="arrow-left"
        iconVariant="solid"
        variant="outline-full"
        className={cn(
          "text-primary border-primary hover:bg-background rounded",
          highlight && "bg-primary text-background"
        )}
        tooltip="Insert Content"
        onClick={async () => {
          await onInsert(item);
          setPreviewing(false);
        }}
        onMouseEnter={() => setPreviewing(true)}
        onMouseLeave={() => setPreviewing(false)}
      />
    );
  }, [onInsert, item, highlight]);

  return (
    <Cell
      className={cn(
        "px-sm hover:bg-background-selected cursor-pointer min-h-[4em] relative",
        !title && "text-secondary",
        selectedItemId === item.id && "bg-background-selected",
        onInsert && "cursor-grab"
      )}
      onClick={() => onSelected(item)}
      cellRef={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <Columns className="shrink-0 mr-md">{insertButton}</Columns>
      <Rows
        className={cn(
          "ml-xs grow",
          isDragging ? "opacity-20" : "",
          isFlagged && "opacity-40"
        )}
      >
        <div>{title || `New ${singularPropertyName}`}</div>
        <div className="text-sm text-secondary">{subtitle}</div>
      </Rows>
      {isStaff && item.distance && item.distance < 1 && (
        <div className="shrink-0 text-xs text-secondary">
          {Math.round((1 - item.distance) * 1000) / 10}
        </div>
      )}
      {isFlagged && (
        <div className="absolute right-0 text-sm bg-secondary text-background py-xs px-sm rounded-l-sm text-center">
          Already
          <br />
          Used
        </div>
      )}
    </Cell>
  );
};

export const getLibraryItemInfo = (
  item: ItemDetails | PastProposalList | ItemSearch
) => {
  let title: string;
  let subtitle = null;
  switch ("kind" in item ? item.kind : "past_proposal") {
    case "project":
      const project = item as ItemDetails;
      title = project.title ?? "";
      if (project.start_month && project.start_year) {
        title += ` (${project.start_month}/${project.start_year})`;
      }
      if (project.customer && project.location) {
        subtitle = `${project.customer} - ${project.location}`;
      } else if (project.customer) {
        subtitle = project.customer;
      } else if (project.location) {
        subtitle = project.location;
      } else {
        subtitle = "-";
      }
      break;
    case "person":
      const person = item as ItemDetails;
      title = person.name ?? "";
      if (person.title) {
        subtitle = person.title;
      } else {
        subtitle = "-";
      }
      break;
    case "overview":
      const overview = item as ItemDetails;
      title = overview.topic ?? "";
      break;
    case "cover_letter":
      const coverLetter = item as ItemDetails;
      title = coverLetter.topic ?? "";
      break;
    case "subcontractor":
      const subcontractor = item as ItemDetails;
      title = subcontractor.name ?? "";
      break;
    case "past_proposal":
      const pastProposal = item as PastProposalList;
      title = pastProposal.name ?? "";
      break;
    case "approach":
      const approach = item as ItemDetails;
      title = approach.title ?? "";
      break;
  }
  return { title, subtitle };
};

export default ContentLibraryListItem;
