import DeprecatedButton from "components/common/DeprecatedButton";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Spacer from "components/common/containers/Spacer";
import { MultipleLists } from "hooks/useRFPsInList";
import { useTextAreaConfirm } from "providers/AlertProvider";
import { FC } from "react";
import { Link } from "react-router-dom";
import ListActionsButton from "./ListActionsButton";
import ToggleFavoriteButton from "./ToggleFavoriteButton";

interface MultipleListsSplashViewProps {
  multipleLists: MultipleLists;
}

export const MultipleListsSplashView: FC<MultipleListsSplashViewProps> = ({
  multipleLists,
}) => {
  const { lists, addNewList, deleteList, renameList } = multipleLists;
  const textAreaConfirm = useTextAreaConfirm();
  const handleAddNewList = async () => {
    const result = await textAreaConfirm("Add to New List", {
      placeholder: "Enter the name of the new list",
      buttons: [
        {
          text: "Cancel",
          id: "cancel",
        },
        {
          text: "Save",
          id: "save",
          variant: "solid",
        },
      ],
    });

    if (result.id !== "save" || !result.text) {
      return;
    }

    addNewList(result.text);
  };

  const hasMultipleLists = Object.keys(lists).length > 1;

  return (
    <Rows className="gap-md">
      <Columns className="gap-md">
        <Rows>
          <h1 className="text-2xl font-semibold">Shortlisted RFPs</h1>
          <p className="text-secondary">
            Make a go/no-go decision on your shortlisted RFPs
          </p>
        </Rows>
        <Spacer />
        <DeprecatedButton
          icon="plus"
          variant="outline"
          text="Add New List"
          onClick={handleAddNewList}
        />
      </Columns>
      <div className="grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 gap-lg">
        {Object.entries(lists)
          .sort((a, b) => a[1].name.localeCompare(b[1].name))
          .map(([listId, list]) => {
            const numRFPs = Object.keys(list.rfpIds).length;
            return (
              <Link to={`/lists/${listId}`} key={listId}>
                <Rows className="min-h-[150px] bg-background-subtle items-center justify-center gap-xs rounded hover:bg-background-selected transition-colors border relative p-xl text-center">
                  <h2 className="text-xl font-semibold">{list.name}</h2>
                  <p className="text-secondary">
                    {list && numRFPs === 1 ? "1 RFP" : `${numRFPs} RFPs`}
                  </p>
                  {hasMultipleLists && (
                    <div className="absolute top-0 left-0">
                      <ToggleFavoriteButton listId={listId} />
                    </div>
                  )}
                  <div
                    className="absolute top-0 right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ListActionsButton
                      listName={list.name}
                      deleteList={() => deleteList(listId)}
                      renameList={(newName) => renameList(listId, newName)}
                    />
                  </div>
                </Rows>
              </Link>
            );
          })}
      </div>
    </Rows>
  );
};
