import { PlateElement, PlateElementProps, useEditorRef } from "@udecode/plate";
import Reference from "components/common/pdf/Reference";
import React, { useEffect, useState } from "react";
import { useReferenceContext } from "../providers/ReferenceProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { ReactEditor } from "slate-react";

const ReferenceElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ children, style, element, ...props }, ref) => {
  const { id, docId, uniqueId } = element as any as {
    id: string;
    docId: string;
    uniqueId: string;
  };
  const editor = useEditorRef();
  const apiClient = useApiClient();
  const [error, setError] = useState<boolean>(false);
  const { onReferenceClicked, activeReferenceId, refManager } =
    useReferenceContext();

  const handleClick = () => {
    onReferenceClicked?.(id as string);
  };

  useEffect(() => {
    // Fill in the doc id of proposal references
    if (!id.startsWith("P-")) {
      return;
    }
    if (!!docId || error || !uniqueId) {
      return;
    }
    const blockId = id.slice(2);
    const fetchProposalId = async () => {
      try {
        const response = await apiClient.user.rfpPastProposalBlocksRead(
          blockId
        );
        const proposalId = response.data.past_proposal.id;
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        editor.setNodes(
          // @ts-ignore
          { docId: proposalId },
          { at: path }
        );
      } catch (e) {
        console.error(e);
        setError(true);
      }
    };
    fetchProposalId();
  }, [docId, id, apiClient, editor, element, error, uniqueId]);

  return (
    <PlateElement
      ref={ref}
      {...props}
      element={element}
      asChild
      contentEditable={false}
    >
      <>
        {children}
        <Reference
          onClick={handleClick}
          active={id === activeReferenceId}
          color="tertiary"
          contentRef={
            refManager
              ? (htmlElement) => {
                  if (!docId || !uniqueId) {
                    return;
                  }
                  if (htmlElement) {
                    refManager.setReference(docId, uniqueId, {
                      path: ReactEditor.findPath(
                        editor as ReactEditor,
                        element
                      ),
                      element: htmlElement,
                    });
                  } else {
                    refManager.clearReference(docId, uniqueId);
                  }
                }
              : undefined
          }
        >
          {refManager ? ((id as string).startsWith("R-") ? "RFP" : "") : id}
        </Reference>
      </>
    </PlateElement>
  );
});

export default ReferenceElement;
