import { FC } from "react";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import Input from "components/common/forms/Input";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";
import { ItemDetails } from "api/Api";

interface OverviewDetailsViewProps {
  details: ItemDetails;
  setDetails: (details: ItemDetails) => void;
}

const OverviewDetailsView: FC<OverviewDetailsViewProps> = ({
  details,
  setDetails,
}) => {
  return (
    <>
      <LabeledFormInput label="Topic">
        <Input
          placeholder="The topic of this overview"
          value={details.topic ?? ""}
          onChange={(e) => setDetails({ ...details, topic: e.target.value })}
        />
      </LabeledFormInput>
      <LabeledFormInput label="Writeup" className="grow overflow-hidden">
        <CollaborativeTextArea
          placeholder="A detailed writeup of the company focusing on this topic"
          className="h-[600px]"
          docId={details.content_id!}
          key={details.content_id}
        />
      </LabeledFormInput>
    </>
  );
};

export default OverviewDetailsView;
