import { usePlaygroundState } from "hooks/usePlaygroundState";
import ToastsPlayground from "./ToastsPlayground";
import OverlaysPlayground from "./OverlaysPlayground";
import AlertsPlayground from "./AlertsPlayground";
import ButtonPlayground from "./ButtonPlayground";
import ColorsPlayground from "./ColorsPlayground";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import { cn } from "lib/utils";
import ModalsPlayground from "./ModalsPlayground";
import ComponentsPlayground from "./ComponentsPlayground";
import ContainersPlayground from "./ContainersPlayground";

const UIPlaygroundRoute = () => {
  const [activeTab, setActiveTab] = usePlaygroundState<string>("tab", "Colors");

  const tabs = [
    { label: "Colors", content: <ColorsPlayground /> },
    { label: "Buttons", content: <ButtonPlayground /> },
    { label: "Toasts", content: <ToastsPlayground /> },
    { label: "Overlays", content: <OverlaysPlayground /> },
    { label: "Alerts", content: <AlertsPlayground /> },
    { label: "Modals", content: <ModalsPlayground /> },
    { label: "Components", content: <ComponentsPlayground /> },
    { label: "Containers", content: <ContainersPlayground /> },
  ];

  return (
    <Columns>
      <Rows className="border-r mr-lg shrink-0">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            onClick={() => setActiveTab(tab.label)}
            className={cn(
              "text-left px-md py-sm transition-colors hover:bg-background-selected",
              activeTab === tab.label
                ? "bg-background-selected text-foreground font-semibold"
                : "hover:bg-background-selected"
            )}
          >
            {tab.label}
          </button>
        ))}
      </Rows>

      {/* Content area on the right */}
      <Rows className="overflow-y-auto disable-admin grow">
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </Rows>
    </Columns>
  );
};

export default UIPlaygroundRoute;
