import { FC } from "react";
import ProposalTitleBar from "./ProposalTitleBar";
import Rows from "components/common/containers/Rows";
import Portal from "components/common/containers/Portal";

interface ProposalDocOverlayProps {
  open: boolean;
  children?: React.ReactNode;
}

/**
 * A component to display content over top of the entire proposal doc editor
 * underneath the same header
 */
const ProposalDocOverlay: FC<ProposalDocOverlayProps> = ({
  open,
  children,
}) => {
  if (!open) {
    return null;
  }
  return (
    <Portal>
      <Rows className="absolute inset-0 bg-background">
        <ProposalTitleBar className="shrink-0" />
        <Rows className="grow border-t bg-background-secondary">
          {children}
        </Rows>
      </Rows>
    </Portal>
  );
};

export default ProposalDocOverlay;
