import React, { ReactNode, useEffect, useState } from "react";
import DeprecatedButton from "../common/DeprecatedButton";
import { useEditorSelection } from "@udecode/plate-common";
import { Point } from "slate";
import { useActiveComment, useComments } from "providers/CommentsProvider";
import { getCommentText } from "odo";
import {
  TableCell,
  TableHeaderCell,
  TableRow,
} from "components/common/containers/Table";
import { copyToClipboard } from "lib/clipboard";

export interface DebugProps {
  className?: string;
  debugValue: any;
  docId?: string;
  onClose: () => void;
}

const Debug: React.FC<DebugProps> = ({
  debugValue,
  docId,
  className,
  onClose,
}) => {
  const [selectedTab, setSelectedTab] = useState<
    "doc" | "suggestions" | "comments" | "data"
  >("doc");

  const [rawDoc, setRawDoc] = useState<string>("");
  useEffect(() => {
    if (debugValue) {
      setRawDoc(JSON.stringify(normalizedDoc(debugValue), null, 2));
    } else {
      setRawDoc("");
    }
  }, [debugValue]);

  let content: ReactNode;
  switch (selectedTab) {
    case "doc":
      content = <DocContent rawDoc={rawDoc} />;
      break;
    case "suggestions":
      content = <SuggestionsContent />;
      break;
    case "data":
      content = <DataContent />;
      break;
    case "comments":
      content = <CommentsContent />;
      break;
  }

  return (
    <div className="flex flex-col grow border">
      <div className="flex shrink-0 border-b">
        <DeprecatedButton
          text="Doc Content"
          className={selectedTab === "doc" ? "bg-border" : ""}
          onClick={() => setSelectedTab("doc")}
        />
        <DeprecatedButton
          text="Suggestions"
          className={selectedTab === "suggestions" ? "bg-border" : ""}
          onClick={() => setSelectedTab("suggestions")}
        />
        <DeprecatedButton
          text="Comments"
          className={selectedTab === "comments" ? "bg-border" : ""}
          onClick={() => setSelectedTab("comments")}
        />
        <DeprecatedButton
          text="Data"
          className={selectedTab === "data" ? "bg-border" : ""}
          onClick={() => setSelectedTab("data")}
        />
        <div className="grow" />
        <DeprecatedButton icon="xmark" className="pr-sm" onClick={onClose} />
      </div>
      <div className="relative grow overflow-y-scroll">{content}</div>
    </div>
  );
};

const normalizedDoc = (doc: any) => {
  // Replace all "content_embedding" with just the length of the array (if not null)
  const docCopy = JSON.parse(JSON.stringify(doc));
  if (!Array.isArray(docCopy)) {
    return docCopy;
  }
  const output: any[] = [];
  for (const element of docCopy) {
    if (element.contentEmbedding) {
      element.contentEmbedding = element.contentEmbedding.length;
    }
    if (element.children) {
      element.children = normalizedDoc(element.children);
    }
    output.push(element);
  }
  return output;
};

const DocContent: React.FC<{ rawDoc: string }> = ({ rawDoc }) => {
  const handleCopyRawDoc = () => {
    copyToClipboard(rawDoc);
  };

  return (
    <div className="w-full flex p-0 m-0">
      <pre className="p-md w-full whitespace-pre-wrap">{rawDoc}</pre>
      <div className="absolute top-sm right-sm flex gap-lg">
        <DeprecatedButton
          variant="solid"
          icon="copy"
          onClick={handleCopyRawDoc}
          text="Copy"
        />
      </div>
    </div>
  );
};

const SuggestionsContent: React.FC = () => {
  return <div>Not Implemented</div>;
  // const [suggestions] = useSuggestions();
  // return (
  //   <table className="w-full">
  //     <thead>
  //       <TableRow variant="header">
  //         <TableHeaderCell>ID</TableHeaderCell>
  //         <TableHeaderCell variant="minimumWidth">Section</TableHeaderCell>
  //       </TableRow>
  //     </thead>
  //     <tbody>
  //       {Object.keys(suggestions).map((suggestionId) => {
  //         const suggestion = suggestions[suggestionId];
  //         return (
  //           <TableRow
  //             key={suggestionId}
  //             className={suggestion.isActive ? "bg-[yellow]" : ""}
  //           >
  //             <TableCell>{suggestionId}</TableCell>
  //             <TableCell variant="minimumWidth">
  //               {suggestion.section ?? "null"}
  //             </TableCell>
  //           </TableRow>
  //         );
  //       })}
  //     </tbody>
  //   </table>
  // );
};

const DataContent: React.FC = () => {
  const selection = useEditorSelection();

  const pointToString = (point: Point | undefined) => {
    if (!point) {
      return "null";
    }
    return `[${point.path.join(", ")}]:${point.offset}`;
  };

  return (
    <div className="flex gap-lg">
      <div>
        <label>Anchor:</label>
        <pre>{pointToString(selection?.anchor)}</pre>
      </div>
      <div>
        <label>Focus:</label>
        <pre>{pointToString(selection?.focus)}</pre>
      </div>
    </div>
  );
};

const CommentsContent: React.FC = () => {
  const comments = useComments()[1];
  const activeComment = useActiveComment();
  return (
    <table className="w-full">
      <thead>
        <TableRow variant="header">
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Is Pending</TableHeaderCell>
          <TableHeaderCell>Is Resolved</TableHeaderCell>
          <TableHeaderCell variant="minimumWidth">Text</TableHeaderCell>
        </TableRow>
      </thead>
      <tbody>
        {Object.keys(comments).map((commentId) => {
          const comment = comments[commentId];
          return (
            <TableRow
              key={commentId}
              className={comment.id === activeComment ? "bg-[yellow]" : ""}
            >
              <TableCell>{commentId}</TableCell>
              <TableCell>{comment.isPending ? "Yes" : "No"}</TableCell>
              <TableCell>{comment.isResolved ? "Yes" : "No"}</TableCell>
              <TableCell variant="minimumWidth">
                {getCommentText(comment)}
              </TableCell>
            </TableRow>
          );
        })}
      </tbody>
    </table>
  );
};

export default Debug;
