import CoreApi from "../../../api/CoreApi";

export interface CachedValue {
  id: number;
  name: string;
  value: string;
}

export interface CachedVariable {
  id: number;
  name: string;
  values: Record<string, CachedValue> | null;
}
class PromptVariables {
  private apiClient: CoreApi;
  private promptId: number;
  private cache: Record<string, CachedVariable> | null = null;

  constructor(promptId: number, apiClient: CoreApi) {
    this.apiClient = apiClient;
    this.promptId = promptId;
  }

  async listVariables(forceRefresh: boolean = false) {
    if (forceRefresh || this.cache === null) {
      this.cache = await this.fetchVariables();
    }
    return Object.values(this.cache);
  }

  async listValues(variableId: number, forceRefresh: boolean = false) {
    if (forceRefresh || this.cache === null || !this.cache[variableId]) {
      this.cache = await this.fetchVariables();
    }
    if (this.cache[variableId]) {
      const variable = this.cache[variableId];
      if (variable.values !== null) {
        return Object.values(variable.values);
      }
    }
    let variable = await this.fetchVariable(variableId);
    this.cache[variableId] = variable;
    return Object.values(variable.values!);
  }

  async getValue(variableId: number, valueId: number) {
    if (this.cache === null || !this.cache[variableId]) {
      this.cache = await this.fetchVariables();
    }
    if (this.cache[variableId]) {
      const variable = this.cache[variableId];
      if (variable.values !== null) {
        return variable.values[valueId];
      }
    }
    let variable = await this.fetchVariable(variableId);
    this.cache[variableId] = variable;
    return variable.values![valueId];
  }

  private async fetchVariables() {
    const response = await this.apiClient.admin.promptRefineryVariableList();
    var cache: Record<string, CachedVariable> = {};
    for (const variable of response.data) {
      cache[variable.id!] = {
        id: variable.id!,
        name: variable.name,
        values: null,
      };
    }
    return cache;
  }

  private async fetchVariable(variableId: number): Promise<CachedVariable> {
    if (this.cache === null) {
      this.cache = await this.fetchVariables();
    }
    const response = await this.apiClient.admin.promptRefineryVariableValueList(
      variableId.toString()
    );
    let values: Record<string, CachedValue> = {};
    for (const value of response.data) {
      values[value.id!] = {
        id: value.id!,
        name: value.name,
        value: value.value,
      };
    }
    return {
      id: variableId,
      name: this.cache[variableId].name,
      values: values,
    };
  }
}

export default PromptVariables;
