import React from "react";
import { PlateElement, PlateElementProps } from "@udecode/plate-common";

import { cn } from "lib/utils";
import { isEmptyContent } from "odo";
import { useEditorRef } from "@udecode/plate";
import { ReactEditor } from "slate-react";
import { SuggestableElement } from "components/proposals/refine/SuggestableElement";

interface ParagraphElementOptions {
  customPlaceholder?: string;
  customClassName?: string;
  hideOnBlur?: boolean;
  hidePlaceholderWhenNotEmpty?: boolean;
}

// function isBoolean(value: any): value is boolean {
//   return value === true || value === false;
// }

const ParagraphElement = ({
  customPlaceholder,
  hideOnBlur,
  customClassName,
  hidePlaceholderWhenNotEmpty,
}: ParagraphElementOptions = {}) => {
  const Component = React.forwardRef<
    React.ElementRef<typeof PlateElement>,
    PlateElementProps
  >(({ className, children, element, style, ...props }, ref) => {
    const editor = useEditorRef();
    // const isFirstElement = useIsFirstElement(element, editor as any);
    const path = ReactEditor.findPath(editor as any, element);
    let placeholder = "";
    if (path.length <= 2) {
      if (hidePlaceholderWhenNotEmpty) {
        if (customPlaceholder && isEmptyContent(editor.children)) {
          placeholder = customPlaceholder;
        }
      } else {
        placeholder = customPlaceholder ?? "";
      }
    }
    if (
      children.props &&
      children.style &&
      ["checked", "unchecked"].includes(children.style.listStyleType)
    ) {
      children.style.listStyleType = "none";
    }

    // Convert all margins to padding so that overlaying other elements fully hides it
    if (!!style) {
      if (style.marginTop) {
        style.paddingTop = style.marginTop;
        delete style.marginTop;
      }
      if (style.marginBottom) {
        style.paddingBottom = style.marginBottom;
        delete style.marginBottom;
      }
      if (style.marginLeft) {
        style.paddingLeft = style.marginLeft;
        delete style.marginLeft;
      }
      if (style.marginRight) {
        style.paddingRight = style.marginRight;
        delete style.marginRight;
      }
      if (style.margin) {
        style.padding = style.margin;
        delete style.margin;
      }
    }
    return (
      <SuggestableElement
        ref={ref}
        element={element}
        asChild
        {...props}
        className={cn(
          // !!element.listStyleType || isBoolean(element.checked)
          //   ? "pt-thin"
          //   : "pt-sm",
          className,
          // isFirstElement && "pt-0",
          customClassName
        )}
      >
        <div
          style={style}
          className={cn(
            !!element.listStyleType && "pl-2m",
            element.listStyleType === "checked" && "line-through text-secondary"
          )}
        >
          {children}
        </div>
      </SuggestableElement>
    );
  });

  Component.displayName = "ParagraphElement";
  return Component;
};

export { ParagraphElement };
