import {
  DeprecatedDropdownMenu,
  DeprecatedDropdownMenuContent,
  DeprecatedDropdownMenuItem,
  DeprecatedDropdownMenuTrigger,
} from "../../EditorView/Menus/DeprecatedDropdownMenu";

interface ModelSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const ModelSelect: React.FC<ModelSelectProps> = ({ value, onChange }) => {
  const options = [
    "gpt-4",
    "gpt-3.5-turbo",
    "haiku",
    "sonnet",
    "sonnet-3-7",
    "opus",
  ];
  return (
    <DeprecatedDropdownMenu>
      <DeprecatedDropdownMenuTrigger>
        <div className="flex gap-xs items-center px-sm h-lg border rounded-sm text-sm">
          {value ?? "gpt-4"}
        </div>
      </DeprecatedDropdownMenuTrigger>
      <DeprecatedDropdownMenuContent>
        {options.map((option) => (
          <DeprecatedDropdownMenuItem
            key={option}
            onClick={() => onChange(option)}
          >
            {option}
          </DeprecatedDropdownMenuItem>
        ))}
      </DeprecatedDropdownMenuContent>
    </DeprecatedDropdownMenu>
  );
};

export default ModelSelect;
