import { RFPDetail } from "api/Api";
import MessageView from "components/common/containers/MessageView";
import { useListsForRFP } from "providers/ShortListsProvider";
import {
  FC,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import RFPOptionsView from "./RFPOptionsView";
import TypingAnimation from "components/common/TypingAnimation";
import Columns from "components/common/containers/Columns";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import Rows from "components/common/containers/Rows";
import Button, { ButtonProps } from "components/common/Button";
import { useApiClient } from "providers/ApiClientProvider";
import CoreApi from "api/CoreApi";
import { message_from_exception } from "utils";
import MarkdownView from "components/MarkdownView";
import { cn } from "lib/utils";
import RFPPDFView from "./RFPPDFView";
import { OverlayOnSmallScreens } from "components/common/containers/overlays/OverlayOnSmallScreens";
import Scrollable from "components/common/containers/Scrollable";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Spinner from "components/common/Spinner";
import * as Sentry from "@sentry/react";
import { usePDFRef } from "hooks/usePDFRef";
import { useConfirmModal } from "components/common/modals/ConfirmModal";

interface AskQuestionsViewProps {
  rfp: RFPDetail;
  refreshRFP: () => Promise<void>;
}

const AskQuestionsView: FC<AskQuestionsViewProps> = ({ rfp, refreshRFP }) => {
  const { inLists } = useListsForRFP(rfp.id);
  const isShortlisted = Object.keys(inLists).length > 0;

  useEffect(() => {
    if (rfp.questionable_status !== "processing") {
      return;
    }

    let clearTimeoutId: NodeJS.Timeout | undefined;
    const repeatedlyRefreshRFP = () => {
      refreshRFP();
      clearTimeoutId = setTimeout(repeatedlyRefreshRFP, 2000);
    };

    clearTimeoutId = setTimeout(repeatedlyRefreshRFP, 2000);

    return () => clearTimeout(clearTimeoutId);
  }, [rfp.questionable_status, refreshRFP]);

  if (!isShortlisted) {
    return (
      <MessageView
        title="Ask Questions"
        icon="circle-question"
        iconVariant="solid"
      >
        <p className="max-w-[450px] text-center">
          Once on a list, you can ask any qualifying questions about the RFP to
          streamline your go/no go decision.
        </p>
        <RFPOptionsView
          rfp={rfp}
          allowStarting={false}
          exclusiveListId={null}
        />
      </MessageView>
    );
  }

  switch (rfp.questionable_status ?? "not-processing") {
    case "ready":
      return <QuestionForm rfp={rfp} />;
    case "not-processing":
      return <NotProcessingView refreshRFP={refreshRFP} />;
    case "processing":
      return <ProcessingView />;
  }
};

const QuestionForm: FC<{ rfp: RFPDetail }> = ({ rfp }) => {
  const [question, setQuestion] = useState("");
  const apiClient = useApiClient();
  const [answer, setAnswer] = useState("");
  const [isWaitingForAnswer, setIsWaitingForAnswer] = useState(false);
  const [isOdoWriting, setIsOdoWriting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUneditedQuestion, setHasUneditedQuestion] = useState(false);
  const textAreaRef = useRef<HTMLDivElement>(null);
  const [referenceBlockIds, setReferenceBlockIds] = useState<string[]>([]);
  const [activeBlockId, setActiveBlockId] = useState<string | null>(null);
  const { ref: pdfViewRef, scrollToElement } = usePDFRef();

  // Separate state to start the width transition
  const [isInitialShowingRFP, setIsInitialShowingRFP] = useState(false);
  // Final flag for showing the RFP (controls the opacity transition)
  const [isShowingRFP, setIsShowingRFP] = useState(false);
  const [hasAnsweredAtLeastOnce, setHasAnsweredAtLeastOnce] = useState(false);
  const [isShowingOverlay, setIsShowingOverlay] = useState(false);
  const confirm = useConfirmModal();

  useEffect(() => {
    if (!(isOdoWriting || isWaitingForAnswer || answer.length > 0)) {
      return;
    }
    setIsInitialShowingRFP(true);
    setTimeout(() => {
      setIsShowingRFP(true);
    }, 300);
  }, [isWaitingForAnswer, answer, isOdoWriting]);

  const handleAsk = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setIsWaitingForAnswer(true);
      const response = await apiClient.postStreamingResponse(
        `/rfp/user/rfp/${rfp.id}/answer/`,
        {
          question,
        }
      );
      if (!response.ok) {
        setError(
          await CoreApi.messageFromResponse(
            response,
            "An error occurred while running the prompt."
          )
        );
        return;
      }

      setAnswer("");
      setIsWaitingForAnswer(false);
      setIsOdoWriting(true);
      for await (const newText of response.iterateText()) {
        setAnswer((prev) => prev + newText);
      }
      setIsOdoWriting(false);
      setHasUneditedQuestion(true);
      setHasAnsweredAtLeastOnce(true);
    } catch (e) {
      setError(message_from_exception(e));
    } finally {
      setIsLoading(false);
      setIsWaitingForAnswer(false);
    }
  };

  const handleQuestionKeydown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Enter") {
      handleAsk();
      e.preventDefault();
    }
  };

  const handleReferenceIdsChanged = useCallback(
    (ids: string[]) => {
      setReferenceBlockIds(ids);
      if (ids.length === 1) {
        setActiveBlockId(ids[0]);
        scrollToElement(ids[0]);
      }
    },
    [scrollToElement]
  );

  const handleReferenceClick = useCallback(
    async (reference: string) => {
      if (!rfp.view_url) {
        const results = await confirm({
          title: "View Reference",
          message:
            "You need to subscribe to see where in the RFP the answer is coming from",
          confirmText: "Book Demo",
        });
        if (results) {
          window.open("https://odo.do/book-demo/?source=ask", "_blank");
        }
        return;
      }
      setActiveBlockId(reference);
      setIsShowingOverlay(true);
      scrollToElement(reference);
    },
    [scrollToElement]
  );

  return (
    <Columns className="grow px-md gap-md w-full justify-center">
      <Scrollable className="max-w-[800px] w-full grow basis-0">
        <Rows className="w-full gap-md my-lg">
          <p>
            Ask any qualifying questions about the RFP to streamline your go/no
            go decision
          </p>
          <QAView
            label="Q"
            disabled={isWaitingForAnswer || isOdoWriting}
            buttonProps={
              hasUneditedQuestion
                ? {
                    text: "Ask Another",
                    icon: "circle-xmark",
                    emphasis: "primary",
                    onClick: () => {
                      setQuestion("");
                      setHasUneditedQuestion(false);
                      textAreaRef.current?.focus();
                    },
                  }
                : {
                    text: hasAnsweredAtLeastOnce ? "Ask Another" : "Ask",
                    emphasis: "primary",
                    icon: "paper-plane",
                    iconVariant: "solid",
                    disabled: question.length === 0,
                    isLoading: isLoading,
                    onClick: handleAsk,
                    metricsId: "rfp_question_asked",
                  }
            }
          >
            <NaturalHeightTextArea
              className="grow min-h-[60px] text-sm border-none px-none py-none rounded-none"
              placeholder="What is the expected project delivery date?"
              ref={textAreaRef}
              value={question}
              disabled={isWaitingForAnswer || isOdoWriting}
              onChange={(newText) => {
                setQuestion(newText);
                setHasUneditedQuestion(false);
              }}
              onKeyDown={handleQuestionKeydown}
            />
          </QAView>
          <QAView
            label="A"
            hidden={isWaitingForAnswer || answer.length === 0}
            children={
              <MarkdownView
                markdown={answer}
                showCursor={isOdoWriting}
                onReferenceIdsChanged={handleReferenceIdsChanged}
                activeReferenceIds={activeBlockId ? [activeBlockId] : []}
                onReferenceClick={handleReferenceClick}
              />
            }
          />
          {error && (
            <Rows className="bg-accent rounded-md px-2m py-md gap-xs">
              <h2 className="text-destructive text-md font-semibold">
                Error Answering
              </h2>
              <p>{error}</p>
              <p>Please try again.</p>
            </Rows>
          )}
        </Rows>
      </Scrollable>
      <OverlayOnSmallScreens
        minWidth={1100}
        title="Reference"
        open={isShowingOverlay}
        forceRender={true}
        variant="full-screen-bordered"
        onClose={() => {
          setIsShowingOverlay(false);
          setActiveBlockId(null);
        }}
        fillWidth={true}
        scrollable={false}
        onOpen={() => {
          if (activeBlockId) {
            setTimeout(() => {
              // Wait for the PDF to be showing (after having set the active block ID)
              scrollToElement(activeBlockId);
            }, 0);
          }
        }}
      >
        <Rows
          className="grow basis-0 w-full transition-[max-width] border-y bg-border"
          style={{
            maxWidth: isInitialShowingRFP ? 1000 : 0,
          }}
        >
          {rfp.view_url ? (
            <RFPPDFView
              ref={pdfViewRef}
              rfpId={rfp.id}
              fileUrl={rfp.view_url}
              className="grow transition-opacity pdf-view"
              style={{
                opacity: isShowingRFP ? 1 : 0,
              }}
              referenceBlocks={referenceBlockIds.map((id) => ({ id }))}
              activeBlockId={activeBlockId}
              setActiveBlockId={setActiveBlockId}
            />
          ) : (
            <MessageView title="View References" icon="magnifying-glass">
              <p className="max-w-[300px] text-center">
                You need to subscribe to see where in the RFP the answer is
                coming from.
              </p>
              <Button
                text="Book a Demo"
                emphasis="primary"
                onClick={() => {
                  window.open("https://odo.do/book-demo/?source=ask", "_blank");
                }}
              />
            </MessageView>
          )}
        </Rows>
      </OverlayOnSmallScreens>
    </Columns>
  );
};

interface QAViewProps {
  label: string;
  children?: ReactNode;
  buttonProps?: ButtonProps;
  hidden?: boolean;
  disabled?: boolean;
}

const QAView: FC<QAViewProps> = ({
  label,
  children,
  buttonProps,
  hidden,
  disabled,
}) => {
  return (
    <Columns
      className={cn(
        "px-2m py-sm border rounded-md gap-xs transition-opacity",
        hidden ? "opacity-0" : "opacity-100",
        disabled && "opacity-50"
      )}
    >
      <h1 className="text-3xl font-semibold">{label}</h1>
      <Rows className="grow ml-sm mt-sm mb-sm overflow-visible">
        {children}
        {buttonProps && (
          <Columns className="grow justify-end mt-md shrink-0">
            <Button {...buttonProps} />
          </Columns>
        )}
      </Rows>
    </Columns>
  );
};

interface NotProcessingViewProps {
  refreshRFP: () => Promise<void>;
}

const NotProcessingView: FC<NotProcessingViewProps> = ({ refreshRFP }) => {
  const [attemptCount, setAttemptCount] = useState(0);
  const maxAttempts = 10;

  useEffect(() => {
    if (attemptCount >= maxAttempts) {
      return;
    }

    const timeoutId = setTimeout(async () => {
      await refreshRFP();
      setAttemptCount((prev) => prev + 1);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [refreshRFP, attemptCount]);

  useEffect(() => {
    if (attemptCount >= maxAttempts) {
      Sentry.captureMessage("RFP is not processing after 10 attempts");
    }
  }, [attemptCount]);

  if (attemptCount < maxAttempts) {
    return (
      <CenteredContainer>
        <Spinner />
      </CenteredContainer>
    );
  }

  return (
    <MessageView
      title="Not Processing"
      icon="circle-exclamation"
      iconVariant="light"
    >
      <p>This RFP is not currently being processed. Please try again later.</p>
    </MessageView>
  );
};

const ProcessingView: FC = () => {
  return (
    <MessageView
      title="Processing RFP"
      icon="hourglass-half"
      iconVariant="solid"
    >
      <TypingAnimation
        options={["Reading RFP line by line", "Analyzing the content"]}
      />
    </MessageView>
  );
};

export default AskQuestionsView;
