import * as Y from "yjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { get_hub_url } from "api/env";
import { slateNodesToInsertDelta, yTextToSlateElement } from "@slate-yjs/core";
import Rows from "components/common/containers/Rows";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Spacer from "components/common/containers/Spacer";
import { odoToast } from "lib/odoToast";
import { useConfirmModal } from "components/common/modals/ConfirmModal";
import Scrollable from "components/common/containers/Scrollable";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import TextArea from "components/common/forms/TextArea";

interface Node {
  initialJson: string;
  yText: any;
}

const ProposalDebug = () => {
  const { proposalId } = useParams();

  const providerRef = useRef<HocuspocusProvider | null>(null);
  const [slateNodes, setSlateNodes] = useState<Node[]>([]);
  const [error, setError] = useState<string | null>(null);

  const reloadWorkingNodes = useCallback(() => {
    if (!providerRef.current) {
      return;
    }

    const doc = providerRef.current.document;
    const content = doc.get("content", Y.XmlText) as any;
    const delta = content.toDelta();
    const nodes: Node[] = [];
    for (const node of delta) {
      const slateElement = yTextToSlateElement(node.insert);
      nodes.push({
        yText: node.insert,
        initialJson: JSON.stringify(slateElement, null, 2),
      });
    }
    setSlateNodes(nodes);
  }, []);

  useEffect(() => {
    const doc = new Y.Doc();
    const provider = new HocuspocusProvider({
      url: get_hub_url("ws", ""),
      name: "prop-" + proposalId,
      token: "insufficient-secret",
      document: doc,
      preserveConnection: false,
      awareness: null,
      onSynced: () => {
        reloadWorkingNodes();
        setError(null);
      },
      onAuthenticationFailed: () => {
        setError("Error Loading Document. Check the proposal id is correct");
      },
    });

    providerRef.current = provider;

    return () => {
      provider.destroy();
    };
  }, [proposalId, reloadWorkingNodes]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!slateNodes) {
    return <div>Loading...</div>;
  }

  return (
    <Rows className="grow gap-lg">
      <h1>Proposal Debug</h1>
      <Scrollable className="grow">
        <Rows className="gap-lg">
          {slateNodes.map((node: Node, index: number) => (
            <NodeEditor key={index} node={node} index={index} />
          ))}
        </Rows>
      </Scrollable>
    </Rows>
  );
};

interface NodeEditorProps {
  node: Node;
  index: number;
}

const NodeEditor = ({ node, index }: NodeEditorProps) => {
  const [json, setJson] = useState<string | null>(node.initialJson);
  const confirm = useConfirmModal();
  const [editing, setEditing] = useState(false);

  const reloadJson = useCallback(() => {
    // setJson(JSON.stringify(yTextToSlateElement(node.yText), null, 4));
  }, [node.yText]);

  const discardChanges = useCallback(async () => {
    const result = await confirm({
      title: "Discard Changes",
      message: "Are you sure you want to discard the changes?",
      confirmText: "Discard",
      isDestructive: true,
    });
    if (!result) return;
    setEditing(false);
    reloadJson();
  }, [reloadJson, confirm]);

  const applyChanges = useCallback(async () => {
    try {
      const slateNodes = JSON.parse(json ?? "[]");
      const result = await confirm({
        title: "Apply Changes",
        message: "Are you sure you want to apply the changes?",
        confirmText: "Apply",
      });
      if (!result) return;
      const insertDelta = slateNodesToInsertDelta([slateNodes]) as any;
      node.yText.delete(0, node.yText.length);
      node.yText.applyDelta(insertDelta);
      odoToast.success({
        title: "Success",
        text: "Changes applied",
      });
      setEditing(false);
    } catch (e) {
      odoToast.caughtError(e);
    }
  }, [confirm, json, node.yText]);

  return (
    <Columns className="gap-md">
      <div>{index}</div>
      <Rows className="grow gap-md">
        {editing ? (
          <NaturalHeightTextArea
            value={json ?? ""}
            disabled={!json}
            onChange={setJson}
          />
        ) : (
          <div className="whitespace-pre-wrap">{json}</div>
        )}
        <Columns className="gap-md">
          {!editing ? (
            <Button
              text="Edit"
              emphasis="primary"
              onClick={() => setEditing(true)}
            />
          ) : (
            <>
              <Button
                text="Discard"
                emphasis="destructive"
                onClick={discardChanges}
              />
              <Spacer />
              <Button text="Apply" emphasis="primary" onClick={applyChanges} />
            </>
          )}
        </Columns>
      </Rows>
    </Columns>
  );
};

export default ProposalDebug;
