import {
  PlateElement,
  PlateElementProps,
  useEditorRef,
} from "@udecode/plate-common";
import { cn } from "lib/utils";
import React, { useEffect, useRef, useState } from "react";
import { createCorePlugins, getCommentIdFromKey, getCommentKeys } from "odo";
import { Plate, PlateContent } from "@udecode/plate";
import { OdoEditor, OdoValue } from "odo";
import components from "lib/plate/components";
import transformIndentListNodeData from "../../../lib/plate/plugins/lists/transformIndentListNodeData";
import { useUpdateCommentMarkData } from "providers/CommentsProvider";
import { v4 } from "uuid";
import { setElement } from "providers/DocElementRefProvider";

const RefineElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ className, children, ...props }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const editor = useEditorRef();
  const { element } = props;
  const [markId] = useState(v4().toString());

  const updateCommentMarkData = useUpdateCommentMarkData();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.zIndex = "inherit";
    }
  }, [contentRef]);

  useEffect(() => {
    const id = element.id as string;
    if (!id) {
      return;
    }
    if (containerRef.current) {
      setElement(editor, id, containerRef.current);
    }

    return () => {
      setElement(editor, id, null);
    };
  }, [editor, element.id]);

  useEffect(() => {
    if (element.comment !== true || !containerRef.current) {
      return;
    }

    const keys = getCommentKeys(element);
    if (keys.length === 0) {
      return;
    }

    const commentIds = keys.map((key) => getCommentIdFromKey(key));
    for (const id of commentIds) {
      updateCommentMarkData(id, markId, containerRef);
    }

    return () => {
      for (const id of commentIds) {
        updateCommentMarkData(id, markId, null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.comment, markId, updateCommentMarkData, contentRef]);

  return (
    <PlateElement
      asChild
      ref={ref}
      className={cn("mx-4xl", className)}
      {...props}
    >
      <div className="grid grid-cols-[1fr] mt-md" ref={containerRef}>
        <div className="row-start-1 col-start-1 relative text-secondary">
          <Plate<OdoValue, OdoEditor>
            id="nested"
            plugins={createCorePlugins(components, transformIndentListNodeData)}
            initialValue={props.element.old as any}
          >
            <PlateContent id="nested" ref={contentRef} />
          </Plate>
        </div>
        <div className="row-start-1 col-start-1 text-primary relative">
          <div className="bg-background">{children}</div>
        </div>
      </div>
    </PlateElement>
  );
});

export default RefineElement;
