import { RFPAllBlock } from "api/Api";
import { PDFDocumentProxy } from "pdfjs-dist";

/**
 * A layout for a single page in the PDF view.
 *
 * The coordinates assume absolute positioning within the doc
 * but are expressed in normalized coordinates (0-1) relative
 * to the document width. (to render the actual document and pages
 * we need to multiply each number by the width)
 *
 * Each page is always rendered to be the full width of the container
 */
interface PageLayout {
  aspectRatio: number;
}

/**
 * A layout for the entire PDF document.
 *
 * This is used to layout the pages in the document.
 */
export interface PDFLayout {
  pages: PageLayout[];
}

export interface ConcretePageLayout {
  top: number;
  left: number;
  height: number;
  width: number;
}

export interface ConcretePDFLayout {
  width: number;
  height: number;
  pages: ConcretePageLayout[];
}

/**
 * Calculate the layout of the PDF document independently of the container size
 */
export const calculateLayout = async (
  doc: PDFDocumentProxy
): Promise<PDFLayout> => {
  // Get the size of each page
  const pages: PageLayout[] = [];
  const pageCount = doc.numPages;
  for (let i = 0; i < pageCount; i++) {
    const page = await doc.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1.0 });
    const width = viewport.width;
    const height = viewport.height;
    pages.push({ aspectRatio: width / height });
  }
  return { pages };
};

/**
 * Calculate the layout of the PDF document based on the container size
 */
export const solidifyLayout = (
  abstractLayout: PDFLayout,
  containerWidth: number,
  marginLeft: number = 12,
  marginRight: number = 12,
  gap: number = 12
): ConcretePDFLayout => {
  const pages: ConcretePageLayout[] = [];
  let currentTop = gap;
  for (const { aspectRatio } of abstractLayout.pages) {
    const top = currentTop;
    const width = containerWidth - marginLeft - marginRight;
    const height = width / aspectRatio;
    pages.push({ top, height, width, left: marginLeft });
    currentTop += height + gap;
  }

  return {
    width: containerWidth,
    height: currentTop,
    pages,
  };
};

export interface ConcreteBlockLayout {
  top: number;
  left: number;
  width: number;
  height: number;
}

/**
 * Get the layout of a specific block
 */
export const getBlockLayout = (
  block: RFPAllBlock,
  layout: ConcretePDFLayout
): ConcreteBlockLayout | null => {
  const page = layout.pages[block.page - 1];
  if (!page) {
    return null;
  }
  return {
    top: page.top + block.bbox.top * page.height,
    left: page.left + block.bbox.left * page.width,
    width: block.bbox.width * page.width,
    height: block.bbox.height * page.height,
  };
};
