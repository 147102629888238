import DeprecatedButton from "../../common/DeprecatedButton";
import { FC } from "react";
import { cn } from "../../../lib/utils";
import { usePromptRefineryContext } from "./PromptRefineryProvider";
import ModelSelect from "./ModelSelect";
import { useConfirm } from "providers/AlertProvider";

interface PromptRefineryFooterProps {
  className?: string;
  onRun: () => void;
  isLoading: boolean;
  variationId: number | null;
  active: boolean;
}

const PromptRefineryFooter: FC<PromptRefineryFooterProps> = ({
  className,
  isLoading,
  variationId,
  active,
  onRun,
}) => {
  const confirm = useConfirm();
  const { data } = usePromptRefineryContext();

  const confirmDelete = async (variationId: number) => {
    if (await confirm("Are you sure you want to delete this variation?")) {
      data.removeVariation(variationId);
    }
  };

  return (
    <div className={cn("flex gap-lg p-md", className)}>
      <DeprecatedButton
        icon="star"
        size="small"
        iconVariant={active ? "solid" : "light"}
        className={cn("text-primary", active && "pointer-events-none")}
        onClick={() => data.setActiveVariation(variationId)}
      />
      <div className="flex-grow" />
      <ModelSelect
        value={data.data.variationModel[variationId ?? -1]}
        onChange={(value) => data.setModel(variationId ?? -1, value)}
      />
      <div className="w-[80px]">
        <DeprecatedButton
          className="w-full"
          icon="play"
          text="Run"
          size="small"
          variant="solid"
          onClick={onRun}
          isLoading={isLoading}
        />
      </div>
      {variationId && (
        <DeprecatedButton
          icon="trash"
          onClick={() => confirmDelete(variationId)}
          size="small"
        />
      )}
      <div className="flex-grow" />
    </div>
  );
};

export default PromptRefineryFooter;
