import useYjsMap from "api/useYjsMap";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useAuthenticatedUser,
  useSetAuthenticatedUser,
} from "./AuthenticatedUserProvider";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { get_hub_url } from "api/env";

import * as Y from "yjs";
import { useNavigate } from "react-router-dom";
import { odoToast } from "lib/odoToast";
import { ShortList, listNameToId } from "odo";
import useBeforeUnload from "hooks/useBeforeUnload";
import { useApiClient } from "./ApiClientProvider";

export type { ShortList };

/**
 * The status of the short lists connection
 *
 * - connecting: The initial status, when the connection is first connecting
 * - connected: The connection is established and the lists are being synced
 * - reconnecting: The connection is lost and the lists are being reconnected
 *   (But the initial data has been loaded)
 * - authentication_failure: The connection is closed because the authentication failed
 * - closed: The connection ran into an initial failure, but it will continue to attempt to
 *   reconnect
 */
export type ShortListsConnectionStatus =
  | "connecting"
  | "connected"
  | "reconnecting"
  | "authentication_failure"
  | "closed";

interface ShortListsProviderData {
  lists: Record<string, ShortList>;
  deleteList: (listId: string) => void;
  addRFPToList: (rfpId: string, listName: string) => void;
  addRFPToNewList: (rfpId: string, listName: string) => void;
  removeRFPFromList: (
    rfpId: string,
    listId: string,
    afterStarting?: boolean
  ) => void;
  renameList: (listId: string, newName: string) => boolean;
  addNewList: (listName: string) => string;
  connectionStatus: ShortListsConnectionStatus;
  hasPendingChanges: boolean;
  refreshConnection: () => void;
}

const ShortListsContext = createContext<ShortListsProviderData | null>(null);

export const DEFAULT_LIST: ShortList = {
  name: "List 1",
  rfpIds: {},
};

export const ShortListsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const providerRef = useRef<HocuspocusProvider | null>(null);
  const delayedStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const delayedLogoutTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { orgId, favoriteListId } = useAuthenticatedUser();
  const navigate = useNavigate();
  // Tracks the current status of the provider
  const [providerStatus, setProviderStatus] = useState<
    // The default status before anything happens
    | "connecting"
    // Upon first connecting, it attempt to authenticate
    | "authenticating"
    // The authentication attempt has failed
    | "authentication_failure"
    // The authentication attempt has succeeded and we're fully connected
    | "authenticated"
    // The connection is closed, whenever the connection is dropped
    | "closed"
  >("connecting");
  // Tracks if the data has ever been synced (only want to show as actually connect
  // if it has synced at least one))
  const [hasLoadedInitialData, setHasLoadedInitialData] = useState(false);
  const [overallStatus, setOverallStatus] =
    useState<ShortListsConnectionStatus>("connecting");
  const [isSynced, setIsSynced] = useState(false);
  const apiClient = useApiClient();
  const setUser = useSetAuthenticatedUser();

  const [lists, setLists, hasPendingChanges] = useYjsMap<ShortList>(
    providerRef.current,
    "short-lists",
    {},
    false
  );

  useBeforeUnload((e) => {
    if (!isSynced && hasLoadedInitialData && hasPendingChanges) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    const doc = new Y.Doc();
    const provider = new HocuspocusProvider({
      url: get_hub_url("ws", ""),
      name: "org-" + orgId,
      token: "insufficient-secret",
      document: doc,
      preserveConnection: false,
      awareness: null,
      onSynced: ({ state }) => {
        setTimeout(() => {
          // Wait until the next render loop to set this because the event
          // comes in at the same time as the actual data. Waiting allows
          // the data to actually be set before downstream code is told
          // the data is ready
          if (state) {
            setHasLoadedInitialData(true);
          }
          setIsSynced(state);
        }, 0);
      },
      onAuthenticated() {
        if (delayedStatusTimeoutRef.current) {
          clearTimeout(delayedStatusTimeoutRef.current);
          delayedStatusTimeoutRef.current = null;
        }
        setProviderStatus("authenticated");
        if (delayedLogoutTimeoutRef.current) {
          clearTimeout(delayedLogoutTimeoutRef.current);
          delayedLogoutTimeoutRef.current = null;
        }
      },
      onAuthenticationFailed(e: any) {
        if (delayedStatusTimeoutRef.current) {
          clearTimeout(delayedStatusTimeoutRef.current);
          delayedStatusTimeoutRef.current = null;
        }
        setProviderStatus("authentication_failure");
        setTimeout(() => {
          providerRef.current?.connect();
        }, 5000);
        if (!delayedLogoutTimeoutRef.current) {
          delayedLogoutTimeoutRef.current = setTimeout(() => {
            window.location.reload();
          }, 10000);
        }
      },
      onClose(e) {
        if (delayedStatusTimeoutRef.current) {
          clearTimeout(delayedStatusTimeoutRef.current);
        }
        // Delay marking the connection as closed to allow for the connection to be
        // reset periodically
        delayedStatusTimeoutRef.current = setTimeout(() => {
          setProviderStatus("closed");
        }, 2000);
      },
      onOpen(e) {
        if (delayedStatusTimeoutRef.current) {
          clearTimeout(delayedStatusTimeoutRef.current);
          delayedStatusTimeoutRef.current = null;
        }
        // Delay marking this connection status as it is almost always a very quuick
        // transitory state before authenticating and/or failing
        // This is particularly notable during connection rests
        delayedStatusTimeoutRef.current = setTimeout(() => {
          setProviderStatus("authenticating");
        }, 2000);
      },
    });

    providerRef.current = provider;

    return () => {
      if (delayedStatusTimeoutRef.current) {
        clearTimeout(delayedStatusTimeoutRef.current);
      }
      provider.destroy();
    };
  }, [orgId]);

  useEffect(() => {
    switch (providerStatus) {
      case "connecting":
      case "authenticating":
        if (hasLoadedInitialData) {
          if (overallStatus !== "reconnecting")
            setOverallStatus("reconnecting");
        } else {
          if (overallStatus !== "connecting") setOverallStatus("connecting");
        }
        break;
      case "authenticated":
        if (hasLoadedInitialData) {
          if (overallStatus !== "connected") setOverallStatus("connected");
        } else {
          // If we haven't loaded the initial data, we want to wait to report
          // as fully connected
          if (overallStatus !== "connecting") setOverallStatus("connecting");
        }
        break;
      case "authentication_failure":
        if (overallStatus !== "authentication_failure")
          setOverallStatus("authentication_failure");
        break;
      case "closed":
        if (hasLoadedInitialData) {
          if (overallStatus !== "reconnecting")
            setOverallStatus("reconnecting");
        } else {
          if (overallStatus !== "closed") setOverallStatus("closed");
        }
        break;
    }
  }, [providerStatus, hasLoadedInitialData, overallStatus]);

  const requireHasLoadedInitialData = useCallback(() => {
    if (!hasLoadedInitialData) {
      odoToast.error({
        icon: "signal-bars-slash",
        title: "Connection Lost",
        text: "We lost connection to the server but it should be restored shortly. Please try again.",
      });
      return false;
    }
    return true;
  }, [hasLoadedInitialData]);

  const addRFPToList = useCallback(
    (rfpId: string, listId: string) => {
      if (!requireHasLoadedInitialData()) return;
      const list = { ...lists[listId] };
      list.rfpIds[rfpId] = true;
      setLists(listId, list);
      odoToast.success({
        title: "RFP Added",
        text: `This RFP can now be found on ${list.name}`,
        cta: {
          text: "View List",
          onClick: () => {
            navigate(`/lists/${listId}/`);
          },
          undo: {
            text: "Back",
            onClick: () => {
              navigate(-1);
            },
          },
        },
      });
      apiClient.user
        .rfpListsAddedCreate(listId, {
          rfp_id: rfpId,
          list_name: list.name,
        })
        .catch(() => {
          console.error("Failed to add report RFP added to list");
        });
    },
    [lists, navigate, setLists, requireHasLoadedInitialData]
  );

  const addRFPToNewList = useCallback(
    (rfpId: string, listName: string) => {
      if (!requireHasLoadedInitialData()) return;
      const listId = listNameToId(listName);
      setLists(listId, {
        ...DEFAULT_LIST,
        name: listName,
        rfpIds: { [rfpId]: true },
      });
      odoToast.success({
        title: "RFP Added",
        text: `This RFP can now be found on ${listName}`,
        cta: {
          text: "View List",
          onClick: () => {
            navigate(`/lists/${listId}/`);
          },
        },
      });
      apiClient.user
        .rfpListsAddedCreate(listId, {
          rfp_id: rfpId,
          list_name: listName,
        })
        .catch(() => {
          console.error("Failed to report RFP added to list");
        });
    },
    [setLists, navigate, requireHasLoadedInitialData]
  );

  const addNewList = useCallback(
    (listName: string) => {
      if (Object.keys(lists).length === 0 && listName !== DEFAULT_LIST.name) {
        // Special Case: If there are no lists and the user is trying to add a new list,
        // we need to add the default list as well (it's only possible to add a new list)
        // from the lists page which is faking the default list being there)
        setLists(listNameToId(DEFAULT_LIST.name), DEFAULT_LIST);
      }

      const listId = listNameToId(listName);
      setLists(listId, { ...DEFAULT_LIST, name: listName });
      odoToast.success({
        title: "List Created",
        text: `A list named ${listName} has been created`,
        cta: {
          text: "View List",
          onClick: () => {
            navigate(`/lists/${listId}/`);
          },
        },
      });
      return listId;
    },
    [setLists, navigate, requireHasLoadedInitialData]
  );

  const renameList = useCallback(
    (listId: string, newName: string) => {
      if (!requireHasLoadedInitialData()) return false;
      const list = { ...lists[listId] };
      const originalName = list.name;
      list.name = newName;
      const newId = listNameToId(newName);
      if (lists[newId]) {
        odoToast.error({
          title: "List Already Exists",
          text: `A list named ${newName} already exists`,
        });
        return false;
      }
      setLists(newId, list);
      setLists(listId, null);
      if (listId === favoriteListId) {
        apiClient.user
          .coreUserPartialUpdate({
            favorite_list_id: newId,
          })
          .then(() => {
            setUser((prev) => {
              if (!prev) return null;
              return { ...prev, favoriteListId: newId };
            });
          });
      }
      odoToast.success({
        title: "List Renamed",
        text: `This list has been renamed to ${newName}`,
        cta: {
          text: "Undo",
          onClick: () => {
            setLists(newId, null);
            setLists(listId, { ...list, name: originalName });
            apiClient.user
              .coreUserPartialUpdate({
                favorite_list_id: listId,
              })
              .then(() => {
                setUser((prev) => {
                  if (!prev) return null;
                  return { ...prev, favoriteListId: listId };
                });
              });
          },
        },
      });
      return true;
    },
    [lists, setLists, requireHasLoadedInitialData]
  );

  const removeRFPFromList = useCallback(
    (rfpId: string, listId: string, afterStarting: boolean = false) => {
      if (!requireHasLoadedInitialData()) return;
      const list = { ...lists[listId] };
      delete list.rfpIds[rfpId];
      setLists(listId, list);
      if (!afterStarting) {
        apiClient.user
          .rfpListsRemovedCreate(listId, {
            rfp_id: rfpId,
            list_name: list.name,
          })
          .catch(() => {
            console.error("Failed to report RFP removed from list");
          });
      }
    },
    [lists, setLists, requireHasLoadedInitialData]
  );

  const deleteList = useCallback(
    (listId: string) => {
      if (!requireHasLoadedInitialData()) return;
      const originalList = lists[listId];
      setLists(listId, null);
      odoToast.warning({
        title: "List Deleted",
        text: "This list has been deleted",
        cta: {
          text: "Undo",
          onClick: () => {
            setLists(listId, originalList);
          },
        },
      });
    },
    [lists, setLists, requireHasLoadedInitialData]
  );

  return (
    <ShortListsContext.Provider
      value={{
        lists,
        addRFPToList,
        addRFPToNewList,
        addNewList,
        removeRFPFromList,
        renameList,
        deleteList,
        connectionStatus: overallStatus,
        hasPendingChanges,
        refreshConnection: () => {
          providerRef.current?.connect();
        },
      }}
    >
      {children}
    </ShortListsContext.Provider>
  );
};

export const useShortLists = () => {
  const data = useContext(ShortListsContext);
  if (!data) {
    throw new Error("ShortListsProvider not found");
  }
  return data;
};

export const useListsForRFP = (rfpId: string) => {
  const {
    lists,
    addRFPToList,
    removeRFPFromList,
    addRFPToNewList,
    connectionStatus,
    addNewList,
  } = useShortLists();

  let inLists: Record<string, ShortList> = {};
  for (const [id, list] of Object.entries(lists)) {
    if (rfpId in list.rfpIds) {
      inLists[id] = list;
    }
  }

  return {
    allLists: lists,
    inLists,
    addRFPToList: (listId: string) => addRFPToList(rfpId, listId),
    removeRFPFromList: (listId: string, afterStarting: boolean = false) =>
      removeRFPFromList(rfpId, listId, afterStarting),
    addRFPToNewList: (listName: string) => addRFPToNewList(rfpId, listName),
    addNewList,
    connectionStatus,
  };
};
