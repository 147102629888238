import { ButtonHTMLAttributes, FC, MouseEvent } from "react";
import Icon, { IconName, IconProps } from "components/common/Icon";
import { cn } from "lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../EditorView/Menus/Tooltip";
import { useOptionalApiClient } from "../../providers/ApiClientProvider";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";

const buttonVariants = cva(
  "hover:opacity-50 flex items-center text-center justify-center whitespace-nowrap",
  {
    variants: {
      variant: {
        DEFAULT: "",
        outline: "border rounded-sm",
        "outline-full": "border rounded-sm p-sm",
        input: "border rounded-sm px-md py-xs justify-start hover:opacity-100",
        solid:
          "bg-primary text-popover px-sm py-xs rounded-sm font-semibold focus:outline-offset-[-1px] focus:outline focus:outline-[black]-90 focus:outline-[2px]",
        "solid-secondary":
          "bg-background text-primary rounded-sm border-primary border",
        navigation: "h-4xl",
        destructive:
          "bg-destructive text-background px-sm py-xs rounded-sm font-semibold focus:outline-offset-[-1px] focus:outline focus:outline-[black]-90 focus:outline-[2px]",
        "destructive-secondary":
          "bg-background text-destructive rounded-sm border-destructive border px-sm py-xs",
        toolbar:
          "p-md hover:bg-background-selected hover:opacity-100 hover:border-background-selected border-background border-x-[3px]",
        placeholder: "bg-background-selected px-sm py-xs rounded-sm border",
      },
      textColor: {
        DEFAULT: "text-foreground",
        primary: "text-primary",
        secondary: "text-secondary hover:opacity-100 hover:text-foreground",
      },
      size: {
        DEFAULT: "h-2xl gap-sm",
        large: "h-3xl gap-sm",
        xlarge: "h-48 gap-md",
        small: "h-lg gap-sm",
        xsmall: "h-2m gap-sm",
      },
      defaultVariants: {
        textColor: "DEFAULT",
        variant: "DEFAULT",
        size: "DEFAULT",
      },
    },
  }
);

export type DeprecatedButtonVariantProps = VariantProps<typeof buttonVariants>;

export interface DeprecatedButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    DeprecatedButtonVariantProps {
  text?: string;
  icon?: IconName;
  iconVariant?: IconProps["variant"];
  disabled?: boolean;
  tooltip?: string;
  tooltipDelayDuration?: number;
  tooltipSide?: "top" | "bottom" | "left" | "right";
  metricsId?: string;
  isLoading?: boolean;
  navigate?: string;
  external?: boolean;
  // If true, the button will not be interactive (and not actually a button)
  // This is to allow using the same visual with other styles of interactivity
  // (e.g. dropdowns)
  visualOnly?: boolean;
}

const DeprecatedButton: FC<DeprecatedButtonProps> = ({
  text,
  icon,
  iconVariant,
  disabled = false,
  variant,
  size = "DEFAULT",
  textColor,
  children,
  tooltip,
  tooltipDelayDuration,
  tooltipSide,
  isLoading,
  metricsId,
  className,
  navigate,
  external,
  visualOnly = false,
  onClick,
  ...props
}) => {
  const apiClient = useOptionalApiClient();
  const content = isLoading ? (
    <>
      <Spinner size="inherit" />
      {text}
      {children}
    </>
  ) : (
    <>
      {icon ? (
        <Icon size={size as any} name={icon} variant={iconVariant} />
      ) : null}
      {text}
      {children}
    </>
  );

  const handleClick = (e: MouseEvent<Element>) => {
    e.stopPropagation();
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (metricsId && apiClient) {
      apiClient.recordMetric(metricsId);
    }
    if (onClick) {
      onClick(e as MouseEvent<HTMLButtonElement>);
    }
  };

  let disabledClasses = "";
  if (disabled) {
    disabledClasses = "cursor-default hover:opacity-100 text-border";

    switch (variant) {
      case "solid":
      case "destructive":
        disabledClasses = cn(disabledClasses, "opacity-50 hover:opacity-50");
        break;
      case "navigation":
        disabledClasses = cn(
          disabledClasses,
          "border-b border-primary text-primary border-b-[2px]"
        );
        break;
      default:
        break;
    }
  }

  const commonProps = {
    ...(navigate ? {} : { disabled }),
    className: cn(
      buttonVariants({ variant, size, textColor }),
      (text?.length || 0) === 0
        ? "aspect-square shrink-0"
        : "aspect-auto px-2m elipsis overflow-hidden",
      !!onClick || !!navigate ? "cursor-pointer" : "",
      isLoading ? "pointer-events-none opacity-50" : "",
      disabledClasses,
      className
    ),
  };

  if (visualOnly) {
    return <div {...commonProps}>{content}</div>;
  }

  const buttonContent = navigate ? (
    external ? (
      <a
        href={navigate}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        {...commonProps}
      >
        {content}
      </a>
    ) : (
      <Link to={navigate} onClick={handleClick} {...commonProps}>
        {content}
      </Link>
    )
  ) : (
    <button {...props} {...commonProps} onClick={handleClick}>
      {content}
    </button>
  );

  if (!tooltip) {
    return buttonContent;
  }

  return (
    <Tooltip delayDuration={tooltipDelayDuration}>
      <TooltipTrigger asChild={true}>{buttonContent}</TooltipTrigger>
      <TooltipContent side={tooltipSide}>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default DeprecatedButton;
