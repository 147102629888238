import CoreApi from "api/CoreApi";
import { get_api_url } from "api/env";
import { Axios, AxiosError } from "axios";
import ErrorPage from "components/ErrorPage";
import Spinner from "components/common/Spinner";
import { cn } from "lib/utils";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 } from "uuid";

interface ApiClientData {
  apiClient: CoreApi;

  addExtraWork: (id: string) => void;
  completeExtraWork: (id: string) => void;
}

const ApiClientContext = createContext<ApiClientData | undefined>(undefined);

export const getApiClient = () =>
  new CoreApi({
    format: "json",
    baseURL: get_api_url("http", "/api/v1"),
    withCredentials: true,
  });

export const ApiClientProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const apiClient = useRef<CoreApi>(getApiClient());
  const [extraWorks, setExtraWorks] = useState<string[]>([]);
  const [healthy, setHealthy] = useState<true | any | null>(null);

  apiClient.current.instance.defaults.headers.common[
    "ngrok-skip-browser-warning"
  ] = "true";

  const addExtraWork = useCallback((id: string) => {
    setExtraWorks((works) => [...works, id]);
  }, []);

  const completeExtraWork = useCallback((id: string) => {
    setExtraWorks((works) => works.filter((work) => work !== id));
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        await apiClient.current.public.coreHealthcheckList({ timeout: 5000 });
        setHealthy(true);
      } catch (e) {
        if (
          e instanceof AxiosError &&
          (e.response?.status === 401 || e.response?.status === 403) &&
          window.location.pathname.startsWith("/login")
        ) {
          setHealthy(true);
        } else {
          setHealthy(e);
        }
      }
    };
    fetch();
  }, []);

  const fullyLoaded = healthy && extraWorks.length === 0;

  if (healthy !== true && healthy !== null) {
    return <ErrorPage error={healthy} />;
  }

  return (
    <ApiClientContext.Provider
      value={{ apiClient: apiClient.current, addExtraWork, completeExtraWork }}
    >
      {healthy && children}
      <div
        className={cn(
          "absolute inset-0 text-secondary gap-sm bg-background-secondary flex flex-col items-center justify-center transition-opacity duration-1000 pointer-events-none"
        )}
        style={{ opacity: fullyLoaded ? 0 : 1 }}
      >
        {!fullyLoaded && <Spinner />}
      </div>
    </ApiClientContext.Provider>
  );
};

export const useApiClient = () => {
  const data = useContext(ApiClientContext);
  if (data === undefined) {
    throw new Error("useApiClient must be used within a ApiClientProvider");
  }
  return data.apiClient;
};

export const useOptionalApiClient = () => {
  const data = useContext(ApiClientContext);
  if (data === undefined) {
    return null;
  }
  return data.apiClient;
};

export const useDoExtraWorkBeforeLoading = () => {
  const data = useContext(ApiClientContext);
  const [id] = useState(v4());
  if (data === undefined) {
    throw new Error(
      "useExtraWork must be used within a AuthenticatedUserProvider"
    );
  }
  useEffect(() => {
    data.addExtraWork(id);
    return () => {
      data.completeExtraWork(id);
    };
    // Explicitly ignore data and id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useCallback(() => {
    data.completeExtraWork(id);
    // Explicitly ignore data and id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
