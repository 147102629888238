import { useCallback } from "react";
import { PlateEditor, useEditorRef } from "@udecode/plate-common";
import { SectionInfo, isOdoHeading, getSectionInfoFromElement } from "odo";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { useConfirmModal } from "components/common/modals/ConfirmModal";

/**
 * Find the path for a section in the editor
 */
export const pathForSection = (section: SectionInfo, editor: PlateEditor) => {
  for (const [index, child] of editor.children.entries()) {
    if (!isOdoHeading(child)) continue;
    const sectionInfo = getSectionInfoFromElement(child);
    if (sectionInfo?.id === section.id) {
      return [index];
    }
  }
  return null;
};

/**
 * Hook that provides actions for manipulating outline elements
 */
export const useOutlineActions = () => {
  const editor = useEditorRef();
  const { scrollToElement } = useEditorDocData();
  const confirm = useConfirmModal();

  /**
   * Scroll to a section in the editor
   */
  const navigateToSection = useCallback(
    (section: SectionInfo) => {
      scrollToElement((element) => {
        const sectionInfo = getSectionInfoFromElement(element);
        return sectionInfo?.id === section.id;
      });

      // Optionally focus the editor at the end of the section
      // setTimeout(() => {
      //   const path = pathForSection(section, editor);
      //   if (path) {
      //     const full = editor.end(path);
      //     focusEditor(editor, full);
      //   }
      // }, 200);
    },
    [scrollToElement]
  );

  return {
    navigateToSection,
    pathForSection: (section: SectionInfo) => pathForSection(section, editor),
  };
};
