import Rows from "components/common/containers/Rows";
import Toggle from "components/common/forms/Toggle";
import Icon, { IconName } from "components/common/Icon";
import { cn } from "lib/utils";
import { FC } from "react";

interface CustomerTypePickerProps {
  values: string[];
  onChange: (values: string[]) => void;
}

export interface CustomerType {
  id: string;
  displayName: string;
  // Name to complete a sentence like "Only show RFPS issued by [pluralName]"
  pluralName: string;
}

const GOVERNMENT_ENTITIY_TYPES: CustomerType[] = [
  { id: "state", displayName: "State", pluralName: "States" },
  { id: "county", displayName: "County", pluralName: "Counties" },
  { id: "city", displayName: "City", pluralName: "Cities" },
  { id: "village", displayName: "Village", pluralName: "Villages" },
  { id: "town", displayName: "Town", pluralName: "Towns" },
  { id: "borough", displayName: "Borough", pluralName: "Boroughs" },
  {
    id: "tribal_nation",
    displayName: "Tribal Nation",
    pluralName: "Tribal Nations",
  },
  { id: "federal", displayName: "Federal", pluralName: "Federal Agencies" },
];

const EDUCATION_TYPES: CustomerType[] = [
  { id: "university", displayName: "University", pluralName: "Universities" },
  { id: "college", displayName: "College", pluralName: "Colleges" },
  { id: "high_school", displayName: "High School", pluralName: "High Schools" },
  {
    id: "middle_school",
    displayName: "Middle School",
    pluralName: "Middle Schools",
  },
  {
    id: "elementary_school",
    displayName: "Elementary School",
    pluralName: "Elementary Schools",
  },
  { id: "k12", displayName: "K-12", pluralName: "K-12 Schools" },
  {
    id: "other_school",
    displayName: "Other School",
    pluralName: "Other Schools",
  },
];

const HEALTH_TYPES: CustomerType[] = [
  { id: "hospital", displayName: "Hospital", pluralName: "Hospitals" },
  {
    id: "other_health",
    displayName: "Other Health",
    pluralName: "Other Health Organizations",
  },
];

const DEPARTMENT_TYPES: CustomerType[] = [
  {
    id: "transit_agency",
    displayName: "Transit Agency",
    pluralName: "Transit Agencies",
  },
  {
    id: "public_works",
    displayName: "Public Works",
    pluralName: "Public Works Departments",
  },
  {
    id: "other_department",
    displayName: "Other Department",
    pluralName: "Other Departments",
  },
];

const NON_PROFIT_TYPES: CustomerType[] = [
  { id: "non_profit", displayName: "Non-Profit", pluralName: "Non-Profits" },
];

export const ALL_TYPES: {
  displayName: string;
  types: CustomerType[];
  icon: IconName;
}[] = [
  {
    displayName: "Government Entities",
    types: GOVERNMENT_ENTITIY_TYPES,
    icon: "landmark",
  },
  { displayName: "Education", types: EDUCATION_TYPES, icon: "graduation-cap" },
  { displayName: "Health", types: HEALTH_TYPES, icon: "hospital" },
  { displayName: "Department", types: DEPARTMENT_TYPES, icon: "sitemap" },
  {
    displayName: "Nonprofit",
    types: NON_PROFIT_TYPES,
    icon: "hand-holding-heart",
  },
];

export const CustomerTypePicker: FC<CustomerTypePickerProps> = ({
  values,
  onChange,
}) => {
  return (
    <Rows className="-my-sm">
      {ALL_TYPES.map(({ displayName, types, icon }) => (
        <CustomerTypeSection
          title={displayName}
          icon={icon}
          types={types}
          values={values}
          onChange={onChange}
        />
      ))}
    </Rows>
  );
};

interface CustomerTypeSectionProps {
  title: string;
  icon?: IconName;
  types: CustomerType[];
  className?: string;
  style?: React.CSSProperties;
  values: string[];
  onChange: (values: string[]) => void;
}

const CustomerTypeSection: FC<CustomerTypeSectionProps> = ({
  title,
  icon,
  types,
  className,
  style,
  values,
  onChange,
}) => {
  return (
    <div
      className={cn(
        "flex flex-col min-[380px]:flex-row border-t first:border-t-0 py-sm text-sm",
        className
      )}
      style={style}
    >
      <Rows className="gap-md items-center justify-center grow pt-sm mb-xs pb-md border-b min-[380px]:w-[150px] text-center min-[380px]:border-b-0 min-[380px]:mx-0 min-[380px]:pb-0">
        {icon && <Icon name={icon} size="large" />}
        <h1>{title}</h1>
      </Rows>
      <Rows className="w-full grow">
        <div
          className={cn(
            "columns-1 w-full grow gap-0",
            types.length > 1 && "min-[500px]:columns-2"
          )}
          style={{ columnFill: "balance" }}
        >
          {types.map((type) => (
            <div key={type.id} className="w-[100%] flex">
              <Toggle
                text={type.displayName}
                toggleType="checkbox"
                className="h-xl hover:bg-background-selected grow justify-start hover:opacity-100 rounded-sm"
                on={values.includes(type.id)}
                onToggle={(on) => {
                  if (on) {
                    onChange([...values, type.id]);
                  } else {
                    onChange(values.filter((id) => id !== type.id));
                  }
                }}
              />
            </div>
          ))}
        </div>
      </Rows>
    </div>
  );
};
