import {
  PastProposalBlockResult,
  PastProposalByProposalResult,
  ProposalRFPBlock,
} from "api/Api";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import Spacer from "components/common/containers/Spacer";
import Stack from "components/common/containers/Stack";
import EditorTextArea, {
  EditorTextAreaRef,
} from "components/common/forms/editor-text-area/EditorTextArea";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import { ReferenceableBlock } from "components/common/pdf/PDFView";
import Reference, { ReferenceColor } from "components/common/pdf/Reference";
import PastProposalPDFView from "components/past-proposal/PastProposalPDFView";
import RFPPDFView from "components/rfps/RFPPDFView";
import useSectionDetails from "hooks/section-details/useSectionDetails";
import { odoToast } from "lib/odoToast";
import useLocalStorage from "lib/useLocalStorage";
import { cn } from "lib/utils";
import { isEmptyContent } from "odo";
import { useApiClient } from "providers/ApiClientProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEffect, useRef, useState } from "react";
import { LengthDropdown } from "./LengthDropdown";
import { usePDFRef } from "hooks/usePDFRef";
import useIsKeydown from "hooks/useIsKeyDown";
import Toggle from "components/common/forms/Toggle";

interface HandsFreeSectionDetailsPanelProps {
  className?: string;
}

const HandsFreeSectionDetailsPanel: React.FC<
  HandsFreeSectionDetailsPanelProps
> = ({ className }) => {
  const { name } = useSectionDetails();

  return (
    <Scrollable>
      <Rows className={cn("p-md gap-md", className)}>
        <p className="text-lg font-semibold">{name}</p>
        <InstructionsView />
      </Rows>
    </Scrollable>
  );
};

const InstructionsView: React.FC = () => {
  const apiClient = useApiClient();
  const [savedId, setSavedId] = useLocalStorage<string>(
    "hands-free-saved-id",
    ""
  );
  const [instructions, setInstructions] = useLocalStorage<ProposalRFPBlock[]>(
    "hands-free-instructions",
    []
  );
  const [context, setContext] = useLocalStorage<ProposalRFPBlock[]>(
    "hands-free-context",
    []
  );
  const [pastProposalBlocks, setPastProposalBlocks] = useLocalStorage<
    PastProposalByProposalResult[]
  >("hands-free-past-proposal-blocks", []);
  const [customInstructions, setCustomInstructions] = useLocalStorage<string>(
    "hands-free-custom-instructions",
    ""
  );
  const [strategy, setStrategy] = useLocalStorage<any[]>(
    "hands-free-strategy",
    [{ type: "p", children: [{ text: "" }] }]
  );
  const [length, setLength] = useLocalStorage<"short" | "medium" | "long">(
    "hands-free-length",
    "medium"
  );
  const [includeOutline, setIncludeOutline] = useLocalStorage<boolean>(
    "hands-free-include-outline",
    false
  );

  const [isLoading, setIsLoading] = useState(false);
  const { details: proposalDetails } = useProposalData();
  const { name, id } = useSectionDetails();
  const [rfpUrl, setRfpUrl] = useState<string | null>(null);
  const [inspecting, setInspecting] = useState(false);
  const { ref: rfpPdfRef, scrollToElement: scrollToRFPElement } = usePDFRef();
  const {
    ref: pastProposalPdfRef,
    scrollToElement: scrollToPastProposalElement,
  } = usePDFRef();
  const strategyEditorRef = useRef<EditorTextAreaRef>(null);

  const [pastProposalId, setPastProposalId] = useState<string | null>(null);
  const [activeRFPBlockId, setActiveRFPBlockId] = useState<string | null>(null);
  const [activePastProposalBlockId, setActivePastProposalBlockId] = useState<
    string | null
  >(null);
  const isShiftDown = useIsKeydown("Shift");

  useEffect(() => {
    if (id === "") {
      return;
    }
    if (savedId === id) {
      return;
    }

    setInstructions([]);
    setContext([]);
    setPastProposalBlocks([]);
    setCustomInstructions("");
    setStrategy([{ type: "p", children: [{ text: "" }] }]);
    setSavedId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const fetchRFP = async () => {
      try {
        const response = await apiClient.user.rfpRfpRead(proposalDetails.rfpId);
        setRfpUrl(response.data.view_url!);
      } catch (e) {
        odoToast.caughtError(e);
      }
    };
    fetchRFP();
  }, [apiClient.user, proposalDetails.rfpId]);

  const refreshInstructions = async () => {
    setIsLoading(true);
    try {
      const response =
        await apiClient.admin.rfpProposalCalculateSectionRfpContext(
          proposalDetails.id,
          {
            section_title: name,
          }
        );
      setInstructions(response.data.rfp_instructions);
      setContext(response.data.rfp_context);
    } catch (error) {
      odoToast.caughtError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPastProposals = async () => {
    try {
      setIsLoading(true);
      const response =
        await apiClient.user.rfpProposalPastProposalBlocksSearchList(
          proposalDetails.id,
          {
            search:
              name + ": " + instructions.map((i) => i.content).join("\n\n"),
          }
        );
      setPastProposalBlocks(response.data.proposals);
    } catch (error) {
      odoToast.caughtError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshStrategy = async () => {
    try {
      setIsLoading(true);
      const rfp_instruction_blocks: string[] = instructions.map((b) => b.id);
      const rfp_context_blocks: string[] = context.map((b) => b.id);
      const past_proposal_blocks: string[] = pastProposalBlocks.flatMap((p) =>
        p.blocks.map((b) => b.id!.toString())
      );
      const response =
        await apiClient.user.rfpProposalRequirementStrategyCreate(
          proposalDetails.id,
          {
            heading: name,
            rfp_instruction_blocks,
            rfp_context_blocks,
            past_proposal_blocks,
            custom_instructions: customInstructions,
            include_outline: includeOutline,
          }
        );
      setStrategy(response.data.strategy);
    } catch (error) {
      odoToast.caughtError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const generate = async () => {
    try {
      setInspecting(false);
      setIsLoading(true);
      await apiClient.user.rfpProposalRequirementDraftCreate(
        proposalDetails.id,
        id,
        {
          heading: name,
          strategy,
          rfp_instruction_block_ids: instructions.map((i) => i.id),
          length,
          use_fixture: isShiftDown,
        }
      );
      odoToast.success({
        title: "Draft started",
        text: "The draft is being generated in the background",
      });
    } catch (error) {
      odoToast.caughtError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const generatePricing = async () => {
    try {
      setInspecting(false);
      setIsLoading(true);
      const rfp_instruction_blocks: string[] = instructions.map((b) => b.id);
      const rfp_context_blocks: string[] = context.map((b) => b.id);
      const past_proposal_blocks: string[] = pastProposalBlocks.flatMap((p) =>
        p.blocks.map((b) => b.id!.toString())
      );
      await apiClient.user.rfpProposalRequirementDraftPricingCreate(
        proposalDetails.id,
        id,
        {
          heading: name,
          rfp_instruction_blocks,
          rfp_context_blocks,
          past_proposal_blocks,
          use_fixture: isShiftDown,
        }
      );
      odoToast.success({
        title: "Draft started",
        text: "The draft is being generated in the background",
      });
    } catch (error) {
      odoToast.caughtError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateTimeline = async () => {
    try {
      setInspecting(false);
      setIsLoading(true);
      const rfp_instruction_blocks: string[] = instructions.map((b) => b.id);
      const rfp_context_blocks: string[] = context.map((b) => b.id);
      const past_proposal_blocks: string[] = pastProposalBlocks.flatMap((p) =>
        p.blocks.map((b) => b.id!.toString())
      );
      await apiClient.user.rfpProposalRequirementDraftTimelineCreate(
        proposalDetails.id,
        id,
        {
          heading: name,
          rfp_instruction_blocks,
          rfp_context_blocks,
          past_proposal_blocks,
          use_fixture: isShiftDown,
        }
      );
      odoToast.success({
        title: "Draft started",
        text: "The draft is being generated in the background",
      });
    } catch (error) {
      odoToast.caughtError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const rfpReferenceBlocks: ReferenceableBlock[] = [
    ...instructions.map((instruction) => ({
      id: instruction.id,
      color: "secondary" as ReferenceColor,
    })),
    ...context.map((block) => ({
      id: block.id,
      color: "primary" as ReferenceColor,
    })),
  ];

  const pastProposalReferenceBlocks: ReferenceableBlock[] = [
    ...pastProposalBlocks.flatMap((proposal) =>
      proposal.blocks.map((block) => ({
        id: block.id!.toString(),
        color: "secondary" as ReferenceColor,
      }))
    ),
  ];

  const handleRFPReferenceClick = (block: ProposalRFPBlock) => {
    setActiveRFPBlockId(block.id);
    setActivePastProposalBlockId(null);
    scrollToRFPElement(block.id);
  };

  const handlePastProposalReferenceClick = (block: PastProposalBlockResult) => {
    setActivePastProposalBlockId(block.id!.toString());
    setActiveRFPBlockId(null);
    setPastProposalId(block.past_proposal_id!);
    scrollToPastProposalElement(block.id!.toString());
  };

  const handleInsertReferenceToStrategy = () => {
    if (activePastProposalBlockId) {
      strategyEditorRef.current?.insertReference(
        `P-${activePastProposalBlockId}`
      );
    } else if (activeRFPBlockId) {
      strategyEditorRef.current?.insertReference(`R-${activeRFPBlockId}`);
    }
  };

  const disableStrategy =
    context.length === 0 || pastProposalBlocks.length === 0;

  if (!name) {
    return <p>Select a section</p>;
  }

  return (
    <>
      <LabeledFormInput
        label="RFP"
        extraControls={
          <Button
            variant="plain"
            icon="refresh"
            onClick={refreshInstructions}
            isLoading={isLoading}
          />
        }
      >
        <p>Instructions: {instructions.length}</p>
        <p>Context: {context.length}</p>
      </LabeledFormInput>
      <LabeledFormInput
        label="Past Proposals"
        extraControls={
          <Button
            variant="plain"
            icon="refresh"
            onClick={refreshPastProposals}
            isLoading={isLoading}
          />
        }
      >
        <p>
          Past Proposals:{" "}
          {pastProposalBlocks.reduce(
            (acc, proposal) => acc + proposal.blocks.length,
            0
          )}
        </p>
      </LabeledFormInput>
      <LabeledFormInput label="Custom Instructions">
        <NaturalHeightTextArea
          value={customInstructions}
          placeholder="Enter custom instructions"
          onChange={(text) => setCustomInstructions(text)}
        />
      </LabeledFormInput>
      <LabeledFormInput
        label="Strategy"
        extraControls={
          <>
            <Toggle
              text="Outline"
              on={includeOutline}
              onToggle={setIncludeOutline}
            />
            <Button
              variant="plain"
              icon="refresh"
              onClick={refreshStrategy}
              isLoading={isLoading}
              disabled={disableStrategy}
            />
          </>
        }
      >
        Strategy: {isEmptyContent(strategy) ? "No" : "Yes"}
      </LabeledFormInput>
      <LabeledFormInput label="Length">
        <LengthDropdown
          length={length}
          setLength={(length) => setLength(length as any)}
        />
      </LabeledFormInput>
      {rfpUrl && (
        <Overlay
          fillWidth={true}
          scrollable={false}
          title="Inspect"
          variant="full-screen-bordered"
          open={inspecting}
          onClose={() => setInspecting(false)}
        >
          <Columns className="gap-lg">
            <Scrollable className="grow basis-0 max-w-[600px]">
              <Rows className="gap-sm">
                <Columns>
                  <h2 className="font-semibold grow">RFP</h2>
                  <Button
                    variant="plain"
                    icon="refresh"
                    onClick={refreshInstructions}
                    isLoading={isLoading}
                  />
                </Columns>
                <LabeledFormInput label="Instructions">
                  <div>
                    {instructions.map((instruction) => (
                      <Reference
                        key={instruction.id}
                        onClick={() => handleRFPReferenceClick(instruction)}
                        color="secondary"
                        active={activeRFPBlockId === instruction.id}
                      >
                        R-{instruction.id}
                      </Reference>
                    ))}
                  </div>
                </LabeledFormInput>
                <LabeledFormInput label="Context">
                  <div>
                    {context.map((block) => (
                      <Reference
                        key={block.id}
                        onClick={() => handleRFPReferenceClick(block)}
                        active={activeRFPBlockId === block.id}
                      >
                        R-{block.id}
                      </Reference>
                    ))}
                  </div>
                </LabeledFormInput>
                <Columns>
                  <h2 className="font-semibold grow mt-lg">Past Proposals</h2>
                  <Button
                    variant="plain"
                    icon="refresh"
                    onClick={refreshPastProposals}
                    isLoading={isLoading}
                  />
                </Columns>
                {pastProposalBlocks.map((proposal) => (
                  <LabeledFormInput
                    key={proposal.proposal_id}
                    label={proposal.proposal_name}
                  >
                    <div>
                      {proposal.blocks.map((block) => (
                        <Reference
                          key={block.id}
                          onClick={() =>
                            handlePastProposalReferenceClick(block)
                          }
                          active={
                            activePastProposalBlockId === block.id!.toString()
                          }
                        >
                          P-{block.id}
                        </Reference>
                      ))}
                    </div>
                  </LabeledFormInput>
                ))}
                <h2 className="font-semibold mt-lg">Custom Instructions</h2>
                <NaturalHeightTextArea
                  value={customInstructions}
                  placeholder="Enter custom instructions"
                  onChange={(text) => setCustomInstructions(text)}
                  className="min-h-[100px]"
                />
                <Columns className="items-center">
                  <h2 className="font-semibold grow mt-lg">Strategy</h2>
                  <Toggle
                    text="Outline"
                    on={includeOutline}
                    onToggle={setIncludeOutline}
                  />
                  <Button
                    variant="plain"
                    icon="refresh"
                    onClick={refreshStrategy}
                    isLoading={isLoading}
                    disabled={disableStrategy}
                  />
                </Columns>
                <EditorTextArea
                  value={strategy}
                  ref={strategyEditorRef}
                  placeholder="Enter strategy"
                  onChange={setStrategy}
                  className="min-h-[100px]"
                  onReferenceClicked={(id) => {
                    if (id.startsWith("R-")) {
                      const rfpBlockId = id.slice(2);
                      setActiveRFPBlockId(rfpBlockId);
                      setActivePastProposalBlockId(null);
                      scrollToRFPElement(rfpBlockId);
                    } else if (id.startsWith("P-")) {
                      const pastProposalBlockId = id.slice(2);
                      const block: PastProposalBlockResult | undefined =
                        pastProposalBlocks
                          .flatMap((p) => p.blocks)
                          .find(
                            (b) => b.id!.toString() === pastProposalBlockId
                          );
                      if (block) {
                        handlePastProposalReferenceClick(block);
                      }
                    }
                  }}
                />
                <LabeledFormInput label="Length">
                  <LengthDropdown
                    length={length}
                    setLength={(length) => setLength(length as any)}
                  />
                </LabeledFormInput>
                <Columns className="shrink-0 py-sm justify-end gap-sm">
                  <Button
                    icon="play"
                    text="Pricing"
                    onClick={generatePricing}
                    disabled={!isShiftDown && disableStrategy}
                    isLoading={isLoading}
                  />
                  <Button
                    icon="play"
                    text="Timeline"
                    onClick={generateTimeline}
                    disabled={!isShiftDown && disableStrategy}
                    isLoading={isLoading}
                  />
                  <Button
                    icon="play"
                    text="Generate"
                    onClick={generate}
                    disabled={!isShiftDown && isEmptyContent(strategy)}
                    isLoading={isLoading}
                  />
                </Columns>
              </Rows>
            </Scrollable>
            <Rows className="grow basis-0">
              <Stack className="grow border-y">
                <RFPPDFView
                  ref={rfpPdfRef}
                  showDebugInfo={true}
                  className={cn(
                    "grow",
                    activePastProposalBlockId
                      ? "opacity-0 pointer-events-none"
                      : "opacity-100"
                  )}
                  rfpId={proposalDetails.rfpId}
                  fileUrl={rfpUrl}
                  referenceBlocks={rfpReferenceBlocks}
                  activeBlockId={activeRFPBlockId ?? null}
                  setActiveBlockId={(blockId) => {
                    if (!blockId) return;
                    setActiveRFPBlockId(blockId);
                  }}
                />
                {pastProposalId && (
                  <PastProposalPDFView
                    ref={pastProposalPdfRef}
                    showDebugInfo={true}
                    className={cn(
                      "grow",
                      activePastProposalBlockId
                        ? "opacity-100"
                        : "opacity-0 pointer-events-none"
                    )}
                    proposalId={pastProposalId!}
                    referenceBlocks={pastProposalReferenceBlocks}
                    activeBlockId={activePastProposalBlockId ?? null}
                    setActiveBlockId={(blockId) => {
                      if (!blockId) return;
                      setActivePastProposalBlockId(blockId);
                    }}
                  />
                )}
              </Stack>
              <Columns className="shrink-0 pt-sm">
                <Button
                  icon="plus"
                  text="Insert Reference to Strategy"
                  onClick={handleInsertReferenceToStrategy}
                  disabled={
                    activeRFPBlockId === null &&
                    activePastProposalBlockId === null
                  }
                />
              </Columns>
            </Rows>
          </Columns>
        </Overlay>
      )}
      <Columns className="shrink-0 pt-sm gap-sm">
        <Button
          icon="magnifying-glass"
          text="Inspect"
          onClick={() => setInspecting(true)}
        />
        <Spacer />
        <Button
          icon="play"
          text="Pricing"
          disabled={!isShiftDown && disableStrategy}
          onClick={generatePricing}
          isLoading={isLoading}
        />
        <Button
          icon="play"
          text="Timeline"
          disabled={!isShiftDown && disableStrategy}
          onClick={generateTimeline}
          isLoading={isLoading}
        />
        <Button
          icon="play"
          text="Generate"
          onClick={generate}
          disabled={!isShiftDown && isEmptyContent(strategy)}
          isLoading={isLoading}
        />
      </Columns>
    </>
  );
};

export default HandsFreeSectionDetailsPanel;
