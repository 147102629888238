import React, { FC, useEffect } from "react";
import { insertTable, useEditorState } from "@udecode/plate";

import { cn } from "lib/utils";

interface AddTableViewProps {
  isOpen: boolean;
  dismiss: () => void;
}

const AddTableView: FC<AddTableViewProps> = ({ isOpen, dismiss }) => {
  const [rows, setRows] = React.useState(4);
  const [cols, setCols] = React.useState(4);
  const editor = useEditorState();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen) return;
      else if (e.key === "Escape") {
        dismiss?.();
      } else if (e.key === "Enter") {
        insertTable(editor, { rowCount: rows, colCount: cols });
        dismiss?.();
      } else if (e.key === "ArrowLeft") {
        setCols(Math.max(cols - 1, 1));
      } else if (e.key === "ArrowRight") {
        setCols(Math.min(cols + 1, 10));
      } else if (e.key === "ArrowUp") {
        setRows(Math.max(rows - 1, 1));
      } else if (e.key === "ArrowDown") {
        setRows(Math.min(rows + 1, 10));
      }
      e.preventDefault();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [rows, cols, isOpen, dismiss, editor]);

  useEffect(() => {
    if (!isOpen) {
      setRows(4);
      setCols(4);
    }
  }, [isOpen]);

  return (
    <>
      <p className="text-sm text-center mb-sm">
        Choose a number of columns and rows
      </p>
      <div className="grid grid-cols-10 grid-rows-10 m-xs">
        {[...Array(100)].map((_, i) => {
          const highlighted = i % 10 < cols && Math.floor(i / 10) < rows;
          return (
            <div
              className="p-thin"
              key={i + 100}
              onMouseOver={() => {
                setRows(Math.floor(i / 10) + 1);
                setCols((i % 10) + 1);
              }}
              onClick={() => {
                insertTable(editor, { rowCount: rows, colCount: cols });
                dismiss?.();
              }}
            >
              <div
                key={i}
                className={cn(
                  "w-lg h-lg rounded-sm m-thin",
                  highlighted ? "bg-primary" : "bg-border"
                )}
              ></div>
            </div>
          );
        })}
      </div>
      <p className="text-center mt-sm">
        {cols} x {rows}
      </p>
    </>
  );
};

export default AddTableView;
