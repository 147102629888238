import NavigationView from "components/common/NavigationView";
import RFPDetailsView from "components/rfps/RFPDetailsView";
import { ShortListsProvider } from "providers/ShortListsProvider";
import { useNavigate, useParams } from "react-router-dom";

const RFPDetailsRoute = () => {
  const { id, tab } = useParams();
  const navigate = useNavigate();

  return (
    <NavigationView scrollable={false} fullWidth={true} padContent={false}>
      <ShortListsProvider>
        <RFPDetailsView
          rfp={id!}
          showTitle={true}
          className="pt-lg"
          constrainWidth={true}
          initialTab={tab as any}
          forceTab={true}
          onTabChange={(newTab) => {
            navigate(`/rfps/${id}/${newTab}/`);
          }}
        />
      </ShortListsProvider>
    </NavigationView>
  );
};
export default RFPDetailsRoute;
