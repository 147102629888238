import { odoToast } from "lib/odoToast";
import { ConcretePageLayout } from "../../common/pdf/layout";
import { Page } from "react-pdf";

interface PDFPageContainerProps {
  pageNumber: number;
  layout: ConcretePageLayout;
}

/**
 * A container for each page in the PDF view.
 *
 * In particular, this is setup to be laid out quickly and efficiently
 * while allowing the actual page to be loaded asynchronously
 */
const PDFPageContainer: React.FC<PDFPageContainerProps> = ({
  pageNumber,
  layout,
}) => {
  return (
    <>
      {/* The container is sized based on the standard page size */}
      <div
        className="absolute items-center"
        style={{
          left: layout.left,
          width: layout.width,
          top: layout.top,
          height: layout.height,
        }}
      >
        <Page
          className="grow"
          renderTextLayer={false}
          renderAnnotationLayer={false}
          width={layout.width}
          // height={layout.height}
          pageNumber={pageNumber}
          onLoadError={(error) => {
            odoToast.caughtError(error);
          }}
        />
      </div>
    </>
  );
};

export default PDFPageContainer;
