import { SectionInfo } from "odo";

/**
 * Illustration
 * ------------------ <- 0
 * |
 * | <all previous sections>
 * |
 * ------------------ <- topOfAbove
 * | Above Section
 * ------------------ <- top
 * | Target Section
 * ------------------ <- bottom
 * | Below Section
 * ------------------ <- bottomOfBelow
 */
export interface SurroundingSectionOffsets {
  // null if there are no above sections
  topOfAbove: number | null;
  above: string | null;

  top: number;
  bottom: number;

  // null if there are no below sections
  below: string | null;
  bottomOfBelow: number | null;

  // boundry for moving to the left
  left: number | null;
  // boundry for moving to the right
  right: number | null;
}

export interface HierarchicalSectionInfo extends SectionInfo {
  children: HierarchicalSectionInfo[];
  parentId?: string;
}

/**
 * Build a hierarchical structure from a flat list of sections
 */
export const buildHierarchy = (
  flatSections: SectionInfo[]
): HierarchicalSectionInfo[] => {
  const result: HierarchicalSectionInfo[] = [];
  const sectionMap = new Map<string, HierarchicalSectionInfo>();

  // Initialize all sections with empty children arrays
  flatSections.forEach((section) => {
    const hierarchicalSection: HierarchicalSectionInfo = {
      ...section,
      children: [],
    };
    sectionMap.set(section.id, hierarchicalSection);
  });

  // Process sections in order
  for (let i = 0; i < flatSections.length; i++) {
    const currentSection = sectionMap.get(flatSections[i].id)!;
    const currentLevel = currentSection.level;

    // Find the parent for this section
    let parentFound = false;

    // Look backward to find a parent (a section with a lower level)
    for (let j = i - 1; j >= 0; j--) {
      const potentialParent = sectionMap.get(flatSections[j].id)!;

      if (potentialParent.level < currentLevel) {
        // This is a parent
        potentialParent.children.push(currentSection);
        currentSection.parentId = potentialParent.id;
        parentFound = true;
        break;
      }
    }

    // If no parent found, this is a top-level section
    if (!parentFound) {
      result.push(currentSection);
    }
  }

  return result;
};

export const flattenHierarchy = (
  sections: HierarchicalSectionInfo[]
): HierarchicalSectionInfo[] => {
  const result: HierarchicalSectionInfo[] = [];
  for (const section of sections) {
    result.push(section);
    if (section.children) {
      result.push(...flattenHierarchy(section.children));
    }
  }
  return result;
};

export const filterChildren = (
  sections: HierarchicalSectionInfo[],
  excludingChildrenOf: string
): HierarchicalSectionInfo[] => {
  const result: HierarchicalSectionInfo[] = [];
  for (const section of sections) {
    if (section.id === excludingChildrenOf) {
      result.push({ ...section, children: [] });
      continue;
    }
    result.push({
      ...section,
      children: filterChildren(section.children, excludingChildrenOf),
    });
  }
  return result;
};

export const getById = (
  sections: HierarchicalSectionInfo[],
  id: string
): HierarchicalSectionInfo | null => {
  for (const section of sections) {
    if (section.id === id) {
      return section;
    }
    if (section.children.length > 0) {
      const foundInChildren = getById(section.children, id);
      if (foundInChildren) {
        return foundInChildren;
      }
    }
  }
  return null;
};

export const getParent = (
  sections: HierarchicalSectionInfo[],
  id: string
): HierarchicalSectionInfo | null => {
  // Check if any of these sections is the direct parent
  for (const section of sections) {
    // Check if any children match the target id
    const directChild = section.children.find((child) => child.id === id);
    if (directChild) {
      return section;
    }

    // Recursively check in children
    const foundInChildren = getParent(section.children, id);
    if (foundInChildren) {
      return foundInChildren;
    }
  }

  return null;
};

export const getPosition = (
  sections: HierarchicalSectionInfo[],
  id: string
): {
  parent: HierarchicalSectionInfo | null;
  index: number;
  section: HierarchicalSectionInfo;
} | null => {
  const parent = getParent(sections, id);
  for (const [index, child] of (parent?.children ?? sections).entries()) {
    if (child.id === id) {
      return {
        parent,
        index,
        section: child,
      };
    }
  }
  return null;
};

export const isSamePosition = (
  pos1: { parent: HierarchicalSectionInfo | null; index: number } | null,
  pos2: { parent: HierarchicalSectionInfo | null; index: number } | null
): boolean => {
  if (!pos1 || !pos2) return false;
  return pos1.parent === pos2.parent && pos1.index === pos2.index;
};

export const indentSection = (
  sections: HierarchicalSectionInfo[],
  id: string,
  direction: "left" | "right"
) => {
  // To simplify the algorithm we will flatt the sections, change the
  // heading level, and then re-hierarchy them
  const flattenedSections = flattenHierarchy(sections);
  for (const section of flattenedSections) {
    if (section.id === id) {
      section.level += direction === "left" ? -1 : 1;
    }
  }
  return buildHierarchy(flattenedSections);
};
