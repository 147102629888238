import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import OutlineView from "components/proposal/outline-view/OutlineView";
import OutlineBuilder from "components/proposal/OutlineBuilder";
import ProposalDocOverlay from "components/proposal/ProposalDocOverlay";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, useState } from "react";

interface OutlineNavPanelProps {}

const OutlineNavPanel: FC<OutlineNavPanelProps> = () => {
  const { isStaff } = useAuthenticatedUser();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Rows className="grow">
        <Columns className="shrink-0 items-center gap-md mt-md border-b pr-md py-xs">
          <h1 className="ml-sm text-sm font-semibold grow">Outline</h1>
          {isStaff && (
            <Button
              text="Edit"
              className="admin"
              emphasis="primary"
              size="small"
              variant="plain"
              onClick={() => setIsOpen(true)}
            />
          )}
        </Columns>
        <OutlineView className="grow" />
      </Rows>
      <ProposalDocOverlay open={isOpen}>
        <OutlineBuilder onClose={() => setIsOpen(false)} />
      </ProposalDocOverlay>
    </>
  );
};

export default OutlineNavPanel;
