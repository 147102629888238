import { ItemDetails } from "api/Api";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { FC } from "react";

interface ApproachDetailsViewProps {
  details: ItemDetails;
  setDetails: (details: ItemDetails) => void;
}

const ApproachDetailsView: FC<ApproachDetailsViewProps> = ({
  details,
  setDetails,
}) => {
  return (
    <>
      <LabeledFormInput label="Title">
        <Input
          placeholder="The title of this approach"
          value={details.title ?? ""}
          onChange={(e) => setDetails({ ...details, title: e.target.value })}
        />
      </LabeledFormInput>
      <LabeledFormInput label="Overview" className="grow overflow-hidden">
        <CollaborativeTextArea
          placeholder="A brief overview of the approach"
          className="h-[600px]"
          docId={details.content_id!}
          key={details.content_id!}
        />
      </LabeledFormInput>
    </>
  );
};

export default ApproachDetailsView;
