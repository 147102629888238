import { SavedSearchDetail } from "api/Api";
import { ResultBase } from "components/common/menus/ComboBox";
import { useCallback, useMemo } from "react";
import { AuthenticatedUser } from "types/AuthenticatedUser";
import { UpdateSearchOptions } from "./RFPSearchMenu";

export const isSavedSearchDraft = (
  search: SavedSearchDetail | null,
  currentUser: AuthenticatedUser
) => !!search?.user && search.user.email === currentUser.email;

export const isEmptySearch = (search: SavedSearchDetail) =>
  search.locations.length === 0 &&
  search.customers.length === 0 &&
  search.keywords.length === 0 &&
  search.exclude_keywords.length === 0 &&
  search.naics_codes.length === 0;

export interface SearchConfiguratorProps {
  search: SavedSearchDetail;
  updateSearch: (
    update: Partial<SavedSearchDetail>,
    options?: UpdateSearchOptions
  ) => void;
  close: () => void;
  refresh: () => void;
  isOpen: boolean;
  isMobile: boolean;
}

export interface SearchResult extends ResultBase {
  label: string;
  details?: string;
}

interface SearchResultsGeneratorOutput {
  options: SearchResult[];
  onSelected?: (
    option: SearchResult,
    search: SavedSearchDetail,
    updateSearch: (update: Partial<SavedSearchDetail>) => void
  ) => void;
}
export type SearchResultsGenerator = (
  searchText: string,
  search: SavedSearchDetail | null
) => SearchResultsGeneratorOutput;

export const useAggregatedSearchResults = (
  outputs: { output: SearchResultsGeneratorOutput; id: string }[]
) => {
  const allOptions = useMemo(() => {
    return outputs.flatMap(({ output, id }) =>
      output.options.map((o) => ({ ...o, id: id + "_" + o.id }))
    );
  }, [outputs]);

  const onSelected = useCallback(
    (
      option: SearchResult,
      search: SavedSearchDetail,
      updateSearch: (update: Partial<SavedSearchDetail>) => void
    ) => {
      const id = option.id.split("_")[0];
      const output = outputs.find((o) => o.id === id);
      output?.output.onSelected?.(option, search, updateSearch);
    },
    [outputs]
  );

  return { options: allOptions, onSelected };
};
