/**
 * Hook for hashing files using SHA-256
 * @returns A function that can hash a single file or an array of files
 */
const useFileHash = () => {
  /**
   * Hash a single file using SHA-256
   * @param file The file to hash
   * @returns A promise that resolves to the hash as a hex string
   */
  const hashSingleFile = async (file: File): Promise<string> => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise<string>((resolve) => {
      reader.onload = async (e) => {
        const hash = await crypto.subtle.digest(
          "SHA-256",
          new Uint8Array(e.target?.result as ArrayBuffer)
        );
        resolve(
          Array.from(new Uint8Array(hash), (b) =>
            b.toString(16).padStart(2, "0")
          ).join("")
        );
      };
    });
  };

  /**
   * Convert a hash array buffer to a hex string
   * @param hashBuffer The hash buffer to convert
   * @returns The hash as a hex string
   */
  const hashBufferToHexString = (hashBuffer: ArrayBuffer): string => {
    return Array.from(new Uint8Array(hashBuffer), (b) =>
      b.toString(16).padStart(2, "0")
    ).join("");
  };

  /**
   * Hash a file or array of files
   * @param fileInput A single File or an array of Files to hash
   * @returns A promise that resolves to the hash as a hex string
   */
  return async (fileInput: File | File[]): Promise<string> => {
    // Handle single file case
    if (!Array.isArray(fileInput)) {
      return hashSingleFile(fileInput);
    }

    // Handle empty array case
    if (fileInput.length === 0) {
      throw new Error("Cannot hash an empty array of files");
    }

    // Handle single file in array case
    if (fileInput.length === 1) {
      return hashSingleFile(fileInput[0]);
    }

    // Handle multiple files case
    // 1. Hash each file individually
    const fileHashes = await Promise.all(fileInput.map(hashSingleFile));

    // 2. Combine the hashes and create a new hash of the combined string
    const combinedHash = await crypto.subtle.digest(
      "SHA-256",
      new TextEncoder().encode(fileHashes.join(""))
    );

    // 3. Convert the final hash to a hex string
    return hashBufferToHexString(combinedHash);
  };
};

export default useFileHash;
