import Button from "components/common/Button";
import MessageView from "components/common/containers/MessageView";
import Rows from "components/common/containers/Rows";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import TextArea from "components/common/forms/TextArea";
import DropdownMenu from "components/common/menus/DropdownMenu";
import Spinner from "components/common/Spinner";
import TypingAnimation from "components/common/TypingAnimation";
import { useState } from "react";

interface Component {
  name: string;
  description: string;
  component: React.ReactNode;
}

const ComponentsPlayground: React.FC = () => {
  const Components: (Component | "break")[] = [
    { name: "Spinner", description: "A basic spinner", component: <Spinner /> },
    {
      name: "Text Spinner",
      description: "A spinner with text",
      component: <Spinner text="Loading" />,
    },
    {
      name: "Typing Once Animation",
      description: "A typing animation that only runs once",
      component: (
        <TypingAnimation
          className="w-[200px]"
          options={["Some example text"]}
          type="once"
        />
      ),
    },
    {
      name: "Typing Loop Animation",
      description: "A typing animation that loops",
      component: (
        <TypingAnimation
          className="w-[200px]"
          options={["Text 1", "Second Text", "More stuff"]}
          type="loop"
        />
      ),
    },
    "break",
    {
      name: "Message View ",
      description: "A container for a message",
      component: (
        <MessageView title="Hello" icon="circle-question">
          <p>This is an example message</p>
        </MessageView>
      ),
    },
    {
      name: "Message View Above",
      description: "A container for a message",
      component: (
        <MessageView title="Hello" icon="circle-question" variant="above">
          <p>This is an example message</p>
        </MessageView>
      ),
    },
    {
      name: "Text Area",
      description: "A fixed height text area",
      component: <SampleTextArea />,
    },
    {
      name: "Natural Height Text Area",
      description: "A text area that grows to fit the content",
      component: <SampleNaturalHeightTextArea />,
    },
    {
      name: "Dropdown Menu",
      description: "A dropdown menu",
      component: (
        <DropdownMenu
          items={[{ text: "One" }, { text: "Two" }, { text: "Three" }]}
        >
          <Button text="Choose" visualOnly={true} />
        </DropdownMenu>
      ),
    },
    {
      name: "Dropdown Menu With Icons",
      description: "A dropdown menu with icons",
      component: (
        <DropdownMenu
          items={[
            { text: "One", icon: "paragraph" },
            { text: "Two", icon: "bold" },
            { text: "Three", icon: "pen" },
          ]}
        >
          <Button text="Choose" visualOnly={true} />
        </DropdownMenu>
      ),
    },
  ];

  return (
    <div className="grow flex flex-wrap justify-start gap-md grow">
      {Components.map((component) => {
        if (component === "break") {
          return <div className="w-full" />;
        }
        return (
          <Rows key={component.name} className="items-center gap-sm">
            <div className="text-lg font-semibold text-center">
              {component.name}
            </div>
            <div className="text-sm text-gray-500">{component.description}</div>
            <div className="border rounded-md p-lg">{component.component}</div>
          </Rows>
        );
      })}
    </div>
  );
};

const SampleTextArea: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [value, setValue] = useState(
    "Starting text that is a bit longer than one line"
  );
  return (
    <Rows className="gap-sm">
      <TextArea
        placeholder="Enter your text here"
        className="w-[300px] h-[60px]"
        disabled={isDisabled}
        value={value}
        onChange={setValue}
      />
      <Button
        onClick={() => setIsDisabled(!isDisabled)}
        text={isDisabled ? "Enable" : "Disable"}
      />
    </Rows>
  );
};

const SampleNaturalHeightTextArea: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [value, setValue] = useState(
    "Starting text that is a big longer than one line"
  );
  return (
    <Rows className="gap-sm">
      <NaturalHeightTextArea
        placeholder="Enter your text here"
        className="w-[300px] min-h-[60px]"
        disabled={isDisabled}
        value={value}
        onChange={setValue}
      />
      <Button
        onClick={() => setIsDisabled(!isDisabled)}
        text={isDisabled ? "Enable" : "Disable"}
      />
    </Rows>
  );
};
export default ComponentsPlayground;
