import { SavedSearchDetail } from "api/Api";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import Icon from "components/common/Icon";
import { KeyTermPill } from "components/common/menus/KeyTermsInput";
import {
  PopoverAnchor,
  PopoverContent,
} from "components/EditorView/Menus/Popover";
import { Popover } from "components/EditorView/Menus/Popover";
import { odoToast } from "lib/odoToast";
import { cn } from "lib/utils";
import {
  ComponentPropsWithoutRef,
  FC,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

interface SearchMenuInputProps extends ComponentPropsWithoutRef<"div"> {
  search: SavedSearchDetail | null;
  updateSearch: (search: Partial<SavedSearchDetail>) => void;
}

const SearchMenuInput: FC<SearchMenuInputProps> = ({
  search,
  updateSearch,
  className,
  ...props
}) => {
  const [inputActive, setInputActive] = useState(false);
  const [query, setQuery] = useState("");
  const anchorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [contentWidth, setContentWidth] = useState(0);

  useEffect(() => {
    if (!anchorRef.current) return;
    const observer = new ResizeObserver((entries) => {
      setContentWidth(entries[0].contentRect.width);
    });
    observer.observe(anchorRef.current);
    return () => observer.disconnect();
  }, []);

  const handleKeydown: KeyboardEventHandler = (e) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      if (query) {
        if (search?.keywords.includes(query)) {
          odoToast.error({
            title: "Keyword already exists",
            text: "Please enter a unique keyword",
          });
        } else {
          updateSearch({ keywords: [...(search?.keywords || []), query] });
          setQuery("");
        }
      }
    } else if (e.key === "Escape") {
      setInputActive(false);
    }
  };

  const handleSearchAcrossAll = () => {
    updateSearch({
      naics_codes: [],
      locations: [],
      keywords: query ? [query] : [],
      exclude_keywords: [],
      customers: [],
      due_after: null,
      due_before: null,
      due_date_range: "all",
      posted_after: null,
      posted_before: null,
      posted_date_range: "all",
    });
    setQuery("");
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (contentRef.current?.contains(e.relatedTarget as Node)) {
      setInputActive(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    } else {
      setInputActive(false);
    }
  };

  return (
    <div {...props} className={cn("flex", className)}>
      <Popover open={inputActive}>
        <PopoverAnchor asChild={false} ref={anchorRef} className="grow flex">
          <Input
            value={query}
            onKeyDown={handleKeydown}
            placeholder="Search"
            className={cn(
              "border-none rounded-none text-sm",
              inputActive ? "bg-background" : "bg-background-selected"
            )}
            icon="magnifying-glass"
            ref={inputRef}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => setInputActive(true)}
            onBlur={handleBlur}
          />
        </PopoverAnchor>
        <PopoverContent
          className="p-none m-none rounded-none rounded-b flex select-none"
          ref={contentRef}
          sideOffset={0}
          alignOffset={0}
          style={{ width: contentWidth + 2 }}
          onOpenAutoFocus={(e) => e.preventDefault()}
          onFocusOutside={(e) => e.preventDefault()}
        >
          <Rows className="grow text-sm">
            <div className="inline-flex px-md grow items-center text-secondary my-sm">
              <Icon name="text" className="ml-xs mr-[14px]" /> Add keywords to
              filter your results
            </div>

            <div
              className={cn(
                !!query && "border-b",
                (!!query || (search?.keywords.length ?? 0) > 0) && "-mt-sm"
              )}
            >
              {search?.keywords.map((term) => {
                return (
                  <KeyTermPill
                    key={term}
                    term={term}
                    isSelected={false}
                    onRemove={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      updateSearch({
                        keywords: search?.keywords.filter((k) => k !== term),
                      });
                    }}
                  />
                );
              })}
              {query && (
                <KeyTermPill
                  key="_"
                  term={query}
                  isSelected={false}
                  className="bg-background-selected cursor-pointer"
                  trailingIcon="turn-down-left"
                  onSelected={() => {
                    updateSearch({
                      keywords: [...(search?.keywords || []), query],
                    });
                    setQuery("");
                  }}
                />
              )}
            </div>
            {!!query && (
              <div
                className="px-md py-sm hover:bg-background-selected cursor-pointer"
                onClick={handleSearchAcrossAll}
              >
                <Icon name="magnifying-glass" className="ml-xs mr-[8px]" /> All
                search results for {query}
              </div>
            )}
          </Rows>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SearchMenuInput;
