import { RFPSearch } from "api/Api";
import DeprecatedButton from "components/common/DeprecatedButton";
import Columns from "components/common/containers/Columns";
import MessageView from "components/common/containers/MessageView";
import Overlay from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import Spacer from "components/common/containers/Spacer";
import Icon from "components/common/Icon";
import RFPDetailsView from "components/rfps/RFPDetailsView";
import RFPListItemView from "components/rfps/RFPListItemView";
import { SingleList } from "hooks/useRFPsInList";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ListActionsButton from "./ListActionsButton";
import ToggleFavoriteButton from "./ToggleFavoriteButton";
import { copyToClipboard } from "lib/clipboard";
import { useTextAreaConfirm } from "providers/AlertProvider";
import Spinner from "components/common/Spinner";
import CenteredContainer from "components/common/containers/CenteredContainer";

interface SingleListViewProps {
  singleList: SingleList;
}

export const SingleListView: FC<SingleListViewProps> = ({ singleList }) => {
  const {
    rfps,
    listName,
    listId,
    deleteList,
    hasOtherLists,
    renameList,
    addNewList,
  } = singleList;
  const navigate = useNavigate();
  const [viewingRFP, setViewingRFP] = useState<RFPSearch | null>(null);
  const textAreaConfirm = useTextAreaConfirm();

  const copyLink = (rfp: RFPSearch) => {
    copyToClipboard(`${window.location.origin}/rfps/${rfp.id}/`);
  };

  const handleAddNewList = async () => {
    const result = await textAreaConfirm("Add to New List", {
      placeholder: "Enter the name of the new list",
      multiline: false,
      defaultId: "save",
      buttons: [
        {
          text: "Cancel",
          id: "cancel",
        },
        {
          text: "Save",
          id: "save",
          variant: "solid",
        },
      ],
    });

    if (result.id !== "save" || !result.text) {
      return;
    }

    const listId = addNewList(result.text);
    navigate(`/lists/${listId}/`);
  };

  return (
    <Rows>
      <Columns className="gap-md">
        <Rows>
          <h1 className="text-2xl font-semibold">Shortlisted RFPs</h1>
          <p className="text-secondary">
            Make a go/no-go decision on your shortlisted RFPs
          </p>
        </Rows>
        <Spacer />
        <DeprecatedButton
          icon="plus"
          variant="outline"
          text="Add New List"
          onClick={handleAddNewList}
        />
      </Columns>
      <Columns className="items-baseline my-md gap-md">
        {hasOtherLists && (
          <>
            <DeprecatedButton
              navigate="/all-lists/"
              text="All Lists"
              className="px-none text-xl"
            />
            <Icon name="chevron-right" className="text-secondary" />
            <Columns className="items-baseline">
              <ToggleFavoriteButton listId={listId} />
              <h2 className="text-xl font-semibold">{listName}</h2>
              <ListActionsButton
                listName={listName}
                deleteList={deleteList}
                renameList={renameList}
              />
            </Columns>
          </>
        )}
        <p className="text-secondary">
          {rfps && (rfps.length === 1 ? "1 RFP" : `${rfps.length} RFPs`)}
        </p>
      </Columns>
      <style>
        {`
          .rfp-item-enter {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
          }
          .rfp-item-enter-active {
            opacity: 1;
            max-height: 500px;
            transition: all 300ms ease-in;
          }
          .rfp-item-exit {
            opacity: 1;
            max-height: 500px;
          }
          .rfp-item-exit-active {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 300ms ease-out;
          }
        `}
      </style>
      <Rows className="gap-md">
        <TransitionGroup component={null}>
          {rfps?.map((rfp) => (
            <CSSTransition key={rfp.id} timeout={300} classNames="rfp-item">
              <RFPListItemView
                rfp={rfp}
                onViewDetails={() => setViewingRFP(rfp)}
                showSelected={false}
                exclusiveListId={listId}
                allowStarting={true}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
        {rfps === null && (
          <CenteredContainer>
            <Spinner />
          </CenteredContainer>
        )}
        {!!rfps && rfps.length === 0 && (
          <MessageView
            className="my-lg text-center"
            title="List Empty"
            icon="inbox"
          >
            <p className="text-center max-w-[400px]">
              Find new RFPs on the RFP page and add them to this list to reivew
              them later.
            </p>
            <DeprecatedButton
              icon="magnifying-glass"
              text="Find RFPs"
              navigate="/rfps/"
              variant="solid"
            />
          </MessageView>
        )}
      </Rows>
      {viewingRFP && (
        <Overlay
          title={viewingRFP.display_name}
          extraTitleBarElements={
            <DeprecatedButton
              icon="link-simple"
              className="ml-sm"
              onClick={() => copyLink(viewingRFP)}
            />
          }
          scrollable={false}
          variant="full-screen-bordered"
          onClose={() => setViewingRFP(null)}
        >
          <RFPDetailsView rfp={viewingRFP} initialTab="ask" />
        </Overlay>
      )}
    </Rows>
  );
};
