import DeprecatedButton from "components/common/DeprecatedButton";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Toggle from "components/common/forms/Toggle";
import { cn } from "lib/utils";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

export interface State {
  abbrevation: string;
  name: string;
  xLocation: number;
  yLocation: number;
}

const ROWS = 8;
const COLUMNS = 12;
const GAP = 8;

export const STATES: State[] = [
  { abbrevation: "AK", name: "Alaska", xLocation: 1, yLocation: 1 },
  { abbrevation: "HI", name: "Hawaii", xLocation: 1, yLocation: 7 },

  { abbrevation: "WA", name: "Washington", xLocation: 2, yLocation: 3 },
  { abbrevation: "OR", name: "Oregon", xLocation: 2, yLocation: 4 },
  { abbrevation: "CA", name: "California", xLocation: 2, yLocation: 5 },

  { abbrevation: "ID", name: "Idaho", xLocation: 3, yLocation: 3 },
  { abbrevation: "NV", name: "Nevada", xLocation: 3, yLocation: 4 },
  { abbrevation: "UT", name: "Utah", xLocation: 3, yLocation: 5 },
  { abbrevation: "AZ", name: "Arizona", xLocation: 3, yLocation: 6 },

  { abbrevation: "MT", name: "Montana", xLocation: 4, yLocation: 3 },
  { abbrevation: "WY", name: "Wyoming", xLocation: 4, yLocation: 4 },
  { abbrevation: "CO", name: "Colorado", xLocation: 4, yLocation: 5 },
  { abbrevation: "NM", name: "New Mexico", xLocation: 4, yLocation: 6 },

  { abbrevation: "ND", name: "North Dakota", xLocation: 5, yLocation: 3 },
  { abbrevation: "SD", name: "South Dakota", xLocation: 5, yLocation: 4 },
  { abbrevation: "NE", name: "Nebraska", xLocation: 5, yLocation: 5 },
  { abbrevation: "KS", name: "Kansas", xLocation: 5, yLocation: 6 },
  { abbrevation: "OK", name: "Oklahoma", xLocation: 5, yLocation: 7 },
  { abbrevation: "TX", name: "Texas", xLocation: 5, yLocation: 8 },

  { abbrevation: "MN", name: "Minnesota", xLocation: 6, yLocation: 4 },
  { abbrevation: "IA", name: "Iowa", xLocation: 6, yLocation: 5 },
  { abbrevation: "MO", name: "Missouri", xLocation: 6, yLocation: 6 },
  { abbrevation: "AR", name: "Arkansas", xLocation: 6, yLocation: 7 },
  { abbrevation: "LA", name: "Louisiana", xLocation: 6, yLocation: 8 },

  { abbrevation: "WI", name: "Wisconsin", xLocation: 7, yLocation: 3 },
  { abbrevation: "IL", name: "Illinois", xLocation: 7, yLocation: 4 },
  { abbrevation: "KY", name: "Kentucky", xLocation: 7, yLocation: 5 },
  { abbrevation: "TN", name: "Tennessee", xLocation: 7, yLocation: 6 },
  { abbrevation: "MS", name: "Mississippi", xLocation: 7, yLocation: 7 },

  { abbrevation: "MI", name: "Michigan", xLocation: 8, yLocation: 3 },
  { abbrevation: "IN", name: "Indiana", xLocation: 8, yLocation: 4 },
  { abbrevation: "OH", name: "Ohio", xLocation: 8, yLocation: 5 },
  { abbrevation: "WV", name: "West Virginia", xLocation: 8, yLocation: 6 },
  { abbrevation: "AL", name: "Alabama", xLocation: 8, yLocation: 7 },

  { abbrevation: "PA", name: "Pennsylvania", xLocation: 9, yLocation: 4 },
  { abbrevation: "VA", name: "Virginia", xLocation: 9, yLocation: 5 },
  { abbrevation: "SC", name: "South Carolina", xLocation: 9, yLocation: 6 },
  { abbrevation: "GA", name: "Georgia", xLocation: 9, yLocation: 7 },

  { abbrevation: "NY", name: "New York", xLocation: 10, yLocation: 3 },
  { abbrevation: "NJ", name: "New Jersey", xLocation: 10, yLocation: 4 },
  { abbrevation: "MD", name: "Maryland", xLocation: 10, yLocation: 5 },
  { abbrevation: "NC", name: "North Carolina", xLocation: 10, yLocation: 6 },
  { abbrevation: "FL", name: "Florida", xLocation: 10, yLocation: 8 },

  { abbrevation: "VT", name: "Vermont", xLocation: 11, yLocation: 2 },
  { abbrevation: "MA", name: "Massachusetts", xLocation: 11, yLocation: 3 },
  { abbrevation: "CT", name: "Connecticut", xLocation: 11, yLocation: 4 },
  { abbrevation: "DE", name: "Delaware", xLocation: 11, yLocation: 5 },

  { abbrevation: "ME", name: "Maine", xLocation: 12, yLocation: 1 },
  { abbrevation: "NH", name: "New Hampshire", xLocation: 12, yLocation: 2 },
  { abbrevation: "RI", name: "Rhode Island", xLocation: 12, yLocation: 3 },
];

interface DragState {
  originX: number;
  originY: number;
  targetX: number;
  targetY: number;
}

interface StatePickerProps {
  stateLocations: string[];
  setStateLocations: Dispatch<SetStateAction<string[]>>;
  className?: string;
  mode?: "map" | "list";
}

const StatePicker: FC<StatePickerProps> = ({
  stateLocations,
  setStateLocations,
  className,
  mode = "map",
}) => {
  const [dragState, setDragState] = useState<DragState | null>(null);
  const [selectionRect, setSelectionRect] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
  } | null>(null);
  const [highlightedStates, setHighlightedStates] = useState<
    Record<string, boolean>
  >({});
  const containerRef = useRef<HTMLDivElement>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const isAllSelected = Object.keys(stateLocations).length === STATES.length;

  const handleAllClick = () => {
    if (isAllSelected) {
      setStateLocations([]);
    } else {
      setStateLocations(
        Object.values(STATES).map((state) => state.abbrevation)
      );
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!mapContainerRef.current) return;

    const containerRect = mapContainerRef.current.getBoundingClientRect();
    const relativeX = event.clientX - containerRect.left;
    const relativeY = event.clientY - containerRect.top;

    setDragState({
      originX: relativeX,
      originY: relativeY,
      targetX: relativeX,
      targetY: relativeY,
    });
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // setShowingMap(entry.contentRect.width > 420);
      }
    });
    observer.observe(containerRef.current);
  }, [containerRef]);

  useEffect(() => {
    if (!dragState || !mapContainerRef.current) {
      setHighlightedStates({});
      setSelectionRect(null);
      return;
    }
    const topLeft = {
      x: Math.min(dragState.originX, dragState.targetX),
      y: Math.min(dragState.originY, dragState.targetY),
    };
    const bottomRight = {
      x: Math.max(dragState.originX, dragState.targetX),
      y: Math.max(dragState.originY, dragState.targetY),
    };
    setSelectionRect({
      top: topLeft.y,
      left: topLeft.x,
      width: bottomRight.x - topLeft.x,
      height: bottomRight.y - topLeft.y,
    });

    setHighlightedStates(
      getSelectedStates(
        topLeft.x,
        topLeft.y,
        bottomRight.x - topLeft.x,
        bottomRight.y - topLeft.y,
        mapContainerRef.current.clientWidth,
        mapContainerRef.current.clientHeight
      )
    );
  }, [dragState]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setDragState(null);
      }
    };

    const handleMouseUp = () => {
      setDragState(null);
      if (!dragState || Object.keys(highlightedStates).length === 0) return;

      setStateLocations((prev) => {
        const keys = Object.keys(highlightedStates);
        const alreadySelected = keys.filter((key) => prev.indexOf(key) !== -1);
        const notAlreadySelected = keys.filter(
          (key) => prev.indexOf(key) === -1
        );

        if (notAlreadySelected.length === 0 && alreadySelected.length === 0)
          return prev;
        if (notAlreadySelected.length === 0) {
          // Toggle all of the keys off
          return prev.filter((state) => !keys.includes(state));
        }
        // Toggle all of the keys on (that aren't already on)
        return [...prev, ...notAlreadySelected];
      });
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!dragState) return;
      if (!mapContainerRef.current) return;

      const containerRect = mapContainerRef.current.getBoundingClientRect();
      const relativeX = event.clientX - containerRect.left;
      const relativeY = event.clientY - containerRect.top;

      setDragState((prev) => {
        if (!prev) return null;
        return { ...prev, targetX: relativeX, targetY: relativeY };
      });
    };

    // Add both event listeners
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("keydown", handleKeyDown);

    // Clean up both listeners
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [highlightedStates, setStateLocations]); // Add selectState as dependency

  return (
    <Rows
      className={cn("w-full grow relative", className)}
      ref={containerRef}
      onMouseDown={handleMouseDown}
    >
      {mode === "list" && (
        <div className="items-start justify-start min-[600px]:columns-3 min-[400px]:columns-2 mt-sm">
          {STATES.map((state) => (
            <div key={state.abbrevation}>
              <Toggle
                key={state.abbrevation}
                toggleType="checkbox"
                text={state.name}
                on={stateLocations.includes(state.abbrevation) ?? false}
                onToggle={() =>
                  setStateLocations((prev) =>
                    prev.includes(state.abbrevation)
                      ? prev.filter((s) => s !== state.abbrevation)
                      : [...prev, state.abbrevation]
                  )
                }
              />
            </div>
          ))}
        </div>
      )}
      {mode === "map" && (
        <div
          className="grid w-full select-none relative overflow-hidden p-md"
          style={{
            gridTemplateRows: `repeat(${ROWS}, minmax(0, 1fr))`,
            gridTemplateColumns: `repeat(${COLUMNS}, minmax(0, 1fr))`,
            gap: `${GAP}px`,
          }}
          ref={mapContainerRef}
        >
          {STATES.map((state) => (
            <Rows
              className={cn(
                "aspect-square rounded-sm flex items-center justify-center text-md font-semibold cursor-pointer",
                stateLocations.indexOf(state.abbrevation) !== -1
                  ? "bg-primary text-background"
                  : highlightedStates[state.abbrevation]
                  ? "bg-border"
                  : "bg-background-selected"
              )}
              style={{ gridColumn: state.xLocation, gridRow: state.yLocation }}
            >
              {state.abbrevation}
            </Rows>
          ))}
          <Rows
            style={{ gridRow: 1, gridColumnStart: 2, gridColumnEnd: 12 }}
            className="items-center justify-center"
          ></Rows>
          {selectionRect && (
            <div
              className="absolute bg-primary opacity-20"
              style={selectionRect}
            ></div>
          )}
        </div>
      )}
    </Rows>
  );
};

const getSelectedStates = (
  selectedX: number, // in pixels
  selectedY: number, // in pixels
  selectedWidth: number, // in pixels
  selectedHeight: number, // in pixels
  containerWidth: number,
  containerHeight: number
): Record<string, boolean> => {
  const states: Record<string, boolean> = {};

  const cellWidth = containerWidth / COLUMNS;
  const cellHeight = containerHeight / ROWS;

  const minX = Math.floor(selectedX / cellWidth) + 1;
  const maxX = Math.ceil((selectedX + selectedWidth) / cellWidth);
  const minY = Math.floor(selectedY / cellHeight) + 1;
  const maxY = Math.ceil((selectedY + selectedHeight) / cellHeight);

  for (const state of STATES) {
    if (
      state.xLocation >= minX &&
      state.xLocation <= maxX &&
      state.yLocation >= minY &&
      state.yLocation <= maxY
    ) {
      states[state.abbrevation] = true;
    }
  }
  return states;
};

export default StatePicker;
