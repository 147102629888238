import { FC, useRef, useState } from "react";
import PromptEditor, { PromptEditorRef } from "./PromptEditor";
import { cn } from "../../../lib/utils";
import DeprecatedButton from "../../common/DeprecatedButton";
import PromptVariableBar from "./PromptVariableBar";
import { PromptVariable } from "./types";
import { PromptContent } from "./PromptContent";
import { usePromptRefineryContext } from "./PromptRefineryProvider";
import { IfElseVarProps } from "./plugins/IfElsePlugin";

interface IfElseEditorProps {
  element: IfElseVarProps;
  className?: string;
  onClose?: () => void;
}

const IfElseEditor: FC<IfElseEditorProps> = ({
  className,
  element,
  onClose,
}) => {
  const { data } = usePromptRefineryContext();
  const ifTrueEditor = useRef<PromptEditorRef>(null);
  const ifFalseEditor = useRef<PromptEditorRef>(null);
  const [focused, setFocusedEditor] = useState<"ifTrue" | "ifFalse" | null>(
    null
  );

  const handleSelectedVariable = (
    variable: PromptVariable,
    as: "text" | "condition"
  ) => {
    if (focused === "ifFalse") {
      ifFalseEditor.current?.insertVariable(variable, as);
    } else {
      ifTrueEditor.current?.insertVariable(variable, as);
    }
  };

  const handleAddVariable = () => {};

  return (
    <div
      className={cn(
        "flex flex-col items-stretch min-h-[300px] p-md gap-md relative",
        className
      )}
    >
      <DeprecatedButton
        icon="xmark"
        className="absolute right-sm top-0"
        size="large"
        onClick={onClose}
      />
      <div className="grow basis-0 flex flex-col gap-xs">
        <PromptVariableBar
          variables={Object.values(data.getVariables())}
          onSelectedVariable={handleSelectedVariable}
          onAddVariable={handleAddVariable}
          className="b-xs"
        />
        <h1 className="font-semibold">If True</h1>
        <div className="border p-md rounded-sm grow">
          <PromptEditor
            ref={ifTrueEditor}
            id={PromptContent.idForIfElseVar(element.id, true)}
            onFocus={() => setFocusedEditor("ifTrue")}
          />
        </div>
      </div>
      <div className="grow basis-0 flex flex-col gap-xs">
        <h1 className="font-semibold">If False</h1>
        <div className="border p-md rounded-sm grow">
          <PromptEditor
            ref={ifFalseEditor}
            id={PromptContent.idForIfElseVar(element.id, false)}
            onFocus={() => setFocusedEditor("ifFalse")}
          />
        </div>
      </div>
    </div>
  );
};

export default IfElseEditor;
