import { useState, useImperativeHandle } from "react";
import { message_from_exception } from "utils";
import DeprecatedButton from "../DeprecatedButton";
import Input from "./Input";
import { cn } from "lib/utils";
import { IconName } from "../Icon";
import TextArea from "./TextArea";
import LabeledFormInput from "./LabeledFormInput";
import AsyncLoadedDiv from "../containers/AsyncLoadedDiv";

export interface BasicFormModel {
  [key: string]: string | string[];
}

export interface BasicFormRef {
  reset: () => void;
}

type InputType = "text" | "long" | "file" | "string_list";

interface BasicFormProps<Model extends BasicFormModel> {
  className?: string;
  title?: string;
  initialModel: Model;
  inputNames: Record<keyof Model, string>;
  inputTypes?: Partial<Record<keyof Model, InputType>>;
  inputOnChange?: Partial<Record<keyof Model, (value: string) => string>>;
  submitOnEnter?: Partial<Record<keyof Model, boolean>>;
  submitText: string;
  submitIcon?: IconName;
  longMinHeight?: number;
  onSubmit: (model: Model, resetForm: () => void) => Promise<void>;
  formRef?: React.Ref<BasicFormRef>;
}

const BasicForm = <T extends BasicFormModel>({
  className,
  title,
  initialModel,
  inputNames,
  inputTypes,
  onSubmit,
  inputOnChange,
  submitText = "Submit",
  submitOnEnter,
  longMinHeight = 460,
  submitIcon,
  formRef,
}: BasicFormProps<T>) => {
  const [values, setValues] = useState<T>(initialModel);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(formRef, () => ({
    reset: () => {
      setValues(initialModel);
      setError(null);
      setIsLoading(false);
    },
  }));

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await onSubmit(values, () => {
        setValues(initialModel);
        setError(null);
      });
      setValues(initialModel);
    } catch (e) {
      setError(message_from_exception(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AsyncLoadedDiv
      className={cn("flex flex-col gap-md", className)}
      value={isLoading ? null : {}}
      whileLoaded={() => (
        <>
          {title && <h1 className="text-lg font-semibold">{title}</h1>}
          <div className="flex flex-col gap-md">
            {Object.entries(inputNames).map(([key, label]) => {
              return (
                <LabeledFormInput label={label} key={key}>
                  <BasicFormElement
                    type={inputTypes?.[key] ?? "text"}
                    value={values[key]}
                    longMinHeight={longMinHeight}
                    onChange={(newValue) => {
                      const onChange = inputOnChange?.[key];
                      if (onChange) {
                        newValue = onChange(newValue as string);
                      }
                      setValues({ ...values, [key]: newValue } as any);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && (submitOnEnter?.[key] ?? true)) {
                        handleSubmit();
                      }
                    }}
                  />
                </LabeledFormInput>
              );
            })}
            <DeprecatedButton
              icon={submitIcon}
              text={submitText}
              variant="solid"
              onClick={handleSubmit}
              className="ml-auto"
            />
            {error && <p className="text-destructive text-center">{error}</p>}
          </div>
        </>
      )}
    />
  );
};

interface BasicFormElementProps {
  type: InputType;
  value: string | string[];
  longMinHeight: number;
  onChange: (newValue: string | File) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const BasicFormElement = ({
  type,
  value,
  longMinHeight,
  onChange,
  onKeyDown,
}: BasicFormElementProps) => {
  if (type === "long") {
    return (
      <TextArea
        className="min-h-[460px]"
        value={Array.isArray(value) ? value.join(",") : value}
        style={{ minHeight: longMinHeight.toString() + "px" }}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    );
  } else if (type === "file") {
    return (
      <Input
        type="file"
        onChange={(e) => e.target.files && onChange(e.target.files[0])}
        onKeyDown={onKeyDown}
      />
    );
  }
  return <Input value={value} onChange={(e) => onChange(e.target.value)} />;
};

export default BasicForm;
