import { TElement } from "@udecode/plate-common";
import { debounce } from "lodash";
import CoreApi from "../../../api/CoreApi";
import PromptVariables from "./PromptVariables";
import { PromptVariable, VariableValueGroup } from "./types";
import PromptStorage from "./PromptStorage";
import { PromptTool } from "types/Prompt";

export class PromptContent {
  private promptId: number;
  private allContent: Record<string, TElement[]>;
  private apiClient: CoreApi;
  private hasChanged: boolean;
  private onChangedCallbacks: ((hasChanged: boolean) => void)[] = [];
  private variables: PromptVariables;
  private storage: PromptStorage;

  private static SYSTEM_ID = "system";
  private static USER_ID = "user";

  public static idForIfElseVar(elementId: string, condition: boolean) {
    return elementId + (condition ? "-if-true" : "-if-false");
  }

  public get variableCache() {
    return this.variables;
  }

  public static getSystemId(variationId: number | null) {
    return variationId
      ? `${PromptContent.SYSTEM_ID}-${variationId}`
      : PromptContent.SYSTEM_ID;
  }

  public static getUserId(variationId: number | null) {
    return variationId
      ? `${PromptContent.USER_ID}-${variationId}`
      : PromptContent.USER_ID;
  }

  static async load(
    promptId: number,
    apiClient: CoreApi
  ): Promise<{
    content: PromptContent;
    variables: Record<string, PromptVariable>;
    valueGroups: VariableValueGroup[];
    variations: number[];
    name: string;
    variationModel: Record<number, string>;
    variationNotes: Record<number, string>;
    variationTools: Record<number, PromptTool[]>;
    activeVariation: number | null;
  }> {
    const storage = new PromptStorage(promptId);
    const hasChanged = storage.hasChanged;
    if (!hasChanged) {
      // Content has not been changed locally, load from server
      const result = await apiClient.admin.promptRefineryPromptRead(promptId);
      const rawContent = result.data.content as any;
      const content = new PromptContent(promptId, rawContent, apiClient, false);
      const name = result.data.name!;
      const variables = result.data.variables! as any;
      const valueGroups = result.data.value_groups! as any;
      const variations = result.data.variations! as any;
      const variationModel = result.data.variation_model! as any;
      const variationNotes = result.data.variation_notes! as any;
      const activeVariation = result.data.active_variation ?? null;
      const variationTools = result.data.variation_tools! as any;

      storage.content = rawContent;
      storage.variables = variables;
      storage.valueGroups = valueGroups;
      storage.variationNotes = variationNotes;
      storage.variations = variations;
      storage.variationModel = variationModel;
      storage.promptName = name;
      storage.hasChanged = false;
      storage.activeVariation = activeVariation;
      storage.variationTools = variationTools;

      return {
        content,
        variables,
        name,
        valueGroups,
        variations,
        variationModel: variationModel,
        variationNotes,
        variationTools,
        activeVariation,
      };
    } else {
      // The prompt has been changed locally, use from there

      const content = new PromptContent(
        promptId,
        storage.content,
        apiClient,
        storage.hasChanged
      );
      return {
        content,
        variables: storage.variables,
        name: storage.promptName,
        valueGroups: storage.valueGroups,
        variations: storage.variations,
        variationModel: storage.variationModel,
        variationNotes: storage.variationNotes,
        variationTools: storage.variationTools,
        activeVariation: storage.activeVariation,
      };
    }
  }

  public saveMeta({
    variables,
    valueGroups,
    variations,
    variationModel,
    variationNotes,
    variationTools,
    activeVariation,
  }: {
    variables: Record<string, PromptVariable>;
    valueGroups: VariableValueGroup[];
    variations: number[];
    variationModel: Record<number, string>;
    variationNotes: Record<number, string>;
    variationTools: Record<number, PromptTool[]>;
    activeVariation: number | null;
  }) {
    this.storage.variables = variables;
    this.storage.valueGroups = valueGroups;
    this.storage.variations = variations;
    this.storage.variationModel = variationModel;
    this.storage.variationTools = variationTools;
    this.storage.hasChanged = true;
    this.storage.variationNotes = variationNotes;
    this.storage.activeVariation = activeVariation;
    this.updateHasChanged(true);
  }

  private constructor(
    promptId: number,
    allContent: Record<string, TElement[]>,
    apiClient: CoreApi,
    hasChanged: boolean
  ) {
    this.promptId = promptId;
    this.apiClient = apiClient;
    this.variables = new PromptVariables(promptId, apiClient);
    this.hasChanged = hasChanged;
    this.allContent = allContent;
    this.storage = new PromptStorage(promptId);
  }

  public get hasChanges() {
    return this.hasChanged;
  }

  public registerOnChanged(callback: (hasChanged: boolean) => void) {
    this.onChangedCallbacks.push(callback);
  }

  public unregisterOnChanged(callback: (hasChanged: boolean) => void) {
    this.onChangedCallbacks = this.onChangedCallbacks.filter(
      (cb) => cb !== callback
    );
  }

  public setContent(id: string, content: TElement[]) {
    if (JSON.stringify(this.allContent[id]) === JSON.stringify(content)) {
      return;
    }
    this.allContent[id] = content;
    this.updateHasChanged(true);
    this.saveLocally();
  }

  public copyContent(fromId: string, toId: string) {
    this.allContent[toId] = this.allContent[fromId];
    this.updateHasChanged(true);
    this.saveLocally();
  }

  public getContent(id: string) {
    let content = this.allContent[id];
    if (content) {
      return content;
    }
    return [{ type: "p", children: [{ text: "" }] }];
  }

  public async saveToServer(
    variables: object,
    valueGroups: VariableValueGroup[],
    variations: number[],
    variationModel: Record<number, string>,
    variationNotes: Record<number, string>,
    variationTools: Record<number, PromptTool[]>,
    activeVariation: number | null
  ) {
    await this.apiClient.admin.promptRefineryPromptPartialUpdate(
      this.promptId,
      {
        content: this.allContent,
        variables,
        value_groups: valueGroups,
        variations,
        variation_model: variationModel,
        variation_notes: variationNotes,
        variation_tools: variationTools,
        active_variation: activeVariation,
      }
    );
    this.updateHasChanged(false);
  }

  public clearLocally() {
    this.storage.clear();
  }

  private saveLocally = debounce(async () => {
    this.immediatelySaveLocally();
  }, 500);

  private immediatelySaveLocally() {
    this.storage.content = this.allContent;
  }

  private updateHasChanged(newValue: boolean) {
    this.storage.hasChanged = newValue;
    this.hasChanged = newValue;
    this.onChangedCallbacks.forEach((cb) => cb(newValue));
  }
}
