import Rows from "components/common/containers/Rows";
import { cn } from "lib/utils";
import SectionDetailsPanel from "./SectionDetailsPanel";
import ReferencesPanel from "./ReferencesPanel";
import {
  RightPanel,
  useEditorDocData,
} from "providers/RequirementContentEditorProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import ContentLibraryPanel from "./ContentLibraryPanel";
import { useMemo } from "react";
import Button from "components/common/Button";

export const RightEditorToolbar: React.FC = () => {
  const { activeRightPanel, setActiveRightPanel } = useEditorDocData();
  const { isWriter } = useAuthenticatedUser();

  const getClickHandler = (panel: RightPanel) => () => {
    if (activeRightPanel === panel) {
      setActiveRightPanel(null);
    } else {
      setActiveRightPanel(panel);
    }
  };

  return (
    <Rows
      className={cn(
        "shrink-0 gap-sm px-md py-sm",
        "pointer-events-none",
        !!activeRightPanel ? "mr-xs" : "absolute right-xs top-0 bottom-0"
      )}
    >
      {isWriter && (
        <Button
          tooltip="Section Details"
          icon="memo-circle-info"
          variant="round"
          emphasis={
            activeRightPanel === "section-details" ? "primary" : "secondary"
          }
          onClick={getClickHandler("section-details")}
          className="admin pointer-events-auto"
        />
      )}
      <Button
        tooltip="References"
        icon="file"
        iconVariant="solid"
        variant="round"
        emphasis={activeRightPanel === "references" ? "primary" : "secondary"}
        onClick={getClickHandler("references")}
        className="pointer-events-auto"
      />
      <Button
        tooltip="Content Library"
        icon="book-open-cover"
        iconVariant="solid"
        variant="round"
        emphasis={
          activeRightPanel === "content-library" ? "primary" : "secondary"
        }
        onClick={getClickHandler("content-library")}
        className="pointer-events-auto"
      />
    </Rows>
  );
};

export const useRightEditorContent = () => {
  const { activeRightPanel } = useEditorDocData();

  const element = useMemo(() => {
    return (
      <>
        {activeRightPanel === "section-details" && <SectionDetailsPanel />}
        <Rows
          className={cn(
            "grow",
            activeRightPanel !== "references" ? "hidden" : ""
          )}
        >
          <ReferencesPanel />
        </Rows>
        <Rows
          className={cn(
            "grow",
            activeRightPanel !== "content-library" ? "hidden" : ""
          )}
        >
          <ContentLibraryPanel />
        </Rows>
      </>
    );
  }, [activeRightPanel]);

  return {
    visible: !!activeRightPanel,
    element,
  };
};
