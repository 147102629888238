import { FC, ReactNode } from "react";
import { useRouteError } from "react-router-dom";
import Icon, { IconName } from "./common/Icon";
import TypingAnimation from "./common/TypingAnimation";
import { message_from_exception } from "utils";
import DeprecatedButton from "./common/DeprecatedButton";

import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

interface ErrorPageProps {
  error?: any;
}

const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  let icon: IconName;
  let title: string;
  let body: ReactNode;
  switch (
    ("status" in error && error.status) ||
    (error instanceof AxiosError && error.response?.status) ||
    ("message" in error && error.message)
  ) {
    case 404:
      icon = "square-question";
      title = "Not Found";
      body = (
        <>
          <TypingAnimation
            options={[
              "I'm sorry, I couldn't find the page you're looking for.",
            ]}
            type="once"
          />
          <DeprecatedButton
            text="Go Home"
            variant="solid"
            onClick={() => (window.location.href = "/")}
          />
        </>
      );
      break;
    case 500:
    case 502:
    case "Network Error":
      Sentry.captureException(error);
      icon = "triangle-person-digging";
      title = "Under Maintenance";
      body = (
        <>
          <TypingAnimation
            options={["I'm sorry for the inconvenience."]}
            type="once"
          />
          <p className="text-secondary text-center">
            This should only take a few minutes.
            <br />
            If it takes longer, please{" "}
            <a className="text-primary" href="mailto:support@odo.do">
              contact support
            </a>
            .
          </p>
          <div className="flex gap-lg">
            <DeprecatedButton
              text="Refresh"
              variant="solid"
              onClick={() => window.location.reload()}
            />
          </div>
        </>
      );
      break;

    default:
      if (
        error instanceof AxiosError &&
        error.response?.status === 401 &&
        !window.location.pathname.startsWith("/login")
      ) {
        window.location.href = "/login";
        return null;
      }
      Sentry.captureException(error);
      icon = "square-exclamation";
      title = "Something Went Wrong";
      body = (
        <>
          <p className="text-secondary">{message_from_exception(error)}</p>
          <div className="flex gap-lg">
            <DeprecatedButton
              text="Try Again"
              variant="solid"
              onClick={() => window.location.reload()}
            />
            <DeprecatedButton
              text="Go Home"
              variant="solid-secondary"
              onClick={() => (window.location.href = "/")}
            />
          </div>
        </>
      );
  }
  return (
    <div className="grow bg-backround-secondary flex flex-col items-center justify-center gap-lg h-screen overflow-y-auto">
      <div className="flex flex-col items-center gap-md">
        <Icon name={icon} variant="solid" size="huge" />
        <h1 className="text-lg font-semibld">{title}</h1>
      </div>
      {body}
    </div>
  );
};

export const RouteErrorPage = () => {
  const routeError = useRouteError() as any;
  return <ErrorPage error={routeError} />;
};

export default ErrorPage;
