import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";

type ColorGroup = {
  title: string;
  colors: {
    name: string;
    variants: string[];
  }[];
};

const colorGroups: ColorGroup[] = [
  {
    title: "Background Colors",
    colors: [
      {
        name: "background",
        variants: [
          "bg-background-lighter",
          "bg-background",
          "bg-background-darker",
        ],
      },
      {
        name: "background-selected",
        variants: [
          "bg-background-selected-lighter",
          "bg-background-selected",
          "bg-background-selected-darker",
        ],
      },
      {
        name: "background-secondary",
        variants: [
          "bg-background-secondary-lighter",
          "bg-background-secondary",
          "bg-background-secondary-darker",
        ],
      },
      {
        name: "background-subtle",
        variants: [
          "bg-background-subtle-lighter",
          "bg-background-subtle",
          "bg-background-subtle-darker",
        ],
      },
      {
        name: "border",
        variants: ["bg-border-lighter", "bg-border", "bg-border-darker"],
      },
    ],
  },
  {
    title: "Text Colors",
    colors: [
      {
        name: "foreground",
        variants: [
          "text-foreground-lighter",
          "text-foreground",
          "text-foreground-darker",
        ],
      },
      {
        name: "placeholder",
        variants: [
          "text-placeholder-lighter",
          "text-placeholder",
          "text-placeholder-darker",
        ],
      },
    ],
  },
  {
    title: "Accent Colors",
    colors: [
      {
        name: "primary",
        variants: ["bg-primary-lighter", "bg-primary", "bg-primary-darker"],
      },
      {
        name: "secondary",
        variants: [
          "bg-secondary-lighter",
          "bg-secondary",
          "bg-secondary-darker",
        ],
      },
      {
        name: "tertiary",
        variants: ["bg-tertiary-lighter", "bg-tertiary", "bg-tertiary-darker"],
      },
    ],
  },
  {
    title: "UI Colors",
    colors: [
      {
        name: "accent",
        variants: ["bg-accent-lighter", "bg-accent", "bg-accent-darker"],
      },
      {
        name: "accent-focused",
        variants: [
          "bg-accent-focused-lighter",
          "bg-accent-focused",
          "bg-accent-focused-darker",
        ],
      },
      {
        name: "highlight",
        variants: [
          "bg-highlight-lighter",
          "bg-highlight",
          "bg-highlight-darker",
        ],
      },
    ],
  },
  {
    title: "Comment Colors",
    colors: [
      {
        name: "comment",
        variants: ["bg-comment-lighter", "bg-comment", "bg-comment-darker"],
      },
      {
        name: "comment-two",
        variants: [
          "bg-comment-two-lighter",
          "bg-comment-two",
          "bg-comment-two-darker",
        ],
      },
      {
        name: "comment-selected",
        variants: [
          "bg-comment-selected-lighter",
          "bg-comment-selected",
          "bg-comment-selected-darker",
        ],
      },
      {
        name: "comment-odo",
        variants: [
          "bg-comment-odo-lighter",
          "bg-comment-odo",
          "bg-comment-odo-darker",
        ],
      },
    ],
  },
  {
    title: "Status Colors",
    colors: [
      {
        name: "additive",
        variants: ["bg-additive-lighter", "bg-additive", "bg-additive-darker"],
      },
      {
        name: "warning",
        variants: ["bg-warning-lighter", "bg-warning", "bg-warning-darker"],
      },
      {
        name: "destructive",
        variants: [
          "bg-destructive-lighter",
          "bg-destructive",
          "bg-destructive-darker",
        ],
      },
    ],
  },
];

const ColorsPlayground = () => {
  return (
    <Scrollable className="">
      <Rows className="gap-xl p-md items-start">
        {colorGroups.map((group) => (
          <Rows key={group.title} className="gap-md">
            <h2 className="text-xl font-semibold">{group.title}</h2>
            <Rows className="gap-sm">
              {group.colors.map((color) => (
                <div
                  key={color.name}
                  className="grid grid-cols-[200px_1fr] gap-md"
                >
                  <span className="text-sm font-medium">{color.name}</span>
                  <div className="grid grid-cols-3 gap-sm">
                    {color.variants.map((variant, index) => (
                      <div
                        key={variant}
                        className="h-lg w-xl flex items-center justify-center"
                      >
                        <div
                          className={variant + " w-full h-full rounded border"}
                          style={{ backgroundColor: variant }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Rows>
          </Rows>
        ))}
      </Rows>
    </Scrollable>
  );
};

export default ColorsPlayground;
