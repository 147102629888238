import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Hook for managing playground state with URL persistence
 * @param key The key to use in the URL search params
 * @param defaultValue The default value to use if no value is in the URL
 * @returns [value, setValue] tuple similar to useState
 */
export function usePlaygroundState<T>(
  key: string,
  defaultValue: T
): [T, (value: T | ((prev: T) => T)) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<T>(() => {
    const urlValue = searchParams.get(key);
    if (urlValue) {
      try {
        return JSON.parse(urlValue) as T;
      } catch {
        return defaultValue;
      }
    }
    return defaultValue;
  });

  useEffect(() => {
    const urlValue = searchParams.get(key);
    const stringifiedValue = JSON.stringify(value);

    if (urlValue !== stringifiedValue) {
      setSearchParams(
        (prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.set(key, stringifiedValue);
          return newParams;
        },
        { replace: true }
      );
    }
  }, [value, key, searchParams, setSearchParams]);

  const setValueWithUrlSync = useCallback((newValue: T | ((prev: T) => T)) => {
    setValue(newValue);
  }, []);

  return [value, setValueWithUrlSync];
}
