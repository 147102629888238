import { createPlateEditor, createPlugins, KEY_DESERIALIZE_HTML, } from "@udecode/plate";
import { createOdoEditorPlugin } from "./odo-editor/index.js";
import { createBasicElementsPlugin } from "@udecode/plate-basic-elements";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { createBasicMarksPlugin } from "@udecode/plate-basic-marks";
import { createLinkPlugin } from "@udecode/plate-link";
import { createHighlightPlugin } from "@udecode/plate-highlight";
import { createCodeBlockPlugin } from "@udecode/plate-code-block";
import { createIndentPlugin } from "@udecode/plate-indent";
import { createTablePlugin } from "@udecode/plate-table";
import { createIndentListPlugin } from "@udecode/plate-indent-list";
import { createCodeBlockAdjustmentsPlugin } from "./code-block-adjustments/index.js";
import { createListAdjustmentsPlugin } from "./list-adjustments/createListAdjustmentsPlugin.js";
import onTableKeyDown from "./table/onTableKeyDown.js";
import getIndentListNode from "./list-adjustments/getIndentListNode.js";
import { createIniitalChecksPlugin } from "./initial-checks/createInitialChecksPlugin.js";
import createReferencesPlugin from "./references/index.js";
export const getCorePluginsList = (transformIndentListNodeData) => {
    return [
        createOdoEditorPlugin(),
        createBasicElementsPlugin(),
        createBasicMarksPlugin(),
        createHighlightPlugin({ options: { hotkey: "mod+option+h" } }),
        createReferencesPlugin(),
        createLinkPlugin(),
        createCodeBlockPlugin(),
        // createSectionControlPlugin(),
        createCodeBlockAdjustmentsPlugin(),
        // @ts-ignore
        createIndentPlugin({
            handlers: {
                onKeyDown: onTableKeyDown,
            },
            inject: {
                props: {
                    validTypes: [ELEMENT_PARAGRAPH, "UL", "OL"],
                },
            },
        }),
        // @ts-ignore
        createIndentListPlugin({
            inject: {
                props: {
                    validTypes: [ELEMENT_PARAGRAPH],
                },
                pluginsByKey: {
                    [KEY_DESERIALIZE_HTML]: {
                        editor: {
                            insertData: {
                                transformData: transformIndentListNodeData,
                            },
                        },
                    },
                },
            },
            deserializeHtml: {
                getNode: getIndentListNode,
            },
        }),
        createListAdjustmentsPlugin(),
        createTablePlugin({
            options: {
                disableExpandOnInsert: true,
                minColumnWidth: 50,
            },
        }),
        // MUST BE LAST
        // This allows it to intercept all other plugins
        // as it is the last one to overwrite any editor functions
        createIniitalChecksPlugin(),
    ];
};
export const createCorePlugins = (components, transformIndentListNodeData) => 
// @ts-ignore
createPlugins(getCorePluginsList(transformIndentListNodeData), {
    components,
});
export const createCoreEditor = (components = {}, transformIndentListNodeData = (data) => data) => createPlateEditor({
    // @ts-ignore
    plugins: createCorePlugins(components, transformIndentListNodeData),
});
