import NavigationView from "components/common/NavigationView";
import { MultipleListsSplashView } from "components/lists/MultipleListsSplashView";
import { SingleListView } from "components/lists/SingleListView";
import ConnectionLost from "components/common/ConnectionLost";
import useRFPsInList from "hooks/useRFPsInList";
import { ShortListsProvider } from "providers/ShortListsProvider";
import { FC } from "react";
import { useParams } from "react-router-dom";
import ConnectionLostPill from "components/common/ConnecionLostPill";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Spinner from "components/common/Spinner";

interface ListsRouteProps {
  forceAll?: boolean;
}

const ListsRoute: FC<ListsRouteProps> = (props) => {
  return (
    <NavigationView selected="lists">
      <ShortListsProvider>
        <ListsContent {...props} />
      </ShortListsProvider>
    </NavigationView>
  );
};

const ListsContent: FC<ListsRouteProps> = ({ forceAll }) => {
  const { listId } = useParams();
  const rfpsInList = useRFPsInList(listId, forceAll);

  let content: React.ReactNode;
  switch (rfpsInList.id) {
    case "loading":
      content = (
        <CenteredContainer>
          <Spinner />
        </CenteredContainer>
      );
      break;
    case "single":
      content = <SingleListView singleList={rfpsInList} />;
      break;
    case "multiple":
      content = <MultipleListsSplashView multipleLists={rfpsInList} />;
      break;
    case "authentication_failure":
      content = <div>Authentication failure</div>;
      break;
    case "closed":
      content = <ConnectionLost className="absolute inset-0" />;
      break;
  }

  if (rfpsInList.status === "reconnecting") {
    return (
      <>
        {content}
        <div className="absolute bottom-sm left-0 right-0 flex justify-center">
          <ConnectionLostPill reconnect={rfpsInList.refreshConnection} />
        </div>
      </>
    );
  }

  return content;
};

export default ListsRoute;
