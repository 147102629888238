import { FC } from "react";
import { PromptVariable } from "./types";
import {
  DeprecatedDropdownMenu,
  DeprecatedDropdownMenuContent,
  DeprecatedDropdownMenuTrigger,
} from "components/EditorView/Menus/DeprecatedDropdownMenu";
import DeprecatedButton from "components/common/DeprecatedButton";
import Input from "components/common/forms/Input";
import { usePromptRefineryContext } from "./PromptRefineryProvider";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import TextArea from "components/common/forms/TextArea";

interface PromptVariableConfigProps {
  variable: PromptVariable;
  handleVariableSelected: (
    variable: PromptVariable,
    as: "text" | "condition"
  ) => void;
}

const PromptVariableConfig: FC<PromptVariableConfigProps> = ({
  variable,
  handleVariableSelected,
}) => {
  const { data } = usePromptRefineryContext();

  return (
    <span className="bg-comment-odo rounded-sm cursor-pointer flex gap-xs items-stretch hover:bg-primary-hover whitespace-nowrap">
      <DeprecatedDropdownMenu defaultOpen={false}>
        <DeprecatedDropdownMenuTrigger>
          <p className="pl-xs">{variable.name}</p>
        </DeprecatedDropdownMenuTrigger>
        <DeprecatedDropdownMenuContent asChild className="z-[8888888888]">
          <div
            className="p-sm flex flex-col gap-md items-stretch min-w-[800px]"
            onClick={(e) => e.stopPropagation()}
          >
            <LabeledFormInput label="Name">
              <Input
                variant="menu"
                value={variable.name}
                onChange={(e) =>
                  data.updateVariable({ id: variable.id, name: e.target.value })
                }
              />
            </LabeledFormInput>
            <LabeledFormInput label="Content">
              <TextArea
                value={variable.content}
                className="p-sm min-h-[50px] text-sm resize bg-popover"
                onChange={(text) =>
                  data.updateVariable({
                    id: variable.id,
                    content: text,
                  })
                }
              />
            </LabeledFormInput>
            <DeprecatedButton
              text="Delete"
              variant="outline"
              className="ml-auto border border-destructive text-destructive"
              onClick={() => data.removeVariable(variable.id)}
            />
          </div>
        </DeprecatedDropdownMenuContent>
      </DeprecatedDropdownMenu>
      <DeprecatedButton
        className="px-xs hover:bg-primary hover:text-background"
        icon="circle-t"
        iconVariant="solid"
        onClick={() => handleVariableSelected(variable, "text")}
      />
      <DeprecatedButton
        className="px-xs hover:bg-primary hover:text-background"
        icon="circle-question"
        iconVariant="solid"
        onClick={() => handleVariableSelected(variable, "condition")}
      />
    </span>
  );
};

export default PromptVariableConfig;
