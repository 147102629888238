import { useApiClient } from "providers/ApiClientProvider";
import usePaginatedData, {
  PaginatedData,
  UsePaginatedDataOptions,
} from "./usePaginatedData";
import { useCallback, useMemo, useState } from "react";
import { ItemSearch, SearchInput } from "api/Api";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import { useAlert } from "providers/AlertProvider";
import { odoToast } from "lib/odoToast";
import { message_from_exception } from "utils";

export type Category = ItemSearch["kind"] | "all";

export const VALID_ITEM_KINDS: Category[] = [
  "project",
  "person",
  "overview",
  "cover_letter",
  "subcontractor",
  "approach",
];

export interface SearchMode {
  query: string;
  name: string;
}

export const SEARCH_MODES: SearchMode[] = [
  {
    query: "default",
    name: "Metadata",
  },
  {
    query: "entities",
    name: "Named Entities",
  },
  {
    query: "avg",
    name: "Avg Content",
  },
  {
    query: "att",
    name: "Att Content",
  },
  {
    query: "combined-avg",
    name: "Avg Content + Title",
  },
  {
    query: "combined-att",
    name: "Att Content + Title",
  },
  {
    query: "title",
    name: "Title",
  },
];

type SearchModeQuery = (typeof SEARCH_MODES)[number]["query"];

interface ContentLibrary {
  // The currently selected high level category
  activeCategory: Category;

  setActiveCategory: (category: Category) => void;

  // The items of the currently selected category (e.g. projects)
  activeItems: ItemSearch[];
  activePaginatedData: PaginatedData;

  // The currently selected item
  selectedItem: ItemSearch | null;
  selectedItemSaveState: SaveState;

  setSelectedItem: (item: ItemSearch | null) => void;
  setSelectedItemSaveState: (state: SaveState) => void;

  // Add a new item to the currently selected category
  addItem: () => void;
  deleteItem: () => Promise<void>;
  updateItem: (item: ItemSearch) => void;
}

const useContentLibrary = (
  initialCategory: Category | undefined,
  initialSelectedItemId: string | undefined,
  proposalId: string | null,
  searchMode?: SearchModeQuery,
  defaultSearchQuery?: string,
  contentEmbedding?: number[] | null
): ContentLibrary => {
  const apiClient = useApiClient();
  const alert = useAlert();
  const [activeCategory, setActiveCategory] = useState<Category>(
    initialCategory ?? "project"
  );
  const [selectedItemSaveState, setSelectedItemSaveState] =
    useState<SaveState>("unchanged");

  const paginatedDataOptions = useMemo<
    UsePaginatedDataOptions<ItemSearch, ItemSearch>
  >(() => {
    return {
      paginationMethod: "POST",
      map: (remote) => remote,
      endpoint: (options) => {
        const { search, ...requestOptions } = options;

        // if (activeCategory === "past_proposal") {
        //   if (!proposalId) {
        //     throw new Error("Past proposal list only supported for proposals");
        //   }
        //   // Apply default search
        //   return apiClient.user.rfpProposalPastList(proposalId, {
        //     ...requestOptions,
        //     search: search ?? defaultSearchQuery,
        //   });
        // }

        let searchInput: SearchInput = {
          proposal_id: proposalId ?? undefined,
          alg: searchMode,
        };

        // Set the category
        if (activeCategory !== "all") {
          searchInput.kind = activeCategory;
        }

        // Set the sorting criteria
        if (search) {
          // The user has set an explicit search -> use it
          searchInput.search = search;
        } else {
          // The user has not set an explicit search -> use default
          searchInput.content_embedding = contentEmbedding ?? undefined;
          searchInput.search = defaultSearchQuery;
        }

        return apiClient.user.rfpContentLibrarySearchCreate(
          searchInput,
          requestOptions
        );
      },
      deps: [activeCategory, searchMode],
    };
  }, [
    activeCategory,
    apiClient.user,
    contentEmbedding,
    defaultSearchQuery,
    proposalId,
    searchMode,
  ]);

  const [items, setItems, paginatedData] = usePaginatedData<
    ItemSearch,
    ItemSearch
  >(paginatedDataOptions);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(
    initialSelectedItemId ?? null
  );

  const selectedItem = items?.find((i) => i.id === selectedItemId) ?? null;

  const addItem = useCallback(async () => {
    try {
      const options = { query: { proposal_id: proposalId } } as any;
      const result = await apiClient.user.rfpContentLibraryCreateCreate({
        ...options,
        kind: activeCategory,
      });
      const item = result.data;
      setItems((prev) => [item, ...(prev || [])]);
      setSelectedItemId(item.id!);
    } catch (e) {
      odoToast.caughtError(e, "Creating Content Item");
    }
  }, [proposalId, activeCategory, apiClient.user, setItems]);

  const deleteItem = useCallback(async () => {
    const item = selectedItem;
    if (!item) return;
    try {
      const options = { query: { proposal_id: proposalId } } as any;
      await apiClient.user.rfpContentLibraryDelete(item.id!, options);
      setItems((prev) => {
        if (!prev) return null;
        return prev.filter((i) => i.id !== item.id);
      });
      setSelectedItemId(null);
    } catch (e) {
      odoToast.caughtError(e, "Deleting Content Item");
    }
  }, [apiClient.user, proposalId, selectedItem, setItems]);

  const updateItem = useCallback(
    async (item: ItemSearch) => {
      setItems((prev) => {
        if (!prev) return null;
        return prev.map((i) => (i.id === item.id ? item : i));
      });
    },
    [setItems]
  );

  const setSelectedItem = useCallback(
    (item: ItemSearch | null) => {
      if (
        selectedItemSaveState === "saving" ||
        selectedItemSaveState === "error"
      ) {
        alert("The current project is still saving");
        return;
      }

      setSelectedItemId(item?.id ?? null);
      setSelectedItemSaveState("unchanged");
    },
    [alert, selectedItemSaveState]
  );

  return {
    activeCategory: activeCategory,
    activeItems: items || [],
    activePaginatedData: paginatedData,
    selectedItem,
    selectedItemSaveState,
    updateItem,
    setSelectedItemSaveState,
    setActiveCategory,
    setSelectedItem,
    addItem,
    deleteItem,
  };
};

export const singularNameFromCategory = (category: Category): string => {
  switch (category) {
    case "project":
      return "Project";
    case "person":
      return "Person";
    case "overview":
      return "Overview";
    case "cover_letter":
      return "Cover Letter";
    case "subcontractor":
      return "Subcontractor";
    case "approach":
      return "Approach";
    case "all":
      return "Item";
  }
};

export default useContentLibrary;
