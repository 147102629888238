import { RFPFile } from "api/Api";
import Button from "components/common/Button";
import Icon, { IconName } from "components/common/Icon";
import DropdownMenu from "components/common/menus/DropdownMenu";
import useTriggerDownload from "hooks/useTriggerDownload";
import { odoToast } from "lib/odoToast";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEffect, useState } from "react";

interface RFPFileMenuProps {
  rfpId: string;
  className?: string;
}

const RFPFileMenu: React.FC<RFPFileMenuProps> = ({ rfpId, className }) => {
  const apiClient = useApiClient();
  const triggerDownload = useTriggerDownload();
  const { metadata } = useProposalData();
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<RFPFile[] | null>(null);

  const handleView = async (file: RFPFile) => {
    try {
      let viewUrl: string | undefined;
      if (file.id === -1) {
        // Get the view url of the main RFP
        const response = await apiClient.user.rfpRfpRead(rfpId);
        viewUrl = response.data.view_url!;
      } else {
        // Get the view url of the specific file
        const response = await apiClient.user.rfpRfpFilesRead(
          rfpId,
          file.id!.toString()
        );
        viewUrl = response.data.read_url;
      }
      if (!viewUrl) {
        throw new Error("Could not find file");
      }
      triggerDownload(viewUrl, "tab");
    } catch (e) {
      odoToast.caughtError(e, "Viewing RFP");
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setFiles(null);
      return;
    }

    const fetchFiles = async () => {
      try {
        const response = await apiClient.user.rfpRfpFilesList(rfpId);
        if (response.data.length === 0) {
          // Use the main RFP instead
          setFiles([
            {
              id: -1,
              name: metadata.title,
              extension: "pdf",
            },
          ]);
        } else {
          setFiles(response.data);
        }
      } catch (e) {
        odoToast.caughtError(e, "Fetching RFP Files");
      }
    };
    fetchFiles();
  }, [isOpen, rfpId, apiClient.user]);

  return (
    <DropdownMenu
      onOpenChange={setIsOpen}
      items={
        files?.map((file) => {
          let icon: IconName = "file";
          switch (file.extension) {
            case "pdf":
              icon = "file-pdf";
              break;
            case "doc":
            case "docx":
              icon = "file-word";
              break;
            case "xls":
            case "xlsx":
              icon = "file-excel";
              break;
          }
          return {
            icon,
            text: file.name,
            onClick: () => handleView(file),
          };
        }) ?? []
      }
    >
      <Button
        icon="files"
        text="RFP"
        variant="round"
        emphasis="tertiary"
        iconVariant="light"
        size="small"
        visualOnly={true}
        // isLoading={files === null}
        className={cn(
          "py-sm px-md flex gap-sm items-center border rounded-sm h-2xl cursor-pointer rounded-full",
          className
        )}
      >
        <Icon name="files" />
        RFP
      </Button>
    </DropdownMenu>
  );
};

export default RFPFileMenu;
