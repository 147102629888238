import Button from "components/common/Button";
import Overlay, {
  OverlayProps,
  OverlayVariant,
} from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import { useState } from "react";

const OverlaysPlayground = () => {
  const variants: [OverlayVariant, string][] = [
    ["centered", "Centered"],
    ["full-screen", "Full Screen"],
    ["full-screen-bordered", "Full Screen Bordered"],
    ["transparent", "Transparent"],
  ];

  return (
    <table>
      <tr>
        <th className="w-[1%] pr-md py-sm"></th>
        <th colSpan={3} className="border-b border-x py-sm">
          With Title
        </th>
        <th colSpan={3} className="border-b py-sm">
          Without Title
        </th>
      </tr>
      <tr>
        <th></th>
        <th className="border-b border-l py-sm">Small</th>
        <th className="border-b py-sm">Medium</th>
        <th className="border-b py-sm border-r">Huge</th>
        <th className="border-b py-sm">Small</th>
        <th className="border-b py-sm">Medium</th>
        <th className="border-b py-sm">Huge</th>
      </tr>
      {variants.map(([variant, name]) => (
        <VariantOverlays key={variant} variant={variant} name={name} />
      ))}
    </table>
  );
};

const SmallContent = () => {
  return (
    <Rows className="gap-md w-full items-start">
      Just a little bit of content
      <Button text="Hello" />
    </Rows>
  );
};

const MediumContent = () => {
  return (
    <Rows className="gap-md">
      <h1>Whati s Lorem Ipsum?</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </p>
      <Button text="Hello" />
    </Rows>
  );
};

const HugeContent = () => {
  return (
    <Rows className="gap-md">
      {Array.from({ length: 10 }, (_, i) => (
        <div key={i}>
          <h1>What is Lorem Ipsum?</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      ))}
      <Button text="Hello" />
    </Rows>
  );
};

const VariantOverlays = ({
  variant,
  name,
}: {
  variant: OverlayVariant;
  name: string;
}) => {
  const [smallProps, setSmallProps] = useState<Omit<
    OverlayProps,
    "children" | "onClose" | "variant"
  > | null>(null);
  const [mediumProps, setMediumProps] = useState<Omit<
    OverlayProps,
    "children" | "onClose" | "variant"
  > | null>(null);
  const [hugeProps, setHugeProps] = useState<Omit<
    OverlayProps,
    "children" | "onClose" | "variant"
  > | null>(null);

  return (
    <>
      <tr className="hover:bg-background-selected">
        <th className="border-r text-right pr-md">{name}</th>
        <td className="py-sm" align="center">
          <Button
            text="Open"
            onClick={() =>
              setSmallProps({
                title: "Small Overlay",
              })
            }
          />
        </td>
        <td className="py-sm" align="center">
          <Button
            text="Open"
            onClick={() =>
              setMediumProps({
                title: "Medium Overlay",
              })
            }
          />
        </td>
        <td className="py-sm border-r" align="center">
          <Button
            text="Open"
            onClick={() =>
              setHugeProps({
                title: "Huge Overlay",
              })
            }
          />
        </td>
        <td className="py-sm" align="center">
          <Button
            text="Open"
            variant="plain"
            onClick={() => setSmallProps({})}
          />
        </td>
        <td className="py-sm" align="center">
          <Button
            text="Open"
            variant="plain"
            onClick={() => setMediumProps({})}
          />
        </td>
        <td className="py-sm" align="center">
          <Button
            text="Open"
            variant="plain"
            onClick={() => setHugeProps({})}
          />
        </td>
      </tr>
      {smallProps && (
        <Overlay
          open={true}
          onClose={() => setSmallProps(null)}
          variant={variant}
          {...smallProps}
        >
          <SmallContent />
        </Overlay>
      )}
      {mediumProps && (
        <Overlay
          open={true}
          onClose={() => setMediumProps(null)}
          variant={variant}
          maxWidth={400}
          {...mediumProps}
        >
          <MediumContent />
        </Overlay>
      )}
      {hugeProps && (
        <Overlay
          open={true}
          onClose={() => setHugeProps(null)}
          variant={variant}
          {...hugeProps}
        >
          <HugeContent />
        </Overlay>
      )}
    </>
  );
};

export default OverlaysPlayground;
