import {
  KeyboardEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AlertProviderContext } from "../AlertProvider";
import { AlertButtonOptions, CustomOptions } from "./types";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import Input from "components/common/forms/Input";
import { odoToast } from "lib/odoToast";

export interface TextAreaButton extends Omit<AlertButtonOptions, "process"> {
  process?: (buttonId: string, text: string) => Promise<void>;
}

interface ConfirmTextAreaOptions
  extends Omit<CustomOptions, "body" | "buttons"> {
  initialText?: string;
  placeholder?: string;
  height?: number;
  buttons: TextAreaButton[];
  multiline?: boolean;
}

interface TextInputProps {
  initialText?: string;
  placeholder?: string;
  height?: number;
  // Must keep text ref up to date
  textRef: React.MutableRefObject<string>;
  resolve: (text: string) => void;
  options: ConfirmTextAreaOptions;
}

export const useTextAreaConfirm = () => {
  const context = useContext(AlertProviderContext);
  if (!context) {
    throw new Error("useTextAreaConfirm must be used within an AlertProvider");
  }

  const textRef = useRef<string>("");
  return async (message: string, options: ConfirmTextAreaOptions) => {
    const multiline = options.multiline ?? true;

    const result = await context.show(message, {
      ...options,
      fillWidth: true,
      buttons: options.buttons.map((button) => ({
        ...button,
        process: async () => {
          await button.process?.(button.id, textRef.current);
        },
      })),
      body: (resolve) => {
        return multiline ? (
          <MultilineInput
            initialText={options.initialText}
            placeholder={options.placeholder}
            height={options.height}
            resolve={resolve}
            textRef={textRef}
            options={options}
          />
        ) : (
          <SingleLineInput
            initialText={options.initialText}
            placeholder={options.placeholder}
            resolve={resolve}
            textRef={textRef}
            options={options}
          />
        );
      },
    });

    return { id: result, text: textRef.current };
  };
};

const MultilineInput: React.FC<TextInputProps> = ({
  initialText,
  placeholder,
  height,
  resolve,
  textRef,
  options,
}) => {
  const [text, setText] = useState(initialText ?? "");
  const textAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    textRef.current = text;
  }, [text, textRef]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (!options.defaultId) return;

    if (e.key === "Enter" && e.shiftKey) {
      resolve(options.defaultId);
    }
  };

  return (
    <NaturalHeightTextArea
      className="w-full -mb-md"
      ref={textAreaRef}
      value={text}
      style={{
        minHeight: height || undefined,
        resize: height ? "none" : undefined,
      }}
      placeholder={placeholder}
      onChange={(value) => setText(value)}
      onKeyDown={handleKeyDown}
    />
  );
};

const SingleLineInput: React.FC<TextInputProps> = ({
  initialText,
  placeholder,
  resolve,
  options,
  textRef,
}) => {
  const [text, setText] = useState(initialText ?? "");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (!options.defaultId) return;
    if (e.key === "Enter") {
      e.stopPropagation();
      if (!text) {
        odoToast.error({
          title: "Value Required",
          text: "You must enter a value to save",
        });
        return;
      }
      resolve(options.defaultId);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      textRef.current = text;
    }
  }, [text, textRef]);

  return (
    <Input
      className="w-full -mb-md"
      value={text}
      ref={inputRef}
      placeholder={placeholder}
      onChange={(e) => setText(e.target.value)}
      onKeyDown={handleKeyDown}
    />
  );
};
