import React, { FC, useEffect } from "react";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { isElement, TElement, toggleNodeType } from "@udecode/plate-common";
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from "@udecode/plate-heading";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";

import { ELEMENT_OL } from "@udecode/plate-list";
import Icon, { IconName } from "components/common/Icon";
import { OdoEditor } from "odo";
import { useOdoEditorRef } from "../../../hooks/odo-editor/useOdoEditorRef";
import { cn, isSelectionInTable } from "../../../lib/utils";
import { FormattingType } from "hooks/odo-editor/useCurrentFormatting";
import DropdownMenu from "components/common/menus/DropdownMenu";
interface TurnIntoItem {
  value: string;
  supportedInTables: boolean;
  label: string;
  description: string;
  icon: IconName;
  type: FormattingType;
  matches: (node: TElement) => boolean;
  onSelect: (editor: OdoEditor) => void;
}

const items: TurnIntoItem[] = [
  {
    value: ELEMENT_PARAGRAPH,
    supportedInTables: true,
    label: "Paragraph",
    description: "Paragraph",
    icon: "paragraph",
    type: "p",
    matches: (node) => node.type === ELEMENT_PARAGRAPH && !node.listStyleType,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
      editor.setNodes({
        // @ts-ignore
        indent: null,
        listStyleType: null,
      });
    },
  },
  {
    value: ELEMENT_H1,
    supportedInTables: false,
    label: "Heading 1",
    description: "Heading 1",
    icon: "h1",
    type: "h1",
    matches: (node) => node.type === ELEMENT_H1,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H1 });
    },
  },
  {
    value: ELEMENT_H2,
    supportedInTables: false,
    label: "Heading 2",
    description: "Heading 2",
    icon: "h2",
    type: "h2",
    matches: (node) => node.type === ELEMENT_H2,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H2 });
    },
  },
  {
    value: ELEMENT_H3,
    supportedInTables: false,
    label: "Heading 3",
    description: "Heading 3",
    icon: "h3",
    type: "h3",
    matches: (node) => node.type === ELEMENT_H3,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H3 });
    },
  },
  {
    value: ELEMENT_H4,
    supportedInTables: false,
    label: "Heading 4",
    description: "Heading 4",
    icon: "h4",
    type: "h4",
    matches: (node) => node.type === ELEMENT_H4,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H4 });
    },
  },
  {
    value: ELEMENT_H5,
    supportedInTables: false,
    label: "Heading 5",
    description: "Heading 5",
    icon: "h5",
    type: "h5",
    matches: (node) => node.type === ELEMENT_H5,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H5 });
    },
  },
  {
    value: ELEMENT_H6,
    supportedInTables: false,
    label: "Heading 6",
    description: "Heading 6",
    icon: "h6",
    type: "h6",
    matches: (node) => node.type === ELEMENT_H6,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H6 });
    },
  },
  {
    value: ELEMENT_BLOCKQUOTE,
    supportedInTables: false,
    label: "Quote",
    description: "Quote (⌘+⇧+.)",
    icon: "block-quote",
    type: "blockquote",
    matches: (node) => node.type === ELEMENT_BLOCKQUOTE,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_BLOCKQUOTE });
    },
  },
  {
    value: ELEMENT_PARAGRAPH,
    supportedInTables: true,
    label: "Bulleted list",
    description: "Bulleted list",
    icon: "list-ul",
    type: "bulleted",
    matches: (node) =>
      node.type === ELEMENT_PARAGRAPH && node.listStyleType === "disc",
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
      // Set list type on all of the nodes
      editor.setNodes({
        // @ts-ignore
        listStyleType: "disc",
      });
      // Set indent only on nodes without an existing indent
      editor.setNodes(
        {
          // @ts-ignore
          listStyleType: "disc",
          // @ts-ignore
          indent: 1,
        },
        {
          match: (node) =>
            isElement(node) && (!("indent" in node) || !node.indent),
        }
      );
    },
  },
  {
    value: ELEMENT_OL,
    supportedInTables: true,
    label: "Numbered list",
    description: "Numbered list",
    icon: "list-ol",
    type: "numbered",
    matches: (node) =>
      node.type === ELEMENT_PARAGRAPH &&
      ["decimal", "lower-roman", "lower-alpha"].includes(
        node.listStyleType as string
      ),
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
      // Set list type on all of the nodes
      editor.setNodes({
        // @ts-ignore
        listStyleType: "decimal",
      });
      // Set indent only on nodes without an existing indent
      editor.setNodes(
        {
          // @ts-ignore
          listStyleType: "decimal",
          // @ts-ignore
          indent: 1,
        },
        {
          match: (node) =>
            isElement(node) && (!("indent" in node) || !node.indent),
        }
      );
    },
  },
  // {
  //   value: ELEMENT_OL,
  //   supportedInTables: true,
  //   label: "Checklist",
  //   description: "Checklist",
  //   icon: "square-check",
  //   matches: (node) =>
  //     node.type === ELEMENT_PARAGRAPH &&
  //     ["checked", "unchecked"].includes(node.listStyleType as string),
  //   onSelect: (editor) => {
  //     toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
  //     editor.setNodes({
  //       // @ts-ignore
  //       indent: 1,
  //       listStyleType: "unchecked",
  //     });
  //   },
  // },
];

const defaultItem = items.find((item) => item.value === ELEMENT_PARAGRAPH)!;

interface TurnIntoDropDownMenuProps {
  selectedElement: string | "multiple" | null;
  refreshFormatting: () => void;
}

export const TurnIntoDropDownMenu: FC<TurnIntoDropDownMenuProps> = ({
  selectedElement,
  refreshFormatting,
  ...rest
}) => {
  const editor = useOdoEditorRef();
  const [filteredItems, setFilteredItems] = React.useState(items);
  const [selectedItem, setSelectedItem] = React.useState<
    TurnIntoItem | "multiple"
  >(defaultItem);

  useEffect(() => {
    const isInTable = isSelectionInTable(editor);
    setFilteredItems(
      items.filter((item) => !isInTable || item.supportedInTables)
    );
  }, [editor]);

  useEffect(() => {
    if (selectedElement === "multiple") {
      setSelectedItem("multiple");
      return;
    }
    if (!selectedElement) {
      setSelectedItem(defaultItem);
      return;
    }

    for (const item of items) {
      if (item.type === selectedElement) {
        setSelectedItem(item);
        return;
      }
    }
    setSelectedItem(defaultItem);
  }, [selectedElement, filteredItems]);

  return (
    <DropdownMenu
      items={filteredItems.map((item) => ({
        icon: item.icon,
        text: item.label,
        onClick: () => {
          item.onSelect(editor);
        },
      }))}
    >
      <span
        className={cn(
          "p-sm flex gap-sm items-center text-sm",
          selectedItem === "multiple" && "italic"
        )}
      >
        {labelFromSelectedItem(selectedItem)}
        <Icon name="chevron-down" />
      </span>
    </DropdownMenu>
  );

  // return (
  //   <DeprecatedDropdownMenu modal={false} {...openState} {...rest}>
  //     <DeprecatedDropdownMenuTrigger asChild>
  //       <span
  //         className={cn(
  //           "p-sm flex gap-sm items-center text-sm",
  //           selectedItem === "multiple" && "italic"
  //         )}
  //       >
  //         {labelFromSelectedItem(selectedItem)}
  //         <Icon name="chevron-down" />
  //       </span>
  //     </DeprecatedDropdownMenuTrigger>

  //     <DeprecatedDropdownMenuContent align="start" className="min-w-0">
  //       <DeprecatedDropdownMenuRadioGroup
  //         className="flex flex-col gap-0.5"
  //         value={valueFromSelectedItem(selectedItem)}
  //         onValueChange={(type) => {
  //           const item = filteredItems.find((item) => item.type === type)!;
  //           item.onSelect(editor);

  //           collapseSelection(editor);
  //           focusEditor(editor);
  //           refreshFormatting();
  //         }}
  //       >
  //         {filteredItems.map(({ value: itemValue, label, icon, type }) => (
  //           <DeprecatedDropdownMenuRadioItem
  //             key={icon}
  //             value={type}
  //             className="min-w-[180px]"
  //           >
  //             <div className="text-center ml-[-4px] pr-xs">
  //               <Icon name={icon} />
  //             </div>
  //             {label}
  //           </DeprecatedDropdownMenuRadioItem>
  //         ))}
  //       </DeprecatedDropdownMenuRadioGroup>
  //     </DeprecatedDropdownMenuContent>
  //   </DeprecatedDropdownMenu>
  // );
};

const valueFromSelectedItem = (
  selectedItem: TurnIntoItem | "multiple" | null
): string | undefined => {
  if (!selectedItem) {
    return undefined;
  }

  if (selectedItem === "multiple") {
    return "multiple";
  }

  return selectedItem.type;
};

const labelFromSelectedItem = (
  selectedItem: TurnIntoItem | "multiple" | null
) => {
  if (!selectedItem) {
    return "Paragraph";
  }

  if (selectedItem === "multiple") {
    return "Multiple";
  }

  return selectedItem.label;
};
