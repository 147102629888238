import { FC, useEffect, useState } from "react";
import Overlay, { OverlayProps } from "./Overlay";

interface OverlayOnSmallScreensProps extends OverlayProps {
  // The minimum width of the screen to not render as overlay
  minWidth: number;
}

export const OverlayOnSmallScreens: FC<OverlayOnSmallScreensProps> = ({
  minWidth,
  children,
  ...props
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  if (screenWidth < minWidth) {
    return <Overlay {...props}>{children}</Overlay>;
  }
  return <>{children}</>;
};
