export interface SplitPaneConfig {
  minSize: number;
  maxSize?: number;
  priority?: number;
  defaultSize?: number;
}

export interface SplitPaneData extends SplitPaneConfig {
  currentSize: number;
  uniqueId: string;
  paneRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const INFINITY = 99999;

class SplitPaneLayoutManager {
  // Each constraint represents a pane
  constraints: SplitPaneData[];
  localStorageKey: string;

  // A new SplitPaneLayoutManager is created when the children change
  constructor(
    constraints: SplitPaneData[],
    initialContainerSize: number,
    localStorageKey: string
  ) {
    this.constraints = constraints;
    this.localStorageKey = localStorageKey;
    this.loadSizes();
    this.adjustContainerSize(initialContainerSize);
    this.applySizes();
  }

  get totalSize() {
    return this.constraints.reduce(
      (acc: number, constraint: SplitPaneData) => acc + constraint.currentSize,
      0
    );
  }

  /**
   *  Used when the user moves a separator.
   */
  moveSeparator(
    separatorIndex: number,
    // Positive means moving right, negative means moving left
    amount: number
  ) {
    if (amount === 0) {
      return;
    }

    const leftConstraints = this.constraints.slice(0, separatorIndex + 1);
    const rightConstraints = this.constraints.slice(separatorIndex + 1);

    // First check if the right side can be adjusted
    if (amount > 0) {
      const canBeAdjusted = this.getSpaceThatCanBeRemoved(rightConstraints);
      amount = Math.min(amount, canBeAdjusted);
    } else {
      const canBeAdjusted = -this.getSpaceThatCanBeAdded(rightConstraints);
      amount = Math.max(amount, canBeAdjusted);
    }

    const remainingOnLeft = this.adjustConstraints(leftConstraints, amount);
    const remainingOnRight = this.adjustConstraints(
      rightConstraints,
      -(amount - remainingOnLeft)
    );
    if (remainingOnRight !== 0) {
      // If there is still remaining space on the right, then we need to give it back to the left
      this.adjustConstraints(leftConstraints, remainingOnRight);
    }

    this.applySizes();
  }

  /**
   * Used when the window/container size changes. (or during initialization)
   */
  adjustContainerSize(newSize: number) {
    const totalSize = this.totalSize;
    const sizeDifference = newSize - totalSize;

    if (sizeDifference === 0) {
      return;
    }

    this.adjustConstraints(this.constraints, sizeDifference);

    this.applySizes();
  }

  /**
   * Used to set the actual widths of each pane.
   */
  getPaneSizes(): number[] {
    return this.constraints.map((constraint) => constraint.currentSize);
  }

  /**
   * Remove space from the given constraints by the given amount.
   *
   * The constraints are modified in place.
   *
   * The total new width of the constraints must be equal to the total old width minus the amount.
   */
  private adjustConstraints(constraints: SplitPaneData[], amount: number) {
    if (constraints.length === 0) return amount;

    let remainingAmount = amount;
    while (remainingAmount > 0 || remainingAmount < 0) {
      const { adjustable, commonAmount } = this.getNextAdjustableConstraints(
        constraints,
        remainingAmount
      );
      if (adjustable.length === 0) {
        // No constraints can be shrunk anymore, so we will just return and the
        // children will be clipped or the container will scroll
        break;
      }

      for (const constraint of adjustable) {
        constraint.currentSize += commonAmount;
        remainingAmount -= commonAmount;
        constraint.defaultSize = constraint.currentSize;
      }
    }

    return remainingAmount;
  }

  /**
   * Gets the next group of constraints that can be adjusted towards the given amount.
   *
   * The result of this function is a group of constraints that should be resized by the
   * `commonAmount`.
   *
   * This is calculated by finding all the constraints with the next highest priority that
   * are not already at the min/max size and finding the maximum that each of those can
   * be adjusted by.
   */
  private getNextAdjustableConstraints(
    constraints: SplitPaneData[],
    adjustment: number
  ): {
    adjustable: SplitPaneData[];
    commonAmount: number;
  } {
    const getAdjustableAmount = (constraint: SplitPaneData): number => {
      if (adjustment > 0) {
        // We're looking to grow
        if (constraint.maxSize === undefined) {
          return INFINITY;
        } else if (constraint.currentSize < constraint.maxSize) {
          return Math.abs(constraint.maxSize - constraint.currentSize);
        } else {
          return 0;
        }
      } else {
        // We're looking to shrink
        if (constraint.currentSize > constraint.minSize) {
          return Math.abs(constraint.currentSize - constraint.minSize);
        } else {
          return 0;
        }
      }
    };

    let allAdjustable = constraints
      .map((constraint) => ({
        constraint,
        amount: getAdjustableAmount(constraint),
      }))
      .filter(({ amount }) => amount > 0);

    const maxPriority = Math.max(
      ...allAdjustable.map(({ constraint }) => constraint.priority ?? 0)
    );
    allAdjustable = allAdjustable.filter(
      ({ constraint }) => (constraint.priority ?? 0) === maxPriority
    );

    let maximumCommonAmount = Math.min(
      ...allAdjustable.map(({ amount }) => amount)
    );
    const targetCommonAmount = Math.abs(adjustment / allAdjustable.length);
    maximumCommonAmount = Math.min(maximumCommonAmount, targetCommonAmount);

    return {
      adjustable: allAdjustable.map(({ constraint }) => constraint),
      commonAmount: adjustment > 0 ? maximumCommonAmount : -maximumCommonAmount,
    };
  }

  private getSpaceThatCanBeAdded(constraints: SplitPaneData[]) {
    let adjustable = 0;
    for (const constraint of constraints) {
      if (constraint.maxSize === undefined) {
        return INFINITY;
      } else {
        adjustable += constraint.maxSize - constraint.currentSize;
      }
    }
    return adjustable;
  }

  private getSpaceThatCanBeRemoved(constraints: SplitPaneData[]) {
    let adjustable = 0;
    for (const constraint of constraints) {
      adjustable += constraint.currentSize - constraint.minSize;
    }
    return adjustable;
  }

  private applySizes() {
    for (const constraint of this.constraints) {
      if (constraint?.paneRef?.current) {
        constraint.paneRef.current.style.width = `${constraint.currentSize}px`;
      }
    }
    // Save the sizes to local storage
    this.saveSizes();
  }

  private saveSizes() {
    // Load sizes from local storage and apply current scopes
    // (should keep around other pane sizes not currently in use)
    const rawSizes = localStorage.getItem(this.localStorageKey);
    let sizes: Record<string, number> = {};
    if (rawSizes && typeof rawSizes === "string") {
      sizes = JSON.parse(rawSizes);
    }
    for (const constraint of this.constraints) {
      if (constraint.currentSize) {
        sizes[constraint.uniqueId] = constraint.currentSize;
      }
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(sizes));
  }

  private loadSizes() {
    // Load the sizes from local storage and apply to current constraints
    const sizes = localStorage.getItem(this.localStorageKey);
    if (!sizes) return;
    const parsedSizes = JSON.parse(sizes);
    for (const constraint of this.constraints) {
      if (parsedSizes[constraint.uniqueId]) {
        constraint.currentSize = parsedSizes[constraint.uniqueId];
      }
    }
  }
}

export default SplitPaneLayoutManager;
