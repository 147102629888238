import Columns from "components/common/containers/Columns";
import Icon, { IconName } from "components/common/Icon";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/EditorView/Menus/Popover";
import { odoToast } from "lib/odoToast";
import { cn } from "lib/utils";
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import {
  isEmptySearch,
  isSavedSearchDraft,
  SearchConfiguratorProps,
} from "./SearchConfiguratorProps";
import { SavedSearchDetail } from "api/Api";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import { message_from_exception } from "utils";
import LocationConfigurator from "./LocationConfigurator";
import IssuingOrganizationConfigurator from "./IssuingOrganizationConfigurator";
import SavedSearchesConfigurator from "./SavedSearchesConfigurator";
import NAICSConfigurator from "./NAICSConfigurator";
import DatesConfigurator from "./DatesConfigurator";
import DeprecatedButton from "components/common/DeprecatedButton";
import SearchMenuInput from "./SearchMenuInput";
import KeywordConfigurator from "./KeywordConfigurator";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Rows from "components/common/containers/Rows";
import Overlay from "components/common/containers/overlays/Overlay";
import Scrollable from "components/common/containers/Scrollable";
import { useApiClient } from "providers/ApiClientProvider";
import { CoachMark, CoachMarkAnchor } from "components/common/CoachMark";
import Button from "components/common/Button";

interface RFPSearchMenuProps {
  className?: string;
  onUpdated?: () => void;
  isPinnedToTop?: boolean;
  resultCount: number | null;
  refreshFilter: () => void;
  search: SavedSearchDetail | null;
  setSearch: Dispatch<SetStateAction<SavedSearchDetail | null>>;
  error: unknown;
  undoUpdateState: SavedSearchDetail | null;
  setUndoUpdateState: Dispatch<SetStateAction<SavedSearchDetail | null>>;
}

export interface UpdateSearchOptions {
  saveToRemote?: boolean;
  mode?: "overwrite" | "merge";
}

const RFPSearchMenu: FC<RFPSearchMenuProps> = ({
  className,
  onUpdated,
  isPinnedToTop = false,
  refreshFilter,
  search,
  setSearch,
  error,
  undoUpdateState,
  setUndoUpdateState,
}) => {
  const apiClient = useApiClient();
  const user = useAuthenticatedUser();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 570);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const refresh = useCallback(() => {
    refreshFilter();
    onUpdated?.();
  }, [refreshFilter, onUpdated]);

  const updateSearch = useCallback(
    (
      update: Partial<SavedSearchDetail>,
      { saveToRemote = true, mode = "overwrite" }: UpdateSearchOptions = {}
    ) => {
      setSearch((prev) => {
        if (!prev) return null;

        const updated =
          mode === "overwrite"
            ? overwriteUpdate(prev, update)
            : mergeUpdate(prev, update);

        if (JSON.stringify(updated) === JSON.stringify(prev)) return prev;

        if (saveToRemote) {
          const applyToServer = async () => {
            try {
              const result =
                await apiClient.user.rfpSavedSearchCurrentPartialUpdate({
                  ...update,
                } as any);
              setSearch(result.data);
              onUpdated?.();
            } catch (error) {
              odoToast.caughtError(error, "Updating Search");
              setSearch(prev);
            }
          };
          applyToServer();
        } else {
          refresh();
        }

        onUpdated?.();

        return {
          ...prev,
          ...update,
        };
      });
    },
    [setSearch, apiClient, refresh, onUpdated]
  );

  const totalKeywords = useMemo(() => {
    return (
      (search?.keywords.length ?? 0) + (search?.exclude_keywords.length ?? 0)
    );
  }, [search]);

  const hasDateFilter = useMemo(() => {
    if (!!search?.due_after) return true;
    if (!!search?.due_before) return true;
    if (!!search?.posted_after) return true;
    if (!!search?.posted_before) return true;
    if (!!search?.due_date_range && search.due_date_range !== "all")
      return true;
    if (!!search?.posted_date_range && search.posted_date_range !== "all")
      return true;
    return false;
  }, [search]);

  const emptySearch = search && isEmptySearch(search);

  const coachMarkContent = undoUpdateState ? (
    <Columns className="gap-md items-center">
      <Rows>
        <h1 className="font-semibold">Search Updated</h1>
        <p className="text-sm">
          We added NAICS codes and keywords to your search configuration.
        </p>
      </Rows>
      <Button
        text="Undo"
        emphasis="primary"
        onClick={() => {
          updateSearch(undoUpdateState);
          setUndoUpdateState(null);
        }}
      />
      <Button
        text="Ok"
        emphasis="secondary"
        onClick={() => setUndoUpdateState(null)}
      />
    </Columns>
  ) : undefined;

  return (
    <div className={cn("relative select-none", className)}>
      <Rows
        className={cn(
          "hover:drop-shadow-low transition-all bg-background-selected overflow-visible border",
          isMobile ? "flex-col" : "flex-row",
          isPinnedToTop ? "rounded-b-sm" : "rounded-sm"
        )}
      >
        <CoachMark content={coachMarkContent}>
          <Columns className="overflow-visible">
            <SearchMenuItem
              label="Saved Searches"
              icon="floppy-disk"
              search={search}
              refresh={refresh}
              badge={null}
              updateSearch={updateSearch}
              className={cn(isPinnedToTop ? "rounded-bl-sm" : "rounded-l-sm")}
              variant={
                isSavedSearchDraft(search, user) && !emptySearch
                  ? "primary"
                  : "default"
              }
              error={error}
              Configurator={SavedSearchesConfigurator}
              isMobile={isMobile}
            />
            <CoachMarkAnchor>
              <SearchMenuItem
                label="NAICS"
                icon="grid-2"
                search={search}
                refresh={refresh}
                badge={
                  search?.naics_codes.length
                    ? search?.naics_codes.length.toString()
                    : null
                }
                clearPartial={{
                  naics_codes: [],
                }}
                updateSearch={updateSearch}
                error={error}
                Configurator={NAICSConfigurator}
                isMobile={isMobile}
              />
            </CoachMarkAnchor>
            <SearchMenuItem
              label="Location"
              icon="location-dot"
              search={search}
              refresh={refresh}
              badge={
                search?.locations.length
                  ? search?.locations.length.toString()
                  : null
              }
              clearPartial={{
                locations: [],
              }}
              updateSearch={updateSearch}
              error={error}
              Configurator={LocationConfigurator}
              isMobile={isMobile}
            />
            <SearchMenuItem
              label="Issuing Organization"
              icon="building"
              search={search}
              refresh={refresh}
              badge={
                search?.customers.length
                  ? search?.customers.length.toString()
                  : null
              }
              clearPartial={{
                customers: [],
              }}
              updateSearch={updateSearch}
              error={error}
              Configurator={IssuingOrganizationConfigurator}
              isMobile={isMobile}
            />
            <CoachMarkAnchor>
              <SearchMenuItem
                label="Keywords"
                icon="text"
                search={search}
                refresh={refresh}
                clearPartial={{
                  keywords: [],
                  exclude_keywords: [],
                }}
                badge={totalKeywords > 0 ? totalKeywords.toString() : null}
                updateSearch={updateSearch}
                error={error}
                Configurator={KeywordConfigurator}
                isMobile={isMobile}
              />
            </CoachMarkAnchor>
            <SearchMenuItem
              label="Dates"
              icon="calendar"
              search={search}
              refresh={refresh}
              clearPartial={{
                due_after: null,
                due_before: null,
                posted_after: null,
                posted_before: null,
                due_date_range: "all",
                posted_date_range: "all",
              }}
              badge={hasDateFilter}
              updateSearch={updateSearch}
              error={error}
              Configurator={DatesConfigurator}
              isMobile={isMobile}
            />
          </Columns>
        </CoachMark>
        <SearchMenuInput
          className="grow border-t min-[570px]:border-l min-[570px]:border-t-0"
          search={search}
          updateSearch={updateSearch}
        />
      </Rows>
    </div>
  );
};

RFPSearchMenu.displayName = "RFPSearchMenu";

interface SearchMenuItemProps {
  label: string;
  icon: IconName;
  badge: string | boolean | null;
  variant?: "default" | "primary";
  search: SavedSearchDetail | null;
  updateSearch: (
    update: Partial<SavedSearchDetail>,
    options?: UpdateSearchOptions
  ) => void;
  error?: unknown;
  clearPartial?: Partial<SavedSearchDetail>;
  Configurator: FC<SearchConfiguratorProps>;
  refresh: () => void;
  className?: string;
  isMobile: boolean;
}

const SearchMenuItem: FC<SearchMenuItemProps> = ({
  label,
  icon,
  className,
  variant = "default",
  badge,
  Configurator,
  search,
  updateSearch,
  clearPartial,
  error,
  refresh,
  isMobile,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClear = useCallback(() => {
    if (!clearPartial || !search) return;
    let originalSearch: Record<string, unknown> = {};
    for (const clearKey in clearPartial) {
      originalSearch[clearKey] = search[clearKey as keyof SavedSearchDetail];
    }
    updateSearch(clearPartial);
    odoToast.warning({
      title: `${label} filter cleared`,
      text: "You can undo this in the search menu",
      cta: {
        text: "Undo",
        onClick: () => {
          updateSearch(originalSearch);
        },
      },
    });
  }, [clearPartial, updateSearch, label, search]);

  const triggerContent = (
    <Columns
      className={cn(
        "gap-sm items-center hover:bg-background-subtle px-sm whitespace-nowrap cursor-pointer relative overflow-visible group h-[39px] relative",
        isOpen && "bg-foreground text-background hover:bg-foreground",
        variant === "primary" &&
          "bg-primary text-background hover:bg-primary hover:opacity-50",
        className
      )}
    >
      <Icon
        name={icon}
        variant="solid"
        className="w-[30px] shrink-0 text-center flex items-center justify-center mr-thin"
      />
      {badge && (
        <div
          className={cn(
            "absolute rounded-full text-background top-[70%] left-[55%] text-xs px-xs min-w-[17px] min-h-[17px] text-center bg-ring"
          )}
        >
          {badge}
        </div>
      )}
    </Columns>
  );

  const panelContent = (
    <>
      <AsyncLoadedDiv
        value={search}
        error={error ? message_from_exception(error) : undefined}
        whileLoaded={(search) => {
          return (
            <Configurator
              search={search}
              updateSearch={updateSearch}
              close={() => setIsOpen(false)}
              refresh={refresh}
              isOpen={isOpen}
              isMobile={isMobile}
            />
          );
        }}
      />
    </>
  );

  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen} modal={false}>
        <PopoverTrigger>{triggerContent}</PopoverTrigger>
        {!isMobile && (
          <PopoverContent
            sideOffset={0}
            side="bottom"
            align="start"
            className="border drop-shadow rounded-[0] rounded-b-md flex flex-col"
          >
            <Columns className="border-b pb-xs -mt-xs mb-sm max-h-[50px] shrink-0">
              <h1 className="text-lg font-semibold grow">{label}</h1>
              {clearPartial && (
                <DeprecatedButton
                  text="Clear Filter"
                  variant="solid-secondary"
                  className={cn(
                    "text-sm h-lg text-destructive border-destructive",
                    !badge && "border-border text-secondary"
                  )}
                  onClick={handleClear}
                  disabled={!badge}
                />
              )}
            </Columns>
            <Scrollable className="grow">{panelContent}</Scrollable>
          </PopoverContent>
        )}
      </Popover>
      {isMobile && (
        <Overlay
          title={label}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          variant="full-screen"
          scrollable={false}
        >
          <Scrollable className="grow">{panelContent}</Scrollable>
          <Columns className="mt-sm justify-end shrink-0">
            {clearPartial && (
              <DeprecatedButton
                text="Clear Filter"
                variant="solid-secondary"
                className={cn(
                  "text-destructive border-destructive",
                  !badge && "border-border text-secondary"
                )}
                onClick={handleClear}
                disabled={!badge}
              />
            )}
          </Columns>
        </Overlay>
      )}
    </>
  );
};

const overwriteUpdate = (
  prev: SavedSearchDetail,
  update: Partial<SavedSearchDetail>
): SavedSearchDetail => {
  return { ...prev, ...update };
};

const mergeArrays = (
  prev: string[] | undefined,
  update: string[] | undefined
): string[] => {
  return [...new Set([...(prev ?? []), ...(update ?? [])])];
};

const mergeUpdate = (
  prev: SavedSearchDetail,
  update: Partial<SavedSearchDetail>
): SavedSearchDetail => {
  return {
    id: prev.id,
    naics_codes: mergeArrays(prev.naics_codes, update.naics_codes),
    keywords: mergeArrays(prev.keywords, update.keywords),
    exclude_keywords: mergeArrays(
      prev.exclude_keywords,
      update.exclude_keywords
    ),
    locations: mergeArrays(prev.locations, update.locations),
    customers: mergeArrays(prev.customers, update.customers),
    due_after: update.due_after ?? prev.due_after,
    due_before: update.due_before ?? prev.due_before,
    posted_after: update.posted_after ?? prev.posted_after,
    posted_before: update.posted_before ?? prev.posted_before,
    due_date_range: update.due_date_range ?? prev.due_date_range,
    posted_date_range: update.posted_date_range ?? prev.posted_date_range,
  };
};

export default RFPSearchMenu;
