import { isCollapsed, useEditorRef } from "@udecode/plate-common";
import Columns from "components/common/containers/Columns";
import Icon, { IconName } from "components/common/Icon";
import { cn } from "lib/utils";
import { useCallback } from "react";
import useAddComment from "../../../lib/plate/plugins/useAddComment";
import { odoUser } from "odo";
import DropdownMenu from "components/common/menus/DropdownMenu";

interface AIActionOption {
  label: string;
  icon: IconName;
  commentText: string;
}

const AIActionsMenu = () => {
  const editor = useEditorRef();
  const addComment = useAddComment();

  const options: AIActionOption[] = [
    {
      label: "Turn into paragraphs",
      icon: "paragraph",
      commentText: "turn this into paragraphs",
    },
    {
      label: "Turn into lists",
      icon: "list",
      commentText: "turn this into lists",
    },
    {
      label: "Make shorter",
      icon: "grip-lines",
      commentText: "make this shorter",
    },
    {
      label: "Make longer",
      icon: "align-justify",
      commentText: "make this longer",
    },
  ];

  const handleAction = useCallback(
    async (option: AIActionOption) => {
      addComment(
        [
          {
            type: "p",
            children: [
              { type: "mention", user: odoUser, children: [{ text: "" }] },
              { text: " " + option.commentText },
            ],
          },
        ],
        undefined,
        true
      );
    },
    [addComment]
  );

  const isDisabled = isCollapsed(editor.selection);

  return (
    <DropdownMenu
      items={options.map((option) => ({
        icon: option.icon,
        text: option.label,
        onClick: () => handleAction(option),
      }))}
    >
      <Columns
        className={cn(
          "gap-sm items-center text-sm px-sm hover:bg-background-tertiary-darker hover:opacity-50 h-[calc(100%-var(--space-xs)*2)]",
          "shrink-0",
          isDisabled ? "text-secondary" : "text-primary"
        )}
      >
        <Icon name="sparkles" />
        AI&nbsp;Actions
      </Columns>
    </DropdownMenu>
  );
};

export default AIActionsMenu;
