import Markdown from "react-markdown";
import OdoCursor from "./cursors/OdoCursor";
import Reference from "./common/pdf/Reference";
import remarkReference, {
  referenceIdsInMarkdown,
} from "../lib/remarkReference";
import { useEffect, useRef, useState } from "react";

export const ODO_CURSOR_MARKDOWN = "[odo-cursor]";

interface MarkdownViewProps {
  markdown: string;
  renderPartials?: boolean;
  onReferenceClick?: (reference: string) => void;
  onReferenceIdsChanged?: (ids: string[]) => void;
  showCursor?: boolean;
  activeReferenceIds?: string[];
}

const MarkdownView = ({
  markdown,
  renderPartials = true,
  showCursor = false,
  onReferenceClick,
  onReferenceIdsChanged,
  activeReferenceIds,
}: MarkdownViewProps) => {
  let truncated = renderPartials ? truncatePartials(markdown) : markdown;
  if (showCursor) {
    truncated += ODO_CURSOR_MARKDOWN;
  }

  const [idTransforms, setIdTransforms] = useState<Record<string, string>>({});
  useEffect(() => {
    const ids = referenceIdsInMarkdown(markdown);
    const newIdTransforms: Record<string, string> = {};
    for (const [index, id] of ids.entries()) {
      newIdTransforms[id] = String(index + 1);
    }
    setIdTransforms(newIdTransforms);
  }, [markdown]);

  useEffect(() => {
    onReferenceIdsChanged?.(Object.keys(idTransforms));
  }, [idTransforms, onReferenceIdsChanged]);

  // const referenceTracker = useRef(new ReferenceTracker());
  return (
    <Markdown
      className="flex flex-col gap-md"
      remarkPlugins={[remarkReference]}
      components={{
        h1: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h1 {...props} className="text-2xl font-bold" />
        ),
        h2: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h2 {...props} className="text-xl font-bold" /> // @ts-ignore
        ),
        h3: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h3 {...props} className="text-lg font-bold" />
        ),
        ol: ({ node, ...props }) => (
          <ol {...props} className="list-decimal list-outside ml-lg" />
        ),
        ul: ({ node, ...props }) => (
          <ul {...props} className="list-disc list-outside ml-lg" />
        ),
        blockquote: ({ node, ...props }) => (
          <blockquote {...props} className="py-sm pl-2m border-l-xs" />
        ),
        a: ({ node, ...props }) => {
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          return <a {...props} className="text-primary underline" />;
        },
        // @ts-ignore - Custom cursor component
        cursor: ({ node, ...props }) => {
          return (
            <span className="inline-block w-thin relative">
              &nbsp;
              <OdoCursor
                showing={true}
                className="absolute bottom-0 -left-thin -top-[2px]"
              />
            </span>
          );
        },
        // @ts-ignore - Custom reference component
        reference: ({ node, ...props }) => {
          const transformed = idTransforms[props.children] ?? props.children;
          return (
            <Reference
              onClick={() => onReferenceClick?.(props.children)}
              active={activeReferenceIds?.includes(props.children)}
            >
              {transformed}
            </Reference>
          );
        },
      }}
    >
      {truncated}
    </Markdown>
  );
};

/**
 * Look for incomplete markdown blocks and truncate them (expecting them to be
 * completed soon.)
 *
 * Supports:
 * - Ids:
 *    - Opening square brackets missing a closing bracket
 */
const truncatePartials = (markdown: string) => {
  // Find the last line (we only truncate incomplete lines)
  let truncated = "";

  // Split the markdown into lines
  const lines = markdown.split("\n");
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (i < lines.length - 1) {
      truncated += line + "\n";
      continue;
    }

    let openings = 0;
    let lastOpen = -1;
    for (let j = 0; j < line.length; j++) {
      if (line[j] === "[") {
        openings++;
        lastOpen = j;
      } else if (line[j] === "]") {
        openings--;
        openings = Math.max(openings, 0);
      }
    }

    if (openings > 0) {
      truncated += line.slice(0, lastOpen) + "\n";
      continue;
    }

    truncated += line;
  }

  return truncated;
};

export default MarkdownView;
